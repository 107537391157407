import React from "react"
import BarHeader from "../components/BarHeader"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { getProject } from "../data/global"
import { useParams } from "react-router-dom"
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SettingsIcon from '@material-ui/icons/Settings'
import StepConnector from '@material-ui/core/StepConnector'
import BackupIcon from '@material-ui/icons/Backup'
import SyncIcon from '@material-ui/icons/Sync'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'
import PieChartIcon from '@material-ui/icons/PieChart'
import Paper from "@material-ui/core/Paper"
import Title from "../components/Title"
import BackNav from "../components/BackNav"

const getTopSteps = () => ['Data Upload', 'Building Machine Learning Models', 'Running on Master List', 'Clustering Datasets', 'Enrichment Analysis', 'Results']

const getBottomSteps = () => ['L1000 Collection', 'L1000 Clustering', 'Cell Painting Collection', 'Cell Painting Clustering', 'Cluster Review']

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
            // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            //     'linear-gradient( 95deg, #91ffa1 0%, #15C55a 50%, #325c9e 100%)'
                'linear-gradient( 95deg, #325c9e 0%, #15C55a 50%, #91ffa1 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
            // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            //     'linear-gradient( 95deg, #91ffa1 0%, #15C55a 50%, #325c9e 100%)',
                'linear-gradient( 95deg, #325c9e 0%, #15C55a 50%, #91ffa1 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        background:
        // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            'linear-gradient( 136deg, #91ffa1 0%, #15C55a 50%, #325c9e 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0% 100%',
        animation: 'fillUp 4s linear infinite'

        // backgroundPosition: '0% 0%',
        // animation: 'fillSide 4s linear infinite'
    },
    completed: {
        backgroundImage:
        // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            'linear-gradient( 136deg, #91ffa1 0%, #15C55a 50%, #325c9e 100%)',
    },
})

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    const icons = {
        1: <BackupIcon />,
        2: <SettingsIcon />,
        3: <SyncIcon />,
        4: <ScatterPlotIcon />,
        5: <EqualizerIcon />,
        6: <PieChartIcon />,
    }

    return (
        <div className={classes.root} style={{ height: 49 }}>
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        </div>
    )
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
}

const stepperUseStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const TopStepper = ({step}) => {
    const classes = stepperUseStyles()
    const activeStep = step
    const steps = getTopSteps()

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}
                     style={{ background: 'none' }}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}

const BottomStepper = () => {
    const classes = stepperUseStyles()
    const activeStep = 3
    const steps = getBottomSteps()

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep} style={{ background: 'none' }}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 100
    },
    circle: {
        width: 300,
        height: 300,
        overflow: 'hidden',
        border: '3px solid black',
        borderRadius: 325,
        marginTop: 50,
        textAlign: 'center',
        background: 'none'
    },
    image: {
        marginLeft: -92,
        marginTop: -58
    }
}))

const Progress = ({step}) => {
    const classes = useStyles()
    const params = useParams()

    step = step === undefined ? 3 : step
    const projectId = params.id

    const project = getProject(projectId)

    if(step === 3 ) {
        return <Box className={classes.root} elevation={3}>
            <BarHeader />
            <Box m={1}>
                <BackNav />
                <Title title={project.title} />

                <TopStepper step={step}/>
                <BottomStepper />
                <Box m={2} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h4" component="h4" style={{ color: 'white' }}>
                        Clustering Cell Painting
                    </Typography>
                    <Typography variant="subtitle1" component="h5" style={{ color: 'white' }}>
                        Finding coherent clusters of compounds within the Cell Painting dataset. Coherent clusters show structural and behavioural similarity, and generate target annotations for downstream processing.
                    </Typography>
                    <Paper className={classes.circle} elevation={3}>
                        <img className={classes.image} alt="Cluster Gif" src="/cluster_3.gif" />
                    </Paper>
                </Box>
            </Box>
        </Box>
    } else {
        return <Box className={classes.root} elevation={3}>
            <BarHeader />
            <Box m={1}>
                <BackNav />
                <Title title={project.title} />
                <TopStepper step={step} />
            </Box>
        </Box>
    }


}

export default Progress
