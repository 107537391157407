import React from "react"
import BarHeader from "../components/BarHeader"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import ProjectCard from "./Project/ProjectSummary"
import NewButton from "../components/NewButton"
import { getProjects } from "../data/global"
import Title from "../components/Title"
import Footer from "../components/Footer"

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 100
    }
}))

const Projects = ({projects}) => {
    return (<Grid item sm={4} ><Grid container spacing={3} >
        {projects.map(project =>
        <Grid item xs={12} key={project.title}>
            <ProjectCard {...project} />
        </Grid>
        )}
    </Grid></Grid>)
}

const ProjectGrid = () => {
    const projects = getProjects()

    const completedProjects = projects.filter(proj => proj.status === 'completed')
    const runningProjects = projects.filter(proj => proj.status === 'running')
    const createdProjects = projects.filter(proj => proj.status === 'created')

    return(
      <Grid container spacing={3}>
          <Projects projects={completedProjects}/>
          <Projects projects={runningProjects}/>
          <Projects projects={createdProjects}/>
      </Grid>
    )
}


const Landing = () => {
    const classes = useStyles()
    return <Box className={classes.root} elevation={3}>
        <BarHeader />
        <Box m={1}>
            <Title title='Projects' />
            <NewButton />
        </Box>
        <Box p={2}>
            <ProjectGrid />
        </Box>
        <Footer/>
    </Box>

}

export default Landing
