import Progress from './Progress'
import React from 'react'
import { useParams } from 'react-router-dom'

const StepProgress = () => {
  const params = useParams()
  const stepNumber = params.stepNumber
  return <Progress step={parseInt(stepNumber)} />
}

export default StepProgress
