import { TARGET_INFO } from "../../data/target"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import React, { useState } from "react"
import * as PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Tooltip from "@material-ui/core/Tooltip"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    link: {
        color: '#556cd6'
    },
    expansionPanelSummary: {
        background: '#f9f8ec'
    }
}))

const GeneExpansionPanel = ({ targetInfo }) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h5" component="h5">Description</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography variant="body2" component="p" gutterBottom>
                        {targetInfo.description}
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" component="h5">GO Terms</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <GoTerms goTerms={targetInfo.go_terms} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

const GoTerm = ({ goTerm }) => {
    const classes = useStyles()

    return <Tooltip title={goTerm.definition}>
        <Typography variant="body1" component="p">
            <Link className={classes.link}
                  target="_blank"
                  href={`http://amigo.geneontology.org/amigo/term/${goTerm.id}`}>{goTerm.id}</Link> - {goTerm.name}
        </Typography>
    </Tooltip>
}
const SymbolIds = props => {

    const classes = useStyles()

    return (<Box m={2}>
        <TableContainer>
            <Table style={{ width: "fit-content" }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>HGNC</TableCell>
                        <TableCell>Entrez</TableCell>
                        <TableCell>Ensembl</TableCell>
                        <TableCell>UniProt</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Link
                                className={classes.link}
                                href={`https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/${props.targetInfo.hgnc}`}
                                target="_blank">
                                {props.targetInfo.hgnc}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <Link className={classes.link}
                                  href={`https://www.ncbi.nlm.nih.gov/gene?cmd=Retrieve&dopt=full_report&list_uids=${props.targetInfo.entrez}`}
                                  target="_blank"> {props.targetInfo.entrez}</Link></TableCell>
                        <TableCell>
                            <Link className={classes.link}
                                  href={`http://useast.ensembl.org/Homo_sapiens/Gene/Summary?g=${props.targetInfo.ensembl}`}
                                  target="_blank"> {props.targetInfo.ensembl}</Link></TableCell>
                        <TableCell>
                            <Link className={classes.link}
                                  href={`https://www.uniprot.org/uniprot/${props.targetInfo.uniprot}`}
                                  target="_blank"> {props.targetInfo.uniprot}</Link></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Box>)
}

SymbolIds.propTypes = { targetInfo: PropTypes.any }
const GoTerms = ({ goTerms }) => {

    let cellularComponents = goTerms.cellular_component
    let biologicalProcesses = goTerms.biological_process
    let molecularFunctions = goTerms.molecular_function

    if (cellularComponents.length < 1 && biologicalProcesses.length < 1 && molecularFunctions.length < 1) {
        return <div>No GO Terms</div>
    }

    return (<Grid container={12} spacing={3}>
        <Grid item xs={4}>
            <Typography variant="h6" component="h6" gutterBottom>
                Cellular Component
            </Typography>
            {cellularComponents.map(goTerm => <GoTerm key={goTerm.id} goTerm={goTerm} />)}
        </Grid>
        <Grid item xs={4}>
            <Typography variant="h6" component="h6" gutterBottom>
                Biological Process
            </Typography>
            {biologicalProcesses.map(goTerm => <GoTerm key={goTerm.id} goTerm={goTerm} />)}
        </Grid>
        <Grid item xs={4}>
            <Typography variant="h6" component="h6" gutterBottom>
                Molecular Function
            </Typography>
            {molecularFunctions.map(goTerm => <GoTerm key={goTerm.id} goTerm={goTerm} />)}
        </Grid>
    </Grid>)
}

const GeneInformation = ({ symbol }) => {

    // Keep track of prior target information so the collapse animiation doesn't look funny
    const [lastTargetInfo, setLastTargetInfo] = useState()
    const [lastSymbol, setLastSymbol] = useState()
    let targetInfo

    // Render something so the collapse animation works
    if (symbol === undefined) {
        // If there is no symbol render the last go term while the animation finishes (if there was one) otherwise just
        // render a large white div - this is required so the first expand animation works properly
        if (lastTargetInfo !== undefined) {
            targetInfo = lastTargetInfo
        } else {
            return <div style={{ height: 1000 }}></div>
        }
    } else {
        targetInfo = TARGET_INFO[symbol]

        if (symbol !== lastSymbol) {
            setLastTargetInfo(targetInfo)
            setLastSymbol(symbol)
        }
    }

    return (
        <div>
            <Typography variant="h4" component="h4" gutterBottom>
                {lastSymbol}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
                {targetInfo.name}
            </Typography>

            <SymbolIds targetInfo={targetInfo} />

            <GeneExpansionPanel targetInfo={targetInfo} />
        </div>
    )
}

export default GeneInformation
