import React, { useCallback, useMemo } from "react"
import { useDropzone } from "react-dropzone"

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#898989',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#717171',
    outline: 'none',
    transition: 'border .24s ease-in-out'
}
const activeStyle = {
    borderColor: '#2196f3'
}
const acceptStyle = {
    borderColor: '#00e676'
}
const rejectStyle = {
    borderColor: '#000000'
}

function createData(headers, array) {
    const data = {}
    headers.forEach((header, i) => data[header] = array[i])
    return data
}

const PhenotypicDataUpload = ({ setHeaders, setRows }) => {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                console.log(binaryStr)
                const rows = binaryStr.split('\n')
                const headers = rows.shift().split(',')
                setHeaders(headers)

                // Only show the first 10 rows
                const limitedRows = rows.splice(0, 10)
                const formattedRows = limitedRows.map(row => createData(headers, row.split(',')))
                setRows(formattedRows)
            }
            reader.readAsText(file)
        })

    }, [setHeaders, setRows])
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ onDrop: onDrop, accept: ['.smi'] })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ])

    return (
        <div {...getRootProps({ style })} >
            <input {...getInputProps()} />
            <p>Drag file here, or click to select files</p>
        </div>
    )
}

export default PhenotypicDataUpload
