import React from "react"

import { makeStyles } from '@material-ui/styles'
import Button from "@material-ui/core/Button"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'left',
        marginBottom: 25,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        width: "100%",
        left: 0
    },
    logo: {
        maxWidth: '-webkit-fill-available',
        width: 200,
        marginLeft: 20,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 448
        }
    },
    loginButton: {
        float: 'right'
    }
}))

const BarHeader = (props) => {
    const classes = useStyles()
    let history = useHistory()

    return (
        <div className={classes.container}>
            <a href='/landing'><img src='/BioS-Logo.png' alt='Biosymetrics Logo' className={classes.logo} /></a>
            <Button
                size='large'
                className={classes.loginButton} onClick={() => history.push('/login')}>Logout</Button>
        </div>
    )
}

export default BarHeader
