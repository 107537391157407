import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles"
import Fab from "@material-ui/core/Fab"
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
        float: 'right'
    },
}))


const NewButton = () => {
    const classes = useStyles()
    let history = useHistory()

    return (
        <Fab className={classes.root} color="primary" aria-label="back" onClick={() => history.push('/project')}>
            <AddIcon />
        </Fab>
    )
}

export default NewButton
