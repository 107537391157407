export const TARGET_INFO = {
    "MAPK13": {
        "name": "mTOR signaling pathway",
        "description": "This gene encodes a member of the mitogen-activated protein (MAP) kinase family. MAP kinases act as an integration point for multiple biochemical signals, and are involved in a wide variety of cellular processes such as proliferation, differentiation, transcription regulation and development. The encoded protein is a p38 MAP kinase and is activated by proinflammatory cytokines and cellular stress. Substrates of the encoded protein include the transcription factor ATF2 and the microtubule dynamics regulator stathmin. Alternatively spliced transcript variants have been observed for this gene. [provided by RefSeq, Jul 2012].",
        "entrez": "5603",
        "ensembl": "ENSG00000156711",
        "uniprot": "O15264",
        "hgnc": "6875",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "transcription, DNA-templated",
                "definition": "The cellular synthesis of RNA on a template of DNA. [GOC:jl, GOC:txnOH]",
                "id": "GO:0006351"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "response to osmotic stress",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating an increase or decrease in the concentration of solutes outside the organism or cell. [GOC:jl]",
                "id": "GO:0006970"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "regulation of gene expression",
                "definition": "Any process that modulates the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010468"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "positive regulation of interleukin-6 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-6 production. [GOC:mah]",
                "id": "GO:0032755"
            }, {
                "name": "cellular response to UV",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an ultraviolet radiation (UV light) stimulus. Ultraviolet radiation is electromagnetic radiation with a wavelength in the range of 10 to 380 nanometers. [GOC:mah]",
                "id": "GO:0034644"
            }, {
                "name": "vascular endothelial growth factor receptor signaling pathway",
                "definition": "Any series of molecular signals initiated by the binding of an extracellular ligand to a vascular endothelial growth factor receptor (VEGFR) located on the surface of the receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling, PR:000001971]",
                "id": "GO:0048010"
            }, {
                "name": "positive regulation of inflammatory response",
                "definition": "Any process that activates or increases the frequency, rate or extent of the inflammatory response. [GOC:ai]",
                "id": "GO:0050729"
            }, {
                "name": "stress-activated MAPK cascade",
                "definition": "A series of molecular signals in which a stress-activated MAP kinase cascade relays one or more of the signals; MAP kinase cascades involve at least three protein kinase activities and culminate in the phosphorylation and activation of a MAP kinase. [GOC:ai, PMID:15936270]",
                "id": "GO:0051403"
            }, {
                "name": "cellular response to hydrogen peroxide",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hydrogen peroxide (H2O2) stimulus. [CHEBI:16240, GOC:mah]",
                "id": "GO:0070301"
            }, {
                "name": "cellular response to interleukin-1",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an interleukin-1 stimulus. [GOC:mah]",
                "id": "GO:0071347"
            }, {
                "name": "cellular response to sorbitol",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a sorbitol stimulus. [CHEBI:30911, GOC:mah]",
                "id": "GO:0072709"
            }, {
                "name": "cellular response to anisomycin",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an anisomycin stimulus. [CHEBI:338412, GOC:mah]",
                "id": "GO:0072740"
            }, {
                "name": "cellular response to sodium arsenite",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a sodium arsenite stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:18674524]",
                "id": "GO:1903936"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "MAP kinase activity",
                "definition": "Catalysis of the reaction: protein + ATP = protein phosphate + ADP. This reaction is the phosphorylation of proteins. Mitogen-activated protein kinase; a family of protein kinases that perform a crucial step in relaying signals from the plasma membrane to the nucleus. They are activated by a wide range of proliferation- or differentiation-inducing signals; activation is strong with agonists such as polypeptide growth factors and tumor-promoting phorbol esters, but weak (in most cell backgrounds) by stress stimuli. [GOC:ma, ISBN:0198547684]",
                "id": "GO:0004707"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.24",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }]
        }
    }, "MAPK12": {
        "name": "mTOR signaling pathway",
        "description": "Activation of members of the mitogen-activated protein kinase family is a major mechanism for transduction of extracellular signals. Stress-activated protein kinases are one subclass of MAP kinases. The protein encoded by this gene functions as a signal transducer during differentiation of myoblasts to myotubes. [provided by RefSeq, Jul 2008].",
        "entrez": "6300",
        "ensembl": "ENSG00000188130",
        "uniprot": "P53778",
        "hgnc": "6874",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "transcription, DNA-templated",
                "definition": "The cellular synthesis of RNA on a template of DNA. [GOC:jl, GOC:txnOH]",
                "id": "GO:0006351"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "DNA damage induced protein phosphorylation",
                "definition": "The widespread phosphorylation of various molecules, triggering many downstream processes, that occurs in response to the detection of DNA damage. [GOC:go_curators]",
                "id": "GO:0006975"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "cell cycle arrest",
                "definition": "A regulatory process that halts progression through the cell cycle during one of the normal phases (G1, S, G2, M). [GOC:dph, GOC:mah, GOC:tb]",
                "id": "GO:0007050"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "muscle organ development",
                "definition": "The process whose specific outcome is the progression of the muscle over time, from its formation to the mature structure. The muscle is an organ consisting of a tissue made up of various elongated cells that are specialized to contract and thus to produce movement and mechanical work. [GOC:jid, ISBN:0198506732]",
                "id": "GO:0007517"
            }, {
                "name": "regulation of gene expression",
                "definition": "Any process that modulates the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010468"
            }, {
                "name": "positive regulation of peptidase activity",
                "definition": "Any process that increases the frequency, rate or extent of peptidase activity, the hydrolysis of peptide bonds within proteins. [GOC:dph, GOC:tb]",
                "id": "GO:0010952"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "myoblast differentiation",
                "definition": "The process in which a relatively unspecialized cell acquires specialized features of a myoblast. A myoblast is a mononucleate cell type that, by fusion with other myoblasts, gives rise to the myotubes that eventually develop into striated muscle fibers. [CL:0000056, GOC:go_curators, GOC:mtg_muscle]",
                "id": "GO:0045445"
            }, {
                "name": "negative regulation of cell cycle",
                "definition": "Any process that stops, prevents or reduces the rate or extent of progression through the cell cycle. [GOC:dph, GOC:go_curators, GOC:tb]",
                "id": "GO:0045786"
            }, {
                "name": "vascular endothelial growth factor receptor signaling pathway",
                "definition": "Any series of molecular signals initiated by the binding of an extracellular ligand to a vascular endothelial growth factor receptor (VEGFR) located on the surface of the receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling, PR:000001971]",
                "id": "GO:0048010"
            }, {
                "name": "positive regulation of muscle cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of muscle cell differentiation. [CL:0000187, GOC:ai]",
                "id": "GO:0051149"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "MAP kinase activity",
                "definition": "Catalysis of the reaction: protein + ATP = protein phosphate + ADP. This reaction is the phosphorylation of proteins. Mitogen-activated protein kinase; a family of protein kinases that perform a crucial step in relaying signals from the plasma membrane to the nucleus. They are activated by a wide range of proliferation- or differentiation-inducing signals; activation is strong with agonists such as polypeptide growth factors and tumor-promoting phorbol esters, but weak (in most cell backgrounds) by stress stimuli. [GOC:ma, ISBN:0198547684]",
                "id": "GO:0004707"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.24",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }]
        }
    }, "VCP": {
        "name": "valosin containing protein",
        "description": "This gene encodes a member of the AAA ATPase family of proteins. The encoded protein plays a role in protein degradation, intracellular membrane fusion, DNA repair and replication, regulation of the cell cycle, and activation of the NF-kappa B pathway. This protein forms a homohexameric complex that interacts with a variety of cofactors and extracts ubiquitinated proteins from lipid membranes or protein complexes. Mutations in this gene cause IBMPFD (inclusion body myopathy with paget disease of bone and frontotemporal dementia), ALS (amyotrophic lateral sclerosis) and Charcot-Marie-Tooth disease in human patients. [provided by RefSeq, Aug 2017].",
        "entrez": "7415",
        "ensembl": "ENSG00000165280",
        "uniprot": "P55072",
        "hgnc": "12666",
        "go_terms": {
            "biological_process": [{
                "name": "DNA repair",
                "definition": "The process of restoring DNA after damage. Genomes are subject to damage by chemical and physical agents in the environment (e.g. UV and ionizing radiations, chemical mutagens, fungal and bacterial toxins, etc.) and by free radicals or alkylating agents endogenously generated in metabolism. DNA is also damaged because of errors during its replication. A variety of different DNA repair pathways have been reported that include direct reversal, base excision repair, nucleotide excision repair, photoreactivation, bypass, double-strand break repair pathway, and mismatch repair pathway. [PMID:11563486]",
                "id": "GO:0006281"
            }, {
                "name": "double-strand break repair",
                "definition": "The repair of double-strand breaks in DNA via homologous and nonhomologous mechanisms to reform a continuous DNA helix. [GOC:elh]",
                "id": "GO:0006302"
            }, {
                "name": "protein folding",
                "definition": "The process of assisting in the covalent and noncovalent assembly of single chain polypeptides or multisubunit complexes into the correct tertiary structure. [GOC:go_curators, GOC:rb]",
                "id": "GO:0006457"
            }, {
                "name": "protein methylation",
                "definition": "The addition of a methyl group to a protein amino acid. A methyl group is derived from methane by the removal of a hydrogen atom. [GOC:ai]",
                "id": "GO:0006479"
            }, {
                "name": "ubiquitin-dependent protein catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of a protein or peptide by hydrolysis of its peptide bonds, initiated by the covalent attachment of a ubiquitin group, or multiple ubiquitin groups, to the protein. [GOC:go_curators]",
                "id": "GO:0006511"
            }, {
                "name": "NADH metabolic process",
                "definition": "The chemical reactions and pathways involving reduced nicotinamide adenine dinucleotide (NADH), a coenzyme present in most living cells and derived from the B vitamin nicotinic acid. [GOC:jl, ISBN:0618254153]",
                "id": "GO:0006734"
            }, {
                "name": "ER to Golgi vesicle-mediated transport",
                "definition": "The directed movement of substances from the endoplasmic reticulum (ER) to the Golgi, mediated by COP II vesicles. Small COP II coated vesicles form from the ER and then fuse directly with the cis-Golgi. Larger structures are transported along microtubules to the cis-Golgi. [GOC:ascb_2009, GOC:dph, GOC:jp, GOC:tb, ISBN:0716731363]",
                "id": "GO:0006888"
            }, {
                "name": "autophagy",
                "definition": "The cellular catabolic process in which cells digest parts of their own cytoplasm; allows for both recycling of macromolecular constituents under conditions of cellular stress and remodeling the intracellular structure for cell differentiation. [GOC:autophagy, ISBN:0198547684, PMID:11099404, PMID:9412464]",
                "id": "GO:0006914"
            }, {
                "name": "activation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that initiates the activity of the inactive enzyme cysteine-type endopeptidase in the context of an apoptotic process. [GOC:al, GOC:dph, GOC:jl, GOC:mtg_apoptosis, GOC:tb, PMID:14744432, PMID:18328827, Wikipedia:Caspase]",
                "id": "GO:0006919"
            }, {
                "name": "cellular response to DNA damage stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating damage to its DNA from environmental insults or errors during metabolism. [GOC:go_curators]",
                "id": "GO:0006974"
            }, {
                "name": "proteasomal protein catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of a protein or peptide by hydrolysis of its peptide bonds that is mediated by the proteasome. [GOC:tb]",
                "id": "GO:0010498"
            }, {
                "name": "positive regulation of mitochondrial membrane potential",
                "definition": "Any process that activates or increases the frequency, rate or extent of establishment or extent of a mitochondrial membrane potential, the electric potential existing across any mitochondrial membrane arising from charges in the membrane itself and from the charges present in the media on either side of the membrane. [GOC:dph, GOC:tb]",
                "id": "GO:0010918"
            }, {
                "name": "macroautophagy",
                "definition": "The major inducible pathway for the general turnover of cytoplasmic constituents in eukaryotic cells, it is also responsible for the degradation of active cytoplasmic enzymes and organelles during nutrient starvation. Macroautophagy involves the formation of double-membrane-bounded autophagosomes which enclose the cytoplasmic constituent targeted for degradation in a membrane-bounded structure.  Autophagosomes then fuse with a lysosome (or vacuole) releasing single-membrane-bounded autophagic bodies that are then degraded within the lysosome (or vacuole). Some types of macroautophagy, e.g. pexophagy, mitophagy, involve selective targeting of the targets to be degraded. [PMID:11099404, PMID:12914914, PMID:15798367, PMID:16973210, PMID:20159618, PMID:9412464]",
                "id": "GO:0016236"
            }, {
                "name": "protein ubiquitination",
                "definition": "The process in which one or more ubiquitin groups are added to a protein. [GOC:ai]",
                "id": "GO:0016567"
            }, {
                "name": "protein deubiquitination",
                "definition": "The removal of one or more ubiquitin groups from a protein. [GOC:ai]",
                "id": "GO:0016579"
            }, {
                "name": "protein N-linked glycosylation via asparagine",
                "definition": "The glycosylation of protein via the N4 atom of peptidyl-asparagine forming N4-glycosyl-L-asparagine; the most common form is N-acetylglucosaminyl asparagine; N-acetylgalactosaminyl asparagine and N4 glucosyl asparagine also occur. This modification typically occurs in extracellular peptides with an N-X-(ST) motif. Partial modification has been observed to occur with cysteine, rather than serine or threonine, in the third position; secondary structure features are important, and proline in the second or fourth positions inhibits modification. [GOC:jsg, RESID:AA0151, RESID:AA0420, RESID:AA0421]",
                "id": "GO:0018279"
            }, {
                "name": "viral genome replication",
                "definition": "Any process involved directly in viral genome replication, including viral nucleotide metabolism. [ISBN:0781702534]",
                "id": "GO:0019079"
            }, {
                "name": "translesion synthesis",
                "definition": "The replication of damaged DNA by synthesis across a lesion in the template strand; a specialized DNA polymerase or replication complex inserts a defined nucleotide across from the lesion which allows DNA synthesis to continue beyond the lesion. This process can be mutagenic depending on the damaged nucleotide and the inserted nucleotide. [GOC:elh, GOC:vw, PMID:10535901]",
                "id": "GO:0019985"
            }, {
                "name": "ubiquitin-dependent ERAD pathway",
                "definition": "The series of steps necessary to target endoplasmic reticulum (ER)-resident proteins for degradation by the cytoplasmic proteasome. Begins with recognition of the ER-resident protein, includes retrotranslocation (dislocation) of the protein from the ER to the cytosol, protein ubiquitination necessary for correct substrate transfer, transport of the protein to the proteasome, and ends with degradation of the protein by the cytoplasmic proteasome. [GOC:mah, GOC:rb, PMID:14607247, PMID:19520858]",
                "id": "GO:0030433"
            }, {
                "name": "endoplasmic reticulum unfolded protein response",
                "definition": "The series of molecular signals generated as a consequence of the presence of unfolded proteins in the endoplasmic reticulum (ER) or other ER-related stress; results in changes in the regulation of transcription and translation. [GOC:mah, PMID:12042763]",
                "id": "GO:0030968"
            }, {
                "name": "retrograde protein transport, ER to cytosol",
                "definition": "The directed movement of unfolded or misfolded proteins from the endoplasmic reticulum to the cytosol through the translocon. [PMID:11994744]",
                "id": "GO:0030970"
            }, {
                "name": "positive regulation of protein complex assembly",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein complex assembly. [GOC:mah]",
                "id": "GO:0031334"
            }, {
                "name": "positive regulation of proteasomal ubiquitin-dependent protein catabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the breakdown of a protein or peptide by hydrolysis of its peptide bonds, initiated by the covalent attachment of ubiquitin, and mediated by the proteasome. [GOC:mah]",
                "id": "GO:0032436"
            }, {
                "name": "endosome to lysosome transport via multivesicular body sorting pathway",
                "definition": "The directed movement of substances from endosomes to lysosomes by a pathway in which molecules are sorted into multivesicular bodies, which then fuse with the lysosome. [GOC:mah, PMID:12461556, PMID:16689637]",
                "id": "GO:0032510"
            }, {
                "name": "protein hexamerization",
                "definition": "The formation of a protein hexamer, a macromolecular structure consisting of six noncovalently associated identical or nonidentical subunits. [GOC:ecd]",
                "id": "GO:0034214"
            }, {
                "name": "ERAD pathway",
                "definition": "The protein catabolic pathway which targets endoplasmic reticulum (ER)-resident proteins for degradation by the cytoplasmic proteasome. It begins with recognition of the ER-resident protein, includes retrotranslocation (dislocation) of the protein from the ER to the cytosol, protein modifications necessary for correct substrate transfer (e.g. ubiquitination), transport of the protein to the proteasome, and ends with degradation of the protein by the cytoplasmic proteasome. [GOC:bf, GOC:PARL, PMID:20940304, PMID:21969857]",
                "id": "GO:0036503"
            }, {
                "name": "regulation of apoptotic process",
                "definition": "Any process that modulates the occurrence or rate of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0042981"
            }, {
                "name": "proteasome-mediated ubiquitin-dependent protein catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of a protein or peptide by hydrolysis of its peptide bonds, initiated by the covalent attachment of ubiquitin, and mediated by the proteasome. [GOC:go_curators]",
                "id": "GO:0043161"
            }, {
                "name": "neutrophil degranulation",
                "definition": "The regulated exocytosis of secretory granules containing preformed mediators such as proteases, lipases, and inflammatory mediators by a neutrophil. [ISBN:0781735149]",
                "id": "GO:0043312"
            }, {
                "name": "establishment of protein localization",
                "definition": "The directed movement of a protein to a specific location. [GOC:bf]",
                "id": "GO:0045184"
            }, {
                "name": "positive regulation of protein catabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the breakdown of a protein by the destruction of the native, active configuration, with or without the hydrolysis of peptide bonds. [GOC:go_curators]",
                "id": "GO:0045732"
            }, {
                "name": "ATP metabolic process",
                "definition": "The chemical reactions and pathways involving ATP, adenosine triphosphate, a universally important coenzyme and enzyme regulator. [GOC:go_curators]",
                "id": "GO:0046034"
            }, {
                "name": "protein homooligomerization",
                "definition": "The process of creating protein oligomers, compounds composed of a small number, usually between three and ten, of identical component monomers. Oligomers may be formed by the polymerization of a number of monomers or the depolymerization of a large protein polymer. [GOC:ai]",
                "id": "GO:0051260"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "endoplasmic reticulum stress-induced pre-emptive quality control",
                "definition": "The response to endoplasimic reticulum stress in which nascent proteins are degraded by attenuation of their translocation into the ER followed by rerouting to the cytosol without cleavage of the signal peptide, and subsequent degradation by the proteasome. [PMID:17129784, PMID:26565908]",
                "id": "GO:0061857"
            }, {
                "name": "aggresome assembly",
                "definition": "The aggregation, arrangement and bonding together of a set of components to form an aggresome; requires the microtubule cytoskeleton and dynein. [GOC:BHF, GOC:rl, PMID:14675537]",
                "id": "GO:0070842"
            }, {
                "name": "error-free translesion synthesis",
                "definition": "The conversion of DNA-damage induced single-stranded gaps into large molecular weight DNA after replication by using a specialized DNA polymerase or replication complex to insert a defined nucleotide across the lesion. This process does not remove the replication-blocking lesions but does not causes an increase in the endogenous mutation level. For S. cerevisiae, RAD30 encodes DNA polymerase eta, which incorporates two adenines. When incorporated across a thymine-thymine dimer, it does not increase the endogenous mutation level. [GOC:elh]",
                "id": "GO:0070987"
            }, {
                "name": "ER-associated misfolded protein catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of misfolded proteins transported from the endoplasmic reticulum and targeted to cytoplasmic proteasomes for degradation. [GOC:mah, GOC:vw, PMID:14607247, PMID:19520858]",
                "id": "GO:0071712"
            }, {
                "name": "flavin adenine dinucleotide catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of flavin adenine dinucleotide, which acts as a coenzyme or prosthetic group of various flavoprotein oxidoreductase enzymes. [CHEBI:24040, GOC:mah]",
                "id": "GO:0072389"
            }, {
                "name": "positive regulation of canonical Wnt signaling pathway",
                "definition": "Any process that increases the rate, frequency, or extent of the Wnt signaling pathway through beta-catenin, the series of molecular signals initiated by binding of a Wnt protein to a frizzled family receptor on the surface of the target cell, followed by propagation of the signal via beta-catenin, and ending with a change in transcription of target genes. [GOC:tb]",
                "id": "GO:0090263"
            }, {
                "name": "autophagosome maturation",
                "definition": "Removal of PI3P and Atg8/LC3 after the closure of the phagophore and before the fusion with the endosome/lysosome (e.g. mammals and insects) or vacuole (yeast), and that very likely destabilizes other Atg proteins and thus enables their efficient dissociation and recycling. [GOC:autophagy, GOC:lf, PMID:28077293]",
                "id": "GO:0097352"
            }, {
                "name": "positive regulation of protein K63-linked deubiquitination",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein K63-linked deubiquitination. [GO_REF:0000058, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:22970133]",
                "id": "GO:1903006"
            }, {
                "name": "positive regulation of Lys63-specific deubiquitinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of Lys63-specific deubiquitinase activity. [GO_REF:0000059, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:22970133]",
                "id": "GO:1903007"
            }, {
                "name": "regulation of aerobic respiration",
                "definition": "Any process that modulates the frequency, rate or extent of aerobic respiration. [GO_REF:0000058, GOC:TermGenie, PMID:19266076]",
                "id": "GO:1903715"
            }, {
                "name": "positive regulation of oxidative phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of oxidative phosphorylation. [GO_REF:0000058, GOC:TermGenie, PMID:10225962]",
                "id": "GO:1903862"
            }, {
                "name": "positive regulation of ubiquitin-specific protease activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of ubiquitin-specific protease (deubiquitinase) activity. [GOC:obol]",
                "id": "GO:2000158"
            }, {
                "name": "positive regulation of ATP biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of ATP biosynthetic process. [GOC:obol]",
                "id": "GO:2001171"
            }],
            "cellular_component": [{
                "name": "proteasome complex",
                "definition": "A large multisubunit complex which catalyzes protein degradation, found in eukaryotes, archaea and some bacteria. In eukaryotes, this complex consists of the barrel shaped proteasome core complex and one or two associated proteins or complexes that act in regulating entry into or exit from the core. [GOC:rb, http://en.wikipedia.org/wiki/Proteasome]",
                "id": "GO:0000502"
            }, {
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "lipid droplet",
                "definition": "An intracellular non-membrane-bounded organelle comprising a matrix of coalesced lipids surrounded by a phospholipid monolayer. May include associated proteins. [GOC:mah, GOC:tb]",
                "id": "GO:0005811"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "macromolecular complex",
                "definition": "A stable assembly of two or more macromolecules, i.e. proteins, nucleic acids, carbohydrates or lipids, in which at least one component is a protein and the constituent parts function together. [GOC:dos, GOC:mah]",
                "id": "GO:0032991"
            }, {
                "name": "VCP-NPL4-UFD1 AAA ATPase complex",
                "definition": "A multiprotein ATPase complex required for the efficient dislocation of ER-lumenal degradation substrates, and their subsequent proteolysis by the proteasome. In budding yeast, this complex includes Cdc48p, Npl4p and Ufd1p proteins. In mammals, this complex includes a hexamer of VCP/p97 (a cytosolic ATPase) and trimers of each of its cofactors UFD1L and NPL4 (NPLOC4) (e.g. a 6:3:3 stoichiometry). [PMID:11813000, PMID:16179952]",
                "id": "GO:0034098"
            }, {
                "name": "secretory granule lumen",
                "definition": "The volume enclosed by the membrane of a secretory granule. [GOC:rph]",
                "id": "GO:0034774"
            }, {
                "name": "azurophil granule lumen",
                "definition": "The volume enclosed by the membrane of an azurophil granule, a primary lysosomal granule found in neutrophil granulocytes that contains a wide range of hydrolytic enzymes and is released into the extracellular fluid. [GOC:bf, PMID:17152095]",
                "id": "GO:0035578"
            }, {
                "name": "site of double-strand break",
                "definition": "A region of a chromosome at which a DNA double-strand break has occurred. DNA damage signaling and repair proteins accumulate at the lesion to respond to the damage and repair the DNA to form a continuous DNA helix. [GOC:bf, GOC:mah, GOC:vw, PMID:20096808, PMID:21035408]",
                "id": "GO:0035861"
            }, {
                "name": "Derlin-1 retrotranslocation complex",
                "definition": "A protein complex that functions in the retrotranslocation step of ERAD (ER-associated protein degradation), and includes at its core Derlin-1 oligomers forming a retrotranslocation channel. [GOC:bf, GOC:PARL, PMID:15215856, PMID:16186510]",
                "id": "GO:0036513"
            }, {
                "name": "myelin sheath",
                "definition": "An electrically insulating fatty layer that surrounds the axons of many neurons. It is an outgrowth of glial cells: Schwann cells supply the myelin for peripheral neurons while oligodendrocytes supply it to those of the central nervous system. [GOC:cjm, GOC:jl, NIF_Subcellular:sao-593830697, Wikipedia:Myelin]",
                "id": "GO:0043209"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }, {
                "name": "ficolin-1-rich granule lumen",
                "definition": "Any membrane-enclosed lumen that is part of a ficolin-1-rich granule. [GO_REF:0000064, GOC:TermGenie, PMID:23650620]",
                "id": "GO:1904813"
            }, {
                "name": "ATPase complex",
                "definition": "A protein complex which is capable of ATPase activity. [GO_REF:0000088, GOC:bhm, GOC:TermGenie, PMID:9606181]",
                "id": "GO:1904949"
            }, {
                "name": "VCP-NSFL1C complex",
                "definition": "A protein complex between the ATPase VCP (p97) and its cofactor p47 (NSFL1C). In human, the protein complex consists of one homotrimer of NSFL1C/p47 per homohexamer of VCP/p97. [GOC:bf, GOC:PARL, PMID:9214505]",
                "id": "GO:1990730"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "RNA binding",
                "definition": "Interacting selectively and non-covalently with an RNA molecule or a portion thereof. [GOC:jl, GOC:mah]",
                "id": "GO:0003723"
            }, {
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "lipid binding",
                "definition": "Interacting selectively and non-covalently with a lipid. [GOC:ai]",
                "id": "GO:0008289"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "ATPase activity",
                "definition": "Catalysis of the reaction: ATP + H2O = ADP + phosphate + 2 H+. May or may not be coupled to another reaction. [EC:3.6.1.3, GOC:jl]",
                "id": "GO:0016887"
            }, {
                "name": "protein phosphatase binding",
                "definition": "Interacting selectively and non-covalently with any protein phosphatase. [GOC:jl]",
                "id": "GO:0019903"
            }, {
                "name": "protein domain specific binding",
                "definition": "Interacting selectively and non-covalently with a specific domain of a protein. [GOC:go_curators]",
                "id": "GO:0019904"
            }, {
                "name": "polyubiquitin modification-dependent protein binding",
                "definition": "Interacting selectively and non-covalently with a protein upon poly-ubiquitination of the target protein. [GOC:pg]",
                "id": "GO:0031593"
            }, {
                "name": "ubiquitin protein ligase binding",
                "definition": "Interacting selectively and non-covalently with a ubiquitin protein ligase enzyme, any of the E3 proteins. [GOC:vp]",
                "id": "GO:0031625"
            }, {
                "name": "deubiquitinase activator activity",
                "definition": "Increases the activity of deubiquitinase, an enzyme that catalyzes the hydrolysis of various forms of polymeric ubiquitin sequences. [GOC:sart, ISBN:0120793709]",
                "id": "GO:0035800"
            }, {
                "name": "K48-linked polyubiquitin modification-dependent protein binding",
                "definition": "Interacting selectively and non-covalently with a protein upon poly-ubiquitination formed by linkages between lysine residues at position 48 in the target protein. [GOC:al, PMID:20739285]",
                "id": "GO:0036435"
            }, {
                "name": "MHC class I protein binding",
                "definition": "Interacting selectively and non-covalently with major histocompatibility complex class I molecules; a set of molecules displayed on cell surfaces that are responsible for lymphocyte recognition and antigen presentation. [GOC:jl]",
                "id": "GO:0042288"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "ADP binding",
                "definition": "Interacting selectively and non-covalently with ADP, adenosine 5'-diphosphate. [GOC:jl]",
                "id": "GO:0043531"
            }, {
                "name": "ubiquitin-like protein ligase binding",
                "definition": "Interacting selectively and non-covalently with a ubiquitin-like protein ligase, such as ubiquitin-ligase. [GOC:jl]",
                "id": "GO:0044389"
            }, {
                "name": "BAT3 complex binding",
                "definition": "Interacting selectively and non-covalently with a BAT3 complex. [GO_REF:000102, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:23246001]",
                "id": "GO:1904288"
            }, {
                "name": "ubiquitin-specific protease binding",
                "definition": "Interacting selectively and non-covalently with a ubiquitin-specific protease. [GOC:bf, GOC:PARL, PMID:24063750]",
                "id": "GO:1990381"
            }]
        },
        "kegg_pathway": {}
    }, "NOD2": {
        "name": "nucleotide binding oligomerization domain containing 2",
        "description": "This gene is a member of the Nod1/Apaf-1 family and encodes a protein with two caspase recruitment (CARD) domains and six leucine-rich repeats (LRRs). The protein is primarily expressed in the peripheral blood leukocytes. It plays a role in the immune response to intracellular bacterial lipopolysaccharides (LPS) by recognizing the muramyl dipeptide (MDP) derived from them and activating the NFKB protein. Mutations in this gene have been associated with Crohn disease and Blau syndrome. Alternatively spliced transcript variants encoding distinct isoforms have been found for this gene. [provided by RefSeq, Jun 2014].",
        "entrez": "64127",
        "ensembl": "ENSG00000167207",
        "uniprot": "Q9HC29",
        "hgnc": "5331",
        "go_terms": {
            "biological_process": [{
                "name": "activation of MAPK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase (MAPK). [PMID:9561267]",
                "id": "GO:0000187"
            }, {
                "name": "cytokine production involved in immune response",
                "definition": "The appearance of a cytokine due to biosynthesis or secretion following a cellular stimulus contributing to an immune response, resulting in an increase in its intracellular or extracellular levels. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002367"
            }, {
                "name": "cytokine secretion involved in immune response",
                "definition": "The regulated release of cytokines from a cell that contributes to an immune response. [GOC:add, ISBN:0781735149]",
                "id": "GO:0002374"
            }, {
                "name": "immune system process",
                "definition": "Any process involved in the development or functioning of the immune system, an organismal system for calibrated responses to potential internal or invasive threats. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0002376"
            }, {
                "name": "positive regulation of dendritic cell antigen processing and presentation",
                "definition": "Any process that activates or increases the frequency, rate, or extent of dendritic cell antigen processing and presentation. [GOC:add]",
                "id": "GO:0002606"
            }, {
                "name": "positive regulation of dendritic cell cytokine production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of dendritic cell cytokine production. [GOC:add]",
                "id": "GO:0002732"
            }, {
                "name": "positive regulation of type 2 immune response",
                "definition": "Any process that activates or increases the frequency, rate, or extent of a type 2 immune response. [GOC:add]",
                "id": "GO:0002830"
            }, {
                "name": "defense response",
                "definition": "Reactions, triggered in response to the presence of a foreign body or the occurrence of an injury, which result in restriction of damage to the organism attacked or prevention/recovery from the infection caused by the attack. [GOC:go_curators]",
                "id": "GO:0006952"
            }, {
                "name": "JNK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a JNK (a MAPK), a JNKK (a MAPKK) and a JUN3K (a MAP3K). The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:signaling, PMID:11790549, PMID:20811974]",
                "id": "GO:0007254"
            }, {
                "name": "response to nutrient",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a nutrient stimulus. [GOC:go_curators]",
                "id": "GO:0007584"
            }, {
                "name": "positive regulation of cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008284"
            }, {
                "name": "detection of biotic stimulus",
                "definition": "The series of events in which a biotic stimulus, one caused or produced by a living organism, is received and converted into a molecular signal. [GOC:hb]",
                "id": "GO:0009595"
            }, {
                "name": "detection of bacterium",
                "definition": "The series of events in which a stimulus from a bacterium is received and converted into a molecular signal. [GOC:hb]",
                "id": "GO:0016045"
            }, {
                "name": "protein deubiquitination",
                "definition": "The removal of one or more ubiquitin groups from a protein. [GOC:ai]",
                "id": "GO:0016579"
            }, {
                "name": "maintenance of gastrointestinal epithelium",
                "definition": "Protection of epithelial surfaces of the gastrointestinal tract from proteolytic and caustic digestive agents. [GOC:mah]",
                "id": "GO:0030277"
            }, {
                "name": "response to muramyl dipeptide",
                "definition": "Any process that results in a change in state or activity of an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a muramyl dipeptide stimulus. Muramyl dipeptide is derived from peptidoglycan. [GOC:add]",
                "id": "GO:0032495"
            }, {
                "name": "detection of muramyl dipeptide",
                "definition": "The series of events in which a muramyl dipeptide stimulus is received by a cell and converted into a molecular signal. Muramyl dipeptide is derived from peptidoglycan. [GOC:rl, PMID:15998797]",
                "id": "GO:0032498"
            }, {
                "name": "positive regulation of interleukin-1 beta production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-1 beta production. [GOC:mah]",
                "id": "GO:0032731"
            }, {
                "name": "positive regulation of interleukin-10 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-10 production. [GOC:mah]",
                "id": "GO:0032733"
            }, {
                "name": "positive regulation of interleukin-17 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of production of any member of the interleukin-17 family of cytokines. [GOC:add, GOC:mah]",
                "id": "GO:0032740"
            }, {
                "name": "positive regulation of interleukin-6 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-6 production. [GOC:mah]",
                "id": "GO:0032755"
            }, {
                "name": "positive regulation of interleukin-8 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-8 production. [GOC:mah]",
                "id": "GO:0032757"
            }, {
                "name": "positive regulation of tumor necrosis factor production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of tumor necrosis factor production. [GOC:mah]",
                "id": "GO:0032760"
            }, {
                "name": "positive regulation of stress-activated MAPK cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the stress-activated MAPK cascade. [GOC:mah]",
                "id": "GO:0032874"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "defense response to bacterium",
                "definition": "Reactions triggered in response to the presence of a bacterium that act to protect the cell or organism. [GOC:jl]",
                "id": "GO:0042742"
            }, {
                "name": "regulation of apoptotic process",
                "definition": "Any process that modulates the occurrence or rate of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0042981"
            }, {
                "name": "positive regulation of I-kappaB kinase/NF-kappaB signaling",
                "definition": "Any process that activates or increases the frequency, rate or extent of I-kappaB kinase/NF-kappaB signaling. [GOC:jl]",
                "id": "GO:0043123"
            }, {
                "name": "positive regulation of MAP kinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of MAP kinase activity. [GOC:dph, GOC:go_curators]",
                "id": "GO:0043406"
            }, {
                "name": "positive regulation of phosphatidylinositol 3-kinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of phosphatidylinositol 3-kinase activity. [GOC:bf]",
                "id": "GO:0043552"
            }, {
                "name": "innate immune response",
                "definition": "Innate immune responses are defense responses mediated by germline encoded components that directly recognize components of potential pathogens. [GO_REF:0000022, GOC:add, GOC:ebc, GOC:mtg_15nov05, GOC:mtg_sensu]",
                "id": "GO:0045087"
            }, {
                "name": "positive regulation of Notch signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of the Notch signaling pathway. [GOC:go_curators]",
                "id": "GO:0045747"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "positive regulation of JNK cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the JNK cascade. [GOC:bf]",
                "id": "GO:0046330"
            }, {
                "name": "positive regulation of gamma-delta T cell activation",
                "definition": "Any process that activates or increases the frequency, rate or extent of gamma-delta T cell activation. [GOC:ai]",
                "id": "GO:0046645"
            }, {
                "name": "positive regulation of epithelial cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of epithelial cell proliferation. [GOC:ai]",
                "id": "GO:0050679"
            }, {
                "name": "positive regulation of interleukin-1 beta secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of the regulated release of interleukin-1 beta from a cell. [GOC:ai]",
                "id": "GO:0050718"
            }, {
                "name": "regulation of inflammatory response",
                "definition": "Any process that modulates the frequency, rate or extent of the inflammatory response, the immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. [GOC:ai]",
                "id": "GO:0050727"
            }, {
                "name": "positive regulation of B cell activation",
                "definition": "Any process that activates or increases the frequency, rate or extent of B cell activation. [GOC:ai]",
                "id": "GO:0050871"
            }, {
                "name": "positive regulation of NF-kappaB transcription factor activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of activity of the transcription factor NF-kappaB. [GOC:dph, GOC:tb, PMID:15087454, PMID:15170030]",
                "id": "GO:0051092"
            }, {
                "name": "protein oligomerization",
                "definition": "The process of creating protein oligomers, compounds composed of a small number, usually between three and ten, of component monomers; protein oligomers may be composed of different or identical monomers. Oligomers may be formed by the polymerization of a number of monomers or the depolymerization of a large protein polymer. [GOC:ai]",
                "id": "GO:0051259"
            }, {
                "name": "positive regulation of oxidoreductase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of oxidoreductase activity, the catalysis of an oxidation-reduction (redox) reaction, a reversible chemical reaction in which the oxidation state of an atom or atoms within a molecule is altered. [GOC:ai]",
                "id": "GO:0051353"
            }, {
                "name": "positive regulation of nitric-oxide synthase biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of a nitric oxide synthase enzyme. [GOC:ai]",
                "id": "GO:0051770"
            }, {
                "name": "positive regulation of prostaglandin-endoperoxide synthase activity",
                "definition": "Any process that increases the rate, frequency or extent of prostaglandin-endoperoxide synthase activity. Prostaglandin-endoperoxide synthase activity is the catalysis of the reaction: arachidonate + donor-H2 + 2 O2 = prostaglandin H2 + acceptor + H2O. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0060585"
            }, {
                "name": "positive regulation of ERK1 and ERK2 cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the ERK1 and ERK2 cascade. [GOC:mah]",
                "id": "GO:0070374"
            }, {
                "name": "nucleotide-binding oligomerization domain containing signaling pathway",
                "definition": "Any series of molecular signals generated as a consequence of binding to a nucleotide-binding oligomerization domain containing (NOD) protein. [GOC:add, PMID:17944960, PMID:18585455]",
                "id": "GO:0070423"
            }, {
                "name": "nucleotide-binding oligomerization domain containing 2 signaling pathway",
                "definition": "Any series of molecular signals generated as a consequence of binding to nucleotide-binding oligomerization domain containing 2 (NOD2). [GOC:add, PMID:17944960, PMID:18585455]",
                "id": "GO:0070431"
            }, {
                "name": "interleukin-1-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-1 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:BHF, GOC:mah, GOC:signaling]",
                "id": "GO:0070498"
            }, {
                "name": "cellular response to peptidoglycan",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a peptidoglycan stimulus. Peptidoglycan is a bacterial cell wall macromolecule. [GOC:mah]",
                "id": "GO:0071224"
            }, {
                "name": "cellular response to muramyl dipeptide",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a muramyl dipeptide stimulus. Muramyl dipeptide is derived from peptidoglycan. [GOC:mah]",
                "id": "GO:0071225"
            }, {
                "name": "cellular response to organic cyclic compound",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic cyclic compound stimulus. [GOC:mah]",
                "id": "GO:0071407"
            }, {
                "name": "positive regulation of cytokine production involved in inflammatory response",
                "definition": "Any process that activates or increases the frequency, rate or extent of cytokine production involved in inflammatory response. [GOC:TermGenie]",
                "id": "GO:1900017"
            }, {
                "name": "positive regulation of NIK/NF-kappaB signaling",
                "definition": "Any process that activates or increases the frequency, rate or extent of NIK/NF-kappaB signaling. [GOC:TermGenie]",
                "id": "GO:1901224"
            }, {
                "name": "positive regulation of protein K63-linked ubiquitination",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein K63-linked ubiquitination. [GOC:TermGenie, PMID:21931591]",
                "id": "GO:1902523"
            }, {
                "name": "negative regulation of macrophage apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of macrophage apoptotic process. [GOC:BHF, GOC:mtg_apoptosis]",
                "id": "GO:2000110"
            }, {
                "name": "positive regulation of prostaglandin-E synthase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of prostaglandin-E synthase activity. [GOC:BHF]",
                "id": "GO:2000363"
            }],
            "cellular_component": [{
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "cytoskeleton",
                "definition": "Any of the various filamentous elements that form the internal framework of cells, and typically remain after treatment of the cells with mild detergent to remove membrane constituents and soluble components of the cytoplasm. The term embraces intermediate filaments, microfilaments, microtubules, the microtrabecular lattice, and other structures characterized by a polymeric filamentous nature and long-range order within the cell. The various elements of the cytoskeleton not only serve in the maintenance of cellular shape but also have roles in other cellular functions, including cellular movement, cell division, endocytosis, and movement of organelles. [GOC:mah, ISBN:0198547684, PMID:16959967]",
                "id": "GO:0005856"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "COP9 signalosome",
                "definition": "A protein complex that catalyzes the deneddylation of proteins, including the cullin component of SCF ubiquitin E3 ligase; deneddylation increases the activity of cullin family ubiquitin ligases. The signalosome is involved in many regulatory process, including some which control development, in many species; also regulates photomorphogenesis in plants; in many species its subunits are highly similar to those of the proteasome. [PMID:11019806, PMID:12186635, PMID:14570571]",
                "id": "GO:0008180"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "basolateral plasma membrane",
                "definition": "The region of the plasma membrane that includes the basal end and sides of the cell. Often used in reference to animal polarized epithelial membranes, where the basal membrane is the part attached to the extracellular matrix, or in plant cells, where the basal membrane is defined with respect to the zygotic axis. [GOC:go_curators]",
                "id": "GO:0016323"
            }, {
                "name": "vesicle",
                "definition": "Any small, fluid-filled, spherical organelle enclosed by membrane. [GOC:mah, GOC:pz, GOC:vesicles]",
                "id": "GO:0031982"
            }, {
                "name": "macromolecular complex",
                "definition": "A stable assembly of two or more macromolecules, i.e. proteins, nucleic acids, carbohydrates or lipids, in which at least one component is a protein and the constituent parts function together. [GOC:dos, GOC:mah]",
                "id": "GO:0032991"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "actin binding",
                "definition": "Interacting selectively and non-covalently with monomeric or multimeric forms of actin, including actin filaments. [GOC:clt]",
                "id": "GO:0003779"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "enzyme binding",
                "definition": "Interacting selectively and non-covalently with any enzyme. [GOC:jl]",
                "id": "GO:0019899"
            }, {
                "name": "protein kinase binding",
                "definition": "Interacting selectively and non-covalently with a protein kinase, any enzyme that catalyzes the transfer of a phosphate group, usually from ATP, to a protein substrate. [GOC:jl]",
                "id": "GO:0019901"
            }, {
                "name": "Hsp70 protein binding",
                "definition": "Interacting selectively and non-covalently with Hsp70 proteins, any of a group of heat shock proteins around 70kDa in size. [ISBN:0198506732]",
                "id": "GO:0030544"
            }, {
                "name": "muramyl dipeptide binding",
                "definition": "Interacting selectively and non-covalently, in a non-covalent manner, with muramyl dipeptide; muramyl dipeptide is derived from peptidoglycan. [GOC:rl]",
                "id": "GO:0032500"
            }, {
                "name": "peptidoglycan binding",
                "definition": "Interacting selectively and non-covalently, in a non-covalent manner, with peptidoglycan, any of a class of glycoconjugates found in bacterial cell walls. [GOC:go_curators, PMID:14698226]",
                "id": "GO:0042834"
            }, {
                "name": "CARD domain binding",
                "definition": "Interacting selectively and non-covalently with a CARD (N-terminal caspase recruitment) domain, a protein-protein interaction domain that belongs to the death domain-fold superfamily. These protein molecule families are similar in structure with each consisting of six or seven anti-parallel alpha-helices that form highly specific homophilic interactions between signaling partners. CARD exists in the N-terminal prodomains of several caspases and in apoptosis-regulatory proteins and mediates the assembly of CARD-containing proteins that participate in activation or suppression of CARD carrying members of the caspase family. [PMID:12054670]",
                "id": "GO:0050700"
            }, {
                "name": "Hsp90 protein binding",
                "definition": "Interacting selectively and non-covalently with Hsp90 proteins, any of a group of heat shock proteins around 90kDa in size. [GOC:ai]",
                "id": "GO:0051879"
            }]
        },
        "kegg_pathway": {}
    }, "CLK4": {
        "name": "CDC like kinase 4",
        "description": "The protein encoded by this gene belongs to the CDC2-like protein kinase (CLK) family. This protein kinase can interact with and phosphorylate the serine- and arginine-rich (SR) proteins, which are known to play an important role in the formation of spliceosomes, and thus may be involved in the regulation of alternative splicing. Studies in the Israeli sand rat Psammomys obesus suggested that the ubiquitin-like 5 (UBL5/BEACON), a highly conserved ubiquitin-like protein, may interact with and regulate the activity of this kinase. Multiple alternatively spliced transcript variants have been observed, but the full-length natures of which have not yet been determined. [provided by RefSeq, Jul 2008].",
        "entrez": "57396",
        "ensembl": "ENSG00000113240",
        "uniprot": "Q9HAZ1",
        "hgnc": "13659",
        "go_terms": {
            "biological_process": [{
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "regulation of RNA splicing",
                "definition": "Any process that modulates the frequency, rate or extent of RNA splicing, the process of removing sections of the primary RNA transcript to remove sequences not present in the mature form of the RNA and joining the remaining sections to form the mature form of the RNA. [GOC:jl]",
                "id": "GO:0043484"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "protein serine/threonine/tyrosine kinase activity",
                "definition": "Catalysis of the reactions: ATP + a protein serine = ADP + protein serine phosphate; ATP + a protein threonine = ADP + protein threonine phosphate; and ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [GOC:mah]",
                "id": "GO:0004712"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }]
        },
        "kegg_pathway": {}
    }, "ABL2": {
        "name": "T cell receptor signaling pathway",
        "description": "This gene encodes a member of the Abelson family of nonreceptor tyrosine protein kinases. The protein is highly similar to the c-abl oncogene 1 protein, including the tyrosine kinase, SH2 and SH3 domains, and it plays a role in cytoskeletal rearrangements through its C-terminal F-actin- and microtubule-binding sequences. This gene is expressed in both normal and tumor cells, and is involved in translocation with the ets variant 6 gene in leukemia. Multiple alternatively spliced transcript variants encoding different protein isoforms have been found for this gene. [provided by RefSeq, Nov 2009].",
        "entrez": "27",
        "ensembl": "ENSG00000143322",
        "uniprot": "P42684",
        "hgnc": "77",
        "go_terms": {
            "biological_process": [{
                "name": "cellular protein modification process",
                "definition": "The covalent alteration of one or more amino acids occurring in proteins, peptides and nascent polypeptides (co-translational, post-translational modifications) occurring at the level of an individual cell. Includes the modification of charged tRNAs that are destined to occur in a protein (pre-translation modification). [GOC:go_curators]",
                "id": "GO:0006464"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "cell adhesion",
                "definition": "The attachment of a cell, either to another cell or to an underlying substrate such as the extracellular matrix, via cell adhesion molecules. [GOC:hb, GOC:pf]",
                "id": "GO:0007155"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "transmembrane receptor protein tyrosine kinase signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to a receptor on the surface of the target cell where the receptor possesses tyrosine kinase activity, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling]",
                "id": "GO:0007169"
            }, {
                "name": "positive regulation of cytosolic calcium ion concentration",
                "definition": "Any process that increases the concentration of calcium ions in the cytosol. [GOC:ai]",
                "id": "GO:0007204"
            }, {
                "name": "regulation of autophagy",
                "definition": "Any process that modulates the frequency, rate or extent of autophagy. Autophagy is the process in which cells digest parts of their own cytoplasm. [GOC:dph, GOC:tb]",
                "id": "GO:0010506"
            }, {
                "name": "positive regulation of phospholipase C activity",
                "definition": "Any process that increases the rate of phospholipase C activity. [GOC:dph, GOC:tb]",
                "id": "GO:0010863"
            }, {
                "name": "positive regulation of neuron projection development",
                "definition": "Any process that increases the rate, frequency or extent of neuron projection development. Neuron projection development is the process whose specific outcome is the progression of a neuron projection over time, from its formation to the mature structure. A neuron projection is any process extending from a neural cell, such as axons or dendrites (collectively called neurites). [GOC:dph, GOC:tb]",
                "id": "GO:0010976"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "regulation of endocytosis",
                "definition": "Any process that modulates the frequency, rate or extent of endocytosis. [GOC:go_curators]",
                "id": "GO:0030100"
            }, {
                "name": "regulation of cell adhesion",
                "definition": "Any process that modulates the frequency, rate or extent of attachment of a cell to another cell or to the extracellular matrix. [GOC:mah]",
                "id": "GO:0030155"
            }, {
                "name": "peptidyl-tyrosine autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own tyrosine amino acid residues, or a tyrosine residue on an identical protein. [PMID:10037737, PMID:10068444, PMID:10940390]",
                "id": "GO:0038083"
            }, {
                "name": "regulation of cell proliferation",
                "definition": "Any process that modulates the frequency, rate or extent of cell proliferation. [GOC:jl]",
                "id": "GO:0042127"
            }, {
                "name": "innate immune response",
                "definition": "Innate immune responses are defense responses mediated by germline encoded components that directly recognize components of potential pathogens. [GO_REF:0000022, GOC:add, GOC:ebc, GOC:mtg_15nov05, GOC:mtg_sensu]",
                "id": "GO:0045087"
            }, {
                "name": "positive regulation of oxidoreductase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of oxidoreductase activity, the catalysis of an oxidation-reduction (redox) reaction, a reversible chemical reaction in which the oxidation state of an atom or atoms within a molecule is altered. [GOC:ai]",
                "id": "GO:0051353"
            }, {
                "name": "cellular response to retinoic acid",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a retinoic acid stimulus. [GOC:mah]",
                "id": "GO:0071300"
            }, {
                "name": "regulation of cell motility",
                "definition": "Any process that modulates the frequency, rate or extent of cell motility. [GOC:mah]",
                "id": "GO:2000145"
            }, {
                "name": "regulation of actin cytoskeleton reorganization",
                "definition": "Any process that modulates the frequency, rate or extent of actin cytoskeleton reorganization. [GOC:BHF]",
                "id": "GO:2000249"
            }],
            "cellular_component": [{
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "cytoskeleton",
                "definition": "Any of the various filamentous elements that form the internal framework of cells, and typically remain after treatment of the cells with mild detergent to remove membrane constituents and soluble components of the cytoplasm. The term embraces intermediate filaments, microfilaments, microtubules, the microtrabecular lattice, and other structures characterized by a polymeric filamentous nature and long-range order within the cell. The various elements of the cytoskeleton not only serve in the maintenance of cellular shape but also have roles in other cellular functions, including cellular movement, cell division, endocytosis, and movement of organelles. [GOC:mah, ISBN:0198547684, PMID:16959967]",
                "id": "GO:0005856"
            }, {
                "name": "actin cytoskeleton",
                "definition": "The part of the cytoskeleton (the internal framework of a cell) composed of actin and associated proteins. Includes actin cytoskeleton-associated complexes. [GOC:jl, ISBN:0395825172, ISBN:0815316194]",
                "id": "GO:0015629"
            }, {
                "name": "extrinsic component of cytoplasmic side of plasma membrane",
                "definition": "The component of a plasma membrane consisting of gene products and protein complexes that are loosely bound to its cytoplasmic surface, but not integrated into the hydrophobic region. [GOC:mah]",
                "id": "GO:0031234"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "phosphotyrosine residue binding",
                "definition": "Interacting selectively and non-covalently with a phosphorylated tyrosine residue within a protein. [PMID:14636584]",
                "id": "GO:0001784"
            }, {
                "name": "actin monomer binding",
                "definition": "Interacting selectively and non-covalently with monomeric actin, also known as G-actin. [GOC:ai]",
                "id": "GO:0003785"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "non-membrane spanning protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + protein L-tyrosine = ADP + protein L-tyrosine phosphate by a non-membrane spanning protein. [EC:2.7.10.2]",
                "id": "GO:0004715"
            }, {
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "manganese ion binding",
                "definition": "Interacting selectively and non-covalently with manganese (Mn) ions. [GOC:ai]",
                "id": "GO:0030145"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "actin filament binding",
                "definition": "Interacting selectively and non-covalently with an actin filament, also known as F-actin, a helical filamentous polymer of globular G-actin subunits. [ISBN:0198506732]",
                "id": "GO:0051015"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.10.2",
            "pathways": [{
                "pathway": "04660",
                "name": "T cell receptor signaling pathway",
                "class": "Organismal Systems; Immune system"
            }]
        }
    }, "GRIA3": {
        "name": "glutamate ionotropic receptor AMPA type subunit 3",
        "description": "Glutamate receptors are the predominant excitatory neurotransmitter receptors in the mammalian brain and are activated in a variety of normal neurophysiologic processes. These receptors are heteromeric protein complexes composed of multiple subunits, arranged to form ligand-gated ion channels. The classification of glutamate receptors is based on their activation by different pharmacologic agonists. The subunit encoded by this gene belongs to a family of AMPA (alpha-amino-3-hydroxy-5-methyl-4-isoxazole propionate)-sensitive glutamate receptors, and is subject to RNA editing (AGA->GGA; R->G). Alternative splicing at this locus results in different isoforms, which may vary in their signal transduction properties. [provided by RefSeq, Jul 2008].",
        "entrez": "2892",
        "ensembl": "ENSG00000125675",
        "uniprot": "P42263",
        "hgnc": "4573",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "glutamate receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of glutamate to a glutamate receptor on the surface of a target cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:9131252]",
                "id": "GO:0007215"
            }, {
                "name": "ion transmembrane transport",
                "definition": "A process in which an ion is transported across a membrane. [GOC:mah]",
                "id": "GO:0034220"
            }, {
                "name": "ionotropic glutamate receptor signaling pathway",
                "definition": "A series of molecular signals initiated by glutamate binding to a glutamate receptor on the surface of the target cell, followed by the movement of ions through a channel in the receptor complex. Ends with regulation of a downstream cellular process, e.g. transcription. [GOC:signaling, ISBN:0198506732]",
                "id": "GO:0035235"
            }, {
                "name": "excitatory postsynaptic potential",
                "definition": "A process that leads to a temporary increase in postsynaptic potential due to the flow of positively charged ions into the postsynaptic cell. The flow of ions that causes an EPSP is an excitatory postsynaptic current (EPSC) and makes it easier for the neuron to fire an action potential. [GOC:dph, GOC:ef]",
                "id": "GO:0060079"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "cell junction",
                "definition": "A cellular component that forms a specialized region of connection between two or more cells or between a cell and the extracellular matrix. At a cell junction, anchoring proteins extend through the plasma membrane to link cytoskeletal proteins in one cell to cytoskeletal proteins in neighboring cells or to proteins in the extracellular matrix. [GOC:mah, http://www.vivo.colostate.edu/hbooks/cmb/cells/pmemb/junctions_a.html, ISBN:0198506732]",
                "id": "GO:0030054"
            }, {
                "name": "endocytic vesicle membrane",
                "definition": "The lipid bilayer surrounding an endocytic vesicle. [GOC:mah]",
                "id": "GO:0030666"
            }, {
                "name": "AMPA glutamate receptor complex",
                "definition": "An assembly of four or five subunits which form a structure with an extracellular N-terminus and a large loop that together form the ligand binding domain. The C-terminus is intracellular. The ionotropic glutamate receptor complex itself acts as a ligand gated ion channel; on binding glutamate, charged ions pass through a channel in the center of the receptor complex. The AMPA receptors mediate fast synaptic transmission in the CNS and are composed of subunits GluR1-4, products from separate genes. These subunits have an extracellular N-terminus and an intracellular C-terminus. [GOC:ef]",
                "id": "GO:0032281"
            }, {
                "name": "synapse",
                "definition": "The junction between a nerve fiber of one neuron and another neuron, muscle fiber or glial cell. As the nerve fiber approaches the synapse it enlarges into a specialized structure, the presynaptic nerve ending, which contains mitochondria and synaptic vesicles. At the tip of the nerve ending is the presynaptic membrane; facing it, and separated from it by a minute cleft (the synaptic cleft) is a specialized area of membrane on the receiving cell, known as the postsynaptic membrane. In response to the arrival of nerve impulses, the presynaptic nerve ending secretes molecules of neurotransmitters into the synaptic cleft. These diffuse across the cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045202"
            }, {
                "name": "postsynaptic membrane",
                "definition": "A specialized area of membrane facing the presynaptic membrane on the tip of the nerve ending and separated from it by a minute cleft (the synaptic cleft). Neurotransmitters cross the synaptic cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045211"
            }],
            "molecular_function": [{
                "name": "amyloid-beta binding",
                "definition": "Interacting selectively and non-covalently with amyloid-beta peptide/protein and/or its precursor. [GOC:hjd]",
                "id": "GO:0001540"
            }, {
                "name": "receptor activity",
                "definition": "Combining with an extracellular or intracellular messenger to initiate a change in cell activity. [GOC:ceb, ISBN:0198506732]",
                "id": "GO:0004872"
            }, {
                "name": "ionotropic glutamate receptor activity",
                "definition": "Catalysis of the transmembrane transfer of an ion by a channel that opens when glutamate has been bound by the channel complex or one of its constituent parts. [ISBN:0198506732]",
                "id": "GO:0004970"
            }, {
                "name": "AMPA glutamate receptor activity",
                "definition": "An ionotropic glutamate receptor activity that exhibits fast gating by glutamate and acts by opening a cation channel permeable to sodium, potassium, and, in the absence of a GluR2 subunit, calcium. [GOC:mah, PMID:10049997, PMID:8804111]",
                "id": "GO:0004971"
            }, {
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "excitatory extracellular ligand-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a channel that opens when a specific extracellular ligand has been bound by the channel complex or one of its constituent parts, where channel opening contributes to an increase in membrane potential. [GOC:mah, ISBN:0323037070]",
                "id": "GO:0005231"
            }, {
                "name": "extracellularly glutamate-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a channel that opens when glutamate is  bound by the channel complex or one of its constituent parts on the extracellular side of the plasma membrane. [GOC:mtg_transport, ISBN:0815340729]",
                "id": "GO:0005234"
            }]
        },
        "kegg_pathway": {}
    }, "CXCL8": {
        "name": "C-X-C motif chemokine ligand 8",
        "description": "The protein encoded by this gene is a member of the CXC chemokine family and is a major mediator of the inflammatory response. The encoded protein is secreted primarily by neutrophils, where it serves as a chemotactic factor by guiding the neutrophils to the site of infection. This chemokine is also a potent angiogenic factor. This gene is believed to play a role in the pathogenesis of bronchiolitis, a common respiratory tract disease caused by viral infection. This gene and other members of the CXC chemokine gene family form a gene cluster in a region of chromosome 4q. [provided by RefSeq, Aug 2017].",
        "entrez": "3576",
        "ensembl": "ENSG00000169429",
        "uniprot": "P10145",
        "hgnc": "6025",
        "go_terms": {
            "biological_process": [{
                "name": "angiogenesis",
                "definition": "Blood vessel formation when new vessels emerge from the proliferation of pre-existing blood vessels. [ISBN:0878932453]",
                "id": "GO:0001525"
            }, {
                "name": "response to molecule of bacterial origin",
                "definition": "Any process that results in a change in state or activity of an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus by molecules of bacterial origin such as peptides derived from bacterial flagellin. [GOC:rl, GOC:sm]",
                "id": "GO:0002237"
            }, {
                "name": "chemotaxis",
                "definition": "The directed movement of a motile cell or organism, or the directed growth of a cell guided by a specific chemical concentration gradient. Movement may be towards a higher concentration (positive chemotaxis) or towards a lower concentration (negative chemotaxis). [ISBN:0198506732]",
                "id": "GO:0006935"
            }, {
                "name": "defense response",
                "definition": "Reactions, triggered in response to the presence of a foreign body or the occurrence of an injury, which result in restriction of damage to the organism attacked or prevention/recovery from the infection caused by the attack. [GOC:go_curators]",
                "id": "GO:0006952"
            }, {
                "name": "inflammatory response",
                "definition": "The immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. The process is characterized by local vasodilation, extravasation of plasma into intercellular spaces and accumulation of white blood cells and macrophages. [GO_REF:0000022, GOC:mtg_15nov05, ISBN:0198506732]",
                "id": "GO:0006954"
            }, {
                "name": "immune response",
                "definition": "Any immune system process that functions in the calibrated response of an organism to a potential internal or invasive threat. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0006955"
            }, {
                "name": "cell cycle arrest",
                "definition": "A regulatory process that halts progression through the cell cycle during one of the normal phases (G1, S, G2, M). [GOC:dph, GOC:mah, GOC:tb]",
                "id": "GO:0007050"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "negative regulation of cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008285"
            }, {
                "name": "regulation of receptor activity",
                "definition": "Any process that modulates the frequency, rate or extent of receptor activity. Receptor activity is when a molecule combines with an extracellular or intracellular messenger to initiate a change in cell activity. [GOC:dph, GOC:tb]",
                "id": "GO:0010469"
            }, {
                "name": "cytokine-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a cytokine to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:19295629]",
                "id": "GO:0019221"
            }, {
                "name": "calcium-mediated signaling",
                "definition": "Any intracellular signal transduction in which the signal is passed on within the cell via calcium ions. [GOC:signaling]",
                "id": "GO:0019722"
            }, {
                "name": "regulation of cell adhesion",
                "definition": "Any process that modulates the frequency, rate or extent of attachment of a cell to another cell or to the extracellular matrix. [GOC:mah]",
                "id": "GO:0030155"
            }, {
                "name": "neutrophil chemotaxis",
                "definition": "The directed movement of a neutrophil cell, the most numerous polymorphonuclear leukocyte found in the blood, in response to an external stimulus, usually an infection or wounding. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0030593"
            }, {
                "name": "receptor internalization",
                "definition": "A receptor-mediated endocytosis process that results in the movement of receptors from the plasma membrane to the inside of the cell. The process begins when cell surface receptors are monoubiquitinated following ligand-induced activation. Receptors are subsequently taken up into endocytic vesicles from where they are either targeted to the lysosome or vacuole for degradation or recycled back to the plasma membrane. [GOC:bf, GOC:mah, GOC:signaling, PMID:15006537, PMID:19643732]",
                "id": "GO:0031623"
            }, {
                "name": "response to endoplasmic reticulum stress",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stress acting at the endoplasmic reticulum. ER stress usually results from the accumulation of unfolded or misfolded proteins in the ER lumen. [GOC:cjm, GOC:mah]",
                "id": "GO:0034976"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "PERK-mediated unfolded protein response",
                "definition": "A series of molecular signals mediated by the endoplasmic reticulum membrane stress sensor PERK (PKR-like ER kinase). Begins with activation of PERK in response to endoplasmic reticulum (ER) stress and ends with regulation of a downstream cellular process, e.g. transcription. The main substrate of PERK is the translation initiation factor eIF2alpha. Serine-phosphorylation of eIF2alpha by PERK inactivates eIF2alpha and inhibits general protein translation. In addition, eIF2alpha phosphorylation preferentially increases the translation of selective mRNAs such as ATF4 (activating transcription factor 4), which up regulates a subset of UPR genes required to restore folding capacity. [GOC:bf, GOC:PARL, PMID:22013210]",
                "id": "GO:0036499"
            }, {
                "name": "neutrophil activation",
                "definition": "The change in morphology and behavior of a neutrophil resulting from exposure to a cytokine, chemokine, cellular ligand, or soluble factor. [GOC:mgi_curators, ISBN:0781735149]",
                "id": "GO:0042119"
            }, {
                "name": "cellular response to fibroblast growth factor stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an fibroblast growth factor stimulus. [GOC:jl, GOC:yaf]",
                "id": "GO:0044344"
            }, {
                "name": "regulation of single stranded viral RNA replication via double stranded DNA intermediate",
                "definition": "Any process that modulates the frequency, rate or extent of single stranded viral RNA replication via double stranded DNA intermediate. [GOC:go_curators]",
                "id": "GO:0045091"
            }, {
                "name": "negative regulation of G-protein coupled receptor protein signaling pathway",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of G-protein coupled receptor protein signaling pathway activity. [GOC:go_curators]",
                "id": "GO:0045744"
            }, {
                "name": "positive regulation of angiogenesis",
                "definition": "Any process that activates or increases angiogenesis. [GOC:go_curators]",
                "id": "GO:0045766"
            }, {
                "name": "embryonic digestive tract development",
                "definition": "The process whose specific outcome is the progression of the gut over time, from its formation to the mature structure during embryonic development. The gut is the region of the digestive tract extending from the beginning of the intestines to the anus. [GOC:go_curators]",
                "id": "GO:0048566"
            }, {
                "name": "induction of positive chemotaxis",
                "definition": "Any process that initiates the directed movement of a motile cell or organism towards a higher concentration in a concentration gradient of a specific chemical. [GOC:ai]",
                "id": "GO:0050930"
            }, {
                "name": "chemokine-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a chemokine to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling]",
                "id": "GO:0070098"
            }, {
                "name": "cellular response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:mah]",
                "id": "GO:0071222"
            }, {
                "name": "cellular response to interleukin-1",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an interleukin-1 stimulus. [GOC:mah]",
                "id": "GO:0071347"
            }, {
                "name": "cellular response to tumor necrosis factor",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a tumor necrosis factor stimulus. [GOC:mah]",
                "id": "GO:0071356"
            }, {
                "name": "positive regulation of neutrophil chemotaxis",
                "definition": "Any process that increases the frequency, rate, or extent of neutrophil chemotaxis. Neutrophil chemotaxis is the directed movement of a neutrophil cell, the most numerous polymorphonuclear leukocyte found in the blood, in response to an external stimulus, usually an infection or wounding. [GOC:dph, GOC:tb]",
                "id": "GO:0090023"
            }, {
                "name": "regulation of entry of bacterium into host cell",
                "definition": "Any process that modulates the frequency, rate or extent of entry of bacterium into host cell. [GOC:obol]",
                "id": "GO:2000535"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "extracellular space",
                "definition": "That part of a multicellular organism outside the cells proper, usually taken to be outside the plasma membranes, and occupied by fluid. [ISBN:0198547684]",
                "id": "GO:0005615"
            }, {
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }],
            "molecular_function": [{
                "name": "cytokine activity",
                "definition": "Functions to control the survival, growth, differentiation and effector function of tissues and cells. [ISBN:0198599471]",
                "id": "GO:0005125"
            }, {
                "name": "interleukin-8 receptor binding",
                "definition": "Interacting selectively and non-covalently with the interleukin-8 receptor. [GOC:go_curators]",
                "id": "GO:0005153"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "chemokine activity",
                "definition": "The function of a family of small chemotactic cytokines; their name is derived from their ability to induce directed chemotaxis in nearby responsive cells. All chemokines possess a number of conserved cysteine residues involved in intramolecular disulfide bond formation. Some chemokines are considered pro-inflammatory and can be induced during an immune response to recruit cells of the immune system to a site of infection, while others are considered homeostatic and are involved in controlling the migration of cells during normal processes of tissue maintenance or development. Chemokines are found in all vertebrates, some viruses and some bacteria. [GOC:BHF, GOC:rl, http://en.wikipedia.org/wiki/Chemokine, http://www.copewithcytokines.de/cope.cgi?key=Cytokines, PMID:12183377]",
                "id": "GO:0008009"
            }]
        },
        "kegg_pathway": {}
    }, "RAF1": {
        "name": "Raf-1 proto-oncogene, serine/threonine kinase",
        "description": "This gene is the cellular homolog of viral raf gene (v-raf). The encoded protein is a MAP kinase kinase kinase (MAP3K), which functions downstream of the Ras family of membrane associated GTPases to which it binds directly. Once activated, the cellular RAF1 protein can phosphorylate to activate the dual specificity protein kinases MEK1 and MEK2, which in turn phosphorylate to activate the serine/threonine specific protein kinases, ERK1 and ERK2. Activated ERKs are pleiotropic effectors of cell physiology and play an important role in the control of gene expression involved in the cell division cycle, apoptosis, cell differentiation and cell migration. Mutations in this gene are associated with Noonan syndrome 5 and LEOPARD syndrome 2. [provided by RefSeq, Jul 2008].",
        "entrez": "5894",
        "ensembl": "ENSG00000132155",
        "uniprot": "P04049",
        "hgnc": "9829",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "activation of MAPKK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase kinase (MAPKK). [PMID:9561267]",
                "id": "GO:0000186"
            }, {
                "name": "response to hypoxia",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating lowered oxygen tension. Hypoxia, defined as a decline in O2 levels below normoxic levels of 20.8 - 20.95%, results in metabolic adaptation at both the cellular and organismal level. [GOC:hjd]",
                "id": "GO:0001666"
            }, {
                "name": "cellular glucose homeostasis",
                "definition": "A cellular homeostatic process involved in the maintenance of an internal steady state of glucose within a cell or between a cell and its external environment. [GOC:dph, GOC:go_curators, GOC:tb]",
                "id": "GO:0001678"
            }, {
                "name": "positive regulation of protein phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of addition of phosphate groups to amino acids within a protein. [GOC:hjd]",
                "id": "GO:0001934"
            }, {
                "name": "stimulatory C-type lectin receptor signaling pathway",
                "definition": "Any series of molecular signals generated as a consequence of binding to a C-type lectin receptor capable of cellular activation. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002223"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "activation of adenylate cyclase activity",
                "definition": "Any process that initiates the activity of the inactive enzyme adenylate cyclase. [GOC:ai]",
                "id": "GO:0007190"
            }, {
                "name": "multicellular organism development",
                "definition": "The biological process whose specific outcome is the progression of a multicellular organism over time from an initial condition (e.g. a zygote or a young adult) to a later condition (e.g. a multicellular animal or an aged adult). [GOC:dph, GOC:ems, GOC:isa_complete, GOC:tb]",
                "id": "GO:0007275"
            }, {
                "name": "heart development",
                "definition": "The process whose specific outcome is the progression of the heart over time, from its formation to the mature structure. The heart is a hollow, muscular organ, which, by contracting rhythmically, keeps up the circulation of the blood. [GOC:jid, UBERON:0000948]",
                "id": "GO:0007507"
            }, {
                "name": "cell proliferation",
                "definition": "The multiplication or reproduction of cells, resulting in the expansion of a cell population. [GOC:mah, GOC:mb]",
                "id": "GO:0008283"
            }, {
                "name": "negative regulation of cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008285"
            }, {
                "name": "negative regulation of signal transduction",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of signal transduction. [GOC:sm]",
                "id": "GO:0009968"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "cell differentiation",
                "definition": "The process in which relatively unspecialized cells, e.g. embryonic or regenerative cells, acquire specialized structural and/or functional features that characterize the cells, tissues, or organs of the mature organism or some other relatively stable phase of the organism's life history. Differentiation includes the processes involved in commitment of a cell to a specific fate and its subsequent development to the mature state. [ISBN:0198506732]",
                "id": "GO:0030154"
            }, {
                "name": "platelet activation",
                "definition": "A series of progressive, overlapping events triggered by exposure of the platelets to subendothelial tissue. These events include shape change, adhesiveness, aggregation, and release reactions. When carried through to completion, these events lead to the formation of a stable hemostatic plug. [http://www.graylab.ac.uk/omd/]",
                "id": "GO:0030168"
            }, {
                "name": "thyroid gland development",
                "definition": "The process whose specific outcome is the progression of the thyroid gland over time, from its formation to the mature structure. The thyroid gland is an endoderm-derived gland that produces thyroid hormone. [GOC:dgh]",
                "id": "GO:0030878"
            }, {
                "name": "negative regulation of protein complex assembly",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of protein complex assembly. [GOC:mah]",
                "id": "GO:0031333"
            }, {
                "name": "positive regulation of peptidyl-serine phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of the phosphorylation of peptidyl-serine. [GOC:mah]",
                "id": "GO:0033138"
            }, {
                "name": "ion transmembrane transport",
                "definition": "A process in which an ion is transported across a membrane. [GOC:mah]",
                "id": "GO:0034220"
            }, {
                "name": "somatic stem cell population maintenance",
                "definition": "Any process by which an organism retains a population of somatic stem cells, undifferentiated cells in the embryo or adult which can undergo unlimited division and give rise to cell types of the body other than those of the germ-line. [GOC:bf, ISBN:0582227089]",
                "id": "GO:0035019"
            }, {
                "name": "regulation of Rho protein signal transduction",
                "definition": "Any process that modulates the frequency, rate or extent of Rho protein signal transduction. [GOC:bf]",
                "id": "GO:0035023"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "insulin secretion involved in cellular response to glucose stimulus",
                "definition": "The regulated release of proinsulin from secretory granules (B granules) in the B cells of the pancreas; accompanied by cleavage of proinsulin to form mature insulin, in response to a glucose stimulus. [GOC:bf, GOC:yaf, PMID:8492079]",
                "id": "GO:0035773"
            }, {
                "name": "response to muscle stretch",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a myofibril being extended beyond its slack length. [GOC:BHF, GOC:vk, PMID:14583192]",
                "id": "GO:0035994"
            }, {
                "name": "wound healing",
                "definition": "The series of events that restore integrity to a damaged tissue, following an injury. [GOC:bf, PMID:15269788]",
                "id": "GO:0042060"
            }, {
                "name": "regulation of apoptotic process",
                "definition": "Any process that modulates the occurrence or rate of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0042981"
            }, {
                "name": "negative regulation of apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043066"
            }, {
                "name": "positive regulation of catalytic activity",
                "definition": "Any process that activates or increases the activity of an enzyme. [GOC:ebc, GOC:jl, GOC:tb, GOC:vw]",
                "id": "GO:0043085"
            }, {
                "name": "negative regulation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of a cysteine-type endopeptidase activity involved in the apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043154"
            }, {
                "name": "intermediate filament cytoskeleton organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of cytoskeletal structures comprising intermediate filaments and their associated proteins. [GOC:ai]",
                "id": "GO:0045104"
            }, {
                "name": "regulation of cell differentiation",
                "definition": "Any process that modulates the frequency, rate or extent of cell differentiation, the process in which relatively unspecialized cells acquire specialized structural and functional features. [GOC:go_curators]",
                "id": "GO:0045595"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "neurotrophin TRK receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a neurotrophin to a receptor on the surface of the target cell where the receptor possesses tyrosine kinase activity, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:bf, GOC:ceb, GOC:jc, GOC:signaling, PMID:12065629, Wikipedia:Trk_receptor]",
                "id": "GO:0048011"
            }, {
                "name": "thymus development",
                "definition": "The process whose specific outcome is the progression of the thymus over time, from its formation to the mature structure. The thymus is a symmetric bi-lobed organ involved primarily in the differentiation of immature to mature T cells, with unique vascular, nervous, epithelial, and lymphoid cell components. [GOC:add, ISBN:0781735149]",
                "id": "GO:0048538"
            }, {
                "name": "face development",
                "definition": "The biological process whose specific outcome is the progression of a face from an initial condition to its mature state. The face is the ventral division of the head. [GOC:dph]",
                "id": "GO:0060324"
            }, {
                "name": "positive regulation of ERK1 and ERK2 cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the ERK1 and ERK2 cascade. [GOC:mah]",
                "id": "GO:0070374"
            }, {
                "name": "death-inducing signaling complex assembly",
                "definition": "A process of protein complex assembly in which the arrangement and bonding together of the set of components that form the protein complex is mediated by a death domain (DD) interaction, as part of the extrinsic apoptotic signaling pathway. [GOC:amm, GOC:mtg_apoptosis, InterPro:IPR000488]",
                "id": "GO:0071550"
            }, {
                "name": "negative regulation of extrinsic apoptotic signaling pathway via death domain receptors",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of extrinsic apoptotic signaling pathway via death domain receptors. [GOC:TermGenie, PMID:17245429]",
                "id": "GO:1902042"
            }, {
                "name": "regulation of cell motility",
                "definition": "Any process that modulates the frequency, rate or extent of cell motility. [GOC:mah]",
                "id": "GO:2000145"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "mitochondrial outer membrane",
                "definition": "The outer, i.e. cytoplasm-facing, lipid bilayer of the mitochondrial envelope. [GOC:ai]",
                "id": "GO:0005741"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "nuclear speck",
                "definition": "A discrete extra-nucleolar subnuclear domain, 20-50 in number, in which splicing factors are seen to be localized by immunofluorescence microscopy. [http://www.cellnucleus.com/]",
                "id": "GO:0016607"
            }, {
                "name": "pseudopodium",
                "definition": "A temporary protrusion or retractile process of a cell, associated with flowing movements of the protoplasm, and serving for locomotion and feeding. [ISBN:0198506732]",
                "id": "GO:0031143"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "MAP kinase kinase kinase activity",
                "definition": "Catalysis of the phosphorylation and activation of a MAP kinase kinase; each MAP kinase kinase can be phosphorylated by any of several MAP kinase kinase kinases. [PMID:9561267]",
                "id": "GO:0004709"
            }, {
                "name": "signal transducer activity, downstream of receptor",
                "definition": "Conveys a signal from an upstream receptor or intracellular signal transducer, converting the signal into a form where it can ultimately trigger a change in the state or activity of a cell. [GOC:bf]",
                "id": "GO:0005057"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "adenylate cyclase binding",
                "definition": "Interacting selectively and non-covalently with the enzyme adenylate cyclase. [GOC:jl]",
                "id": "GO:0008179"
            }, {
                "name": "adenylate cyclase activator activity",
                "definition": "Increases the activity of the enzyme that catalyzes the reaction: ATP = 3',5'-cyclic AMP + diphosphate. [GOC:dph, GOC:tb]",
                "id": "GO:0010856"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "Ras GTPase binding",
                "definition": "Interacting selectively and non-covalently with any member of the Ras superfamily of monomeric GTPases. [GOC:mah]",
                "id": "GO:0017016"
            }, {
                "name": "enzyme binding",
                "definition": "Interacting selectively and non-covalently with any enzyme. [GOC:jl]",
                "id": "GO:0019899"
            }, {
                "name": "mitogen-activated protein kinase kinase binding",
                "definition": "Interacting selectively and non-covalently with a mitogen-activated protein kinase kinase, any protein that can phosphorylate a MAP kinase. [GOC:mah]",
                "id": "GO:0031434"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "protein heterodimerization activity",
                "definition": "Interacting selectively and non-covalently with a nonidentical protein to form a heterodimer. [GOC:ai]",
                "id": "GO:0046982"
            }]
        },
        "kegg_pathway": {}
    }, "TTK": {
        "name": "TTK protein kinase",
        "description": "This gene encodes a dual specificity protein kinase with the ability to phosphorylate tyrosine, serine and threonine. Associated with cell proliferation, this protein is essential for chromosome alignment at the centromere during mitosis and is required for centrosome duplication. It has been found to be a critical mitotic checkpoint protein for accurate segregation of chromosomes during mitosis. Tumorigenesis may occur when this protein fails to degrade and produces excess centrosomes resulting in aberrant mitotic spindles. Alternative splicing results in multiple transcript variants. [provided by RefSeq, Nov 2009].",
        "entrez": "7272",
        "ensembl": "ENSG00000112742",
        "uniprot": "P33981",
        "hgnc": "12401",
        "go_terms": {
            "biological_process": [{
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "spindle organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of the spindle, the array of microtubules and associated molecules that forms between opposite poles of a eukaryotic cell during DNA segregation and serves to move the duplicated chromosomes apart. [GOC:go_curators, GOC:mah]",
                "id": "GO:0007051"
            }, {
                "name": "mitotic spindle organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of the microtubule spindle during a mitotic cell cycle. [GOC:go_curators, GOC:mah]",
                "id": "GO:0007052"
            }, {
                "name": "mitotic cell cycle checkpoint",
                "definition": "A cell cycle checkpoint that ensures accurate chromosome replication and segregation by preventing progression through a mitotic cell cycle until conditions are suitable for the cell to proceed to the next stage. [GOC:mtg_cell_cycle]",
                "id": "GO:0007093"
            }, {
                "name": "mitotic spindle assembly checkpoint",
                "definition": "A cell cycle checkpoint that delays the metaphase/anaphase transition of a mitotic nuclear division until the spindle is correctly assembled and chromosomes are attached to the spindle. [GOC:mtg_cell_cycle, PMID:12360190]",
                "id": "GO:0007094"
            }, {
                "name": "positive regulation of cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008284"
            }, {
                "name": "positive regulation of pathway-restricted SMAD protein phosphorylation",
                "definition": "Any process that increases the rate, frequency or extent of pathway-restricted SMAD protein phosphorylation. Pathway-restricted SMAD proteins and common-partner SMAD proteins are involved in the transforming growth factor beta receptor signaling pathways. [GOC:dph, GOC:tb]",
                "id": "GO:0010862"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "female meiosis chromosome segregation",
                "definition": "The cell cycle process in which genetic material, in the form of chromosomes, is organized and then physically separated and apportioned to two or more sets during the meiotic cell cycle in a female. [GOC:ai]",
                "id": "GO:0016321"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "meiotic spindle assembly checkpoint",
                "definition": "A cell cycle checkpoint that delays the metaphase/anaphase transition of a meiotic cell cycle until the spindle is correctly assembled and chromosomes are attached to the spindle. [GOC:mah]",
                "id": "GO:0033316"
            }, {
                "name": "protein localization to kinetochore",
                "definition": "Any process in which a protein is transported to, or maintained at, the kinetochore. [GOC:mah]",
                "id": "GO:0034501"
            }, {
                "name": "protein localization to chromosome",
                "definition": "Any process in which a protein is transported to, or maintained at, a specific location on a chromosome. [GOC:mah]",
                "id": "GO:0034502"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "chromosome separation",
                "definition": "The cell cycle process in which paired chromosomes are detached from each other. Chromosome separation begins with the release of cohesin complexes from chromosomes; in budding yeast, this includes the cleavage of cohesin complexes along the chromosome arms, followed by the separation of the centromeric regions. Chromosome separation also includes formation of chromatid axes mediated by condensins, and ends with the disentangling of inter-sister catenation catalyzed by topoisomerase II (topo II). [GOC:ai, GOC:lb, GOC:mah, GOC:mtg_cell_cycle, PMID:20352243]",
                "id": "GO:0051304"
            }, {
                "name": "protein localization to meiotic spindle midzone",
                "definition": "A process in which a protein is transported to, or maintained in, a location within a meiotic spindle midzone. [GO_REF:0000087, GOC:kmv, GOC:TermGenie, pmid:12707312]",
                "id": "GO:1903096"
            }],
            "cellular_component": [{
                "name": "kinetochore",
                "definition": "A multisubunit complex that is located at the centromeric region of DNA and provides an attachment point for the spindle microtubules. [GOC:elh]",
                "id": "GO:0000776"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "spindle",
                "definition": "The array of microtubules and associated molecules that forms between opposite poles of a eukaryotic cell during mitosis or meiosis and serves to move the duplicated chromosomes apart. [ISBN:0198547684]",
                "id": "GO:0005819"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "protein serine/threonine/tyrosine kinase activity",
                "definition": "Catalysis of the reactions: ATP + a protein serine = ADP + protein serine phosphate; ATP + a protein threonine = ADP + protein threonine phosphate; and ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [GOC:mah]",
                "id": "GO:0004712"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "protein homodimerization activity",
                "definition": "Interacting selectively and non-covalently with an identical protein to form a homodimer. [GOC:jl]",
                "id": "GO:0042803"
            }]
        },
        "kegg_pathway": {}
    }, "ABCG2": {
        "name": "ATP binding cassette subfamily G member 2 (Junior blood group)",
        "description": "The membrane-associated protein encoded by this gene is included in the superfamily of ATP-binding cassette (ABC) transporters. ABC proteins transport various molecules across extra- and intra-cellular membranes. ABC genes are divided into seven distinct subfamilies (ABC1, MDR/TAP, MRP, ALD, OABP, GCN20, White). This protein is a member of the White subfamily. Alternatively referred to as a breast cancer resistance protein, this protein functions as a xenobiotic transporter which may play a major role in multi-drug resistance. It likely serves as a cellular defense mechanism in response to mitoxantrone and anthracycline exposure. Significant expression of this protein has been observed in the placenta, which may suggest a potential role for this molecule in placenta tissue. Multiple transcript variants encoding different isoforms have been found for this gene. [provided by RefSeq, Apr 2012].",
        "entrez": "9429",
        "ensembl": "ENSG00000118777",
        "uniprot": "Q9UNQ0",
        "hgnc": "74",
        "go_terms": {
            "biological_process": [{
                "name": "drug transmembrane transport",
                "definition": "The process in which a drug is transported across a membrane. [GOC:ai, GOC:bf]",
                "id": "GO:0006855"
            }, {
                "name": "cellular iron ion homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of iron ions at the level of a cell. [GOC:ai, GOC:mah]",
                "id": "GO:0006879"
            }, {
                "name": "heme transport",
                "definition": "The directed movement of heme, any compound of iron complexed in a porphyrin (tetrapyrrole) ring, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0015886"
            }, {
                "name": "cholesterol efflux",
                "definition": "The directed movement of cholesterol, cholest-5-en-3-beta-ol, out of a cell or organelle. [GOC:sart]",
                "id": "GO:0033344"
            }, {
                "name": "response to drug",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a drug stimulus. A drug is a substance used in the diagnosis, treatment or prevention of a disease. [GOC:jl]",
                "id": "GO:0042493"
            }, {
                "name": "xenobiotic transport",
                "definition": "The directed movement of a xenobiotic, a compound foreign to living organisms, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:go_curators]",
                "id": "GO:0042908"
            }, {
                "name": "urate metabolic process",
                "definition": "The chemical reactions and pathways involving urate, the anion of uric acid, 2,6,8-trioxypurine, the end product of purine metabolism in certain mammals and the main excretory product in uricotelic animals. [ISBN:0198506732]",
                "id": "GO:0046415"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "apical plasma membrane",
                "definition": "The region of the plasma membrane located at the apical end of the cell. [GOC:curators]",
                "id": "GO:0016324"
            }, {
                "name": "mitochondrial membrane",
                "definition": "Either of the lipid bilayers that surround the mitochondrion and form the mitochondrial envelope. [GOC:mah, NIF_Subcellular:sao1045389829]",
                "id": "GO:0031966"
            }, {
                "name": "receptor complex",
                "definition": "Any protein complex that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:go_curators]",
                "id": "GO:0043235"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "transporter activity",
                "definition": "Enables the directed movement of substances (such as macromolecules, small molecules, ions) into, out of or within a cell, or between cells. A transporter is in a fixed location in the cell and allows molecules to pass via a channel or a pore in its structure. [GOC:ai, GOC:dgf]",
                "id": "GO:0005215"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "xenobiotic transmembrane transporting ATPase activity",
                "definition": "Catalysis of the reaction: ATP + H2O + xenobiotic(in) = ADP + phosphate + xenobiotic(out). [EC:3.6.3.44]",
                "id": "GO:0008559"
            }, {
                "name": "heme transporter activity",
                "definition": "Enables the directed movement of heme, any compound of iron complexed in a porphyrin (tetrapyrrole) ring, into, out of or within a cell, or between cells. [GOC:ai]",
                "id": "GO:0015232"
            }, {
                "name": "ATPase activity",
                "definition": "Catalysis of the reaction: ATP + H2O = ADP + phosphate + 2 H+. May or may not be coupled to another reaction. [EC:3.6.1.3, GOC:jl]",
                "id": "GO:0016887"
            }, {
                "name": "cholesterol transporter activity",
                "definition": "Enables the directed movement of cholesterol into, out of or within a cell, or between cells. Cholesterol is the principal sterol of vertebrates and the precursor of many steroids, including bile acids and steroid hormones. [GOC:ai]",
                "id": "GO:0017127"
            }, {
                "name": "ATPase activity, coupled to transmembrane movement of substances",
                "definition": "Catalysis of the reaction: ATP + H2O = ADP + phosphate, to directly drive the active transport of a substance across a membrane. [EC:3.6.1.3, GOC:jl]",
                "id": "GO:0042626"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "protein homodimerization activity",
                "definition": "Interacting selectively and non-covalently with an identical protein to form a homodimer. [GOC:jl]",
                "id": "GO:0042803"
            }]
        },
        "kegg_pathway": {}
    }, "NR2E3": {
        "name": "nuclear receptor subfamily 2 group E member 3",
        "description": "This protein is part of a large family of nuclear receptor transcription factors involved in signaling pathways. Nuclear receptors have been shown to regulate pathways involved in embryonic development, as well as in maintenance of proper cell function in adults. Members of this family are characterized by discrete domains that function in DNA and ligand binding. This gene encodes a retinal nuclear receptor that is a ligand-dependent transcription factor. Defects in this gene are a cause of enhanced S cone syndrome. Alternatively spliced transcript variants encoding different isoforms have been identified. [provided by RefSeq, Jul 2008].",
        "entrez": "10002",
        "ensembl": "ENSG00000278570",
        "uniprot": "Q9Y5X4",
        "hgnc": "7974",
        "go_terms": {
            "biological_process": [{
                "name": "negative regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0000122"
            }, {
                "name": "transcription, DNA-templated",
                "definition": "The cellular synthesis of RNA on a template of DNA. [GOC:jl, GOC:txnOH]",
                "id": "GO:0006351"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "transcription from RNA polymerase II promoter",
                "definition": "The synthesis of RNA from a DNA template by RNA polymerase II, originating at an RNA polymerase II promoter. Includes transcription of messenger RNA (mRNA) and certain small nuclear RNAs (snRNAs). [GOC:jl, GOC:txnOH, ISBN:0321000382]",
                "id": "GO:0006366"
            }, {
                "name": "transcription initiation from RNA polymerase II promoter",
                "definition": "Any process involved in the assembly of the RNA polymerase II preinitiation complex (PIC) at an RNA polymerase II promoter region of a DNA template, resulting in the subsequent synthesis of RNA from that promoter. The initiation phase includes PIC assembly and the formation of the first few bonds in the RNA chain, including abortive initiation, which occurs when the first few nucleotides are repeatedly synthesized and then released. Promoter clearance, or release, is the transition between the initiation and elongation phases of transcription. [GOC:mah, GOC:txnOH]",
                "id": "GO:0006367"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "visual perception",
                "definition": "The series of events required for an organism to receive a visual stimulus, convert it to a molecular signal, and recognize and characterize the signal. Visual stimuli are detected in the form of photons and are processed to form an image. [GOC:ai]",
                "id": "GO:0007601"
            }, {
                "name": "phototransduction",
                "definition": "The sequence of reactions within a cell required to convert absorbed photons into a molecular signal. [GOC:go_curators]",
                "id": "GO:0007602"
            }, {
                "name": "negative regulation of cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008285"
            }, {
                "name": "intracellular receptor signaling pathway",
                "definition": "Any series of molecular signals initiated by a ligand binding to an receptor located within a cell. [GOC:bf, GOC:mah]",
                "id": "GO:0030522"
            }, {
                "name": "eye photoreceptor cell development",
                "definition": "Development of a photoreceptor, a sensory cell in the eye that reacts to the presence of light. They usually contain a pigment that undergoes a chemical change when light is absorbed, thus stimulating a nerve. [GOC:jl, ISBN:0192800981]",
                "id": "GO:0042462"
            }, {
                "name": "steroid hormone mediated signaling pathway",
                "definition": "A series of molecular signals mediated by a steroid hormone binding to a receptor. [PMID:12606724]",
                "id": "GO:0043401"
            }, {
                "name": "positive regulation of rhodopsin gene expression",
                "definition": "Any process that activates or increases the frequency, rate or extent of rhodopsin gene expression. [GOC:dph, GOC:go_curators, GOC:tb]",
                "id": "GO:0045872"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "response to stimulus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus. The process begins with detection of the stimulus and ends with a change in state or activity or the cell or organism. [GOC:ai, GOC:bf]",
                "id": "GO:0050896"
            }, {
                "name": "retina development in camera-type eye",
                "definition": "The process whose specific outcome is the progression of the retina over time, from its formation to the mature structure. The retina is the innermost layer or coating at the back of the eyeball, which is sensitive to light and in which the optic nerve terminates. [GOC:bf, GOC:dph, ISBN:0815340729]",
                "id": "GO:0060041"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "transcription factor complex",
                "definition": "A protein complex that is capable of associating with DNA by direct binding, or via other DNA-binding proteins or complexes, and regulating transcription. [GOC:jl]",
                "id": "GO:0005667"
            }],
            "molecular_function": [{
                "name": "RNA polymerase II proximal promoter sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with a specific upstream regulatory DNA sequence (transcription factor recognition sequence or binding site) located in the proximal promoter of a gene transcribed by RNA polymerase II. The proximal promoter is in cis with and relatively close to the core promoter. [GOC:pg, GOC:txnOH]",
                "id": "GO:0000978"
            }, {
                "name": "transcriptional activator activity, RNA polymerase II proximal promoter sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with a sequence of DNA that is in cis with and relatively close to a core promoter for RNA polymerase II (RNAP II) in order to activate or increase the frequency, rate or extent of transcription from the RNAP II promoter. [GOC:txnOH]",
                "id": "GO:0001077"
            }, {
                "name": "transcriptional activator activity, RNA polymerase II transcription regulatory region sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with a sequence of DNA that is in the transcription regulatory region for RNA polymerase II (RNAP II) in order to activate or increase the frequency, rate or extent of transcription from the RNAP II promoter. [GOC:krc]",
                "id": "GO:0001228"
            }, {
                "name": "DNA binding",
                "definition": "Any molecular function by which a gene product interacts selectively and non-covalently with DNA (deoxyribonucleic acid). [GOC:dph, GOC:jl, GOC:tb, GOC:vw]",
                "id": "GO:0003677"
            }, {
                "name": "DNA binding transcription factor activity",
                "definition": "Interacting selectively and non-covalently with a specific DNA sequence (sometimes referred to as a motif) within the regulatory region of a gene in order to modulate transcription. [GOC:curators, GOC:txnOH]",
                "id": "GO:0003700"
            }, {
                "name": "steroid hormone receptor activity",
                "definition": "Combining with a steroid hormone and transmitting the signal within the cell to initiate a change in cell activity or function. [GOC:signaling, PMID:14708019]",
                "id": "GO:0003707"
            }, {
                "name": "nuclear receptor activity",
                "definition": "Combining with a signal and transmitting the signal to the transcriptional machinery by interacting selectively and non-covalently with a specific DNA sequence in order to modulate transcription by RNA polymerase II. [GOC:signaling, GOC:txnOH]",
                "id": "GO:0004879"
            }, {
                "name": "zinc ion binding",
                "definition": "Interacting selectively and non-covalently with zinc (Zn) ions. [GOC:ai]",
                "id": "GO:0008270"
            }, {
                "name": "sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with DNA of a specific nucleotide composition, e.g. GC-rich DNA binding, or with a specific sequence motif or type of DNA e.g. promotor binding or rDNA binding. [GOC:jl]",
                "id": "GO:0043565"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "KCNJ4": {
        "name": "potassium inwardly rectifying channel subfamily J member 4",
        "description": "Several different potassium channels are known to be involved with electrical signaling in the nervous system. One class is activated by depolarization whereas a second class is not. The latter are referred to as inwardly rectifying K+ channels, and they have a greater tendency to allow potassium to flow into the cell rather than out of it. This asymmetry in potassium ion conductance plays a key role in the excitability of muscle cells and neurons. The protein encoded by this gene is an integral membrane protein and member of the inward rectifier potassium channel family. The encoded protein has a small unitary conductance compared to other members of this protein family. Two transcript variants encoding the same protein have been found for this gene. [provided by RefSeq, Jul 2008].",
        "entrez": "3761",
        "ensembl": "ENSG00000168135",
        "uniprot": "P48050",
        "hgnc": "6265",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "potassium ion transport",
                "definition": "The directed movement of potassium ions (K+) into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006813"
            }, {
                "name": "potassium ion import",
                "definition": "The directed movement of potassium ions into a cell or organelle. [GOC:sm]",
                "id": "GO:0010107"
            }, {
                "name": "ion transmembrane transport",
                "definition": "A process in which an ion is transported across a membrane. [GOC:mah]",
                "id": "GO:0034220"
            }, {
                "name": "regulation of ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of ions from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0034765"
            }, {
                "name": "cardiac conduction",
                "definition": "Transfer of an organized electrical impulse across the heart to coordinate the contraction of cardiac muscles. The process begins with generation of an action potential (in the sinoatrial node (SA) in humans) and ends with a change in the rate, frequency, or extent of the contraction of the heart muscles. [GOC:dph]",
                "id": "GO:0061337"
            }, {
                "name": "potassium ion transmembrane transport",
                "definition": "A process in which a potassium ion is transported from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0071805"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "voltage-gated potassium channel complex",
                "definition": "A protein complex that forms a transmembrane channel through which potassium ions may cross a cell membrane in response to changes in membrane potential. [GOC:mah]",
                "id": "GO:0008076"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "basolateral plasma membrane",
                "definition": "The region of the plasma membrane that includes the basal end and sides of the cell. Often used in reference to animal polarized epithelial membranes, where the basal membrane is the part attached to the extracellular matrix, or in plant cells, where the basal membrane is defined with respect to the zygotic axis. [GOC:go_curators]",
                "id": "GO:0016323"
            }, {
                "name": "cell junction",
                "definition": "A cellular component that forms a specialized region of connection between two or more cells or between a cell and the extracellular matrix. At a cell junction, anchoring proteins extend through the plasma membrane to link cytoskeletal proteins in one cell to cytoskeletal proteins in neighboring cells or to proteins in the extracellular matrix. [GOC:mah, http://www.vivo.colostate.edu/hbooks/cmb/cells/pmemb/junctions_a.html, ISBN:0198506732]",
                "id": "GO:0030054"
            }, {
                "name": "cytoplasmic vesicle membrane",
                "definition": "The lipid bilayer surrounding a cytoplasmic vesicle. [GOC:mah]",
                "id": "GO:0030659"
            }, {
                "name": "cytoplasmic vesicle",
                "definition": "A vesicle found in the cytoplasm of a cell. [GOC:ai, GOC:mah, GOC:vesicles]",
                "id": "GO:0031410"
            }, {
                "name": "synapse",
                "definition": "The junction between a nerve fiber of one neuron and another neuron, muscle fiber or glial cell. As the nerve fiber approaches the synapse it enlarges into a specialized structure, the presynaptic nerve ending, which contains mitochondria and synaptic vesicles. At the tip of the nerve ending is the presynaptic membrane; facing it, and separated from it by a minute cleft (the synaptic cleft) is a specialized area of membrane on the receiving cell, known as the postsynaptic membrane. In response to the arrival of nerve impulses, the presynaptic nerve ending secretes molecules of neurotransmitters into the synaptic cleft. These diffuse across the cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045202"
            }, {
                "name": "postsynaptic membrane",
                "definition": "A specialized area of membrane facing the presynaptic membrane on the tip of the nerve ending and separated from it by a minute cleft (the synaptic cleft). Neurotransmitters cross the synaptic cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045211"
            }],
            "molecular_function": [{
                "name": "inward rectifier potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by an inwardly-rectifying voltage-gated channel. An inwardly rectifying current-voltage relation is one where at any given driving force the inward flow of K+ ions exceeds the outward flow for the opposite driving force. The inward-rectification is due to a voltage-dependent block of the channel pore by a specific ligand or ligands, and as a result the macroscopic conductance depends on the difference between membrane voltage and the K+ equilibrium potential rather than on membrane voltage itself. [GOC:cb, GOC:mah, PMID:14977398]",
                "id": "GO:0005242"
            }, {
                "name": "voltage-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a voltage-gated channel. An ion is an atom or group of atoms carrying an electric charge by virtue of having gained or lost one or more electrons. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0198506732, ISBN:0815340729]",
                "id": "GO:0005244"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "G-protein activated inward rectifier potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by an inwardly-rectifying voltage-gated channel, where the inward rectification is due to a voltage-dependent block of the channel pore by a G protein. An inwardly rectifying current-voltage relation is one where at any given driving force the inward flow of K+ ions exceeds the outward flow for the opposite driving force. [GOC:cb, GOC:mah]",
                "id": "GO:0015467"
            }, {
                "name": "PDZ domain binding",
                "definition": "Interacting selectively and non-covalently with a PDZ domain of a protein, a domain found in diverse signaling proteins. [GOC:go_curators, Pfam:PF00595]",
                "id": "GO:0030165"
            }]
        },
        "kegg_pathway": {}
    }, "KCNJ3": {
        "name": "potassium inwardly rectifying channel subfamily J member 3",
        "description": "Potassium channels are present in most mammalian cells, where they participate in a wide range of physiologic responses. The protein encoded by this gene is an integral membrane protein and inward-rectifier type potassium channel. The encoded protein, which has a greater tendency to allow potassium to flow into a cell rather than out of a cell, is controlled by G-proteins and plays an important role in regulating heartbeat. It associates with three other G-protein-activated potassium channels to form a heteromultimeric pore-forming complex that also couples to neurotransmitter receptors in the brain and whereby channel activation can inhibit action potential firing by hyperpolarizing the plasma membrane. These multimeric G-protein-gated inwardly-rectifying potassium (GIRK) channels may play a role in the pathophysiology of epilepsy, addiction, Down's syndrome, ataxia, and Parkinson's disease. Alternative splicing results in multiple transcript variants encoding distinct proteins. [provided by RefSeq, May 2012].",
        "entrez": "3760",
        "ensembl": "ENSG00000162989",
        "uniprot": "P48549",
        "hgnc": "6264",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "potassium ion transport",
                "definition": "The directed movement of potassium ions (K+) into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006813"
            }, {
                "name": "potassium ion import",
                "definition": "The directed movement of potassium ions into a cell or organelle. [GOC:sm]",
                "id": "GO:0010107"
            }, {
                "name": "regulation of ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of ions from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0034765"
            }, {
                "name": "response to electrical stimulus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an electrical stimulus. [GOC:ai]",
                "id": "GO:0051602"
            }, {
                "name": "regulation of heart rate by cardiac conduction",
                "definition": "A cardiac conduction process that modulates the frequency or rate of heart contraction. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086091"
            }, {
                "name": "membrane repolarization during atrial cardiac muscle cell action potential",
                "definition": "The process in which ions are transported across a membrane such that the atrial cardiomyocyte membrane potential changes in the direction from the positive membrane potential at the peak of the action potential towards the negative resting potential. [GOC:dph, GOC:mtg_cardiac_conduct_nov11, GOC:tb]",
                "id": "GO:0098914"
            }, {
                "name": "membrane repolarization during ventricular cardiac muscle cell action potential",
                "definition": "The process in which ions are transported across a membrane such that the ventricular cardiomyocyte membrane potential changes in the direction from the positive membrane potential at the peak of the action potential towards the negative resting potential. [GOC:BHF, GOC:dph, GOC:mtg_cardiac_conduct_nov11, GOC:tb]",
                "id": "GO:0098915"
            }, {
                "name": "ventricular cardiac muscle cell membrane repolarization",
                "definition": "The process in which ions are transported across the plasma membrane of a ventricular cardiac muscle cell such that the membrane potential changes in the repolarizing direction, toward the steady state potential. For example, the repolarization during an action potential is from a positive membrane potential towards a negative resting potential. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0099625"
            }, {
                "name": "potassium ion import across plasma membrane",
                "definition": "The directed movement of potassium ions from outside of a cell, across the plasma membrane and into the cytosol. [PMID:9139127]",
                "id": "GO:1990573"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "voltage-gated potassium channel complex",
                "definition": "A protein complex that forms a transmembrane channel through which potassium ions may cross a cell membrane in response to changes in membrane potential. [GOC:mah]",
                "id": "GO:0008076"
            }, {
                "name": "external side of plasma membrane",
                "definition": "The leaflet of the plasma membrane that faces away from the cytoplasm and any proteins embedded or anchored in it or attached to its surface. [GOC:dos, GOC:tb]",
                "id": "GO:0009897"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "T-tubule",
                "definition": "Invagination of the plasma membrane of a muscle cell that extends inward from the cell surface around each myofibril. The ends of T-tubules make contact with the sarcoplasmic reticulum membrane. [GOC:mtg_muscle, ISBN:0815316194]",
                "id": "GO:0030315"
            }],
            "molecular_function": [{
                "name": "inward rectifier potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by an inwardly-rectifying voltage-gated channel. An inwardly rectifying current-voltage relation is one where at any given driving force the inward flow of K+ ions exceeds the outward flow for the opposite driving force. The inward-rectification is due to a voltage-dependent block of the channel pore by a specific ligand or ligands, and as a result the macroscopic conductance depends on the difference between membrane voltage and the K+ equilibrium potential rather than on membrane voltage itself. [GOC:cb, GOC:mah, PMID:14977398]",
                "id": "GO:0005242"
            }, {
                "name": "voltage-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a voltage-gated channel. An ion is an atom or group of atoms carrying an electric charge by virtue of having gained or lost one or more electrons. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0198506732, ISBN:0815340729]",
                "id": "GO:0005244"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "G-protein activated inward rectifier potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by an inwardly-rectifying voltage-gated channel, where the inward rectification is due to a voltage-dependent block of the channel pore by a G protein. An inwardly rectifying current-voltage relation is one where at any given driving force the inward flow of K+ ions exceeds the outward flow for the opposite driving force. [GOC:cb, GOC:mah]",
                "id": "GO:0015467"
            }, {
                "name": "voltage-gated potassium channel activity involved in atrial cardiac muscle cell action potential repolarization",
                "definition": "Enables the transmembrane transfer of a potassium ion by a voltage-gated channel through the plasma membrane of an atrial cardiomyocyte contributing to the repolarization phase of an action potential. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086089"
            }, {
                "name": "voltage-gated potassium channel activity involved in ventricular cardiac muscle cell action potential repolarization",
                "definition": "Enables the transmembrane transfer of a potassium ion by a voltage-gated channel through the plasma membrane of a ventricular cardiomyocyte contributing to the repolarization phase of an action potential. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11, GOC:rl, GOC:TermGenie, PMID:8528244]",
                "id": "GO:1902282"
            }]
        },
        "kegg_pathway": {}
    }, "MAPK11": {
        "name": "mTOR signaling pathway",
        "description": "This gene encodes a member of a family of protein kinases that are involved in the integration of biochemical signals for a wide variety of cellular processes, including cell proliferation, differentiation, transcriptional regulation, and development. The encoded protein can be activated by proinflammatory cytokines and environmental stresses through phosphorylation by mitogen activated protein kinase kinases (MKKs). Alternative splicing results in multiple transcript variants. [provided by RefSeq, Mar 2014].",
        "entrez": "5600",
        "ensembl": "ENSG00000185386",
        "uniprot": "Q15759",
        "hgnc": "6873",
        "go_terms": {
            "biological_process": [{
                "name": "activation of MAPK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase (MAPK). [PMID:9561267]",
                "id": "GO:0000187"
            }, {
                "name": "transcription, DNA-templated",
                "definition": "The cellular synthesis of RNA on a template of DNA. [GOC:jl, GOC:txnOH]",
                "id": "GO:0006351"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "Ras protein signal transduction",
                "definition": "A series of molecular signals within the cell that are mediated by a member of the Ras superfamily of proteins switching to a GTP-bound active state. [GOC:bf]",
                "id": "GO:0007265"
            }, {
                "name": "positive regulation of gene expression",
                "definition": "Any process that increases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010628"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "vascular endothelial growth factor receptor signaling pathway",
                "definition": "Any series of molecular signals initiated by the binding of an extracellular ligand to a vascular endothelial growth factor receptor (VEGFR) located on the surface of the receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling, PR:000001971]",
                "id": "GO:0048010"
            }, {
                "name": "regulation of DNA binding transcription factor activity",
                "definition": "Any process that modulates the frequency, rate or extent of the activity of a transcription factor, any factor involved in the initiation or regulation of transcription. [GOC:ai]",
                "id": "GO:0051090"
            }, {
                "name": "positive regulation of muscle cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of muscle cell differentiation. [CL:0000187, GOC:ai]",
                "id": "GO:0051149"
            }, {
                "name": "stress-activated MAPK cascade",
                "definition": "A series of molecular signals in which a stress-activated MAP kinase cascade relays one or more of the signals; MAP kinase cascades involve at least three protein kinase activities and culminate in the phosphorylation and activation of a MAP kinase. [GOC:ai, PMID:15936270]",
                "id": "GO:0051403"
            }, {
                "name": "regulation of cardiac muscle cell proliferation",
                "definition": "Any process that modulates the frequency, rate or extent of cardiac muscle cell proliferation. [GOC:dph, GOC:rph]",
                "id": "GO:0060043"
            }, {
                "name": "negative regulation of cardiac muscle cell proliferation",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cardiac muscle cell proliferation. [GOC:dph, GOC:rph]",
                "id": "GO:0060044"
            }, {
                "name": "cellular response to interleukin-1",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an interleukin-1 stimulus. [GOC:mah]",
                "id": "GO:0071347"
            }, {
                "name": "cellular response to virus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus from a virus. [GOC:dos]",
                "id": "GO:0098586"
            }, {
                "name": "regulation of signal transduction by p53 class mediator",
                "definition": "Any process that modulates the frequency, rate or extent of signal transduction by p53 class mediator. [GOC:TermGenie]",
                "id": "GO:1901796"
            }, {
                "name": "positive regulation of interleukin-12 secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of interleukin-12 secretion. [GOC:obol]",
                "id": "GO:2001184"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "MAP kinase activity",
                "definition": "Catalysis of the reaction: protein + ATP = protein phosphate + ADP. This reaction is the phosphorylation of proteins. Mitogen-activated protein kinase; a family of protein kinases that perform a crucial step in relaying signals from the plasma membrane to the nucleus. They are activated by a wide range of proliferation- or differentiation-inducing signals; activation is strong with agonists such as polypeptide growth factors and tumor-promoting phorbol esters, but weak (in most cell backgrounds) by stress stimuli. [GOC:ma, ISBN:0198547684]",
                "id": "GO:0004707"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.24",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }]
        }
    }, "CYP11B1": {
        "name": "Steroid hormone biosynthesis",
        "description": "This gene encodes a member of the cytochrome P450 superfamily of enzymes. The cytochrome P450 proteins are monooxygenases which catalyze many reactions involved in drug metabolism and synthesis of cholesterol, steroids and other lipids. This protein localizes to the mitochondrial inner membrane and is involved in the conversion of progesterone to cortisol in the adrenal cortex. Mutations in this gene cause congenital adrenal hyperplasia due to 11-beta-hydroxylase deficiency. Transcript variants encoding different isoforms have been noted for this gene. [provided by RefSeq, Jul 2008].",
        "entrez": "1584",
        "ensembl": "ENSG00000160882",
        "uniprot": "P15538",
        "hgnc": "2591",
        "go_terms": {
            "biological_process": [{
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "steroid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of steroids, compounds with a 1,2,cyclopentanoperhydrophenanthrene nucleus; includes de novo formation and steroid interconversion by modification. [GOC:go_curators]",
                "id": "GO:0006694"
            }, {
                "name": "C21-steroid hormone biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of C21-steroid hormones, steroid compounds containing 21 carbons which function as hormones. [GOC:ai]",
                "id": "GO:0006700"
            }, {
                "name": "glucocorticoid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of glucocorticoids, hormonal C21 corticosteroids synthesized from cholesterol. [ISBN:0198506732]",
                "id": "GO:0006704"
            }, {
                "name": "immune response",
                "definition": "Any immune system process that functions in the calibrated response of an organism to a potential internal or invasive threat. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0006955"
            }, {
                "name": "steroid metabolic process",
                "definition": "The chemical reactions and pathways involving steroids, compounds with a 1,2,cyclopentanoperhydrophenanthrene nucleus. [ISBN:0198547684]",
                "id": "GO:0008202"
            }, {
                "name": "regulation of blood pressure",
                "definition": "Any process that modulates the force with which blood travels through the circulatory system. The process is controlled by a balance of processes that increase pressure and decrease pressure. [GOC:dph, GOC:mtg_cardio, ISBN:0721643949]",
                "id": "GO:0008217"
            }, {
                "name": "sterol metabolic process",
                "definition": "The chemical reactions and pathways involving sterols, steroids with one or more hydroxyl groups and a hydrocarbon side-chain in the molecule. [ISBN:0198547684]",
                "id": "GO:0016125"
            }, {
                "name": "aldosterone biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of aldosterone, a corticosteroid hormone that is produced by the zona glomerulosa of the adrenal cortex and regulates salt (sodium and potassium) and water balance. [PMID:16527843]",
                "id": "GO:0032342"
            }, {
                "name": "cellular response to hormone stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hormone stimulus. [GOC:mah]",
                "id": "GO:0032870"
            }, {
                "name": "cortisol biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of cortisol, the steroid hormone 11-beta-17,21-trihydroxypregn-4-ene-3,20-dione. Cortisol is synthesized from cholesterol in the adrenal gland and controls carbohydrate, fat and protein metabolism and has anti-inflammatory properties. [CHEBI:17650, GOC:BHF, GOC:mah, GOC:rl]",
                "id": "GO:0034651"
            }, {
                "name": "cellular response to potassium ion",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a potassium ion stimulus. [GOC:yaf]",
                "id": "GO:0035865"
            }, {
                "name": "glucose homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of glucose within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042593"
            }, {
                "name": "oxidation-reduction process",
                "definition": "A metabolic process that results in the removal or addition of one or more electrons to or from a substance, with or without the concomitant removal or addition of a proton or protons. [GOC:dhl, GOC:ecd, GOC:jh2, GOC:jid, GOC:mlg, GOC:rph]",
                "id": "GO:0055114"
            }],
            "cellular_component": [{
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "mitochondrial inner membrane",
                "definition": "The inner, i.e. lumen-facing, lipid bilayer of the mitochondrial envelope. It is highly folded to form cristae. [GOC:ai]",
                "id": "GO:0005743"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "mitochondrial membrane",
                "definition": "Either of the lipid bilayers that surround the mitochondrion and form the mitochondrial envelope. [GOC:mah, NIF_Subcellular:sao1045389829]",
                "id": "GO:0031966"
            }],
            "molecular_function": [{
                "name": "monooxygenase activity",
                "definition": "Catalysis of the incorporation of one atom from molecular oxygen into a compound and the reduction of the other atom of oxygen to water. [http://www.onelook.com/, ISBN:0198506732]",
                "id": "GO:0004497"
            }, {
                "name": "steroid 11-beta-monooxygenase activity",
                "definition": "Catalysis of the reaction: a steroid + reduced adrenal ferredoxin + O2 = an 11-beta-hydroxysteroid + oxidized adrenal ferredoxin + H2O. [EC:1.14.15.4]",
                "id": "GO:0004507"
            }, {
                "name": "iron ion binding",
                "definition": "Interacting selectively and non-covalently with iron (Fe) ions. [GOC:ai]",
                "id": "GO:0005506"
            }, {
                "name": "oxidoreductase activity",
                "definition": "Catalysis of an oxidation-reduction (redox) reaction, a reversible chemical reaction in which the oxidation state of an atom or atoms within a molecule is altered. One substrate acts as a hydrogen or electron donor and becomes oxidized, while the other acts as hydrogen or electron acceptor and becomes reduced. [GOC:go_curators]",
                "id": "GO:0016491"
            }, {
                "name": "oxidoreductase activity, acting on paired donors, with incorporation or reduction of molecular oxygen",
                "definition": "Catalysis of an oxidation-reduction (redox) reaction in which hydrogen or electrons are transferred from each of two donors, and molecular oxygen is reduced or incorporated into a donor. [GOC:mah]",
                "id": "GO:0016705"
            }, {
                "name": "heme binding",
                "definition": "Interacting selectively and non-covalently with heme, any compound of iron complexed in a porphyrin (tetrapyrrole) ring. [CHEBI:30413, GOC:ai]",
                "id": "GO:0020037"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "corticosterone 18-monooxygenase activity",
                "definition": "Catalysis of the reaction: corticosterone + reduced adrenal ferredoxin + O2 = 18-hydroxycorticosterone + oxidized adrenal ferredoxin + H2O. [EC:1.14.15.5, MetaCyc:CORTICOSTERONE-18-MONOOXYGENASE-RXN]",
                "id": "GO:0047783"
            }]
        },
        "kegg_pathway": {
            "ec": "1.14.15.4",
            "pathways": [{
                "pathway": "00140",
                "name": "Steroid hormone biosynthesis",
                "class": "Metabolism; Lipid metabolism"
            }]
        }
    }, "MAP2K2": {
        "name": "mitogen-activated protein kinase kinase 2",
        "description": "The protein encoded by this gene is a dual specificity protein kinase that belongs to the MAP kinase kinase family. This kinase is known to play a critical role in mitogen growth factor signal transduction. It phosphorylates and thus activates MAPK1/ERK2 and MAPK2/ERK3. The activation of this kinase itself is dependent on the Ser/Thr phosphorylation by MAP kinase kinase kinases. Mutations in this gene cause cardiofaciocutaneous syndrome (CFC syndrome), a disease characterized by heart defects, cognitive disability, and distinctive facial features similar to those found in Noonan syndrome. The inhibition or degradation of this kinase is also found to be involved in the pathogenesis of Yersinia and anthrax. A pseudogene, which is located on chromosome 7, has been identified for this gene. [provided by RefSeq, Jul 2008].",
        "entrez": "5605",
        "ensembl": "ENSG00000126934",
        "uniprot": "P36507",
        "hgnc": "6842",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "activation of MAPK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase (MAPK). [PMID:9561267]",
                "id": "GO:0000187"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "regulation of mitotic cell cycle",
                "definition": "Any process that modulates the rate or extent of progress through the mitotic cell cycle. [GOC:dph, GOC:go_curators, GOC:tb]",
                "id": "GO:0007346"
            }, {
                "name": "negative regulation of gene expression",
                "definition": "Any process that decreases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010629"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "regulation of stress-activated MAPK cascade",
                "definition": "Any process that modulates the frequency, rate or extent of signal transduction mediated by the stress-activated MAPK cascade. [GOC:mah]",
                "id": "GO:0032872"
            }, {
                "name": "proteolysis in other organism",
                "definition": "The chemical reactions and pathways performed by an organism resulting in the hydrolysis of proteins in another organism by cleavage of their peptide bonds. [GOC:bf, GOC:fj, PMID:15922779]",
                "id": "GO:0035897"
            }, {
                "name": "peptidyl-serine autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own serine amino acid residues, or a serine residue on an identical protein. [GOC:pm]",
                "id": "GO:0036289"
            }, {
                "name": "regulation of apoptotic process",
                "definition": "Any process that modulates the occurrence or rate of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0042981"
            }, {
                "name": "positive regulation of transcription, DNA-templated",
                "definition": "Any process that activates or increases the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045893"
            }, {
                "name": "ERK1 and ERK2 cascade",
                "definition": "An intracellular protein kinase cascade containing at least ERK1 or ERK2 (MAPKs), a MEK (a MAPKK) and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:add, GOC:signaling, ISBN:0121245462, ISBN:0896039986, PMID:20811974]",
                "id": "GO:0070371"
            }, {
                "name": "positive regulation of protein serine/threonine kinase activity",
                "definition": "Any process that increases the rate, frequency, or extent of protein serine/threonine kinase activity. [GOC:mah]",
                "id": "GO:0071902"
            }, {
                "name": "regulation of Golgi inheritance",
                "definition": "Any process that modulates the rate, frequency or extent of Golgi inheritance. Golgi inheritance is the partitioning of Golgi apparatus between daughter cells at cell division. [GOC:ascb_2009, GOC:dph, GOC:tb]",
                "id": "GO:0090170"
            }, {
                "name": "positive regulation of production of miRNAs involved in gene silencing by miRNA",
                "definition": "Any process that activates or increases the frequency, rate or extent of production of miRNAs involved in gene silencing by miRNA. [GO_REF:0000058, GOC:BHF, GOC:BHF_miRNA, GOC:rph, GOC:TermGenie, PMID:22269326]",
                "id": "GO:1903800"
            }, {
                "name": "regulation of early endosome to late endosome transport",
                "definition": "Any process that modulates the frequency, rate or extent of early endosome to late endosome transport. [GOC:BHF]",
                "id": "GO:2000641"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "early endosome",
                "definition": "A membrane-bounded organelle that receives incoming material from primary endocytic vesicles that have been generated by clathrin-dependent and clathrin-independent endocytosis; vesicles fuse with the early endosome to deliver cargo for sorting into recycling or degradation pathways. [GOC:mah, NIF_Subcellular:nlx_subcell_20090701, PMID:19696797]",
                "id": "GO:0005769"
            }, {
                "name": "late endosome",
                "definition": "A prelysosomal endocytic organelle differentiated from early endosomes by lower lumenal pH and different protein composition. Late endosomes are more spherical than early endosomes and are mostly juxtanuclear, being concentrated near the microtubule organizing center. [NIF_Subcellular:nlx_subcell_20090702, PMID:11964142, PMID:2557062]",
                "id": "GO:0005770"
            }, {
                "name": "peroxisomal membrane",
                "definition": "The lipid bilayer surrounding a peroxisome. [GOC:mah]",
                "id": "GO:0005778"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "microtubule",
                "definition": "Any of the long, generally straight, hollow tubes of internal diameter 12-15 nm and external diameter 24 nm found in a wide variety of eukaryotic cells; each consists (usually) of 13 protofilaments of polymeric tubulin, staggered in such a manner that the tubulin monomers are arranged in a helical pattern on the microtubular surface, and with the alpha/beta axes of the tubulin subunits parallel to the long axis of the tubule; exist in equilibrium with pool of tubulin monomers and can be rapidly assembled or disassembled in response to physiological stimuli; concerned with force generation, e.g. in the spindle. [ISBN:0879693568]",
                "id": "GO:0005874"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "cell-cell junction",
                "definition": "A cell junction that forms a connection between two or more cells in a multicellular organism; excludes direct cytoplasmic junctions such as ring canals. [GOC:dgh, GOC:hb, GOC:mah]",
                "id": "GO:0005911"
            }, {
                "name": "focal adhesion",
                "definition": "Small region on the surface of a cell that anchors the cell to the extracellular matrix and that forms a point of termination of actin filaments. [ISBN:0124325653, ISBN:0815316208]",
                "id": "GO:0005925"
            }, {
                "name": "cytoplasmic side of plasma membrane",
                "definition": "The leaflet the plasma membrane that faces the cytoplasm and any proteins embedded or anchored in it or attached to its surface. [GOC:dos, GOC:tb]",
                "id": "GO:0009898"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "MAP kinase kinase activity",
                "definition": "Catalysis of the concomitant phosphorylation of threonine (T) and tyrosine (Y) residues in a Thr-Glu-Tyr (TEY) thiolester sequence in a MAP kinase (MAPK) substrate. [ISBN:0198547684]",
                "id": "GO:0004708"
            }, {
                "name": "protein serine/threonine/tyrosine kinase activity",
                "definition": "Catalysis of the reactions: ATP + a protein serine = ADP + protein serine phosphate; ATP + a protein threonine = ADP + protein threonine phosphate; and ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [GOC:mah]",
                "id": "GO:0004712"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "PDZ domain binding",
                "definition": "Interacting selectively and non-covalently with a PDZ domain of a protein, a domain found in diverse signaling proteins. [GOC:go_curators, Pfam:PF00595]",
                "id": "GO:0030165"
            }, {
                "name": "protein serine/threonine kinase activator activity",
                "definition": "Binds to and increases the activity of a protein serine/threonine kinase. [GOC:go_curators]",
                "id": "GO:0043539"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "scaffold protein binding",
                "definition": "Interacting selectively and non-covalently with a scaffold protein. Scaffold proteins are crucial regulators of many key signaling pathways. Although not strictly defined in function, they are known to interact and/or bind with multiple members of a signaling pathway, tethering them into complexes. [GOC:BHF, GOC:sjp, PMID:10433269, Wikipedia:Scaffold_protein]",
                "id": "GO:0097110"
            }]
        },
        "kegg_pathway": {}
    }, "AVPR1B": {
        "name": "arginine vasopressin receptor 1B",
        "description": "The protein encoded by this gene acts as receptor for arginine vasopressin. This receptor belongs to the subfamily of G-protein coupled receptors which includes AVPR1A, V2R and OXT receptors. Its activity is mediated by G proteins which stimulate a phosphatidylinositol-calcium second messenger system. The receptor is primarily located in the anterior pituitary, where it stimulates ACTH release. It is expressed at high levels in ACTH-secreting pituitary adenomas as well as in bronchial carcinoids responsible for the ectopic ACTH syndrome. A spliced antisense transcript of this gene has been reported but its function is not known. [provided by RefSeq, Jul 2008].",
        "entrez": "553",
        "ensembl": "ENSG00000198049",
        "uniprot": "P47901",
        "hgnc": "896",
        "go_terms": {
            "biological_process": [{
                "name": "regulation of systemic arterial blood pressure by vasopressin",
                "definition": "The regulation of blood pressure mediated by the signaling molecule vasopressin. Vasopressin is produced in the hypothalamus, and affects vasoconstriction, and renal water transport. [GOC:mtg_cardio, ISBN:0721643949]",
                "id": "GO:0001992"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "activation of phospholipase C activity",
                "definition": "The initiation of the activity of the inactive enzyme phospolipase C as the result of a series of molecular signals generated as a consequence of a G-protein coupled receptor binding to its physiological ligand. [GOC:dph, GOC:mah, GOC:tb, PMID:8280098]",
                "id": "GO:0007202"
            }, {
                "name": "positive regulation of cytosolic calcium ion concentration",
                "definition": "Any process that increases the concentration of calcium ions in the cytosol. [GOC:ai]",
                "id": "GO:0007204"
            }, {
                "name": "cellular response to hormone stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hormone stimulus. [GOC:mah]",
                "id": "GO:0032870"
            }, {
                "name": "positive regulation of vasoconstriction",
                "definition": "Any process that activates or increases the frequency, rate or extent of vasoconstriction. [GOC:go_curators]",
                "id": "GO:0045907"
            }, {
                "name": "response to peptide",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a peptide stimulus. [GOC:pr, GOC:TermGenie]",
                "id": "GO:1901652"
            }],
            "cellular_component": [{
                "name": "endosome",
                "definition": "A vacuole to which materials ingested by endocytosis are delivered. [ISBN:0198506732, PMID:19696797]",
                "id": "GO:0005768"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "vasopressin receptor activity",
                "definition": "Combining with vasopressin to initiate a change in cell activity. [GOC:ai]",
                "id": "GO:0005000"
            }, {
                "name": "protein kinase C binding",
                "definition": "Interacting selectively and non-covalently with protein kinase C. [GOC:jl]",
                "id": "GO:0005080"
            }, {
                "name": "peptide binding",
                "definition": "Interacting selectively and non-covalently with peptides, any of a group of organic compounds comprising two or more amino acids linked by peptide bonds. [GOC:jl]",
                "id": "GO:0042277"
            }]
        },
        "kegg_pathway": {}
    }, "DYRK3": {
        "name": "dual specificity tyrosine phosphorylation regulated kinase 3",
        "description": "This gene product belongs to the DYRK family of dual-specificity protein kinases that catalyze autophosphorylation on serine/threonine and tyrosine residues. The members of this family share structural similarity, however, differ in their substrate specificity, suggesting their involvement in different cellular functions. The encoded protein has been shown to autophosphorylate on tyrosine residue and catalyze phosphorylation of histones H3 and H2B in vitro. Alternatively spliced transcript variants encoding different isoforms have been identified. [provided by RefSeq, Jul 2008].",
        "entrez": "8444",
        "ensembl": "ENSG00000143479",
        "uniprot": "O43781",
        "hgnc": "3094",
        "go_terms": {
            "biological_process": [{
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "erythrocyte differentiation",
                "definition": "The process in which a myeloid precursor cell acquires specializes features of an erythrocyte. [GOC:mah]",
                "id": "GO:0030218"
            }, {
                "name": "stress granule disassembly",
                "definition": "The disaggregation of a stress granule into its constituent protein and RNA parts. [GOC:BHF, PMID:19825938]",
                "id": "GO:0035617"
            }, {
                "name": "negative regulation of apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043066"
            }, {
                "name": "negative regulation of DNA damage response, signal transduction by p53 class mediator",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the cascade of processes induced by the cell cycle regulator phosphoprotein p53, or an equivalent protein, in response to the detection of DNA damage. [GOC:jl]",
                "id": "GO:0043518"
            }, {
                "name": "regulation of cellular response to stress",
                "definition": "Any process that modulates the frequency, rate or extent of a cellular response to stress. Cellular response to stress is a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating the organism is under stress. The stress is usually, but not necessarily, exogenous (e.g. temperature, humidity, ionizing radiation). [GOC:dhl]",
                "id": "GO:0080135"
            }, {
                "name": "regulation of TORC1 signaling",
                "definition": "Any process that modulates the frequency, rate or extent of TORC1 signaling. [GO_REF:0000058, GOC:TermGenie]",
                "id": "GO:1903432"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "cytoplasmic stress granule",
                "definition": "A dense aggregation in the cytosol composed of proteins and RNAs that appear when the cell is under stress. [GOC:ans, PMID:17284590, PMID:17601829, PMID:17967451, PMID:20368989]",
                "id": "GO:0010494"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "protein serine/threonine/tyrosine kinase activity",
                "definition": "Catalysis of the reactions: ATP + a protein serine = ADP + protein serine phosphate; ATP + a protein threonine = ADP + protein threonine phosphate; and ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [GOC:mah]",
                "id": "GO:0004712"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "TNF": {
        "name": "tumor necrosis factor",
        "description": "This gene encodes a multifunctional proinflammatory cytokine that belongs to the tumor necrosis factor (TNF) superfamily. This cytokine is mainly secreted by macrophages. It can bind to, and thus functions through its receptors TNFRSF1A/TNFR1 and TNFRSF1B/TNFBR. This cytokine is involved in the regulation of a wide spectrum of biological processes including cell proliferation, differentiation, apoptosis, lipid metabolism, and coagulation. This cytokine has been implicated in a variety of diseases, including autoimmune diseases, insulin resistance, and cancer. Knockout studies in mice also suggested the neuroprotective function of this cytokine. [provided by RefSeq, Jul 2008].",
        "entrez": "7124",
        "ensembl": "ENSG00000230108",
        "uniprot": "P01375",
        "hgnc": "11892",
        "go_terms": {
            "biological_process": [{
                "name": "protein import into nucleus, translocation",
                "definition": "A protein transport process that contributes to protein import into the nucleus, and that results in the vectorial transfer of a cargo-carrier protein complex through the nuclear pore complex from the cytoplasmic side to the nucleoplasmic side of the nuclear envelope. [GOC:curators, ISBN:0198506732, PMID:14570049, PMID:9126736]",
                "id": "GO:0000060"
            }, {
                "name": "negative regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0000122"
            }, {
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "activation of MAPKKK activity",
                "definition": "Any process that initiates the activity of the inactive enzyme MAP kinase kinase kinase (MAPKKK). [PMID:9561267]",
                "id": "GO:0000185"
            }, {
                "name": "activation of MAPK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase (MAPK). [PMID:9561267]",
                "id": "GO:0000187"
            }, {
                "name": "positive regulation of cytokine production",
                "definition": "Any process that activates or increases the frequency, rate or extent of production of a cytokine. [GOC:add, ISBN:0781735149]",
                "id": "GO:0001819"
            }, {
                "name": "positive regulation of protein phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of addition of phosphate groups to amino acids within a protein. [GOC:hjd]",
                "id": "GO:0001934"
            }, {
                "name": "chronic inflammatory response to antigenic stimulus",
                "definition": "A chronic inflammatory response to an antigenic stimulus. A chronic inflammatory response persists indefinitely during days, weeks, or months in the life of an individual. [GOC:add, ISBN:0781735149]",
                "id": "GO:0002439"
            }, {
                "name": "negative regulation of cytokine secretion involved in immune response",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, or extent of cytokine secretion contributing to an immune response. [GOC:add]",
                "id": "GO:0002740"
            }, {
                "name": "activation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that initiates the activity of the inactive enzyme cysteine-type endopeptidase in the context of an apoptotic process. [GOC:al, GOC:dph, GOC:jl, GOC:mtg_apoptosis, GOC:tb, PMID:14744432, PMID:18328827, Wikipedia:Caspase]",
                "id": "GO:0006919"
            }, {
                "name": "inflammatory response",
                "definition": "The immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. The process is characterized by local vasodilation, extravasation of plasma into intercellular spaces and accumulation of white blood cells and macrophages. [GO_REF:0000022, GOC:mtg_15nov05, ISBN:0198506732]",
                "id": "GO:0006954"
            }, {
                "name": "immune response",
                "definition": "Any immune system process that functions in the calibrated response of an organism to a potential internal or invasive threat. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0006955"
            }, {
                "name": "I-kappaB kinase/NF-kappaB signaling",
                "definition": "The process in which a signal is passed on to downstream components within the cell through the I-kappaB-kinase (IKK)-dependent activation of NF-kappaB. The cascade begins with activation of a trimeric IKK complex (consisting of catalytic kinase subunits IKKalpha and/or IKKbeta, and the regulatory scaffold protein NEMO) and ends with the regulation of transcription of target genes by NF-kappaB. In a resting state, NF-kappaB dimers are bound to I-kappaB proteins, sequestering NF-kappaB in the cytoplasm. Phosphorylation of I-kappaB targets I-kappaB for ubiquitination and proteasomal degradation, thus releasing the NF-kappaB dimers, which can translocate to the nucleus to bind DNA and regulate transcription. [GOC:bf, GOC:jl, PMID:12773372, Reactome:REACT_13696.1]",
                "id": "GO:0007249"
            }, {
                "name": "extrinsic apoptotic signaling pathway via death domain receptors",
                "definition": "A series of molecular signals in which a signal is conveyed from the cell surface to trigger the apoptotic death of a cell. The pathway starts with a ligand binding to a death domain receptor on the cell surface, and ends when the execution phase of apoptosis is triggered. [GOC:mah, GOC:mtg_apoptosis]",
                "id": "GO:0008625"
            }, {
                "name": "response to virus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus from a virus. [GOC:hb]",
                "id": "GO:0009615"
            }, {
                "name": "response to salt stress",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating an increase or decrease in the concentration of salt (particularly but not exclusively sodium and chloride ions) in the environment. [GOC:jl]",
                "id": "GO:0009651"
            }, {
                "name": "regulation of receptor activity",
                "definition": "Any process that modulates the frequency, rate or extent of receptor activity. Receptor activity is when a molecule combines with an extracellular or intracellular messenger to initiate a change in cell activity. [GOC:dph, GOC:tb]",
                "id": "GO:0010469"
            }, {
                "name": "positive regulation of gene expression",
                "definition": "Any process that increases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010628"
            }, {
                "name": "negative regulation of gene expression",
                "definition": "Any process that decreases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010629"
            }, {
                "name": "regulation of tumor necrosis factor-mediated signaling pathway",
                "definition": "Any process that modulates the rate or extent of the tumor necrosis factor-mediated signaling pathway. The tumor necrosis factor-mediated signaling pathway is the series of molecular signals generated as a consequence of tumor necrosis factor binding to a cell surface receptor. [GOC:dph, GOC:tb]",
                "id": "GO:0010803"
            }, {
                "name": "negative regulation of lipid storage",
                "definition": "Any process that decreases the rate, frequency or extent of lipid storage. Lipid storage is the accumulation and maintenance in cells or tissues of lipids, compounds soluble in organic solvents but insoluble or sparingly soluble in aqueous solvents. Lipid reserves can be accumulated during early developmental stages for mobilization and utilization at later stages of development. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010888"
            }, {
                "name": "cytokine-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a cytokine to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:19295629]",
                "id": "GO:0019221"
            }, {
                "name": "sequestering of triglyceride",
                "definition": "The process of binding or confining any triester of glycerol such that it is separated from other components of a biological system. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0030730"
            }, {
                "name": "cortical actin cytoskeleton organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of actin-based cytoskeletal structures in the cell cortex, i.e. just beneath the plasma membrane. [GOC:dph, GOC:jl, GOC:mah, GOC:pf]",
                "id": "GO:0030866"
            }, {
                "name": "positive regulation of protein complex assembly",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein complex assembly. [GOC:mah]",
                "id": "GO:0031334"
            }, {
                "name": "positive regulation of fever generation",
                "definition": "Any process that activates or increases the frequency, rate, or extent of fever generation. [GOC:add]",
                "id": "GO:0031622"
            }, {
                "name": "lipopolysaccharide-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a lipopolysaccharide (LPS) to a receptor on the surface of a target cell, and ending with regulation of a downstream cellular process, e.g. transcription. Lipopolysaccharides are major components of the outer membrane of Gram-negative bacteria, making them prime targets for recognition by the immune system. [GOC:mah, GOC:signaling, PMID:15379975]",
                "id": "GO:0031663"
            }, {
                "name": "negative regulation of interleukin-6 production",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, or extent of interleukin-6 production. [GOC:mah]",
                "id": "GO:0032715"
            }, {
                "name": "positive regulation of chemokine production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of chemokine production. [GOC:mah]",
                "id": "GO:0032722"
            }, {
                "name": "positive regulation of interleukin-8 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-8 production. [GOC:mah]",
                "id": "GO:0032757"
            }, {
                "name": "receptor biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:mah]",
                "id": "GO:0032800"
            }, {
                "name": "positive regulation of peptidyl-serine phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of the phosphorylation of peptidyl-serine. [GOC:mah]",
                "id": "GO:0033138"
            }, {
                "name": "tumor necrosis factor-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a tumor necrosis factor to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling]",
                "id": "GO:0033209"
            }, {
                "name": "positive regulation of heterotypic cell-cell adhesion",
                "definition": "Any process that activates or increases the frequency, rate, or extent of heterotypic cell-cell adhesion. [GOC:add]",
                "id": "GO:0034116"
            }, {
                "name": "negative regulation of myosin-light-chain-phosphatase activity",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of myosin-light-chain-phosphatase activity. [GOC:bf, GOC:go_curators]",
                "id": "GO:0035509"
            }, {
                "name": "positive regulation of NF-kappaB import into nucleus",
                "definition": "Any process that activates or increases the frequency, rate or extent of transfer of NF-kappaB, a transcription factor for eukaryotic RNA polymerase II promoters, from the cytoplasm into the nucleus, across the nuclear membrane. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0042346"
            }, {
                "name": "positive regulation of apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043065"
            }, {
                "name": "positive regulation of programmed cell death",
                "definition": "Any process that activates or increases the frequency, rate or extent of programmed cell death, cell death resulting from activation of endogenous cellular processes. [GOC:jl]",
                "id": "GO:0043068"
            }, {
                "name": "regulation of I-kappaB kinase/NF-kappaB signaling",
                "definition": "Any process that modulates I-kappaB kinase/NF-kappaB signaling. [GOC:jl, PMID:12773372]",
                "id": "GO:0043122"
            }, {
                "name": "positive regulation of I-kappaB kinase/NF-kappaB signaling",
                "definition": "Any process that activates or increases the frequency, rate or extent of I-kappaB kinase/NF-kappaB signaling. [GOC:jl]",
                "id": "GO:0043123"
            }, {
                "name": "negative regulation of protein complex disassembly",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of protein complex disassembly, the disaggregation of a protein complex into its constituent components. [GOC:jl]",
                "id": "GO:0043242"
            }, {
                "name": "positive regulation of protein complex disassembly",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein complex disassembly, the disaggregation of a protein complex into its constituent components. [GOC:jl]",
                "id": "GO:0043243"
            }, {
                "name": "positive regulation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that activates or increases the activity of a cysteine-type endopeptidase involved in the apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043280"
            }, {
                "name": "positive regulation of MAP kinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of MAP kinase activity. [GOC:dph, GOC:go_curators]",
                "id": "GO:0043406"
            }, {
                "name": "protein kinase B signaling",
                "definition": "A series of reactions, mediated by the intracellular serine/threonine kinase protein kinase B (also called AKT), which occurs as a result of a single trigger reaction or compound. [GOC:bf, PMID:20517722]",
                "id": "GO:0043491"
            }, {
                "name": "positive regulation of JUN kinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of JUN kinase activity. [GOC:jl]",
                "id": "GO:0043507"
            }, {
                "name": "negative regulation of viral genome replication",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of viral genome replication. [GOC:go_curators]",
                "id": "GO:0045071"
            }, {
                "name": "positive regulation of chemokine biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of chemokines. [GOC:go_curators]",
                "id": "GO:0045080"
            }, {
                "name": "positive regulation of interleukin-8 biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of interleukin-8. [GOC:go_curators]",
                "id": "GO:0045416"
            }, {
                "name": "positive regulation of nitric oxide biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of nitric oxide. [GOC:go_curators]",
                "id": "GO:0045429"
            }, {
                "name": "negative regulation of fat cell differentiation",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of adipocyte differentiation. [GOC:go_curators]",
                "id": "GO:0045599"
            }, {
                "name": "positive regulation of osteoclast differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of osteoclast differentiation. [GOC:go_curators]",
                "id": "GO:0045672"
            }, {
                "name": "positive regulation of protein catabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the breakdown of a protein by the destruction of the native, active configuration, with or without the hydrolysis of peptide bonds. [GOC:go_curators]",
                "id": "GO:0045732"
            }, {
                "name": "positive regulation of cell adhesion",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell adhesion. [GOC:go_curators]",
                "id": "GO:0045785"
            }, {
                "name": "positive regulation of protein kinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein kinase activity. [GOC:go_curators]",
                "id": "GO:0045860"
            }, {
                "name": "negative regulation of transcription, DNA-templated",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045892"
            }, {
                "name": "positive regulation of transcription, DNA-templated",
                "definition": "Any process that activates or increases the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045893"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "embryonic digestive tract development",
                "definition": "The process whose specific outcome is the progression of the gut over time, from its formation to the mature structure during embryonic development. The gut is the region of the digestive tract extending from the beginning of the intestines to the anus. [GOC:go_curators]",
                "id": "GO:0048566"
            }, {
                "name": "positive regulation of smooth muscle cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of smooth muscle cell proliferation. [CL:0000192, GOC:ebc]",
                "id": "GO:0048661"
            }, {
                "name": "positive regulation of cytokine secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of the regulated release of cytokines from a cell. [GOC:ai]",
                "id": "GO:0050715"
            }, {
                "name": "positive regulation of phagocytosis",
                "definition": "Any process that activates or increases the frequency, rate or extent of phagocytosis. [GOC:ai]",
                "id": "GO:0050766"
            }, {
                "name": "regulation of insulin secretion",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of insulin. [GOC:ai]",
                "id": "GO:0050796"
            }, {
                "name": "leukocyte tethering or rolling",
                "definition": "Transient adhesive interactions between leukocytes and endothelial cells lining blood vessels. Carbohydrates on circulating leukocytes bind selectins on the vessel wall causing the leukocytes to slow down and roll along the inner surface of the vessel wall. During this rolling motion, transitory bonds are formed and broken between selectins and their ligands. Typically the first step in cellular extravasation (the movement of leukocytes out of the circulatory system, towards the site of tissue damage or infection). [GOC:bf, ISBN:0781735149, PMID:14680625, PMID:14708592, PMID:7507411, PMID:8600538, Wikipedia:Leukocyte_extravasation]",
                "id": "GO:0050901"
            }, {
                "name": "negative regulation of lipid catabolic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the chemical reactions and pathways resulting in the breakdown of lipids. [GOC:ai]",
                "id": "GO:0050995"
            }, {
                "name": "positive regulation of membrane protein ectodomain proteolysis",
                "definition": "Any process that activates or increases the frequency, rate or extent of membrane protein ectodomain peptidolysis. [GOC:ai]",
                "id": "GO:0051044"
            }, {
                "name": "positive regulation of DNA binding transcription factor activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of activity of a transcription factor, any factor involved in the initiation or regulation of transcription. [GOC:ai]",
                "id": "GO:0051091"
            }, {
                "name": "positive regulation of NF-kappaB transcription factor activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of activity of the transcription factor NF-kappaB. [GOC:dph, GOC:tb, PMID:15087454, PMID:15170030]",
                "id": "GO:0051092"
            }, {
                "name": "positive regulation of protein transport",
                "definition": "Any process that activates or increases the frequency, rate or extent of the directed movement of a protein into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0051222"
            }, {
                "name": "response to glucocorticoid",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a glucocorticoid stimulus. Glucocorticoids are hormonal C21 corticosteroids synthesized from cholesterol with the ability to bind with the cortisol receptor and trigger similar effects. Glucocorticoids act primarily on carbohydrate and protein metabolism, and have anti-inflammatory effects. [CHEBI:24261, GOC:ai, PMID:9884123]",
                "id": "GO:0051384"
            }, {
                "name": "positive regulation of NFAT protein import into nucleus",
                "definition": "Any process that activates or increases the frequency, rate or extent of the movement of an NFAT protein from the cytoplasm to the nucleus. [GOC:ai]",
                "id": "GO:0051533"
            }, {
                "name": "positive regulation of vitamin D biosynthetic process",
                "definition": "Any process that increases the rate, frequency or extent of a vitamin D biosynthetic process. Vitamin D biosynthesis is the chemical reactions and pathways resulting in the formation of vitamin D, any of a group of related, fat-soluble compounds that are derived from delta-5,7 steroids and play a central role in calcium metabolism. Specific forms of vitamin D include calciferol (ergocalciferol; vitamin D2) and cholecalciferol (calciol; vitamin D3). [CHEBI:27300, GOC:BHF, GOC:mah, ISBN:0471331309]",
                "id": "GO:0060557"
            }, {
                "name": "positive regulation of calcidiol 1-monooxygenase activity",
                "definition": "Any process that increases the rate, frequency or extent of calcidiol 1-monooxygenase activity. Calcidiol 1-monooxygenase activity is the catalysis of the reaction: calcidiol + NADPH + H+ + O2 = calcitriol + NADP+ + H2O. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0060559"
            }, {
                "name": "negative regulation of branching involved in lung morphogenesis",
                "definition": "Any process that decreases the rate, frequency, or extent of the process in which a highly ordered sequence of patterning events generates the branched structures of the lung, consisting of reiterated combinations of bud outgrowth, elongation, and dichotomous subdivision of terminal units. [GOC:dph, GOC:yaf]",
                "id": "GO:0061048"
            }, {
                "name": "positive regulation of ERK1 and ERK2 cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the ERK1 and ERK2 cascade. [GOC:mah]",
                "id": "GO:0070374"
            }, {
                "name": "cellular response to nicotine",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a nicotine stimulus. [GOC:mah]",
                "id": "GO:0071316"
            }, {
                "name": "cellular response to organic cyclic compound",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic cyclic compound stimulus. [GOC:mah]",
                "id": "GO:0071407"
            }, {
                "name": "death-inducing signaling complex assembly",
                "definition": "A process of protein complex assembly in which the arrangement and bonding together of the set of components that form the protein complex is mediated by a death domain (DD) interaction, as part of the extrinsic apoptotic signaling pathway. [GOC:amm, GOC:mtg_apoptosis, InterPro:IPR000488]",
                "id": "GO:0071550"
            }, {
                "name": "positive regulation of mononuclear cell migration",
                "definition": "Any process that increases the rate, frequency or extent of mononuclear cell migration. Mononuclear cell migration is the movement of a mononuclear cell within or between different tissues and organs of the body. [GOC:mah]",
                "id": "GO:0071677"
            }, {
                "name": "positive regulation of podosome assembly",
                "definition": "Any process that activates or increases the rate or extent of podosome assembly. [GOC:mah, GOC:sl]",
                "id": "GO:0071803"
            }, {
                "name": "protein localization to plasma membrane",
                "definition": "A process in which a protein is transported to, or maintained in, a specific location in the plasma membrane. [GOC:mah]",
                "id": "GO:0072659"
            }, {
                "name": "extrinsic apoptotic signaling pathway",
                "definition": "A series of molecular signals in which a signal is conveyed from the cell surface to trigger the apoptotic death of a cell. The pathway starts with either a ligand binding to a cell surface receptor, or a ligand being withdrawn from a cell surface receptor (e.g. in the case of signaling by dependence receptors), and ends when the execution phase of apoptosis is triggered. [GOC:mtg_apoptosis, GOC:yaf, PMID:17340152]",
                "id": "GO:0097191"
            }, {
                "name": "necroptotic signaling pathway",
                "definition": "A series of molecular signals which triggers the necroptotic death of a cell. The pathway starts with reception of a signal, is characterized by activation of receptor-interacting serine/threonine-protein kinase 1 and/or 3 (RIPK1/3, also called RIP1/3), and ends when the execution phase of necroptosis is triggered. [GOC:mtg_apoptosis, PMID:20823910]",
                "id": "GO:0097527"
            }, {
                "name": "positive regulation of NIK/NF-kappaB signaling",
                "definition": "Any process that activates or increases the frequency, rate or extent of NIK/NF-kappaB signaling. [GOC:TermGenie]",
                "id": "GO:1901224"
            }, {
                "name": "positive regulation of superoxide dismutase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of superoxide dismutase activity. [GOC:TermGenie]",
                "id": "GO:1901671"
            }, {
                "name": "regulation of establishment of endothelial barrier",
                "definition": "Any process that modulates the frequency, rate or extent of establishment of endothelial barrier. [GO_REF:0000058, GOC:als, GOC:TermGenie, PMID:24851274]",
                "id": "GO:1903140"
            }, {
                "name": "negative regulation of bicellular tight junction assembly",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of tight junction assembly. [GO_REF:0000058, GOC:jz, GOC:TermGenie, PMID:25050009]",
                "id": "GO:1903347"
            }, {
                "name": "positive regulation of leukocyte adhesion to vascular endothelial cell",
                "definition": "Any process that activates or increases the frequency, rate or extent of leukocyte adhesion to vascular endothelial cell. [GO_REF:0000058, GOC:bc, GOC:BHF, GOC:BHF_miRNA, GOC:TermGenie, PMID:23897866]",
                "id": "GO:1904996"
            }, {
                "name": "positive regulation of leukocyte adhesion to arterial endothelial cell",
                "definition": "Any process that activates or increases the frequency, rate or extent of leukocyte adhesion to arterial endothelial cell. [GO_REF:0000058, GOC:bc, GOC:BHF, GOC:BHF_miRNA, GOC:TermGenie, PMID:22267480]",
                "id": "GO:1904999"
            }, {
                "name": "positive regulation of protein localization to cell surface",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein localization to the cell surface. [GOC:obol]",
                "id": "GO:2000010"
            }, {
                "name": "positive regulation of ceramide biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of ceramide biosynthetic process. [GOC:dph]",
                "id": "GO:2000304"
            }, {
                "name": "positive regulation of blood microparticle formation",
                "definition": "Any process that activates or increases the frequency, rate or extent of blood microparticle formation. [GOC:BHF, GOC:mah]",
                "id": "GO:2000334"
            }, {
                "name": "positive regulation of chemokine (C-X-C motif) ligand 2 production",
                "definition": "Any process that activates or increases the frequency, rate or extent of chemokine (C-X-C motif) ligand 2 production. [GOC:BHF, GOC:mah]",
                "id": "GO:2000343"
            }, {
                "name": "negative regulation of extrinsic apoptotic signaling pathway in absence of ligand",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of extrinsic apoptotic signaling pathway in absence of ligand. [GOC:mtg_apoptosis]",
                "id": "GO:2001240"
            }],
            "cellular_component": [{
                "name": "phagocytic cup",
                "definition": "An invagination of the cell membrane formed by an actin dependent process during phagocytosis. Following internalization it is converted into a phagosome. [PMID:10358769]",
                "id": "GO:0001891"
            }, {
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "extracellular space",
                "definition": "That part of a multicellular organism outside the cells proper, usually taken to be outside the plasma membranes, and occupied by fluid. [ISBN:0198547684]",
                "id": "GO:0005615"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "external side of plasma membrane",
                "definition": "The leaflet of the plasma membrane that faces away from the cytoplasm and any proteins embedded or anchored in it or attached to its surface. [GOC:dos, GOC:tb]",
                "id": "GO:0009897"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }, {
                "name": "recycling endosome",
                "definition": "An organelle consisting of a network of tubules that functions in targeting molecules, such as receptors transporters and lipids, to the plasma membrane. [GOC:dph, GOC:jid, GOC:kmv, GOC:rph, PMID:10930469, PMID:15601896, PMID:16246101, PMID:21556374, PMID:21562044]",
                "id": "GO:0055037"
            }],
            "molecular_function": [{
                "name": "protease binding",
                "definition": "Interacting selectively and non-covalently with any protease or peptidase. [GOC:hjd]",
                "id": "GO:0002020"
            }, {
                "name": "cytokine activity",
                "definition": "Functions to control the survival, growth, differentiation and effector function of tissues and cells. [ISBN:0198599471]",
                "id": "GO:0005125"
            }, {
                "name": "tumor necrosis factor receptor binding",
                "definition": "Interacting selectively and non-covalently with the tumor necrosis factor receptor. [GOC:ai]",
                "id": "GO:0005164"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "transcription regulatory region DNA binding",
                "definition": "Interacting selectively and non-covalently with a DNA region that regulates the transcription of a region of DNA, which may be a gene, cistron, or operon. Binding may occur as a sequence specific interaction or as an interaction observed only once a factor has been recruited to the DNA by other factors. [GOC:jl, GOC:txnOH, SO:0005836]",
                "id": "GO:0044212"
            }]
        },
        "kegg_pathway": {}
    }, "NPY5R": {
        "name": "neuropeptide Y receptor Y5",
        "description": "The protein encoded by this gene is a receptor for neuropeptide Y and peptide YY. The encoded protein appears to be involved in regulating food intake, with defects in this gene being associated with eating disorders. Also, the encoded protein is involved in a pathway that protects neuroblastoma cells from chemotherapy-induced cell death, providing a possible therapeutic target against neuroblastoma. Three transcript variants encoding the same protein have been found for this gene. [provided by RefSeq, Nov 2015].",
        "entrez": "4889",
        "ensembl": "ENSG00000164129",
        "uniprot": "Q15761",
        "hgnc": "7958",
        "go_terms": {
            "biological_process": [{
                "name": "positive regulation of acute inflammatory response",
                "definition": "Any process that activates or increases the frequency, rate, or extent of an acute inflammatory response. [GOC:add]",
                "id": "GO:0002675"
            }, {
                "name": "negative regulation of acute inflammatory response to antigenic stimulus",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, or extent of an acute inflammatory response to an antigenic stimulus. [GOC:add]",
                "id": "GO:0002865"
            }, {
                "name": "outflow tract morphogenesis",
                "definition": "The process in which the anatomical structures of the outflow tract are generated and organized. The outflow tract is the portion of the heart through which blood flows into the arteries. [GOC:mtg_heart, UBERON:0004145]",
                "id": "GO:0003151"
            }, {
                "name": "cardiac left ventricle morphogenesis",
                "definition": "The process in which the left cardiac ventricle is generated and organized. [GOC:mtg_heart]",
                "id": "GO:0003214"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "neuropeptide signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a peptide neurotransmitter binding to a cell surface receptor. [GOC:mah, ISBN:0815316194]",
                "id": "GO:0007218"
            }, {
                "name": "chemical synaptic transmission",
                "definition": "The vesicular release of classical neurotransmitter molecules from a presynapse, across a chemical synapse, the subsequent activation of neurotransmitter receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:jl, MeSH:D009435]",
                "id": "GO:0007268"
            }, {
                "name": "aging",
                "definition": "A developmental process that is a deterioration and loss of function over time. Aging includes loss of functions such as resistance to disease, homeostasis, and fertility, as well as wear and tear. Aging includes cellular senescence, but is more inclusive. May precede death and may succeed developmental maturation (GO:0021700). [GOC:PO_curators]",
                "id": "GO:0007568"
            }, {
                "name": "feeding behavior",
                "definition": "Behavior associated with the intake of food. [GOC:mah]",
                "id": "GO:0007631"
            }, {
                "name": "blood circulation",
                "definition": "The flow of blood through the body of an animal, enabling the transport of nutrients to the tissues and the removal of waste products. [GOC:mtg_heart, ISBN:0192800825]",
                "id": "GO:0008015"
            }, {
                "name": "positive regulation of cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008284"
            }, {
                "name": "negative regulation of glutamate secretion",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the controlled release of glutamate. [GOC:ef]",
                "id": "GO:0014050"
            }, {
                "name": "negative regulation of synaptic transmission, GABAergic",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of GABAergic synaptic transmission, the process of communication from a neuron to another neuron across a synapse using the neurotransmitter gamma-aminobutyric acid (GABA). [GOC:mah]",
                "id": "GO:0032229"
            }, {
                "name": "eating behavior",
                "definition": "The specific behavior of an organism relating to the intake of food, any substance (usually solid) that can be metabolized by an organism to give energy and build tissue. [GOC:jl, GOC:pr, ISBN:01928006X]",
                "id": "GO:0042755"
            }, {
                "name": "negative regulation of apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043066"
            }, {
                "name": "positive regulation of smooth muscle cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of smooth muscle cell proliferation. [CL:0000192, GOC:ebc]",
                "id": "GO:0048661"
            }, {
                "name": "generation of ovulation cycle rhythm",
                "definition": "The process which controls the timing of the type of sexual cycle seen in female mammals. [GOC:dph]",
                "id": "GO:0060112"
            }, {
                "name": "positive regulation of ERK1 and ERK2 cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the ERK1 and ERK2 cascade. [GOC:mah]",
                "id": "GO:0070374"
            }],
            "cellular_component": [{
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }],
            "molecular_function": [{
                "name": "peptide YY receptor activity",
                "definition": "Combining with gut peptide YY to initiate a change in cell activity. [PMID:9315606]",
                "id": "GO:0001601"
            }, {
                "name": "pancreatic polypeptide receptor activity",
                "definition": "Combining with pancreatic polypeptide PP to initiate a change in cell activity. [PMID:9315606]",
                "id": "GO:0001602"
            }, {
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "neuropeptide Y receptor activity",
                "definition": "Combining with neuropeptide Y to initiate a change in cell activity. [PMID:9315606]",
                "id": "GO:0004983"
            }]
        },
        "kegg_pathway": {}
    }, "ELOVL2": {
        "name": "Fatty acid elongation",
        "description": "",
        "entrez": "54898",
        "ensembl": "ENSG00000197977",
        "uniprot": "Q9NXB9",
        "hgnc": "14416",
        "go_terms": {
            "biological_process": [{
                "name": "very long-chain fatty acid metabolic process",
                "definition": "The chemical reactions and pathways involving a fatty acid which has a chain length greater than C22. [CHEBI:27283, GOC:hjd]",
                "id": "GO:0000038"
            }, {
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "fatty acid metabolic process",
                "definition": "The chemical reactions and pathways involving fatty acids, aliphatic monocarboxylic acids liberated from naturally occurring fats and oils by hydrolysis. [ISBN:0198547684]",
                "id": "GO:0006631"
            }, {
                "name": "fatty acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a fatty acid, any of the aliphatic monocarboxylic acids that can be liberated by hydrolysis from naturally occurring fats and oils. Fatty acids are predominantly straight-chain acids of 4 to 24 carbon atoms, which may be saturated or unsaturated; branched fatty acids and hydroxy fatty acids also occur, and very long chain acids of over 30 carbons are found in waxes. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0006633"
            }, {
                "name": "unsaturated fatty acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of an unsaturated fatty acid, any fatty acid containing one or more double bonds between carbon atoms. [GOC:mah, MetaCyc:PWY-762, MetaCyc:PWY-782]",
                "id": "GO:0006636"
            }, {
                "name": "fatty acid elongation, saturated fatty acid",
                "definition": "Elongation of a saturated fatty acid chain. [GOC:mah]",
                "id": "GO:0019367"
            }, {
                "name": "sphingolipid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of sphingolipids, any of a class of lipids containing the long-chain amine diol sphingosine or a closely related base (a sphingoid). [GOC:mah, ISBN:0198506732]",
                "id": "GO:0030148"
            }, {
                "name": "fatty acid elongation, monounsaturated fatty acid",
                "definition": "Elongation of a fatty acid chain into which one C-C double bond has been introduced. [GOC:mah]",
                "id": "GO:0034625"
            }, {
                "name": "fatty acid elongation, polyunsaturated fatty acid",
                "definition": "Elongation of a fatty acid chain into which two or more C-C double bonds have been introduced. [GOC:mah]",
                "id": "GO:0034626"
            }, {
                "name": "long-chain fatty-acyl-CoA biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a long-chain fatty-acyl-CoA any derivative of coenzyme A in which the sulfhydryl group is in a thioester linkage with a long-chain fatty-acyl group. Long-chain fatty-acyl-CoAs have chain lengths of C13 or more. [CHEBI:33184, ISBN:0198506732]",
                "id": "GO:0035338"
            }, {
                "name": "alpha-linolenic acid metabolic process",
                "definition": "The chemical reactions and pathways involving alpha-linolenic acid, an unsaturated omega-6 fatty acid that has the molecular formula C18H32O2. [CHEBI:32387]",
                "id": "GO:0036109"
            }, {
                "name": "very long-chain fatty acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a fatty acid which has a chain length greater than C22. [CHEBI:27283, GOC:go_curators]",
                "id": "GO:0042761"
            }, {
                "name": "linoleic acid metabolic process",
                "definition": "The chemical reactions and pathways involving linoleic acid, an unsaturated omega-6 fatty acid that has the molecular formula C18H32O2. [Wikipedia:Linoleic_Acid]",
                "id": "GO:0043651"
            }],
            "cellular_component": [{
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "integral component of endoplasmic reticulum membrane",
                "definition": "The component of the endoplasmic reticulum membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:mah]",
                "id": "GO:0030176"
            }],
            "molecular_function": [{
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "fatty acid elongase activity",
                "definition": "Catalysis of the reaction: fatty acid (C-16 or longer) + 2-C = fatty acid (C-16 or longer + 2-C). [GOC:tb]",
                "id": "GO:0009922"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "transferase activity, transferring acyl groups other than amino-acyl groups",
                "definition": "Catalysis of the transfer of an acyl group, other than amino-acyl, from one compound (donor) to another (acceptor). [GOC:jl]",
                "id": "GO:0016747"
            }, {
                "name": "3-oxo-arachidoyl-CoA synthase activity",
                "definition": "Catalysis of the reaction: stearoyl-CoA(4-) + malonyl-CoA(5-) + H+ <=> 3-oxoicosanoyl-CoA. + carbon dioxide + coenzyme A. [EC:2.3.1.199]",
                "id": "GO:0102336"
            }, {
                "name": "3-oxo-cerotoyl-CoA synthase activity",
                "definition": "Catalysis of the reaction: tetracosanoyl-CoA(4-) + malonyl-CoA(5-) + H+ <=> 3-oxohexacosanoyl-CoA + carbon dioxide + coenzyme A. [EC:2.3.1.199]",
                "id": "GO:0102337"
            }, {
                "name": "3-oxo-lignoceronyl-CoA synthase activity",
                "definition": "Catalysis of the reaction: behenoyl-CoA(4-) + malonyl-CoA(5-) + H+ <=> 3-oxotetracosanoyl-CoA. + carbon dioxide + coenzyme A. [EC:2.3.1.199]",
                "id": "GO:0102338"
            }, {
                "name": "very-long-chain 3-ketoacyl-CoA synthase activity",
                "definition": "Catalysis of the reaction: malonyl-CoA + a very-long-chain 2,3,4-saturated fatty acyl CoA = carbon dioxide + coenzyme A + a very-long-chain oxoacyl-CoA [EC:2.3.1.199]",
                "id": "GO:0102756"
            }]
        },
        "kegg_pathway": {
            "ec": "2.3.1.199",
            "pathways": [{
                "pathway": "00062",
                "name": "Fatty acid elongation",
                "class": "Metabolism; Lipid metabolism"
            }]
        }
    }, "STK11": {
        "name": "serine/threonine kinase 11",
        "description": "This gene, which encodes a member of the serine/threonine kinase family, regulates cell polarity and functions as a tumor suppressor. Mutations in this gene have been associated with Peutz-Jeghers syndrome, an autosomal dominant disorder characterized by the growth of polyps in the gastrointestinal tract, pigmented macules on the skin and mouth, and other neoplasms. Alternate transcriptional splice variants of this gene have been observed but have not been thoroughly characterized. [provided by RefSeq, Jul 2008].",
        "entrez": "6794",
        "ensembl": "ENSG00000118046",
        "uniprot": "Q15831",
        "hgnc": "11389",
        "go_terms": {
            "biological_process": [{
                "name": "regulation of cell growth",
                "definition": "Any process that modulates the frequency, rate, extent or direction of cell growth. [GOC:go_curators]",
                "id": "GO:0001558"
            }, {
                "name": "tissue homeostasis",
                "definition": "A homeostatic process involved in the maintenance of an internal steady state within a defined tissue of an organism, including control of cellular proliferation and death and control of metabolic function. [GOC:add, GOC:isa_complete]",
                "id": "GO:0001894"
            }, {
                "name": "vasculature development",
                "definition": "The process whose specific outcome is the progression of the vasculature over time, from its formation to the mature structure. The vasculature is an interconnected tubular multi-tissue structure that contains fluid that is actively transported around the organism. [GOC:dph, UBERON:0002409]",
                "id": "GO:0001944"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "autophagy",
                "definition": "The cellular catabolic process in which cells digest parts of their own cytoplasm; allows for both recycling of macromolecular constituents under conditions of cellular stress and remodeling the intracellular structure for cell differentiation. [GOC:autophagy, ISBN:0198547684, PMID:11099404, PMID:9412464]",
                "id": "GO:0006914"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "cellular response to DNA damage stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating damage to its DNA from environmental insults or errors during metabolism. [GOC:go_curators]",
                "id": "GO:0006974"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "cell cycle arrest",
                "definition": "A regulatory process that halts progression through the cell cycle during one of the normal phases (G1, S, G2, M). [GOC:dph, GOC:mah, GOC:tb]",
                "id": "GO:0007050"
            }, {
                "name": "spermatogenesis",
                "definition": "The process of formation of spermatozoa, including spermatocytogenesis and spermiogenesis. [GOC:jid, ISBN:9780878933846]",
                "id": "GO:0007283"
            }, {
                "name": "nervous system development",
                "definition": "The process whose specific outcome is the progression of nervous tissue over time, from its formation to its mature state. [GOC:dgh]",
                "id": "GO:0007399"
            }, {
                "name": "axonogenesis",
                "definition": "De novo generation of a long process of a neuron, including the terminal branched region. Refers to the morphogenesis or creation of shape or form of the developing axon, which carries efferent (outgoing) action potentials from the cell body towards target cells. [GOC:dph, GOC:jid, GOC:pg, GOC:pr, ISBN:0198506732]",
                "id": "GO:0007409"
            }, {
                "name": "negative regulation of cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008285"
            }, {
                "name": "response to ionizing radiation",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a ionizing radiation stimulus. Ionizing radiation is radiation with sufficient energy to remove electrons from atoms and may arise from spontaneous decay of unstable isotopes, resulting in alpha and beta particles and gamma rays. Ionizing radiation also includes X-rays. [PMID:12509526]",
                "id": "GO:0010212"
            }, {
                "name": "positive regulation of autophagy",
                "definition": "Any process that activates, maintains or increases the rate of autophagy. Autophagy is the process in which cells digest parts of their own cytoplasm. [GOC:dph, GOC:tb]",
                "id": "GO:0010508"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "establishment of cell polarity",
                "definition": "The specification and formation of anisotropic intracellular organization or cell growth patterns. [GOC:mah]",
                "id": "GO:0030010"
            }, {
                "name": "regulation of Wnt signaling pathway",
                "definition": "Any process that modulates the frequency, rate or extent of the activity of the Wnt signal transduction pathway. [GOC:dph, GOC:mah, GOC:tb]",
                "id": "GO:0030111"
            }, {
                "name": "cell differentiation",
                "definition": "The process in which relatively unspecialized cells, e.g. embryonic or regenerative cells, acquire specialized structural and/or functional features that characterize the cells, tissues, or organs of the mature organism or some other relatively stable phase of the organism's life history. Differentiation includes the processes involved in commitment of a cell to a specific fate and its subsequent development to the mature state. [ISBN:0198506732]",
                "id": "GO:0030154"
            }, {
                "name": "negative regulation of cell growth",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, extent or direction of cell growth. [GOC:go_curators]",
                "id": "GO:0030308"
            }, {
                "name": "positive regulation of transforming growth factor beta receptor signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of TGF-beta receptor signaling pathway activity. [GOC:go_curators]",
                "id": "GO:0030511"
            }, {
                "name": "activation of protein kinase activity",
                "definition": "Any process that initiates the activity of an inactive protein kinase. [GOC:mah]",
                "id": "GO:0032147"
            }, {
                "name": "TCR signalosome assembly",
                "definition": "The aggregation, arrangement and bonding together of a set of components to form a TCR signalosome. [GOC:krc, PMID:22426112]",
                "id": "GO:0036399"
            }, {
                "name": "glucose homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of glucose within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042593"
            }, {
                "name": "anoikis",
                "definition": "Apoptosis triggered by inadequate or inappropriate adherence to substrate e.g. after disruption of the interactions between normal epithelial cells and the extracellular matrix. [GOC:jl, http://www.copewithcytokines.de/]",
                "id": "GO:0043276"
            }, {
                "name": "positive thymic T cell selection",
                "definition": "The process of sparing immature T cells in the thymus which react with self-MHC protein complexes with low affinity levels from apoptotic death. [ISBN:0781735149, PMID:12414722]",
                "id": "GO:0045059"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "regulation of dendrite morphogenesis",
                "definition": "Any process that modulates the frequency, rate or extent of dendrite morphogenesis. [GOC:ai]",
                "id": "GO:0048814"
            }, {
                "name": "positive regulation of peptidyl-tyrosine phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of the phosphorylation of peptidyl-tyrosine. [GOC:ai]",
                "id": "GO:0050731"
            }, {
                "name": "positive regulation of axonogenesis",
                "definition": "Any process that activates or increases the frequency, rate or extent of axonogenesis. [GOC:ai]",
                "id": "GO:0050772"
            }, {
                "name": "T cell receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the cross-linking of an antigen receptor on a T cell. [GOC:add]",
                "id": "GO:0050852"
            }, {
                "name": "negative regulation of lipid biosynthetic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of lipids. [GOC:ai]",
                "id": "GO:0051055"
            }, {
                "name": "Golgi localization",
                "definition": "Any process in which the Golgi is transported to, and/or maintained in, a specific location within the cell. [GOC:ai]",
                "id": "GO:0051645"
            }, {
                "name": "regulation of protein kinase B signaling",
                "definition": "Any process that modulates the frequency, rate or extent of protein kinase B signaling, a series of reactions mediated by the intracellular serine/threonine kinase protein kinase B. [GOC:ai]",
                "id": "GO:0051896"
            }, {
                "name": "canonical Wnt signaling pathway",
                "definition": "The series of molecular signals initiated by binding of a Wnt protein to a frizzled family receptor on the surface of the target cell, followed by propagation of the signal via beta-catenin, and ending with a change in transcription of target genes. In this pathway, the activated receptor signals via downstream effectors that result in the inhibition of beta-catenin phosphorylation, thereby preventing degradation of beta-catenin. Stabilized beta-catenin can then accumulate and travel to the nucleus to trigger changes in transcription of target genes. [GOC:bf, GOC:dph, PMID:11532397, PMID:19619488]",
                "id": "GO:0060070"
            }, {
                "name": "negative regulation of epithelial cell proliferation involved in prostate gland development",
                "definition": "Any process that decreases the rate, frequency or extent of epithelial cell proliferation that contributes to the progression of the prostate gland over time. [GOC:dph]",
                "id": "GO:0060770"
            }, {
                "name": "cellular response to UV-B",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a UV-B radiation stimulus. UV-B radiation (UV-B light) spans the wavelengths 280 to 315 nm. [GOC:mah]",
                "id": "GO:0071493"
            }, {
                "name": "positive regulation of protein serine/threonine kinase activity",
                "definition": "Any process that increases the rate, frequency, or extent of protein serine/threonine kinase activity. [GOC:mah]",
                "id": "GO:0071902"
            }, {
                "name": "intrinsic apoptotic signaling pathway by p53 class mediator",
                "definition": "A series of molecular signals in which an intracellular signal is conveyed to trigger the apoptotic death of a cell. The pathway is induced by the cell cycle regulator phosphoprotein p53, or an equivalent protein, and ends when the execution phase of apoptosis is triggered. [GOC:mah, GOC:mtg_apoptosis]",
                "id": "GO:0072332"
            }, {
                "name": "dendrite extension",
                "definition": "Long distance growth of a single dendrite involved in cellular development. [GOC:BHF, GOC:rl]",
                "id": "GO:0097484"
            }, {
                "name": "positive regulation of protein localization to nucleus",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein localization to nucleus. [GOC:TermGenie]",
                "id": "GO:1900182"
            }, {
                "name": "regulation of signal transduction by p53 class mediator",
                "definition": "Any process that modulates the frequency, rate or extent of signal transduction by p53 class mediator. [GOC:TermGenie]",
                "id": "GO:1901796"
            }, {
                "name": "negative regulation of TORC1 signaling",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of TORC1 signaling. [GO_REF:0000058, GOC:TermGenie, PMID:25366275]",
                "id": "GO:1904262"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "TCR signalosome",
                "definition": "A multi-protein complex containing at least the T-cell receptor complex and the LAT (linker for activation of T cells) scaffold protein. Also contains a variety of signaling proteins including co-receptors, kinases, phosphatases and adaptors such as CD8. Connects events on the plasma membrane to distal signaling cascades to ultimately modulate T cell biology. [GOC:krc, PMID:17534068, PMID:20107804, PMID:22426112]",
                "id": "GO:0036398"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "p53 binding",
                "definition": "Interacting selectively and non-covalently with one of the p53 family of proteins. [GOC:hjd]",
                "id": "GO:0002039"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "LRR domain binding",
                "definition": "Interacting selectively and non-covalently with a LRR domain (leucine rich repeats) of a protein. [GOC:go_curators, Pfam:PF00560]",
                "id": "GO:0030275"
            }, {
                "name": "protein kinase activator activity",
                "definition": "Binds to and increases the activity of a protein kinase, an enzyme which phosphorylates a protein. [GOC:ai]",
                "id": "GO:0030295"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "PDE8A": {
        "name": "phosphodiesterase 8A",
        "description": "The protein encoded by this gene belongs to the cyclic nucleotide phosphodiesterase (PDE) family, and PDE8 subfamily. This PDE hydrolyzes the second messenger, cAMP, which is a regulator and mediator of a number of cellular responses to extracellular signals. Thus, by regulating the cellular concentration of cAMP, this protein plays a key role in many important physiological processes. Alternatively spliced transcript variants encoding different isoforms have been found for this gene.[provided by RefSeq, Jul 2011].",
        "entrez": "5151",
        "ensembl": "ENSG00000073417",
        "uniprot": "O60658",
        "hgnc": "8793",
        "go_terms": {
            "biological_process": [{
                "name": "positive regulation of protein phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of addition of phosphate groups to amino acids within a protein. [GOC:hjd]",
                "id": "GO:0001934"
            }, {
                "name": "cAMP catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of the nucleotide cAMP (cyclic AMP, adenosine 3',5'-cyclophosphate). [ISBN:0198506732]",
                "id": "GO:0006198"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "cyclic nucleotide metabolic process",
                "definition": "The chemical reactions and pathways involving a cyclic nucleotide, a nucleotide in which the phosphate group is in diester linkage to two positions on the sugar residue. [GOC:go_curators, ISBN:0198506732]",
                "id": "GO:0009187"
            }, {
                "name": "negative regulation of cell death",
                "definition": "Any process that decreases the rate or frequency of cell death. Cell death is the specific activation or halting of processes within a cell so that its vital functions markedly cease, rather than simply deteriorating gradually over time, which culminates in cell death. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0060548"
            }, {
                "name": "positive regulation of ERK1 and ERK2 cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the ERK1 and ERK2 cascade. [GOC:mah]",
                "id": "GO:0070374"
            }, {
                "name": "cellular response to epidermal growth factor stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an epidermal growth factor stimulus. [GOC:mah]",
                "id": "GO:0071364"
            }, {
                "name": "negative regulation of hydrogen peroxide-induced cell death",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of hydrogen peroxide-induced cell death. [GO_REF:0000058, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:14749723, PMID:24252804]",
                "id": "GO:1903206"
            }],
            "cellular_component": [{
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "3',5'-cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: nucleoside 3',5'-cyclic phosphate + H2O = nucleoside 5'-phosphate. [EC:3.1.4.17]",
                "id": "GO:0004114"
            }, {
                "name": "3',5'-cyclic-AMP phosphodiesterase activity",
                "definition": "Catalysis of the reaction: adenosine 3',5'-cyclic phosphate + H2O = adenosine 5'-phosphate. [GOC:ai]",
                "id": "GO:0004115"
            }, {
                "name": "phosphoric diester hydrolase activity",
                "definition": "Catalysis of the hydrolysis of a phosphodiester to give a phosphomonoester and a free hydroxyl group. [EC:3.1.4, GOC:curators]",
                "id": "GO:0008081"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "kinase binding",
                "definition": "Interacting selectively and non-covalently with a kinase, any enzyme that catalyzes the transfer of a phosphate group. [GOC:jl]",
                "id": "GO:0019900"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "ASIC3": {
        "name": "acid sensing ion channel subunit 3",
        "description": "This gene encodes a member of the degenerin/epithelial sodium channel (DEG/ENaC) superfamily. The members of this family are amiloride-sensitive sodium channels that contain intracellular N and C termini, two hydrophobic transmembrane regions, and a large extracellular loop, which has many cysteine residues with conserved spacing. The member encoded by this gene is an acid sensor and may play an important role in the detection of lasting pH changes. In addition, a heteromeric association between this member and acid-sensing (proton-gated) ion channel 2 has been observed as proton-gated channels sensitive to gadolinium. Alternatively spliced transcript variants have been described. [provided by RefSeq, Feb 2012].",
        "entrez": "9311",
        "ensembl": "ENSG00000213199",
        "uniprot": "Q9UHC3",
        "hgnc": "101",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "cation transport",
                "definition": "The directed movement of cations, atoms or small molecules with a net positive charge, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006812"
            }, {
                "name": "sodium ion transport",
                "definition": "The directed movement of sodium ions (Na+) into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006814"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "sensory perception",
                "definition": "The series of events required for an organism to receive a sensory stimulus, convert it to a molecular signal, and recognize and characterize the signal. This is a neurological process. [GOC:ai, GOC:dph]",
                "id": "GO:0007600"
            }, {
                "name": "response to heat",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a heat stimulus, a temperature stimulus above the optimal temperature for that organism. [GOC:lr]",
                "id": "GO:0009408"
            }, {
                "name": "response to mechanical stimulus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a mechanical stimulus. [GOC:hb]",
                "id": "GO:0009612"
            }, {
                "name": "response to acidic pH",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a pH stimulus with pH < 7. pH is a measure of the acidity or basicity of an aqueous solution. [GOC:go_curators, GOC:tb, http://en.wikipedia.org/wiki/PH]",
                "id": "GO:0010447"
            }, {
                "name": "ion transmembrane transport",
                "definition": "A process in which an ion is transported across a membrane. [GOC:mah]",
                "id": "GO:0034220"
            }, {
                "name": "sodium ion transmembrane transport",
                "definition": "A process in which a sodium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:vw]",
                "id": "GO:0035725"
            }, {
                "name": "enterobactin transport",
                "definition": "The directed movement of the siderochrome enterobactin, a cyclic trimer of 2, 3 dihydroxybenzoylserine into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [CHEBI:28855, GOC:jl]",
                "id": "GO:0042930"
            }, {
                "name": "detection of chemical stimulus involved in sensory perception",
                "definition": "The series of events in which a chemical stimulus is received and converted into a molecular signal as part of sensory perception. [GOC:ai, GOC:dos]",
                "id": "GO:0050907"
            }, {
                "name": "sensory perception of sour taste",
                "definition": "The series of events required to receive a sour taste stimulus, convert it to a molecular signal, and recognize and characterize the signal. This is a neurological process. [GOC:ai]",
                "id": "GO:0050915"
            }, {
                "name": "detection of temperature stimulus involved in sensory perception",
                "definition": "The series of events in which a temperature stimulus is received and converted into a molecular signal as part of sensory perception. [GOC:ai, GOC:dos]",
                "id": "GO:0050961"
            }, {
                "name": "detection of temperature stimulus involved in sensory perception of pain",
                "definition": "The series of events involved in the perception of pain in which a temperature stimulus is received and converted into a molecular signal. [GOC:ai, GOC:dos]",
                "id": "GO:0050965"
            }, {
                "name": "detection of mechanical stimulus involved in sensory perception of pain",
                "definition": "The series of events involved in the perception of pain in which a mechanical stimulus is received and converted into a molecular signal. [GOC:ai, GOC:dos]",
                "id": "GO:0050966"
            }, {
                "name": "detection of chemical stimulus involved in sensory perception of pain",
                "definition": "The series of events involved in the perception of pain in which a chemical stimulus is received and converted into a molecular signal. [GOC:ai]",
                "id": "GO:0050968"
            }, {
                "name": "detection of mechanical stimulus involved in sensory perception",
                "definition": "The series of events in which a mechanical stimulus is received and converted into a molecular signal as part of sensory perception. [GOC:ai, GOC:dos]",
                "id": "GO:0050974"
            }, {
                "name": "cation transmembrane transport",
                "definition": "The process in which a cation is transported across a membrane. [GOC:dos, GOC:vw]",
                "id": "GO:0098655"
            }],
            "cellular_component": [{
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "cation channel activity",
                "definition": "Enables the energy-independent passage of cations across a lipid bilayer down a concentration gradient. [GOC:def, GOC:dph, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005261"
            }, {
                "name": "sodium channel activity",
                "definition": "Enables the facilitated diffusion of a sodium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:BHF, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005272"
            }, {
                "name": "ligand-gated sodium channel activity",
                "definition": "Enables the transmembrane transfer of a sodium ion by a channel that opens when a specific ligand has been bound by the channel complex or one of its constituent parts. [GOC:mah]",
                "id": "GO:0015280"
            }, {
                "name": "PDZ domain binding",
                "definition": "Interacting selectively and non-covalently with a PDZ domain of a protein, a domain found in diverse signaling proteins. [GOC:go_curators, Pfam:PF00595]",
                "id": "GO:0030165"
            }, {
                "name": "enterobactin transmembrane transporter activity",
                "definition": "Enables the directed movement of the siderochrome enterochelin, a cyclic trimer of 2, 3 dihydroxybenzoylserine from one side of a membrane to the other. [CHEBI:28855, GOC:jl]",
                "id": "GO:0042931"
            }, {
                "name": "acid-sensing ion channel activity",
                "definition": "Enables the transmembrane transfer of a sodium ion by a neuronal, voltage-insensitive channel that opens when an extracellular proton has been bound by the channel complex. [GOC:jl]",
                "id": "GO:0044736"
            }]
        },
        "kegg_pathway": {}
    }, "EPHX1": {
        "name": "Metabolism of xenobiotics by cytochrome P450",
        "description": "Epoxide hydrolase is a critical biotransformation enzyme that converts epoxides from the degradation of aromatic compounds to trans-dihydrodiols which can be conjugated and excreted from the body. Epoxide hydrolase functions in both the activation and detoxification of epoxides. Mutations in this gene cause preeclampsia, epoxide hydrolase deficiency or increased epoxide hydrolase activity. Alternatively spliced transcript variants encoding the same protein have been found for this gene.[provided by RefSeq, Dec 2008].",
        "entrez": "2052",
        "ensembl": "ENSG00000143819",
        "uniprot": "P07099",
        "hgnc": "3401",
        "go_terms": {
            "biological_process": [{
                "name": "cellular aromatic compound metabolic process",
                "definition": "The chemical reactions and pathways involving aromatic compounds, any organic compound characterized by one or more planar rings, each of which contains conjugated double bonds and delocalized pi electrons, as carried out by individual cells. [GOC:ai, ISBN:0198506732]",
                "id": "GO:0006725"
            }, {
                "name": "xenobiotic metabolic process",
                "definition": "The chemical reactions and pathways involving a xenobiotic compound, a compound foreign to living organisms. Used of chemical compounds, e.g. a xenobiotic chemical, such as a pesticide. [GOC:cab2]",
                "id": "GO:0006805"
            }, {
                "name": "response to toxic substance",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a toxic stimulus. [GOC:lr]",
                "id": "GO:0009636"
            }, {
                "name": "response to organic cyclic compound",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic cyclic compound stimulus. [CHEBI:33832, GOC:ef]",
                "id": "GO:0014070"
            }, {
                "name": "aromatic compound catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of aromatic compounds, any substance containing an aromatic carbon ring. [GOC:ai]",
                "id": "GO:0019439"
            }, {
                "name": "epoxide metabolic process",
                "definition": "The chemical reactions and pathways involving epoxides, compounds in which an oxygen atom is directly attached to two adjacent or non-adjacent carbon atoms of a carbon chain or ring system; thus cyclic ethers. [CHEBI:37407, GOC:rs, PMID:15822179]",
                "id": "GO:0097176"
            }],
            "cellular_component": [{
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "organelle membrane",
                "definition": "A membrane that is one of the two lipid bilayers of an organelle envelope or the outermost membrane of single membrane bound organelle. [GOC:dos, GOC:mah]",
                "id": "GO:0031090"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }],
            "molecular_function": [{
                "name": "catalytic activity",
                "definition": "Catalysis of a biochemical reaction at physiological temperatures. In biologically catalyzed reactions, the reactants are known as substrates, and the catalysts are naturally occurring macromolecular substances known as enzymes. Enzymes possess specific binding sites for substrates, and are usually composed wholly or largely of protein, but RNA that has catalytic activity (ribozyme) is often also regarded as enzymatic. [GOC:vw, ISBN:0198506732]",
                "id": "GO:0003824"
            }, {
                "name": "epoxide hydrolase activity",
                "definition": "Catalysis of the reaction: an epoxide + H2O = a glycol. [EC:3.3.2.10]",
                "id": "GO:0004301"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "cis-stilbene-oxide hydrolase activity",
                "definition": "Catalysis of the reaction: cis-stilbene oxide + H2O = (+)-(1R,2R)-1,2-diphenylethane-1,2-diol. [EC:3.3.2.9]",
                "id": "GO:0033961"
            }]
        },
        "kegg_pathway": {
            "ec": "3.3.2.9",
            "pathways": [{
                "pathway": "00980",
                "name": "Metabolism of xenobiotics by cytochrome P450",
                "class": "Metabolism; Xenobiotics biodegradation and metabolism"
            }]
        }
    }, "CYP11B2": {
        "name": "Steroid hormone biosynthesis",
        "description": "This gene encodes a member of the cytochrome P450 superfamily of enzymes. The cytochrome P450 proteins are monooxygenases which catalyze many reactions involved in drug metabolism and synthesis of cholesterol, steroids and other lipids. This protein localizes to the mitochondrial inner membrane. The enzyme has steroid 18-hydroxylase activity to synthesize aldosterone and 18-oxocortisol as well as steroid 11 beta-hydroxylase activity. Mutations in this gene cause corticosterone methyl oxidase deficiency. [provided by RefSeq, Jul 2008].",
        "entrez": "1585",
        "ensembl": "ENSG00000179142",
        "uniprot": "P19099",
        "hgnc": "2592",
        "go_terms": {
            "biological_process": [{
                "name": "regulation of blood volume by renal aldosterone",
                "definition": "The process in which the hormone aldosterone decreases the rate of diuresis and natriuresis resulting in increased blood volume. [GOC:dph, GOC:tb, ISBN:0721643949]",
                "id": "GO:0002017"
            }, {
                "name": "renal water homeostasis",
                "definition": "Renal process involved in the maintenance of an internal steady state of water in the body. [GOC:mtg_cardio]",
                "id": "GO:0003091"
            }, {
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "steroid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of steroids, compounds with a 1,2,cyclopentanoperhydrophenanthrene nucleus; includes de novo formation and steroid interconversion by modification. [GOC:go_curators]",
                "id": "GO:0006694"
            }, {
                "name": "C21-steroid hormone biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of C21-steroid hormones, steroid compounds containing 21 carbons which function as hormones. [GOC:ai]",
                "id": "GO:0006700"
            }, {
                "name": "mineralocorticoid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of mineralocorticoids, hormonal C21 corticosteroids synthesized from cholesterol. [ISBN:0198506732]",
                "id": "GO:0006705"
            }, {
                "name": "steroid metabolic process",
                "definition": "The chemical reactions and pathways involving steroids, compounds with a 1,2,cyclopentanoperhydrophenanthrene nucleus. [ISBN:0198547684]",
                "id": "GO:0008202"
            }, {
                "name": "sterol metabolic process",
                "definition": "The chemical reactions and pathways involving sterols, steroids with one or more hydroxyl groups and a hydrocarbon side-chain in the molecule. [ISBN:0198547684]",
                "id": "GO:0016125"
            }, {
                "name": "aldosterone biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of aldosterone, a corticosteroid hormone that is produced by the zona glomerulosa of the adrenal cortex and regulates salt (sodium and potassium) and water balance. [PMID:16527843]",
                "id": "GO:0032342"
            }, {
                "name": "cellular response to hormone stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hormone stimulus. [GOC:mah]",
                "id": "GO:0032870"
            }, {
                "name": "cortisol biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of cortisol, the steroid hormone 11-beta-17,21-trihydroxypregn-4-ene-3,20-dione. Cortisol is synthesized from cholesterol in the adrenal gland and controls carbohydrate, fat and protein metabolism and has anti-inflammatory properties. [CHEBI:17650, GOC:BHF, GOC:mah, GOC:rl]",
                "id": "GO:0034651"
            }, {
                "name": "cellular response to potassium ion",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a potassium ion stimulus. [GOC:yaf]",
                "id": "GO:0035865"
            }, {
                "name": "potassium ion homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of potassium ions within an organism or cell. [GOC:jid, GOC:mah]",
                "id": "GO:0055075"
            }, {
                "name": "sodium ion homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of sodium ions within an organism or cell. [GOC:ai, GOC:jid, GOC:mah]",
                "id": "GO:0055078"
            }, {
                "name": "oxidation-reduction process",
                "definition": "A metabolic process that results in the removal or addition of one or more electrons to or from a substance, with or without the concomitant removal or addition of a proton or protons. [GOC:dhl, GOC:ecd, GOC:jh2, GOC:jid, GOC:mlg, GOC:rph]",
                "id": "GO:0055114"
            }],
            "cellular_component": [{
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "mitochondrial inner membrane",
                "definition": "The inner, i.e. lumen-facing, lipid bilayer of the mitochondrial envelope. It is highly folded to form cristae. [GOC:ai]",
                "id": "GO:0005743"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "mitochondrial membrane",
                "definition": "Either of the lipid bilayers that surround the mitochondrion and form the mitochondrial envelope. [GOC:mah, NIF_Subcellular:sao1045389829]",
                "id": "GO:0031966"
            }],
            "molecular_function": [{
                "name": "monooxygenase activity",
                "definition": "Catalysis of the incorporation of one atom from molecular oxygen into a compound and the reduction of the other atom of oxygen to water. [http://www.onelook.com/, ISBN:0198506732]",
                "id": "GO:0004497"
            }, {
                "name": "steroid 11-beta-monooxygenase activity",
                "definition": "Catalysis of the reaction: a steroid + reduced adrenal ferredoxin + O2 = an 11-beta-hydroxysteroid + oxidized adrenal ferredoxin + H2O. [EC:1.14.15.4]",
                "id": "GO:0004507"
            }, {
                "name": "iron ion binding",
                "definition": "Interacting selectively and non-covalently with iron (Fe) ions. [GOC:ai]",
                "id": "GO:0005506"
            }, {
                "name": "steroid hydroxylase activity",
                "definition": "Catalysis of the formation of a hydroxyl group on a steroid by incorporation of oxygen from O2. [ISBN:0721662544]",
                "id": "GO:0008395"
            }, {
                "name": "oxidoreductase activity",
                "definition": "Catalysis of an oxidation-reduction (redox) reaction, a reversible chemical reaction in which the oxidation state of an atom or atoms within a molecule is altered. One substrate acts as a hydrogen or electron donor and becomes oxidized, while the other acts as hydrogen or electron acceptor and becomes reduced. [GOC:go_curators]",
                "id": "GO:0016491"
            }, {
                "name": "oxidoreductase activity, acting on paired donors, with incorporation or reduction of molecular oxygen",
                "definition": "Catalysis of an oxidation-reduction (redox) reaction in which hydrogen or electrons are transferred from each of two donors, and molecular oxygen is reduced or incorporated into a donor. [GOC:mah]",
                "id": "GO:0016705"
            }, {
                "name": "heme binding",
                "definition": "Interacting selectively and non-covalently with heme, any compound of iron complexed in a porphyrin (tetrapyrrole) ring. [CHEBI:30413, GOC:ai]",
                "id": "GO:0020037"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "corticosterone 18-monooxygenase activity",
                "definition": "Catalysis of the reaction: corticosterone + reduced adrenal ferredoxin + O2 = 18-hydroxycorticosterone + oxidized adrenal ferredoxin + H2O. [EC:1.14.15.5, MetaCyc:CORTICOSTERONE-18-MONOOXYGENASE-RXN]",
                "id": "GO:0047783"
            }]
        },
        "kegg_pathway": {
            "ec": "1.14.15.4",
            "pathways": [{
                "pathway": "00140",
                "name": "Steroid hormone biosynthesis",
                "class": "Metabolism; Lipid metabolism"
            }]
        }
    }, "IL2RA": {
        "name": "interleukin 2 receptor subunit alpha",
        "description": "The interleukin 2 (IL2) receptor alpha (IL2RA) and beta (IL2RB) chains, together with the common gamma chain (IL2RG), constitute the high-affinity IL2 receptor. Homodimeric alpha chains (IL2RA) result in low-affinity receptor, while homodimeric beta (IL2RB) chains produce a medium-affinity receptor. Normally an integral-membrane protein, soluble IL2RA has been isolated and determined to result from extracellular proteolyisis. Alternately-spliced IL2RA mRNAs have been isolated, but the significance of each is presently unknown. Mutations in this gene are associated with interleukin 2 receptor alpha deficiency.[provided by RefSeq, Nov 2009].",
        "entrez": "3559",
        "ensembl": "ENSG00000134460",
        "uniprot": "P01589",
        "hgnc": "6008",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "immune system process",
                "definition": "Any process involved in the development or functioning of the immune system, an organismal system for calibrated responses to potential internal or invasive threats. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0002376"
            }, {
                "name": "inflammatory response to antigenic stimulus",
                "definition": "An inflammatory response to an antigenic stimulus, which can be include any number of T cell or B cell epitopes. [GOC:add, ISBN:0781735149]",
                "id": "GO:0002437"
            }, {
                "name": "regulation of T cell tolerance induction",
                "definition": "Any process that modulates the frequency, rate, or extent of T cell tolerance induction. [GOC:add]",
                "id": "GO:0002664"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "activation-induced cell death of T cells",
                "definition": "A T cell apoptotic process that occurs towards the end of the expansion phase following the initial activation of mature T cells by antigen and is triggered by T cell receptor stimulation and signals transmitted via various surface-expressed members of the TNF receptor family such as Fas ligand, Fas, and TNF and the p55 and p75 TNF receptors. [GOC:add, GOC:mtg_apoptosis, ISBN:0781765196, PMID:12414721, PMID:12752672]",
                "id": "GO:0006924"
            }, {
                "name": "inflammatory response",
                "definition": "The immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. The process is characterized by local vasodilation, extravasation of plasma into intercellular spaces and accumulation of white blood cells and macrophages. [GO_REF:0000022, GOC:mtg_15nov05, ISBN:0198506732]",
                "id": "GO:0006954"
            }, {
                "name": "immune response",
                "definition": "Any immune system process that functions in the calibrated response of an organism to a potential internal or invasive threat. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0006955"
            }, {
                "name": "cell surface receptor signaling pathway",
                "definition": "A series of molecular signals initiated by activation of a receptor on the surface of a cell. The pathway begins with binding of an extracellular ligand to a cell surface receptor, or for receptors that signal in the absence of a ligand, by ligand-withdrawal or the activity of a constitutively active receptor. The pathway ends with regulation of a downstream cellular process, e.g. transcription. [GOC:bf, GOC:mah, GOC:pr, GOC:signaling]",
                "id": "GO:0007166"
            }, {
                "name": "Notch signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to the receptor Notch on the surface of a target cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:go_curators, GOC:signaling]",
                "id": "GO:0007219"
            }, {
                "name": "cell proliferation",
                "definition": "The multiplication or reproduction of cells, resulting in the expansion of a cell population. [GOC:mah, GOC:mb]",
                "id": "GO:0008283"
            }, {
                "name": "cytokine-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a cytokine to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:19295629]",
                "id": "GO:0019221"
            }, {
                "name": "interleukin-2-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-2 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:nhn, GOC:signaling]",
                "id": "GO:0038110"
            }, {
                "name": "positive regulation of T cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of T cell proliferation. [GOC:ai]",
                "id": "GO:0042102"
            }, {
                "name": "positive regulation of activated T cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of activated T cell proliferation. [GOC:jl]",
                "id": "GO:0042104"
            }, {
                "name": "negative regulation of T cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of T cell proliferation. [GOC:jl]",
                "id": "GO:0042130"
            }, {
                "name": "T cell homeostasis",
                "definition": "The process of regulating the proliferation and elimination of T cells such that the total number of T cells within a whole or part of an organism is stable over time in the absence of an outside stimulus. [GOC:mgi_curators, ISBN:0781735149]",
                "id": "GO:0043029"
            }, {
                "name": "positive regulation of T cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of T cell differentiation. [GOC:go_curators]",
                "id": "GO:0045582"
            }, {
                "name": "regulation of regulatory T cell differentiation",
                "definition": "Any process that modulates the frequency, rate or extent of differentiation of regulatory T cells. [ISBN:0781735149]",
                "id": "GO:0045589"
            }, {
                "name": "regulation of T cell homeostatic proliferation",
                "definition": "Any process that modulates the frequency, rate or extent of resting T cell proliferation. [GOC:go_curators]",
                "id": "GO:0046013"
            }, {
                "name": "negative regulation of lymphocyte proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of lymphocyte proliferation. [GOC:ai]",
                "id": "GO:0050672"
            }, {
                "name": "negative regulation of defense response to virus",
                "definition": "Any process that stops, prevents or reduces the rate or extent of antiviral mechanisms, thereby facilitating viral replication. [GOC:ai]",
                "id": "GO:0050687"
            }, {
                "name": "negative regulation of inflammatory response",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the inflammatory response. [GOC:ai]",
                "id": "GO:0050728"
            }, {
                "name": "negative regulation of immune response",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the immune response, the immunological reaction of an organism to an immunogenic stimulus. [GOC:ai]",
                "id": "GO:0050777"
            }, {
                "name": "regulation of molecular function",
                "definition": "Any process that modulates the frequency, rate or extent of a molecular function, an elemental biological activity occurring at the molecular level, such as catalysis or binding. [GOC:isa_complete]",
                "id": "GO:0065009"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "external side of plasma membrane",
                "definition": "The leaflet of the plasma membrane that faces away from the cytoplasm and any proteins embedded or anchored in it or attached to its surface. [GOC:dos, GOC:tb]",
                "id": "GO:0009897"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "interleukin-2 receptor activity",
                "definition": "Combining with interleukin-2 and transmitting the signal from one side of the membrane to the other to initiate a change in cell activity. [GOC:jl, GOC:signaling]",
                "id": "GO:0004911"
            }, {
                "name": "Ras guanyl-nucleotide exchange factor activity",
                "definition": "Stimulates the exchange of guanyl nucleotides associated with a GTPase of the Ras superfamily. Under normal cellular physiological conditions, the concentration of GTP is higher than that of GDP, favoring the replacement of GDP by GTP in association with the GTPase. [GOC:mah]",
                "id": "GO:0005088"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "drug binding",
                "definition": "Interacting selectively and non-covalently with a drug, any naturally occurring or synthetic substance, other than a nutrient, that, when administered or applied to an organism, affects the structure or functioning of the organism; in particular, any such substance used in the diagnosis, prevention, or treatment of disease. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0008144"
            }, {
                "name": "interleukin-2 binding",
                "definition": "Interacting selectively and non-covalently with interleukin-2. [GOC:jl]",
                "id": "GO:0019976"
            }]
        },
        "kegg_pathway": {}
    }, "DPP4": {
        "name": "dipeptidyl peptidase 4",
        "description": "The protein encoded by this gene is identical to adenosine deaminase complexing protein-2, and to the T-cell activation antigen CD26. It is an intrinsic membrane glycoprotein and a serine exopeptidase that cleaves X-proline dipeptides from the N-terminus of polypeptides. [provided by RefSeq, Jul 2008].",
        "entrez": "1803",
        "ensembl": "ENSG00000197635",
        "uniprot": "P27487",
        "hgnc": "3009",
        "go_terms": {
            "biological_process": [{
                "name": "behavioral fear response",
                "definition": "An acute behavioral change resulting from a perceived external threat. [GOC:dph, PMID:9920659]",
                "id": "GO:0001662"
            }, {
                "name": "response to hypoxia",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating lowered oxygen tension. Hypoxia, defined as a decline in O2 levels below normoxic levels of 20.8 - 20.95%, results in metabolic adaptation at both the cellular and organismal level. [GOC:hjd]",
                "id": "GO:0001666"
            }, {
                "name": "proteolysis",
                "definition": "The hydrolysis of proteins into smaller polypeptides and/or amino acids by cleavage of their peptide bonds. [GOC:bf, GOC:mah]",
                "id": "GO:0006508"
            }, {
                "name": "cell adhesion",
                "definition": "The attachment of a cell, either to another cell or to an underlying substrate such as the extracellular matrix, via cell adhesion molecules. [GOC:hb, GOC:pf]",
                "id": "GO:0007155"
            }, {
                "name": "positive regulation of cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008284"
            }, {
                "name": "negative regulation of extracellular matrix disassembly",
                "definition": "Any process that decreases the rate, frequency or extent of extracellular matrix disassembly. Extracellular matrix disassembly is a process that results in the breakdown of the extracellular matrix. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010716"
            }, {
                "name": "T cell costimulation",
                "definition": "The process of providing, via surface-bound receptor-ligand pairs, a second, antigen-independent, signal in addition to that provided by the T cell receptor to augment T cell activation. [ISBN:0781735149]",
                "id": "GO:0031295"
            }, {
                "name": "regulation of cell-cell adhesion mediated by integrin",
                "definition": "Any process that modulates the frequency, rate, or extent of cell-cell adhesion mediated by integrin. [GOC:add]",
                "id": "GO:0033632"
            }, {
                "name": "locomotory exploration behavior",
                "definition": "The specific movement from place to place of an organism in response to a novel environment. [GOC:sart, PMID:17151232]",
                "id": "GO:0035641"
            }, {
                "name": "psychomotor behavior",
                "definition": "The specific behavior of an organism that combines cognitive functions and physical movement. For example, driving a car, throwing a ball, or playing a musical instrument. [GOC:nhn, GOC:pr, PMID:17159989, Wikipedia:Psychomotor_learning]",
                "id": "GO:0036343"
            }, {
                "name": "T cell activation",
                "definition": "The change in morphology and behavior of a mature or immature T cell resulting from exposure to a mitogen, cytokine, chemokine, cellular ligand, or an antigen for which it is specific. [GOC:mgi_curators, ISBN:0781735140]",
                "id": "GO:0042110"
            }, {
                "name": "endothelial cell migration",
                "definition": "The orderly movement of an endothelial cell into the extracellular matrix to form an endothelium. [GOC:go_curators]",
                "id": "GO:0043542"
            }, {
                "name": "viral entry into host cell",
                "definition": "The process that occurs after viral attachment by which a virus, or viral nucleic acid, breaches the plasma membrane or cell envelope and enters the host cell. The process ends when the viral nucleic acid is released into the host cell cytoplasm. [GOC:jl]",
                "id": "GO:0046718"
            }, {
                "name": "regulation of insulin secretion",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of insulin. [GOC:ai]",
                "id": "GO:0050796"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "lysosomal membrane",
                "definition": "The lipid bilayer surrounding the lysosome and separating its contents from the cell cytoplasm. [GOC:ai]",
                "id": "GO:0005765"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "focal adhesion",
                "definition": "Small region on the surface of a cell that anchors the cell to the extracellular matrix and that forms a point of termination of actin filaments. [ISBN:0124325653, ISBN:0815316208]",
                "id": "GO:0005925"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "apical plasma membrane",
                "definition": "The region of the plasma membrane located at the apical end of the cell. [GOC:curators]",
                "id": "GO:0016324"
            }, {
                "name": "lamellipodium",
                "definition": "A thin sheetlike process extended by the leading edge of a migrating cell or extending cell process; contains a dense meshwork of actin filaments. [ISBN:0815316194]",
                "id": "GO:0030027"
            }, {
                "name": "cell junction",
                "definition": "A cellular component that forms a specialized region of connection between two or more cells or between a cell and the extracellular matrix. At a cell junction, anchoring proteins extend through the plasma membrane to link cytoskeletal proteins in one cell to cytoskeletal proteins in neighboring cells or to proteins in the extracellular matrix. [GOC:mah, http://www.vivo.colostate.edu/hbooks/cmb/cells/pmemb/junctions_a.html, ISBN:0198506732]",
                "id": "GO:0030054"
            }, {
                "name": "endocytic vesicle",
                "definition": "A membrane-bounded intracellular vesicle formed by invagination of the plasma membrane around an extracellular substance. Endocytic vesicles fuse with early endosomes to deliver the cargo for further sorting. [GOC:go_curators, PMID:19696797]",
                "id": "GO:0030139"
            }, {
                "name": "lamellipodium membrane",
                "definition": "The portion of the plasma membrane surrounding a lamellipodium. [GOC:mah]",
                "id": "GO:0031258"
            }, {
                "name": "cell projection",
                "definition": "A prolongation or process extending from a cell, e.g. a flagellum or axon. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0042995"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }, {
                "name": "intercellular canaliculus",
                "definition": "An extremely narrow tubular channel located between adjacent cells. An instance of this is the secretory canaliculi occurring between adjacent parietal cells in the gastric mucosa of vertebrates. [ISBN:0721662544]",
                "id": "GO:0046581"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }, {
                "name": "invadopodium membrane",
                "definition": "The portion of the plasma membrane surrounding an invadopodium. [GOC:mah]",
                "id": "GO:0071438"
            }],
            "molecular_function": [{
                "name": "virus receptor activity",
                "definition": "Combining with a virus component and mediating entry of the virus into the cell. [GOC:bf, GOC:dph, PMID:7621403, UniProtKB-KW:KW-1183]",
                "id": "GO:0001618"
            }, {
                "name": "protease binding",
                "definition": "Interacting selectively and non-covalently with any protease or peptidase. [GOC:hjd]",
                "id": "GO:0002020"
            }, {
                "name": "aminopeptidase activity",
                "definition": "Catalysis of the hydrolysis of N-terminal amino acid residues from in a polypeptide chain. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0004177"
            }, {
                "name": "serine-type endopeptidase activity",
                "definition": "Catalysis of the hydrolysis of internal, alpha-peptide bonds in a polypeptide chain by a catalytic mechanism that involves a catalytic triad consisting of a serine nucleophile that is activated by a proton relay involving an acidic residue (e.g. aspartate or glutamate) and a basic residue (usually histidine). [GOC:mah, http://merops.sanger.ac.uk/about/glossary.htm#CATTYPE, ISBN:0716720094]",
                "id": "GO:0004252"
            }, {
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "peptidase activity",
                "definition": "Catalysis of the hydrolysis of a peptide bond. A peptide bond is a covalent bond formed when the carbon atom from the carboxyl group of one amino acid shares electrons with the nitrogen atom from the amino group of a second amino acid. [GOC:jl, ISBN:0815332181]",
                "id": "GO:0008233"
            }, {
                "name": "serine-type peptidase activity",
                "definition": "Catalysis of the hydrolysis of peptide bonds in a polypeptide chain by a catalytic mechanism that involves a catalytic triad consisting of a serine nucleophile that is activated by a proton relay involving an acidic residue (e.g. aspartate or glutamate) and a basic residue (usually histidine). [http://merops.sanger.ac.uk/about/glossary.htm#CATTYPE, ISBN:0716720094]",
                "id": "GO:0008236"
            }, {
                "name": "dipeptidyl-peptidase activity",
                "definition": "Catalysis of the hydrolysis of N-terminal dipeptides from a polypeptide chain. [GOC:mb]",
                "id": "GO:0008239"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "protein homodimerization activity",
                "definition": "Interacting selectively and non-covalently with an identical protein to form a homodimer. [GOC:jl]",
                "id": "GO:0042803"
            }]
        },
        "kegg_pathway": {}
    }, "TNKS": {
        "name": "tankyrase",
        "description": "",
        "entrez": "8658",
        "ensembl": "ENSG00000173273",
        "uniprot": "O95271",
        "hgnc": "11941",
        "go_terms": {
            "biological_process": [{
                "name": "protein polyubiquitination",
                "definition": "Addition of multiple ubiquitin groups to a protein, forming a ubiquitin chain. [ISBN:0815316194]",
                "id": "GO:0000209"
            }, {
                "name": "protein ADP-ribosylation",
                "definition": "The transfer, from NAD, of ADP-ribose to protein amino acids. [GOC:pr, RESID:AA0040, RESID:AA0168, RESID:AA0169, RESID:AA0231, RESID:AA0237, RESID:AA0295]",
                "id": "GO:0006471"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "mitotic spindle organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of the microtubule spindle during a mitotic cell cycle. [GOC:go_curators, GOC:mah]",
                "id": "GO:0007052"
            }, {
                "name": "protein transport",
                "definition": "The directed movement of proteins into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0015031"
            }, {
                "name": "Wnt signaling pathway",
                "definition": "The series of molecular signals initiated by binding of a Wnt protein to a frizzled family receptor on the surface of the target cell and ending with a change in cell state. [GOC:dph, GOC:go_curators, PMID:11532397]",
                "id": "GO:0016055"
            }, {
                "name": "protein deubiquitination",
                "definition": "The removal of one or more ubiquitin groups from a protein. [GOC:ai]",
                "id": "GO:0016579"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "regulation of telomere maintenance via telomerase",
                "definition": "Any process that modulates the frequency, rate or extent of the addition of telomeric repeats by telomerase. [GOC:mah]",
                "id": "GO:0032210"
            }, {
                "name": "positive regulation of telomere maintenance via telomerase",
                "definition": "Any process that activates or increases the frequency, rate or extent of the addition of telomeric repeats by telomerase. [GOC:mah]",
                "id": "GO:0032212"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "mRNA transport",
                "definition": "The directed movement of mRNA, messenger ribonucleic acid, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0051028"
            }, {
                "name": "spindle assembly",
                "definition": "The aggregation, arrangement and bonding together of a set of components to form the spindle, the array of microtubules and associated molecules that serves to move duplicated chromosomes apart. [GOC:ai, GOC:expert_rg, GOC:mtg_sensu, GOC:tb]",
                "id": "GO:0051225"
            }, {
                "name": "cell division",
                "definition": "The process resulting in division and partitioning of components of a cell to form more cells; may or may not be accompanied by the physical separation of a cell into distinct, individually membrane-bounded daughter cells. [GOC:di, GOC:go_curators, GOC:pr]",
                "id": "GO:0051301"
            }, {
                "name": "positive regulation of telomerase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of telomerase activity, the catalysis of the reaction: deoxynucleoside triphosphate + DNA(n) = diphosphate + DNA(n+1). [GOC:ai]",
                "id": "GO:0051973"
            }, {
                "name": "protein localization to chromosome, telomeric region",
                "definition": "Any process in which a protein is transported to, or maintained at, the telomeric region of a chromosome. [GOC:BHF, GOC:mah]",
                "id": "GO:0070198"
            }, {
                "name": "protein poly-ADP-ribosylation",
                "definition": "The transfer of multiple ADP-ribose residues from NAD to a protein amino acid, forming a poly(ADP-ribose) chain. [GOC:BHF, GOC:mah, GOC:rl]",
                "id": "GO:0070212"
            }, {
                "name": "protein auto-ADP-ribosylation",
                "definition": "The ADP-ribosylation by a protein of one or more of its own amino acid residues, or residues on an identical protein. [GOC:BHF, GOC:rl]",
                "id": "GO:0070213"
            }, {
                "name": "positive regulation of canonical Wnt signaling pathway",
                "definition": "Any process that increases the rate, frequency, or extent of the Wnt signaling pathway through beta-catenin, the series of molecular signals initiated by binding of a Wnt protein to a frizzled family receptor on the surface of the target cell, followed by propagation of the signal via beta-catenin, and ending with a change in transcription of target genes. [GOC:tb]",
                "id": "GO:0090263"
            }, {
                "name": "positive regulation of telomere capping",
                "definition": "Any process that activates or increases the frequency, rate or extent of telomere capping. [GO_REF:0000058, GOC:BHF, GOC:BHF_telomere, GOC:nc, GOC:TermGenie, PMID:23959892]",
                "id": "GO:1904355"
            }, {
                "name": "negative regulation of telomere maintenance via telomere lengthening",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of telomere maintenance via telomere lengthening. [GO_REF:0000058, GOC:BHF, GOC:BHF_telomere, GOC:nc, GOC:TermGenie, PMID:23959892]",
                "id": "GO:1904357"
            }, {
                "name": "negative regulation of telomeric DNA binding",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of telomeric DNA binding. [GO_REF:0000059, GOC:BHF, GOC:BHF_telomere, GOC:nc, GOC:TermGenie, PMID:24415760]",
                "id": "GO:1904743"
            }, {
                "name": "negative regulation of maintenance of mitotic sister chromatid cohesion, telomeric",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of maintenance of mitotic sister chromatid cohesion, telomeric. [GO_REF:0000058, GOC:BHF, GOC:BHF_telomere, GOC:rph, GOC:TermGenie, PMID:26373281]",
                "id": "GO:1904908"
            }],
            "cellular_component": [{
                "name": "Golgi membrane",
                "definition": "The lipid bilayer surrounding any of the compartments of the Golgi apparatus. [GOC:mah]",
                "id": "GO:0000139"
            }, {
                "name": "pericentriolar material",
                "definition": "A network of small fibers that surrounds the centrioles in cells; contains the microtubule nucleating activity of the centrosome. [GOC:clt, ISBN:0815316194]",
                "id": "GO:0000242"
            }, {
                "name": "chromosome, centromeric region",
                "definition": "The region of a chromosome that includes the centromeric DNA and associated proteins. In monocentric chromosomes, this region corresponds to a single area of the chromosome, whereas in holocentric chromosomes, it is evenly distributed along the chromosome. [GOC:cjm, GOC:elh, GOC:kmv, GOC:pr]",
                "id": "GO:0000775"
            }, {
                "name": "chromosome, telomeric region",
                "definition": "The terminal region of a linear chromosome that includes the telomeric DNA repeats and associated proteins. [GOC:elh]",
                "id": "GO:0000781"
            }, {
                "name": "nuclear chromosome, telomeric region",
                "definition": "The terminal region of a linear nuclear chromosome that includes the telomeric DNA repeats and associated proteins. [GOC:elh]",
                "id": "GO:0000784"
            }, {
                "name": "spindle pole",
                "definition": "Either of the ends of a spindle, where spindle microtubules are organized; usually contains a microtubule organizing center and accessory molecules, spindle microtubules and astral microtubules. [GOC:clt]",
                "id": "GO:0000922"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nuclear pore",
                "definition": "Any of the numerous similar discrete openings in the nuclear envelope of a eukaryotic cell, where the inner and outer nuclear membranes are joined. [ISBN:0198547684]",
                "id": "GO:0005643"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "chromosome",
                "definition": "A structure composed of a very long molecule of DNA and associated proteins (e.g. histones) that carries hereditary information. [ISBN:0198547684]",
                "id": "GO:0005694"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "cytoskeleton",
                "definition": "Any of the various filamentous elements that form the internal framework of cells, and typically remain after treatment of the cells with mild detergent to remove membrane constituents and soluble components of the cytoplasm. The term embraces intermediate filaments, microfilaments, microtubules, the microtrabecular lattice, and other structures characterized by a polymeric filamentous nature and long-range order within the cell. The various elements of the cytoskeleton not only serve in the maintenance of cellular shape but also have roles in other cellular functions, including cellular movement, cell division, endocytosis, and movement of organelles. [GOC:mah, ISBN:0198547684, PMID:16959967]",
                "id": "GO:0005856"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "nuclear body",
                "definition": "Extra-nucleolar nuclear domains usually visualized by confocal microscopy and fluorescent antibodies to specific proteins. [GOC:ma, PMID:10330182]",
                "id": "GO:0016604"
            }, {
                "name": "nuclear membrane",
                "definition": "Either of the lipid bilayers that surround the nucleus and form the nuclear envelope; excludes the intermembrane space. [GOC:mah, GOC:pz]",
                "id": "GO:0031965"
            }, {
                "name": "mitotic spindle pole",
                "definition": "Either of the ends of a mitotic spindle, a spindle that forms as part of mitosis, where spindle microtubules are organized; usually contains a microtubule organizing center and accessory molecules, spindle microtubules and astral microtubules. [GOC:vw]",
                "id": "GO:0097431"
            }],
            "molecular_function": [{
                "name": "NAD+ ADP-ribosyltransferase activity",
                "definition": "Catalysis of the reaction: NAD+ + (ADP-D-ribosyl)(n)-acceptor = nicotinamide + (ADP-D-ribosyl)(n+1)-acceptor. [EC:2.4.2.30]",
                "id": "GO:0003950"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "zinc ion binding",
                "definition": "Interacting selectively and non-covalently with zinc (Zn) ions. [GOC:ai]",
                "id": "GO:0008270"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "transferase activity, transferring glycosyl groups",
                "definition": "Catalysis of the transfer of a glycosyl group from one compound (donor) to another (acceptor). [GOC:jl, ISBN:0198506732]",
                "id": "GO:0016757"
            }, {
                "name": "histone binding",
                "definition": "Interacting selectively and non-covalently with a histone, any of a group of water-soluble proteins found in association with the DNA of eukaroytic chromosomes. They are involved in the condensation and coiling of chromosomes during cell division and have also been implicated in nonspecific suppression of gene activity. [GOC:jl]",
                "id": "GO:0042393"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "WEE1": {
        "name": "T cell receptor signaling pathway",
        "description": "This gene encodes a nuclear protein, which is a tyrosine kinase belonging to the Ser/Thr family of protein kinases. This protein catalyzes the inhibitory tyrosine phosphorylation of CDC2/cyclin B kinase, and appears to coordinate the transition between DNA replication and mitosis by protecting the nucleus from cytoplasmically activated CDC2 kinase. [provided by RefSeq, Jul 2008].",
        "entrez": "7465",
        "ensembl": "ENSG00000166483",
        "uniprot": "P30291",
        "hgnc": "12761",
        "go_terms": {
            "biological_process": [{
                "name": "G1/S transition of mitotic cell cycle",
                "definition": "The mitotic cell cycle transition by which a cell in G1 commits to S phase. The process begins with the build up of G1 cyclin-dependent kinase (G1 CDK), resulting in the activation of transcription of G1 cyclins. The process ends with the positive feedback of the G1 cyclins on the G1 CDK which commits the cell to S phase, in which DNA replication is initiated. [GOC:mtg_cell_cycle]",
                "id": "GO:0000082"
            }, {
                "name": "G2/M transition of mitotic cell cycle",
                "definition": "The mitotic cell cycle transition by which a cell in G2 commits to M phase. The process begins when the kinase activity of M cyclin/CDK complex reaches a threshold high enough for the cell cycle to proceed. This is accomplished by activating a positive feedback loop that results in the accumulation of unphosphorylated and active M cyclin/CDK complex. [GOC:mtg_cell_cycle]",
                "id": "GO:0000086"
            }, {
                "name": "microtubule cytoskeleton organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of cytoskeletal structures comprising microtubules and their associated proteins. [GOC:mah]",
                "id": "GO:0000226"
            }, {
                "name": "mitotic cell cycle",
                "definition": "Progression through the phases of the mitotic cell cycle, the most common eukaryotic cell cycle, which canonically comprises four successive phases called G1, S, G2, and M and includes replication of the genome and the subsequent segregation of chromosomes into daughter cells. In some variant cell cycles nuclear replication or nuclear division may not be followed by cell division, or G1 and G2 phases may be absent. [GOC:mah, ISBN:0815316194, Reactome:69278]",
                "id": "GO:0000278"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "establishment of cell polarity",
                "definition": "The specification and formation of anisotropic intracellular organization or cell growth patterns. [GOC:mah]",
                "id": "GO:0030010"
            }, {
                "name": "neuron projection morphogenesis",
                "definition": "The process in which the anatomical structures of a neuron projection are generated and organized. A neuron projection is any process extending from a neural cell, such as axons or dendrites. [GOC:mah]",
                "id": "GO:0048812"
            }, {
                "name": "cell division",
                "definition": "The process resulting in division and partitioning of components of a cell to form more cells; may or may not be accompanied by the physical separation of a cell into distinct, individually membrane-bounded daughter cells. [GOC:di, GOC:go_curators, GOC:pr]",
                "id": "GO:0051301"
            }, {
                "name": "regulation of cell cycle",
                "definition": "Any process that modulates the rate or extent of progression through the cell cycle. [GOC:ai, GOC:dph, GOC:tb]",
                "id": "GO:0051726"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "nucleolus",
                "definition": "A small, dense body one or more of which are present in the nucleus of eukaryotic cells. It is rich in RNA and protein, is not bounded by a limiting membrane, and is not seen during mitosis. Its prime function is the transcription of the nucleolar DNA into 45S ribosomal-precursor RNA, the processing of this RNA into 5.8S, 18S, and 28S components of ribosomal RNA, and the association of these components with 5S RNA and proteins synthesized outside the nucleolus. This association results in the formation of ribonucleoprotein precursors; these pass into the cytoplasm and mature into the 40S and 60S subunits of the ribosome. [ISBN:0198506732]",
                "id": "GO:0005730"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "non-membrane spanning protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + protein L-tyrosine = ADP + protein L-tyrosine phosphate by a non-membrane spanning protein. [EC:2.7.10.2]",
                "id": "GO:0004715"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.10.2",
            "pathways": [{
                "pathway": "04660",
                "name": "T cell receptor signaling pathway",
                "class": "Organismal Systems; Immune system"
            }]
        }
    }, "KCNA3": {
        "name": "potassium voltage-gated channel subfamily A member 3",
        "description": "Potassium channels represent the most complex class of voltage-gated ion channels from both functional and structural standpoints. Their diverse functions include regulating neurotransmitter release, heart rate, insulin secretion, neuronal excitability, epithelial electrolyte transport, smooth muscle contraction, and cell volume. Four sequence-related potassium channel genes - shaker, shaw, shab, and shal - have been identified in Drosophila, and each has been shown to have human homolog(s). This gene encodes a member of the potassium channel, voltage-gated, shaker-related subfamily. This member contains six membrane-spanning domains with a shaker-type repeat in the fourth segment. It belongs to the delayed rectifier class, members of which allow nerve cells to efficiently repolarize following an action potential. It plays an essential role in T-cell proliferation and activation. This gene appears to be intronless and it is clustered together with KCNA2 and KCNA10 genes on chromosome 1. [provided by RefSeq, Jul 2008].",
        "entrez": "3738",
        "ensembl": "ENSG00000177272",
        "uniprot": "P22001",
        "hgnc": "6221",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "potassium ion transport",
                "definition": "The directed movement of potassium ions (K+) into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006813"
            }, {
                "name": "regulation of ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of ions from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0034765"
            }, {
                "name": "protein homooligomerization",
                "definition": "The process of creating protein oligomers, compounds composed of a small number, usually between three and ten, of identical component monomers. Oligomers may be formed by the polymerization of a number of monomers or the depolymerization of a large protein polymer. [GOC:ai]",
                "id": "GO:0051260"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "potassium ion transmembrane transport",
                "definition": "A process in which a potassium ion is transported from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0071805"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "voltage-gated potassium channel complex",
                "definition": "A protein complex that forms a transmembrane channel through which potassium ions may cross a cell membrane in response to changes in membrane potential. [GOC:mah]",
                "id": "GO:0008076"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "axon",
                "definition": "The long process of a neuron that conducts nerve impulses, usually away from the cell body to the terminals and varicosities, which are sites of storage and release of neurotransmitter. [GOC:nln, ISBN:0198506732]",
                "id": "GO:0030424"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }],
            "molecular_function": [{
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "voltage-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a voltage-gated channel. An ion is an atom or group of atoms carrying an electric charge by virtue of having gained or lost one or more electrons. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0198506732, ISBN:0815340729]",
                "id": "GO:0005244"
            }, {
                "name": "voltage-gated potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by a voltage-gated channel. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0815340729]",
                "id": "GO:0005249"
            }, {
                "name": "delayed rectifier potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by a delayed rectifying voltage-gated channel. A delayed rectifying current-voltage relation is one where channel activation kinetics are time-dependent, and inactivation is slow. [GOC:mah, PMID:11343411, PMID:2462513]",
                "id": "GO:0005251"
            }, {
                "name": "potassium channel activity",
                "definition": "Enables the facilitated diffusion of a potassium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:BHF, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005267"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "outward rectifier potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by an outwardly-rectifying voltage-gated channel. An outwardly rectifying current-voltage relation is one where at any given driving force the outward flow of K+ ions exceeds the inward flow for the opposite driving force. [GOC:mah]",
                "id": "GO:0015271"
            }]
        },
        "kegg_pathway": {}
    }, "NGFR": {
        "name": "nerve growth factor receptor",
        "description": "Nerve growth factor receptor contains an extracellular domain containing four 40-amino acid repeats with 6 cysteine residues at conserved positions followed by a serine/threonine-rich region, a single transmembrane domain, and a 155-amino acid cytoplasmic domain. The cysteine-rich region contains the nerve growth factor binding domain. [provided by RefSeq, Jul 2008].",
        "entrez": "4804",
        "ensembl": "ENSG00000064300",
        "uniprot": "P08138",
        "hgnc": "7809",
        "go_terms": {
            "biological_process": [{
                "name": "intracellular protein transport",
                "definition": "The directed movement of proteins in a cell, including the movement of proteins between specific compartments or structures within a cell, such as organelles of a eukaryotic cell. [GOC:mah]",
                "id": "GO:0006886"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "activation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that initiates the activity of the inactive enzyme cysteine-type endopeptidase in the context of an apoptotic process. [GOC:al, GOC:dph, GOC:jl, GOC:mtg_apoptosis, GOC:tb, PMID:14744432, PMID:18328827, Wikipedia:Caspase]",
                "id": "GO:0006919"
            }, {
                "name": "inflammatory response",
                "definition": "The immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. The process is characterized by local vasodilation, extravasation of plasma into intercellular spaces and accumulation of white blood cells and macrophages. [GO_REF:0000022, GOC:mtg_15nov05, ISBN:0198506732]",
                "id": "GO:0006954"
            }, {
                "name": "immune response",
                "definition": "Any immune system process that functions in the calibrated response of an organism to a potential internal or invasive threat. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0006955"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "Rho protein signal transduction",
                "definition": "A series of molecular signals within the cell that are mediated by a member of the Rho family of proteins switching to a GTP-bound active state. [GOC:bf]",
                "id": "GO:0007266"
            }, {
                "name": "multicellular organism development",
                "definition": "The biological process whose specific outcome is the progression of a multicellular organism over time from an initial condition (e.g. a zygote or a young adult) to a later condition (e.g. a multicellular animal or an aged adult). [GOC:dph, GOC:ems, GOC:isa_complete, GOC:tb]",
                "id": "GO:0007275"
            }, {
                "name": "nervous system development",
                "definition": "The process whose specific outcome is the progression of nervous tissue over time, from its formation to its mature state. [GOC:dgh]",
                "id": "GO:0007399"
            }, {
                "name": "axon guidance",
                "definition": "The chemotaxis process that directs the migration of an axon growth cone to a specific target site in response to a combination of attractive and repulsive cues. [ISBN:0878932437]",
                "id": "GO:0007411"
            }, {
                "name": "negative regulation of neuron projection development",
                "definition": "Any process that decreases the rate, frequency or extent of neuron projection development. Neuron projection development is the process whose specific outcome is the progression of a neuron projection over time, from its formation to the mature structure. A neuron projection is any process extending from a neural cell, such as axons or dendrites (collectively called neurites). [GOC:dph, GOC:tb]",
                "id": "GO:0010977"
            }, {
                "name": "cell differentiation",
                "definition": "The process in which relatively unspecialized cells, e.g. embryonic or regenerative cells, acquire specialized structural and/or functional features that characterize the cells, tissues, or organs of the mature organism or some other relatively stable phase of the organism's life history. Differentiation includes the processes involved in commitment of a cell to a specific fate and its subsequent development to the mature state. [ISBN:0198506732]",
                "id": "GO:0030154"
            }, {
                "name": "membrane protein intracellular domain proteolysis",
                "definition": "The proteolytic cleavage of a transmembrane protein leading to the release of an intracellular domain. [PMID:12808018]",
                "id": "GO:0031293"
            }, {
                "name": "response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:add, ISBN:0721601464]",
                "id": "GO:0032496"
            }, {
                "name": "circadian regulation of gene expression",
                "definition": "Any process that modulates the frequency, rate or extent of gene expression such that an expression pattern recurs with a regularity of approximately 24 hours. [GOC:mah]",
                "id": "GO:0032922"
            }, {
                "name": "tumor necrosis factor-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a tumor necrosis factor to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling]",
                "id": "GO:0033209"
            }, {
                "name": "glucose homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of glucose within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042593"
            }, {
                "name": "positive regulation of apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043065"
            }, {
                "name": "negative regulation of apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043066"
            }, {
                "name": "negative regulation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of a cysteine-type endopeptidase activity involved in the apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043154"
            }, {
                "name": "regulation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that modulates the activity of a cysteine-type endopeptidase involved in apoptosis. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043281"
            }, {
                "name": "neurotrophin TRK receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a neurotrophin to a receptor on the surface of the target cell where the receptor possesses tyrosine kinase activity, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:bf, GOC:ceb, GOC:jc, GOC:signaling, PMID:12065629, Wikipedia:Trk_receptor]",
                "id": "GO:0048011"
            }, {
                "name": "rhythmic process",
                "definition": "Any process pertinent to the generation and maintenance of rhythms in the physiology of an organism. [GOC:jid]",
                "id": "GO:0048511"
            }, {
                "name": "negative regulation of axonogenesis",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of axonogenesis. [GOC:ai]",
                "id": "GO:0050771"
            }, {
                "name": "positive regulation of axonogenesis",
                "definition": "Any process that activates or increases the frequency, rate or extent of axonogenesis. [GOC:ai]",
                "id": "GO:0050772"
            }, {
                "name": "neuron apoptotic process",
                "definition": "Any apoptotic process in a neuron, the basic cellular unit of nervous tissue. Each neuron consists of a body, an axon, and dendrites. Their purpose is to receive, conduct, and transmit impulses in the nervous system. [CL:0000540, GOC:mtg_apoptosis, MeSH:A.08.663]",
                "id": "GO:0051402"
            }, {
                "name": "apoptotic signaling pathway",
                "definition": "A series of molecular signals which triggers the apoptotic death of a cell. The pathway starts with reception of a signal, and ends when the execution phase of apoptosis is triggered. [GOC:mtg_apoptosis]",
                "id": "GO:0097190"
            }, {
                "name": "positive regulation of protein localization to nucleus",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein localization to nucleus. [GOC:TermGenie]",
                "id": "GO:1900182"
            }, {
                "name": "positive regulation of pri-miRNA transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of pri-miRNA transcription from RNA polymerase II promoter. [GO_REF:0000058, GOC:dph, GOC:kmv, GOC:TermGenie, PMID:24699545]",
                "id": "GO:1902895"
            }, {
                "name": "negative regulation of blood vessel endothelial cell proliferation involved in sprouting angiogenesis",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of blood vessel endothelial cell proliferation involved in sprouting angiogenesis. [GO_REF:0000058, GOC:TermGenie, PMID:23388056]",
                "id": "GO:1903588"
            }, {
                "name": "cellular response to amyloid-beta",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a amyloid-beta stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:23555824]",
                "id": "GO:1904646"
            }, {
                "name": "regulation of glucose import in response to insulin stimulus",
                "definition": "Any process that modulates the frequency, rate or extent of glucose import in response to insulin stimulus. [GOC:BHF]",
                "id": "GO:2001273"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "endosome",
                "definition": "A vacuole to which materials ingested by endocytosis are delivered. [ISBN:0198506732, PMID:19696797]",
                "id": "GO:0005768"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "receptor activity",
                "definition": "Combining with an extracellular or intracellular messenger to initiate a change in cell activity. [GOC:ceb, ISBN:0198506732]",
                "id": "GO:0004872"
            }, {
                "name": "transmembrane signaling receptor activity",
                "definition": "Combining with an extracellular or intracellular signal and transmitting the signal from one side of the membrane to the other to initiate a change in cell activity or state as part of signal transduction. [GOC:go_curators, Wikipedia:Transmembrane_receptor]",
                "id": "GO:0004888"
            }, {
                "name": "tumor necrosis factor-activated receptor activity",
                "definition": "Combining with tumor necrosis factor, a proinflammatory cytokine produced by monocytes and macrophages, to initiate a change in cell function. [GOC:jl, http://lookwayup.com/]",
                "id": "GO:0005031"
            }, {
                "name": "death receptor activity",
                "definition": "Combining with an extracellular messenger (called a death ligand), and transmitting the signal from one side of the plasma membrane to the other to initiate apoptotic or necrotic cell death. [GOC:bf, GOC:BHF, GOC:ecd, GOC:mtg_apoptosis, GOC:rl, PMID:10209153]",
                "id": "GO:0005035"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "calmodulin binding",
                "definition": "Interacting selectively and non-covalently with calmodulin, a calcium-binding protein with many roles, both in the calcium-bound and calcium-free states. [GOC:krc]",
                "id": "GO:0005516"
            }, {
                "name": "coreceptor activity",
                "definition": "Combining with an extracellular or intracellular messenger, and in cooperation with a nearby primary receptor, initiating a change in cell activity. [GOC:go_curators]",
                "id": "GO:0015026"
            }, {
                "name": "Rab GTPase binding",
                "definition": "Interacting selectively and non-covalently with Rab protein, any member of the Rab subfamily of the Ras superfamily of monomeric GTPases. [GOC:mah]",
                "id": "GO:0017137"
            }, {
                "name": "ubiquitin protein ligase binding",
                "definition": "Interacting selectively and non-covalently with a ubiquitin protein ligase enzyme, any of the E3 proteins. [GOC:vp]",
                "id": "GO:0031625"
            }, {
                "name": "neurotrophin binding",
                "definition": "Interacting selectively and non-covalently with a neurotrophin, any of a family of growth factors that prevent apoptosis in neurons and promote nerve growth. [GOC:jl, http://www.mercksource.com/, PR:000021998]",
                "id": "GO:0043121"
            }, {
                "name": "nerve growth factor binding",
                "definition": "Interacting selectively and non-covalently with nerve growth factor (NGF). [GOC:dgh]",
                "id": "GO:0048406"
            }]
        },
        "kegg_pathway": {}
    }, "SLC2A1": {
        "name": "solute carrier family 2 member 1",
        "description": "This gene encodes a major glucose transporter in the mammalian blood-brain barrier. The encoded protein is found primarily in the cell membrane and on the cell surface, where it can also function as a receptor for human T-cell leukemia virus (HTLV) I and II. Mutations in this gene have been found in a family with paroxysmal exertion-induced dyskinesia. [provided by RefSeq, Apr 2013].",
        "entrez": "6513",
        "ensembl": "ENSG00000117394",
        "uniprot": "P11166",
        "hgnc": "11005",
        "go_terms": {
            "biological_process": [{
                "name": "lactose biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of lactose, the disaccharide galactopyranosyl-glucose. [GOC:go_curators]",
                "id": "GO:0005989"
            }, {
                "name": "response to osmotic stress",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating an increase or decrease in the concentration of solutes outside the organism or cell. [GOC:jl]",
                "id": "GO:0006970"
            }, {
                "name": "female pregnancy",
                "definition": "The set of physiological processes that allow an embryo or foetus to develop within the body of a female animal. It covers the time from fertilization of a female ovum by a male spermatozoon until birth. [ISBN:0192800825]",
                "id": "GO:0007565"
            }, {
                "name": "carbohydrate transport",
                "definition": "The directed movement of carbohydrate into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. Carbohydrates are any of a group of organic compounds based of the general formula Cx(H2O)y. [GOC:ai]",
                "id": "GO:0008643"
            }, {
                "name": "regulation of glucose transport",
                "definition": "Any process that modulates the frequency, rate or extent of glucose transport. Glucose transport is the directed movement of the hexose monosaccharide glucose into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:dph, GOC:tb]",
                "id": "GO:0010827"
            }, {
                "name": "glucose transport",
                "definition": "The directed movement of the hexose monosaccharide glucose into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0015758"
            }, {
                "name": "L-ascorbic acid metabolic process",
                "definition": "The chemical reactions and pathways involving L-ascorbic acid, (2R)-2-[(1S)-1,2-dihydroxyethyl]-4-hydroxy-5-oxo-2,5-dihydrofuran-3-olate; L-ascorbic acid is vitamin C and has co-factor and anti-oxidant activities in many species. [CHEBI:38290, GOC:jl, ISBN:0198506732]",
                "id": "GO:0019852"
            }, {
                "name": "cerebral cortex development",
                "definition": "The progression of the cerebral cortex over time from its initial formation until its mature state. The cerebral cortex is the outer layered region of the telencephalon. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06]",
                "id": "GO:0021987"
            }, {
                "name": "response to insulin",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an insulin stimulus. Insulin is a polypeptide hormone produced by the islets of Langerhans of the pancreas in mammals, and by the homologous organs of other organisms. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0032868"
            }, {
                "name": "cellular response to glucose starvation",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of deprivation of glucose. [GOC:jl]",
                "id": "GO:0042149"
            }, {
                "name": "xenobiotic transport",
                "definition": "The directed movement of a xenobiotic, a compound foreign to living organisms, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:go_curators]",
                "id": "GO:0042908"
            }, {
                "name": "regulation of insulin secretion",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of insulin. [GOC:ai]",
                "id": "GO:0050796"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "macromolecular complex assembly",
                "definition": "The aggregation, arrangement and bonding together of a set of macromolecules to form a complex. [GOC:jl]",
                "id": "GO:0065003"
            }, {
                "name": "dehydroascorbic acid transport",
                "definition": "The directed movement of dehydroascorbate into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. Dehydroascorbate, 5-(1,2-dihydroxyethyl)furan-2,3,4(5H)-trione, is an oxidized form of vitamin C. [GOC:sl]",
                "id": "GO:0070837"
            }, {
                "name": "cellular response to mechanical stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a mechanical stimulus. [GOC:mah]",
                "id": "GO:0071260"
            }, {
                "name": "cellular hyperosmotic response",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of detection of, or exposure to, a hyperosmotic environment, i.e. an environment with a higher concentration of solutes than the organism or cell. [GOC:mah]",
                "id": "GO:0071474"
            }, {
                "name": "response to Thyroglobulin triiodothyronine",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a Thyroglobulin triiodothyronine stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:7531505]",
                "id": "GO:1904016"
            }, {
                "name": "glucose transmembrane transport",
                "definition": "The process in which glucose is transported across a membrane. [GO_REF:0000069, GOC:TermGenie, PMID:9090050]",
                "id": "GO:1904659"
            }],
            "cellular_component": [{
                "name": "Golgi membrane",
                "definition": "The lipid bilayer surrounding any of the compartments of the Golgi apparatus. [GOC:mah]",
                "id": "GO:0000139"
            }, {
                "name": "female pronucleus",
                "definition": "The pronucleus originating from the ovum that is being fertilized. [GOC:hjd, ISBN:0198506732]",
                "id": "GO:0001939"
            }, {
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "caveola",
                "definition": "A membrane raft that forms small pit, depression, or invagination that communicates with the outside of a cell and extends inward, indenting the cytoplasm and the cell membrane. Examples include flask-shaped invaginations of the plasma membrane in adipocytes associated with caveolin proteins, and minute pits or incuppings of the cell membrane formed during pinocytosis.  Caveolae may be pinched off to form free vesicles within the cytoplasm. [GOC:mah, ISBN:0721662544, PMID:16645198]",
                "id": "GO:0005901"
            }, {
                "name": "cell-cell junction",
                "definition": "A cell junction that forms a connection between two or more cells in a multicellular organism; excludes direct cytoplasmic junctions such as ring canals. [GOC:dgh, GOC:hb, GOC:mah]",
                "id": "GO:0005911"
            }, {
                "name": "intercalated disc",
                "definition": "A complex cell-cell junction at which myofibrils terminate in cardiomyocytes; mediates mechanical and electrochemical integration between individual cardiomyocytes. The intercalated disc contains regions of tight mechanical attachment (fasciae adherentes and desmosomes) and electrical coupling (gap junctions) between adjacent cells. [GOC:mtg_muscle, PMID:11732910]",
                "id": "GO:0014704"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "basolateral plasma membrane",
                "definition": "The region of the plasma membrane that includes the basal end and sides of the cell. Often used in reference to animal polarized epithelial membranes, where the basal membrane is the part attached to the extracellular matrix, or in plant cells, where the basal membrane is defined with respect to the zygotic axis. [GOC:go_curators]",
                "id": "GO:0016323"
            }, {
                "name": "apical plasma membrane",
                "definition": "The region of the plasma membrane located at the apical end of the cell. [GOC:curators]",
                "id": "GO:0016324"
            }, {
                "name": "Z disc",
                "definition": "Platelike region of a muscle sarcomere to which the plus ends of actin filaments are attached. [GOC:mtg_muscle, ISBN:0815316194]",
                "id": "GO:0030018"
            }, {
                "name": "midbody",
                "definition": "A thin cytoplasmic bridge formed between daughter cells at the end of cytokinesis. The midbody forms where the contractile ring constricts, and may persist for some time before finally breaking to complete cytokinesis. [ISBN:0815316194]",
                "id": "GO:0030496"
            }, {
                "name": "cortical actin cytoskeleton",
                "definition": "The portion of the actin cytoskeleton, comprising filamentous actin and associated proteins, that lies just beneath the plasma membrane. [GOC:mah]",
                "id": "GO:0030864"
            }, {
                "name": "vesicle",
                "definition": "Any small, fluid-filled, spherical organelle enclosed by membrane. [GOC:mah, GOC:pz, GOC:vesicles]",
                "id": "GO:0031982"
            }, {
                "name": "sarcolemma",
                "definition": "The outer membrane of a muscle cell, consisting of the plasma membrane, a covering basement membrane (about 100 nm thick and sometimes common to more than one fiber), and the associated loose network of collagen fibers. [ISBN:0198506732]",
                "id": "GO:0042383"
            }, {
                "name": "melanosome",
                "definition": "A tissue-specific, membrane-bounded cytoplasmic organelle within which melanin pigments are synthesized and stored. Melanosomes are synthesized in melanocyte cells. [GOC:jl, PMID:11584301]",
                "id": "GO:0042470"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }, {
                "name": "blood microparticle",
                "definition": "A phospholipid microvesicle that is derived from any of several cell types, such as platelets, blood cells, endothelial cells, or others, and contains membrane receptors as well as other proteins characteristic of the parental cell. Microparticles are heterogeneous in size, and are characterized as microvesicles free of nucleic acids. [GOC:BHF, GOC:mah, PMID:16373184]",
                "id": "GO:0072562"
            }],
            "molecular_function": [{
                "name": "transporter activity",
                "definition": "Enables the directed movement of substances (such as macromolecules, small molecules, ions) into, out of or within a cell, or between cells. A transporter is in a fixed location in the cell and allows molecules to pass via a channel or a pore in its structure. [GOC:ai, GOC:dgf]",
                "id": "GO:0005215"
            }, {
                "name": "glucose transmembrane transporter activity",
                "definition": "Enables the transfer of the hexose monosaccharide glucose from one side of a membrane to the other. [GOC:ai, GOC:mtg_transport, ISBN:0815340729]",
                "id": "GO:0005355"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "kinase binding",
                "definition": "Interacting selectively and non-covalently with a kinase, any enzyme that catalyzes the transfer of a phosphate group. [GOC:jl]",
                "id": "GO:0019900"
            }, {
                "name": "transmembrane transporter activity",
                "definition": "Enables the transfer of a substance, usually a specific substance or a group of related substances, from one side of a membrane to the other. [GOC:jid, GOC:mtg_transport, ISBN:0815340729]",
                "id": "GO:0022857"
            }, {
                "name": "dehydroascorbic acid transmembrane transporter activity",
                "definition": "Enables the transfer of dehydroascorbate, 5-(1,2-dihydroxyethyl)furan-2,3,4(5H)-trione, from one side of a membrane to the other. [CHEBI:17242]",
                "id": "GO:0033300"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "xenobiotic transmembrane transporter activity",
                "definition": "Enables the directed movement of a xenobiotic, a compound foreign to living organisms, from one side of a membrane to the other. [GOC:go_curators]",
                "id": "GO:0042910"
            }, {
                "name": "protein self-association",
                "definition": "Interacting selectively and non-covalently with a domain within the same polypeptide. [GOC:jl]",
                "id": "GO:0043621"
            }, {
                "name": "D-glucose transmembrane transporter activity",
                "definition": "Enables the transfer of the D-enantiomer of the hexose monosaccharide glucose from one side of a membrane to the other. [GOC:jid, GOC:jsg, GOC:mah]",
                "id": "GO:0055056"
            }]
        },
        "kegg_pathway": {}
    }, "XBP1": {
        "name": "X-box binding protein 1",
        "description": "This gene encodes a transcription factor that regulates MHC class II genes by binding to a promoter element referred to as an X box. This gene product is a bZIP protein, which was also identified as a cellular transcription factor that binds to an enhancer in the promoter of the T cell leukemia virus type 1 promoter. It may increase expression of viral proteins by acting as the DNA binding partner of a viral transactivator. It has been found that upon accumulation of unfolded proteins in the endoplasmic reticulum (ER), the mRNA of this gene is processed to an active form by an unconventional splicing mechanism that is mediated by the endonuclease inositol-requiring enzyme 1 (IRE1). The resulting loss of 26 nt from the spliced mRNA causes a frame-shift and an isoform XBP1(S), which is the functionally active transcription factor. The isoform encoded by the unspliced mRNA, XBP1(U), is constitutively expressed, and thought to function as a negative feedback regulator of XBP1(S), which shuts off transcription of target genes during the recovery phase of ER stress. A pseudogene of XBP1 has been identified and localized to chromosome 5. [provided by RefSeq, Jul 2008].",
        "entrez": "7494",
        "ensembl": "ENSG00000100219",
        "uniprot": "P17861",
        "hgnc": "12801",
        "go_terms": {
            "biological_process": [{
                "name": "angiogenesis",
                "definition": "Blood vessel formation when new vessels emerge from the proliferation of pre-existing blood vessels. [ISBN:0878932453]",
                "id": "GO:0001525"
            }, {
                "name": "liver development",
                "definition": "The process whose specific outcome is the progression of the liver over time, from its formation to the mature structure. The liver is an exocrine gland which secretes bile and functions in metabolism of protein and carbohydrate and fat, synthesizes substances involved in the clotting of the blood, synthesizes vitamin A, detoxifies poisonous substances, stores glycogen, and breaks down worn-out erythrocytes. [GOC:add, ISBN:068340007X]",
                "id": "GO:0001889"
            }, {
                "name": "endothelial cell proliferation",
                "definition": "The multiplication or reproduction of endothelial cells, resulting in the expansion of a cell population. Endothelial cells are thin flattened cells which line the inside surfaces of body cavities, blood vessels, and lymph vessels, making up the endothelium. [GOC:add, ISBN:0781735149]",
                "id": "GO:0001935"
            }, {
                "name": "epithelial cell maturation",
                "definition": "The developmental process, independent of morphogenetic (shape) change, that is required for an epithelial cell to attain its fully functional state. An epithelial cell is a cell usually found in a two-dimensional sheet with a free surface. [GOC:dph]",
                "id": "GO:0002070"
            }, {
                "name": "positive regulation of immunoglobulin production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of immunoglobulin production. [GOC:add]",
                "id": "GO:0002639"
            }, {
                "name": "transcription, DNA-templated",
                "definition": "The cellular synthesis of RNA on a template of DNA. [GOC:jl, GOC:txnOH]",
                "id": "GO:0006351"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "transcription from RNA polymerase II promoter",
                "definition": "The synthesis of RNA from a DNA template by RNA polymerase II, originating at an RNA polymerase II promoter. Includes transcription of messenger RNA (mRNA) and certain small nuclear RNAs (snRNAs). [GOC:jl, GOC:txnOH, ISBN:0321000382]",
                "id": "GO:0006366"
            }, {
                "name": "ubiquitin-dependent protein catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of a protein or peptide by hydrolysis of its peptide bonds, initiated by the covalent attachment of a ubiquitin group, or multiple ubiquitin groups, to the protein. [GOC:go_curators]",
                "id": "GO:0006511"
            }, {
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "fatty acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a fatty acid, any of the aliphatic monocarboxylic acids that can be liberated by hydrolysis from naturally occurring fats and oils. Fatty acids are predominantly straight-chain acids of 4 to 24 carbon atoms, which may be saturated or unsaturated; branched fatty acids and hydroxy fatty acids also occur, and very long chain acids of over 30 carbons are found in waxes. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0006633"
            }, {
                "name": "autophagy",
                "definition": "The cellular catabolic process in which cells digest parts of their own cytoplasm; allows for both recycling of macromolecular constituents under conditions of cellular stress and remodeling the intracellular structure for cell differentiation. [GOC:autophagy, ISBN:0198547684, PMID:11099404, PMID:9412464]",
                "id": "GO:0006914"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "immune response",
                "definition": "Any immune system process that functions in the calibrated response of an organism to a potential internal or invasive threat. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0006955"
            }, {
                "name": "response to unfolded protein",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an unfolded protein stimulus. [GOC:jl]",
                "id": "GO:0006986"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter involved in unfolded protein response",
                "definition": "The activation of genes whose promoters contain a specific sequence elements such as the unfolded protein response element (UPRE; consensus CAGCGTG) or the ER stress-response element (ERSE; CCAAN(N)9CCACG), as a result of signaling via the unfolded protein response. [GOC:dph, GOC:mah, GOC:tb, GOC:txnOH, PMID:12042763]",
                "id": "GO:0006990"
            }, {
                "name": "multicellular organism development",
                "definition": "The biological process whose specific outcome is the progression of a multicellular organism over time from an initial condition (e.g. a zygote or a young adult) to a later condition (e.g. a multicellular animal or an aged adult). [GOC:dph, GOC:ems, GOC:isa_complete, GOC:tb]",
                "id": "GO:0007275"
            }, {
                "name": "muscle organ development",
                "definition": "The process whose specific outcome is the progression of the muscle over time, from its formation to the mature structure. The muscle is an organ consisting of a tissue made up of various elongated cells that are specialized to contract and thus to produce movement and mechanical work. [GOC:jid, ISBN:0198506732]",
                "id": "GO:0007517"
            }, {
                "name": "positive regulation of cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008284"
            }, {
                "name": "regulation of autophagy",
                "definition": "Any process that modulates the frequency, rate or extent of autophagy. Autophagy is the process in which cells digest parts of their own cytoplasm. [GOC:dph, GOC:tb]",
                "id": "GO:0010506"
            }, {
                "name": "negative regulation of myotube differentiation",
                "definition": "Any process that decreases the frequency, rate or extent of myotube differentiation. Myotube differentiation is the process in which a relatively unspecialized cell acquires specialized features of a myotube cell. Myotubes are multinucleated cells that are formed when proliferating myoblasts exit the cell cycle, differentiate and fuse. [GOC:dph, GOC:tb]",
                "id": "GO:0010832"
            }, {
                "name": "protein transport",
                "definition": "The directed movement of proteins into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0015031"
            }, {
                "name": "cell differentiation",
                "definition": "The process in which relatively unspecialized cells, e.g. embryonic or regenerative cells, acquire specialized structural and/or functional features that characterize the cells, tissues, or organs of the mature organism or some other relatively stable phase of the organism's life history. Differentiation includes the processes involved in commitment of a cell to a specific fate and its subsequent development to the mature state. [ISBN:0198506732]",
                "id": "GO:0030154"
            }, {
                "name": "positive regulation of cell migration",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell migration. [GOC:go_curators]",
                "id": "GO:0030335"
            }, {
                "name": "endoplasmic reticulum unfolded protein response",
                "definition": "The series of molecular signals generated as a consequence of the presence of unfolded proteins in the endoplasmic reticulum (ER) or other ER-related stress; results in changes in the regulation of transcription and translation. [GOC:mah, PMID:12042763]",
                "id": "GO:0030968"
            }, {
                "name": "exocrine pancreas development",
                "definition": "The process whose specific outcome is the progression of the exocrine pancreas over time, from its formation to the mature structure. The exocrine pancreas produces and store zymogens of digestive enzymes, such as chymotrypsinogen and trypsinogen in the acinar cells. [GOC:cvs]",
                "id": "GO:0031017"
            }, {
                "name": "protein destabilization",
                "definition": "Any process that decreases the stability of a protein, making it more vulnerable to degradative processes or aggregation. [GOC:mah]",
                "id": "GO:0031648"
            }, {
                "name": "cellular response to nutrient",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a nutrient stimulus. [GOC:mah]",
                "id": "GO:0031670"
            }, {
                "name": "cellular response to insulin stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an insulin stimulus. Insulin is a polypeptide hormone produced by the islets of Langerhans of the pancreas in mammals, and by the homologous organs of other organisms. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0032869"
            }, {
                "name": "cellular triglyceride homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of triglyceride within a cell or between a cell and its external environment. [GOC:BHF]",
                "id": "GO:0035356"
            }, {
                "name": "positive regulation of vascular wound healing",
                "definition": "Any process that increases the rate, frequency, or extent of blood vessel formation when new vessels emerge from the proliferation of pre-existing blood vessels and contribute to the series of events that restore integrity to damaged vasculature. [GOC:rph]",
                "id": "GO:0035470"
            }, {
                "name": "IRE1-mediated unfolded protein response",
                "definition": "A series of molecular signals mediated by the endoplasmic reticulum stress sensor IRE1 (Inositol-requiring transmembrane kinase/endonuclease). Begins with activation of IRE1 in response to endoplasmic reticulum (ER) stress, and ends with regulation of a downstream cellular process, e.g. transcription. One target of activated IRE1 is the transcription factor HAC1 in yeast, or XBP1 in mammals; IRE1 cleaves an intron of a mRNA coding for HAC1/XBP1 to generate an activated HAC1/XBP1 transcription factor, which controls the up regulation of UPR-related genes. At least in mammals, IRE1 can also signal through additional intracellular pathways including JNK and NF-kappaB. [GOC:bf, GOC:PARL, PMID:22013210]",
                "id": "GO:0036498"
            }, {
                "name": "ATF6-mediated unfolded protein response",
                "definition": "A series of molecular signals mediated by the endoplasmic reticulum membrane stress sensor ATF6 (activating transcription factor 6). Begins with activation of ATF6 in response to endoplasmic reticulum (ER) stress, and ends with regulation of a downstream cellular process, e.g. transcription. Under conditions of endoplasmic reticulum stress, ATF6 translocates to the Golgi where it is processed by proteases to release a cytoplasmic domain (ATF6f), which operates as a transcriptional activator of many genes required to restore folding capacity. [GOC:bf, GOC:PARL, PMID:22013210]",
                "id": "GO:0036500"
            }, {
                "name": "cellular response to glucose starvation",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of deprivation of glucose. [GOC:jl]",
                "id": "GO:0042149"
            }, {
                "name": "glucose homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of glucose within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042593"
            }, {
                "name": "cholesterol homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of cholesterol within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042632"
            }, {
                "name": "negative regulation of apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043066"
            }, {
                "name": "positive regulation of MHC class II biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of MHC class II. [GOC:go_curators]",
                "id": "GO:0045348"
            }, {
                "name": "positive regulation of B cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of B cell differentiation. [GOC:go_curators]",
                "id": "GO:0045579"
            }, {
                "name": "positive regulation of T cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of T cell differentiation. [GOC:go_curators]",
                "id": "GO:0045582"
            }, {
                "name": "positive regulation of fat cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of adipocyte differentiation. [GOC:go_curators]",
                "id": "GO:0045600"
            }, {
                "name": "positive regulation of angiogenesis",
                "definition": "Any process that activates or increases angiogenesis. [GOC:go_curators]",
                "id": "GO:0045766"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "neuron development",
                "definition": "The process whose specific outcome is the progression of a neuron over time, from initial commitment of the cell to a specific fate, to the fully functional differentiated cell. [GOC:dph]",
                "id": "GO:0048666"
            }, {
                "name": "positive regulation of immunoglobulin secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of the regulated release of immunoglobulins from a cell. [GOC:ai]",
                "id": "GO:0051024"
            }, {
                "name": "fatty acid homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of fatty acid within an organism or cell. [GOC:BHF, GOC:rl]",
                "id": "GO:0055089"
            }, {
                "name": "sterol homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of sterol within an organism or cell. [GOC:BHF, GOC:rl]",
                "id": "GO:0055092"
            }, {
                "name": "adipose tissue development",
                "definition": "The process whose specific outcome is the progression of adipose tissue over time, from its formation to the mature structure. Adipose tissue is specialized tissue that is used to store fat. [GOC:dph]",
                "id": "GO:0060612"
            }, {
                "name": "epithelial cell maturation involved in salivary gland development",
                "definition": "The developmental process, independent of morphogenetic (shape) change, that is required for an epithelial cell of the salivary gland to attain its fully functional state. [GOC:dph]",
                "id": "GO:0060691"
            }, {
                "name": "intrinsic apoptotic signaling pathway in response to endoplasmic reticulum stress",
                "definition": "A series of molecular signals in which an intracellular signal is conveyed to trigger the apoptotic death of a cell. The pathway is induced in response to a stimulus indicating endoplasmic reticulum (ER) stress, and ends when the execution phase of apoptosis is triggered. ER stress usually results from the accumulation of unfolded or misfolded proteins in the ER lumen. [GOC:mah, GOC:mtg_apoptosis, PMID:18701708]",
                "id": "GO:0070059"
            }, {
                "name": "cellular response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:mah]",
                "id": "GO:0071222"
            }, {
                "name": "cellular response to amino acid stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an amino acid stimulus. An amino acid is a carboxylic acids containing one or more amino groups. [CHEBI:33709, GOC:mah]",
                "id": "GO:0071230"
            }, {
                "name": "cellular response to fructose stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a fructose stimulus. [GOC:mah]",
                "id": "GO:0071332"
            }, {
                "name": "cellular response to glucose stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a glucose stimulus. [GOC:mah]",
                "id": "GO:0071333"
            }, {
                "name": "cellular response to interleukin-4",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an interleukin-4 stimulus. [GOC:mah]",
                "id": "GO:0071353"
            }, {
                "name": "cellular response to peptide hormone stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a peptide hormone stimulus. A peptide hormone is any of a class of peptides that are secreted into the blood stream and have endocrine functions in living animals. [GOC:mah]",
                "id": "GO:0071375"
            }, {
                "name": "positive regulation of plasma cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of plasma cell differentiation. [GOC:TermGenie]",
                "id": "GO:1900100"
            }, {
                "name": "positive regulation of endoplasmic reticulum unfolded protein response",
                "definition": "Any process that activates or increases the frequency, rate or extent of endoplasmic reticulum unfolded protein response. [GOC:TermGenie]",
                "id": "GO:1900103"
            }, {
                "name": "positive regulation of proteasomal protein catabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of proteasomal protein catabolic process. [GOC:BHF, GOC:rl, GOC:TermGenie, PMID:21669198]",
                "id": "GO:1901800"
            }, {
                "name": "negative regulation of endoplasmic reticulum stress-induced intrinsic apoptotic signaling pathway",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of an endoplasmic reticulum stress-induced intrinsic apoptotic signaling pathway. [GOC:BHF, GOC:mtg_apoptosis, GOC:rl, GOC:TermGenie, PMID:20160352]",
                "id": "GO:1902236"
            }, {
                "name": "positive regulation of lactation",
                "definition": "Any process that activates or increases the frequency, rate or extent of lactation. [GO_REF:0000058, GOC:mr, GOC:TermGenie, PMID:19563620]",
                "id": "GO:1903489"
            }, {
                "name": "response to insulin-like growth factor stimulus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an insulin-like growth factor stimulus. [PMID:21932665]",
                "id": "GO:1990418"
            }, {
                "name": "cellular response to leukemia inhibitory factor",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a leukemia inhibitory factor stimulus. [PMID:12801913]",
                "id": "GO:1990830"
            }, {
                "name": "positive regulation of hepatocyte proliferation",
                "definition": "Any process that activates or increases the frequency, rate or extent of hepatocyte proliferation. [GOC:BHF, GOC:mah]",
                "id": "GO:2000347"
            }, {
                "name": "positive regulation of interleukin-6 secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of interleukin-6 secretion. [GOC:BHF]",
                "id": "GO:2000778"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "cell",
                "definition": "The basic structural and functional unit of all organisms. Includes the plasma membrane and any external encapsulating structures such as the cell wall and cell envelope. [GOC:go_curators]",
                "id": "GO:0005623"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "RNA polymerase II regulatory region sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with a specific sequence of DNA that is part of a regulatory region that controls the transcription of a gene or cistron by RNA polymerase II. [GOC:txnOH]",
                "id": "GO:0000977"
            }, {
                "name": "RNA polymerase II transcription factor activity, sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with a specific DNA sequence in order to modulate transcription by RNA polymerase II. The transcription factor may or may not also interact selectively with a protein or macromolecular complex. [GOC:txnOH]",
                "id": "GO:0000981"
            }, {
                "name": "core promoter binding",
                "definition": "Interacting selectively and non-covalently with the regulatory region composed of the transcription start site and binding sites for the basal transcription machinery. Binding may occur as a sequence specific interaction or as an interaction observed only once a factor has been recruited to the DNA by other factors. [GOC:txnOH]",
                "id": "GO:0001047"
            }, {
                "name": "RNA polymerase II transcription factor binding",
                "definition": "Interacting selectively and non-covalently with an RNA polymerase II transcription factor, any protein required to initiate or regulate transcription by RNA polymerase II. [GOC:txnOH]",
                "id": "GO:0001085"
            }, {
                "name": "enhancer sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with a specific sequence of DNA that is part of an enhancer, a transcription regulatory region that is somewhat distal from the core promoter and which enhances transcription from that promoter. [GOC:txnOH]",
                "id": "GO:0001158"
            }, {
                "name": "DNA binding",
                "definition": "Any molecular function by which a gene product interacts selectively and non-covalently with DNA (deoxyribonucleic acid). [GOC:dph, GOC:jl, GOC:tb, GOC:vw]",
                "id": "GO:0003677"
            }, {
                "name": "DNA binding transcription factor activity",
                "definition": "Interacting selectively and non-covalently with a specific DNA sequence (sometimes referred to as a motif) within the regulatory region of a gene in order to modulate transcription. [GOC:curators, GOC:txnOH]",
                "id": "GO:0003700"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with DNA of a specific nucleotide composition, e.g. GC-rich DNA binding, or with a specific sequence motif or type of DNA e.g. promotor binding or rDNA binding. [GOC:jl]",
                "id": "GO:0043565"
            }, {
                "name": "protein heterodimerization activity",
                "definition": "Interacting selectively and non-covalently with a nonidentical protein to form a heterodimer. [GOC:ai]",
                "id": "GO:0046982"
            }]
        },
        "kegg_pathway": {}
    }, "FAS": {
        "name": "Fas cell surface death receptor",
        "description": "The protein encoded by this gene is a member of the TNF-receptor superfamily. This receptor contains a death domain. It has been shown to play a central role in the physiological regulation of programmed cell death, and has been implicated in the pathogenesis of various malignancies and diseases of the immune system. The interaction of this receptor with its ligand allows the formation of a death-inducing signaling complex that includes Fas-associated death domain protein (FADD), caspase 8, and caspase 10. The autoproteolytic processing of the caspases in the complex triggers a downstream caspase cascade, and leads to apoptosis. This receptor has been also shown to activate NF-kappaB, MAPK3/ERK1, and MAPK8/JNK, and is found to be involved in transducing the proliferating signals in normal diploid fibroblast and T cells. Several alternatively spliced transcript variants have been described, some of which are candidates for nonsense-mediated mRNA decay (NMD). The isoforms lacking the transmembrane domain may negatively regulate the apoptosis mediated by the full length isoform. [provided by RefSeq, Mar 2011].",
        "entrez": "355",
        "ensembl": "ENSG00000026103",
        "uniprot": "P25445",
        "hgnc": "11920",
        "go_terms": {
            "biological_process": [{
                "name": "positive regulation of protein phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of addition of phosphate groups to amino acids within a protein. [GOC:hjd]",
                "id": "GO:0001934"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "activation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that initiates the activity of the inactive enzyme cysteine-type endopeptidase in the context of an apoptotic process. [GOC:al, GOC:dph, GOC:jl, GOC:mtg_apoptosis, GOC:tb, PMID:14744432, PMID:18328827, Wikipedia:Caspase]",
                "id": "GO:0006919"
            }, {
                "name": "inflammatory response",
                "definition": "The immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. The process is characterized by local vasodilation, extravasation of plasma into intercellular spaces and accumulation of white blood cells and macrophages. [GO_REF:0000022, GOC:mtg_15nov05, ISBN:0198506732]",
                "id": "GO:0006954"
            }, {
                "name": "immune response",
                "definition": "Any immune system process that functions in the calibrated response of an organism to a potential internal or invasive threat. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0006955"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "multicellular organism development",
                "definition": "The biological process whose specific outcome is the progression of a multicellular organism over time from an initial condition (e.g. a zygote or a young adult) to a later condition (e.g. a multicellular animal or an aged adult). [GOC:dph, GOC:ems, GOC:isa_complete, GOC:tb]",
                "id": "GO:0007275"
            }, {
                "name": "response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:add, ISBN:0721601464]",
                "id": "GO:0032496"
            }, {
                "name": "regulation of stress-activated MAPK cascade",
                "definition": "Any process that modulates the frequency, rate or extent of signal transduction mediated by the stress-activated MAPK cascade. [GOC:mah]",
                "id": "GO:0032872"
            }, {
                "name": "tumor necrosis factor-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a tumor necrosis factor to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling]",
                "id": "GO:0033209"
            }, {
                "name": "cellular response to amino acid starvation",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of deprivation of amino acids. [GOC:ecd]",
                "id": "GO:0034198"
            }, {
                "name": "Fas signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a ligand to the receptor Fas on the surface of the cell, and ending with regulation of a downstream cellular process, e.g. transcription. Fas is a death domain-containing member of the tumor necrosis factor receptor (TNFR) superfamily. [GOC:nhn, PMID:12040174, Wikipedia:Fas_receptor]",
                "id": "GO:0036337"
            }, {
                "name": "regulation of cell proliferation",
                "definition": "Any process that modulates the frequency, rate or extent of cell proliferation. [GOC:jl]",
                "id": "GO:0042127"
            }, {
                "name": "regulation of apoptotic process",
                "definition": "Any process that modulates the occurrence or rate of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0042981"
            }, {
                "name": "positive regulation of apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043065"
            }, {
                "name": "negative regulation of apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043066"
            }, {
                "name": "macromolecular complex assembly",
                "definition": "The aggregation, arrangement and bonding together of a set of macromolecules to form a complex. [GOC:jl]",
                "id": "GO:0065003"
            }, {
                "name": "cellular response to mechanical stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a mechanical stimulus. [GOC:mah]",
                "id": "GO:0071260"
            }, {
                "name": "cellular response to hyperoxia",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating increased oxygen tension. [GOC:mah]",
                "id": "GO:0071455"
            }, {
                "name": "apoptotic signaling pathway",
                "definition": "A series of molecular signals which triggers the apoptotic death of a cell. The pathway starts with reception of a signal, and ends when the execution phase of apoptosis is triggered. [GOC:mtg_apoptosis]",
                "id": "GO:0097190"
            }, {
                "name": "extrinsic apoptotic signaling pathway",
                "definition": "A series of molecular signals in which a signal is conveyed from the cell surface to trigger the apoptotic death of a cell. The pathway starts with either a ligand binding to a cell surface receptor, or a ligand being withdrawn from a cell surface receptor (e.g. in the case of signaling by dependence receptors), and ends when the execution phase of apoptosis is triggered. [GOC:mtg_apoptosis, GOC:yaf, PMID:17340152]",
                "id": "GO:0097191"
            }, {
                "name": "activation of cysteine-type endopeptidase activity involved in apoptotic signaling pathway",
                "definition": "Any process that initiates the activity of an inactive cysteine-type endopeptidase involved in the apoptotic signaling pathway. [GOC:mtg_apoptosis]",
                "id": "GO:0097296"
            }, {
                "name": "necroptotic signaling pathway",
                "definition": "A series of molecular signals which triggers the necroptotic death of a cell. The pathway starts with reception of a signal, is characterized by activation of receptor-interacting serine/threonine-protein kinase 1 and/or 3 (RIPK1/3, also called RIP1/3), and ends when the execution phase of necroptosis is triggered. [GOC:mtg_apoptosis, PMID:20823910]",
                "id": "GO:0097527"
            }, {
                "name": "regulation of extrinsic apoptotic signaling pathway via death domain receptors",
                "definition": "Any process that modulates the frequency, rate or extent of extrinsic apoptotic signaling pathway via death domain receptors. [GOC:TermGenie, PMID:17245429]",
                "id": "GO:1902041"
            }, {
                "name": "negative regulation of extrinsic apoptotic signaling pathway via death domain receptors",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of extrinsic apoptotic signaling pathway via death domain receptors. [GOC:TermGenie, PMID:17245429]",
                "id": "GO:1902042"
            }, {
                "name": "positive regulation of apoptotic signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of apoptotic signaling pathway. [GOC:mtg_apoptosis]",
                "id": "GO:2001235"
            }, {
                "name": "positive regulation of cysteine-type endopeptidase activity involved in apoptotic signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of cysteine-type endopeptidase activity involved in apoptotic signaling pathway. [GOC:mtg_apoptosis]",
                "id": "GO:2001269"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "nuclear body",
                "definition": "Extra-nucleolar nuclear domains usually visualized by confocal microscopy and fluorescent antibodies to specific proteins. [GOC:ma, PMID:10330182]",
                "id": "GO:0016604"
            }, {
                "name": "death-inducing signaling complex",
                "definition": "A protein complex formed by the association of signaling proteins with a death receptor upon ligand binding. The complex includes procaspases and death domain-containing proteins in addition to the ligand-bound receptor, and may control the activation of caspases 8 and 10. [GOC:mtg_apoptosis, PMID:12628743, PMID:12655293, PMID:8521815]",
                "id": "GO:0031264"
            }, {
                "name": "CD95 death-inducing signaling complex",
                "definition": "A protein complex formed upon binding of Fas/CD95/APO-1 to its ligand. The complex includes FADD/Mort1, procaspase-8/10 and c-FLIP in addition to the ligand-bound receptor. [PMID:12628743, PMID:12655293]",
                "id": "GO:0031265"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "receptor activity",
                "definition": "Combining with an extracellular or intracellular messenger to initiate a change in cell activity. [GOC:ceb, ISBN:0198506732]",
                "id": "GO:0004872"
            }, {
                "name": "transmembrane signaling receptor activity",
                "definition": "Combining with an extracellular or intracellular signal and transmitting the signal from one side of the membrane to the other to initiate a change in cell activity or state as part of signal transduction. [GOC:go_curators, Wikipedia:Transmembrane_receptor]",
                "id": "GO:0004888"
            }, {
                "name": "tumor necrosis factor-activated receptor activity",
                "definition": "Combining with tumor necrosis factor, a proinflammatory cytokine produced by monocytes and macrophages, to initiate a change in cell function. [GOC:jl, http://lookwayup.com/]",
                "id": "GO:0005031"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "calmodulin binding",
                "definition": "Interacting selectively and non-covalently with calmodulin, a calcium-binding protein with many roles, both in the calcium-bound and calcium-free states. [GOC:krc]",
                "id": "GO:0005516"
            }, {
                "name": "kinase binding",
                "definition": "Interacting selectively and non-covalently with a kinase, any enzyme that catalyzes the transfer of a phosphate group. [GOC:jl]",
                "id": "GO:0019900"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "tumor necrosis factor binding",
                "definition": "Interacting selectively and non-covalently with tumor necrosis factor, a proinflammatory cytokine produced by monocytes and macrophages. [GOC:jl, http://lookwayup.com/]",
                "id": "GO:0043120"
            }]
        },
        "kegg_pathway": {}
    }, "CAMK1": {
        "name": "calcium/calmodulin dependent protein kinase I",
        "description": "Calcium/calmodulin-dependent protein kinase I is expressed in many tissues and is a component of a calmodulin-dependent protein kinase cascade.  Calcium/calmodulin directly activates calcium/calmodulin-dependent protein kinase I by binding to the enzyme and indirectly promotes the phosphorylation and synergistic activation of the enzyme by calcium/calmodulin-dependent protein kinase I kinase. [provided by RefSeq, Jul 2008].",
        "entrez": "8536",
        "ensembl": "ENSG00000134072",
        "uniprot": "Q14012",
        "hgnc": "1459",
        "go_terms": {
            "biological_process": [{
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "nucleocytoplasmic transport",
                "definition": "The directed movement of molecules between the nucleus and the cytoplasm. [GOC:go_curators]",
                "id": "GO:0006913"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "multicellular organism development",
                "definition": "The biological process whose specific outcome is the progression of a multicellular organism over time from an initial condition (e.g. a zygote or a young adult) to a later condition (e.g. a multicellular animal or an aged adult). [GOC:dph, GOC:ems, GOC:isa_complete, GOC:tb]",
                "id": "GO:0007275"
            }, {
                "name": "nervous system development",
                "definition": "The process whose specific outcome is the progression of nervous tissue over time, from its formation to its mature state. [GOC:dgh]",
                "id": "GO:0007399"
            }, {
                "name": "metabolic process",
                "definition": "The chemical reactions and pathways, including anabolism and catabolism, by which living organisms transform chemical substances. Metabolic processes typically transform small molecules, but also include macromolecular processes such as DNA repair and replication, and protein synthesis and degradation. [GOC:go_curators, ISBN:0198547684]",
                "id": "GO:0008152"
            }, {
                "name": "positive regulation of neuron projection development",
                "definition": "Any process that increases the rate, frequency or extent of neuron projection development. Neuron projection development is the process whose specific outcome is the progression of a neuron projection over time, from its formation to the mature structure. A neuron projection is any process extending from a neural cell, such as axons or dendrites (collectively called neurites). [GOC:dph, GOC:tb]",
                "id": "GO:0010976"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "cell differentiation",
                "definition": "The process in which relatively unspecialized cells, e.g. embryonic or regenerative cells, acquire specialized structural and/or functional features that characterize the cells, tissues, or organs of the mature organism or some other relatively stable phase of the organism's life history. Differentiation includes the processes involved in commitment of a cell to a specific fate and its subsequent development to the mature state. [ISBN:0198506732]",
                "id": "GO:0030154"
            }, {
                "name": "negative regulation of protein binding",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of protein binding. [GOC:mah]",
                "id": "GO:0032091"
            }, {
                "name": "regulation of protein localization",
                "definition": "Any process that modulates the frequency, rate or extent of any process in which a protein is transported to, or maintained in, a specific location. [GOC:dph, GOC:mah, GOC:tb]",
                "id": "GO:0032880"
            }, {
                "name": "positive regulation of peptidyl-serine phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of the phosphorylation of peptidyl-serine. [GOC:mah]",
                "id": "GO:0033138"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "regulation of protein binding",
                "definition": "Any process that modulates the frequency, rate or extent of protein binding. [GOC:go_curators]",
                "id": "GO:0043393"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "positive regulation of protein export from nucleus",
                "definition": "Any process that activates or increases the frequency, rate or extent of directed movement of proteins from the nucleus into the cytoplasm. [GOC:bf]",
                "id": "GO:0046827"
            }, {
                "name": "regulation of muscle cell differentiation",
                "definition": "Any process that modulates the frequency, rate or extent of muscle cell differentiation. [CL:0000187, GOC:ai]",
                "id": "GO:0051147"
            }, {
                "name": "positive regulation of muscle cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of muscle cell differentiation. [CL:0000187, GOC:ai]",
                "id": "GO:0051149"
            }, {
                "name": "positive regulation of synapse structural plasticity",
                "definition": "Any process that activates, maintains or increases the frequency, rate or extent of synaptic structural plasticity. [GOC:ai]",
                "id": "GO:0051835"
            }, {
                "name": "positive regulation of syncytium formation by plasma membrane fusion",
                "definition": "Any process that increases the frequency, rate or extent of the formation of a syncytium, a mass of cytoplasm containing several nuclei enclosed within a single plasma membrane, by the fusion of the plasma membranes of two or more individual cells. [GOC:dph]",
                "id": "GO:0060143"
            }, {
                "name": "positive regulation of dendritic spine development",
                "definition": "Any process that increases the rate, frequency, or extent of dendritic spine development, the process whose specific outcome is the progression of the dendritic spine over time, from its formation to the mature structure. [GOC:dph]",
                "id": "GO:0060999"
            }, {
                "name": "positive regulation of protein serine/threonine kinase activity",
                "definition": "Any process that increases the rate, frequency, or extent of protein serine/threonine kinase activity. [GOC:mah]",
                "id": "GO:0071902"
            }, {
                "name": "positive regulation of protein acetylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein acetylation. [GOC:TermGenie, PMID:22117195]",
                "id": "GO:1901985"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "catalytic activity",
                "definition": "Catalysis of a biochemical reaction at physiological temperatures. In biologically catalyzed reactions, the reactants are known as substrates, and the catalysts are naturally occurring macromolecular substances known as enzymes. Enzymes possess specific binding sites for substrates, and are usually composed wholly or largely of protein, but RNA that has catalytic activity (ribozyme) is often also regarded as enzymatic. [GOC:vw, ISBN:0198506732]",
                "id": "GO:0003824"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "calmodulin-dependent protein kinase activity",
                "definition": "Catalysis of the reactions: ATP + a protein serine = ADP + protein serine phosphate; and ATP + a protein threonine = ADP + protein threonine phosphate. These reactions require the presence of calcium-bound calmodulin. [GOC:mah, PMID:11264466]",
                "id": "GO:0004683"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "calmodulin binding",
                "definition": "Interacting selectively and non-covalently with calmodulin, a calcium-binding protein with many roles, both in the calcium-bound and calcium-free states. [GOC:krc]",
                "id": "GO:0005516"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }]
        },
        "kegg_pathway": {}
    }, "LYN": {
        "name": "T cell receptor signaling pathway",
        "description": "This gene encodes a tyrosine protein kinase, which maybe involved in the regulation of mast cell degranulation, and erythroid differentiation. Alternatively spliced transcript variants encoding different isoforms have been found for this gene. [provided by RefSeq, Jul 2011].",
        "entrez": "4067",
        "ensembl": "ENSG00000254087",
        "uniprot": "P07948",
        "hgnc": "6735",
        "go_terms": {
            "biological_process": [{
                "name": "B cell homeostasis",
                "definition": "The process of regulating the proliferation and elimination of B cells such that the total number of B cells within a whole or part of an organism is stable over time in the absence of an outside stimulus. [GOC:add, ISBN:0781735149, PMID:12956429]",
                "id": "GO:0001782"
            }, {
                "name": "regulation of cytokine production",
                "definition": "Any process that modulates the frequency, rate, or extent of production of a cytokine. [GOC:add, ISBN:0781735149]",
                "id": "GO:0001817"
            }, {
                "name": "regulation of protein phosphorylation",
                "definition": "Any process that modulates the frequency, rate or extent of addition of phosphate groups into an amino acid in a protein. [GOC:hjd]",
                "id": "GO:0001932"
            }, {
                "name": "negative regulation of protein phosphorylation",
                "definition": "Any process that stops, prevents or reduces the rate of addition of phosphate groups to amino acids within a protein. [GOC:hjd]",
                "id": "GO:0001933"
            }, {
                "name": "positive regulation of protein phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of addition of phosphate groups to amino acids within a protein. [GOC:hjd]",
                "id": "GO:0001934"
            }, {
                "name": "stimulatory C-type lectin receptor signaling pathway",
                "definition": "Any series of molecular signals generated as a consequence of binding to a C-type lectin receptor capable of cellular activation. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002223"
            }, {
                "name": "adaptive immune response",
                "definition": "An immune response mediated by cells expressing specific receptors for antigen produced through a somatic diversification process, and allowing for an enhanced secondary response to subsequent exposures to the same antigen (immunological memory). [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002250"
            }, {
                "name": "immune system process",
                "definition": "Any process involved in the development or functioning of the immune system, an organismal system for calibrated responses to potential internal or invasive threats. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0002376"
            }, {
                "name": "Fc receptor mediated stimulatory signaling pathway",
                "definition": "A series of molecular signals generated as a consequence of a the binding of the Fc portion of an immunoglobulin by an Fc receptor capable of activating or perpetuating an immune response. The Fc portion of an immunoglobulin is its C-terminal constant region. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002431"
            }, {
                "name": "tolerance induction to self antigen",
                "definition": "Tolerance induction directed at self antigens. [GO_REF:0000022, GOC:jal, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002513"
            }, {
                "name": "histamine secretion by mast cell",
                "definition": "The regulated release of histamine by a mast cell or group of mast cells. [GOC:add, ISBN:0781735149]",
                "id": "GO:0002553"
            }, {
                "name": "platelet degranulation",
                "definition": "The regulated exocytosis of secretory granules containing preformed mediators such as histamine and serotonin by a platelet. [GOC:add]",
                "id": "GO:0002576"
            }, {
                "name": "negative regulation of myeloid leukocyte differentiation",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, or extent of myeloid leukocyte differentiation. [GOC:add]",
                "id": "GO:0002762"
            }, {
                "name": "immune response-regulating cell surface receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to a receptor on the surface of the target cell capable of activating, perpetuating, or inhibiting an immune response. [GOC:add, ISBN:0781735149, PMID:15771571]",
                "id": "GO:0002768"
            }, {
                "name": "Fc receptor mediated inhibitory signaling pathway",
                "definition": "A series of molecular signals generated as a consequence of the binding of the Fc portion of an immunoglobulin by an Fc receptor capable of inhibiting an immune effector process contributing to an immune response. The Fc portion of an immunoglobulin is its C-terminal constant region. [GOC:add, ISBN:0781735149]",
                "id": "GO:0002774"
            }, {
                "name": "regulation of B cell apoptotic process",
                "definition": "Any process that modulates the frequency, rate, or extent of B cell apoptotic process. [GOC:add, GOC:mtg_apoptosis]",
                "id": "GO:0002902"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "inflammatory response",
                "definition": "The immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. The process is characterized by local vasodilation, extravasation of plasma into intercellular spaces and accumulation of white blood cells and macrophages. [GO_REF:0000022, GOC:mtg_15nov05, ISBN:0198506732]",
                "id": "GO:0006954"
            }, {
                "name": "cellular response to DNA damage stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating damage to its DNA from environmental insults or errors during metabolism. [GOC:go_curators]",
                "id": "GO:0006974"
            }, {
                "name": "response to sterol depletion",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating deprivation of sterols. Sterols are a group of steroids characterized by the presence of one or more hydroxyl groups and a hydrocarbon side-chain in the molecule. [GOC:bf, ISBN:0198506732]",
                "id": "GO:0006991"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "transmembrane receptor protein tyrosine kinase signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to a receptor on the surface of the target cell where the receptor possesses tyrosine kinase activity, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling]",
                "id": "GO:0007169"
            }, {
                "name": "central nervous system development",
                "definition": "The process whose specific outcome is the progression of the central nervous system over time, from its formation to the mature structure. The central nervous system is the core nervous system that serves an integrating and coordinating function. In vertebrates it consists of the brain and spinal cord. In those invertebrates with a central nervous system it typically consists of a brain, cerebral ganglia and a nerve cord. [GOC:bf, GOC:jid, ISBN:0582227089]",
                "id": "GO:0007417"
            }, {
                "name": "blood coagulation",
                "definition": "The sequential process in which the multiple coagulation factors of the blood interact, ultimately resulting in the formation of an insoluble fibrin clot; it may be divided into three stages: stage 1, the formation of intrinsic and extrinsic prothrombin converting principle; stage 2, the formation of thrombin; stage 3, the formation of stable fibrin polymers. [http://www.graylab.ac.uk/omd/, ISBN:0198506732]",
                "id": "GO:0007596"
            }, {
                "name": "positive regulation of cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008284"
            }, {
                "name": "negative regulation of cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008285"
            }, {
                "name": "response to toxic substance",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a toxic stimulus. [GOC:lr]",
                "id": "GO:0009636"
            }, {
                "name": "response to hormone",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hormone stimulus. [GOC:jl]",
                "id": "GO:0009725"
            }, {
                "name": "response to carbohydrate",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a carbohydrate stimulus. [GOC:jl]",
                "id": "GO:0009743"
            }, {
                "name": "positive regulation of neuron projection development",
                "definition": "Any process that increases the rate, frequency or extent of neuron projection development. Neuron projection development is the process whose specific outcome is the progression of a neuron projection over time, from its formation to the mature structure. A neuron projection is any process extending from a neural cell, such as axons or dendrites (collectively called neurites). [GOC:dph, GOC:tb]",
                "id": "GO:0010976"
            }, {
                "name": "oligodendrocyte development",
                "definition": "The process aimed at the progression of an oligodendrocyte over time, from initial commitment of the cell to a specific fate, to the fully functional differentiated cell. An oligodendrocyte is a type of glial cell involved in myelinating the axons in the central nervous system. [GOC:dgh, GOC:ef]",
                "id": "GO:0014003"
            }, {
                "name": "response to organic cyclic compound",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic cyclic compound stimulus. [CHEBI:33832, GOC:ef]",
                "id": "GO:0014070"
            }, {
                "name": "viral process",
                "definition": "A multi-organism process in which a virus is a participant. The other participant is the host. Includes infection of a host cell, replication of the viral genome, and assembly of progeny virus particles. In some cases the viral genetic material may integrate into the host genome and only subsequently, under particular circumstances, 'complete' its life cycle. [GOC:bf, GOC:jl, GOC:mah]",
                "id": "GO:0016032"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "signal transduction by protein phosphorylation",
                "definition": "A process in which the transfer of one or more phosphate groups to a substrate transmits a signal to the phosphorylated substrate. [GOC:mtg_signal, GOC:signaling]",
                "id": "GO:0023014"
            }, {
                "name": "hemopoiesis",
                "definition": "The process whose specific outcome is the progression of the myeloid and lymphoid derived organ/tissue systems of the blood and other parts of the body over time, from formation to the mature structure. The site of hemopoiesis is variable during development, but occurs primarily in bone marrow or kidney in many adult vertebrates. [GOC:dgh, ISBN:0198506732]",
                "id": "GO:0030097"
            }, {
                "name": "platelet activation",
                "definition": "A series of progressive, overlapping events triggered by exposure of the platelets to subendothelial tissue. These events include shape change, adhesiveness, aggregation, and release reactions. When carried through to completion, these events lead to the formation of a stable hemostatic plug. [http://www.graylab.ac.uk/omd/]",
                "id": "GO:0030168"
            }, {
                "name": "erythrocyte differentiation",
                "definition": "The process in which a myeloid precursor cell acquires specializes features of an erythrocyte. [GOC:mah]",
                "id": "GO:0030218"
            }, {
                "name": "positive regulation of cell migration",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell migration. [GOC:go_curators]",
                "id": "GO:0030335"
            }, {
                "name": "negative regulation of B cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of B cell proliferation. [GOC:mah]",
                "id": "GO:0030889"
            }, {
                "name": "neuron projection development",
                "definition": "The process whose specific outcome is the progression of a neuron projection over time, from its formation to the mature structure. A neuron projection is any process extending from a neural cell, such as axons or dendrites (collectively called neurites). [GOC:mah]",
                "id": "GO:0031175"
            }, {
                "name": "T cell costimulation",
                "definition": "The process of providing, via surface-bound receptor-ligand pairs, a second, antigen-independent, signal in addition to that provided by the T cell receptor to augment T cell activation. [ISBN:0781735149]",
                "id": "GO:0031295"
            }, {
                "name": "lipopolysaccharide-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a lipopolysaccharide (LPS) to a receptor on the surface of a target cell, and ending with regulation of a downstream cellular process, e.g. transcription. Lipopolysaccharides are major components of the outer membrane of Gram-negative bacteria, making them prime targets for recognition by the immune system. [GOC:mah, GOC:signaling, PMID:15379975]",
                "id": "GO:0031663"
            }, {
                "name": "cellular response to extracellular stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an extracellular stimulus. [GOC:mah]",
                "id": "GO:0031668"
            }, {
                "name": "response to insulin",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an insulin stimulus. Insulin is a polypeptide hormone produced by the islets of Langerhans of the pancreas in mammals, and by the homologous organs of other organisms. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0032868"
            }, {
                "name": "regulation of mast cell activation",
                "definition": "Any process that modulates the frequency, rate, or extent of mast cell activation. [GOC:mah]",
                "id": "GO:0033003"
            }, {
                "name": "regulation of cell adhesion mediated by integrin",
                "definition": "Any process that modulates the frequency, rate, or extent of cell adhesion mediated by integrin. [GOC:add]",
                "id": "GO:0033628"
            }, {
                "name": "negative regulation of toll-like receptor 2 signaling pathway",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, or extent of toll-like receptor 2 signaling pathway. [GOC:add, PMID:16551253, PMID:17328678]",
                "id": "GO:0034136"
            }, {
                "name": "negative regulation of toll-like receptor 4 signaling pathway",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, or extent of toll-like receptor 4 signaling pathway. [GOC:add, PMID:16551253, PMID:17328678]",
                "id": "GO:0034144"
            }, {
                "name": "cellular response to heat",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a heat stimulus, a temperature stimulus above the optimal temperature for that organism. [GOC:mah]",
                "id": "GO:0034605"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "peptidyl-tyrosine autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own tyrosine amino acid residues, or a tyrosine residue on an identical protein. [PMID:10037737, PMID:10068444, PMID:10940390]",
                "id": "GO:0038083"
            }, {
                "name": "Fc-epsilon receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of the Fc portion of immunoglobulin E (IgE) to an Fc-epsilon receptor on the surface of a signal-receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. The Fc portion of an immunoglobulin is its C-terminal constant region. [GOC:phg, PMID:12413516, PMID:15048725]",
                "id": "GO:0038095"
            }, {
                "name": "Fc-gamma receptor signaling pathway involved in phagocytosis",
                "definition": "An Fc-gamma receptor signaling pathway that contributes to the endocytic engulfment of external particulate material by phagocytes. [GOC:phg, PMID:12488490, PMID:15466916]",
                "id": "GO:0038096"
            }, {
                "name": "positive regulation of phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of addition of phosphate groups to a molecule. [GOC:jl]",
                "id": "GO:0042327"
            }, {
                "name": "response to drug",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a drug stimulus. A drug is a substance used in the diagnosis, treatment or prevention of a disease. [GOC:jl]",
                "id": "GO:0042493"
            }, {
                "name": "positive regulation of tyrosine phosphorylation of STAT protein",
                "definition": "Any process that activates or increases the frequency, rate or extent of the introduction of a phosphate group to a tyrosine residue of a STAT (Signal Transducer and Activator of Transcription) protein. [GOC:jl, PMID:11426647]",
                "id": "GO:0042531"
            }, {
                "name": "response to amino acid",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an amino acid stimulus. An amino acid is a carboxylic acids containing one or more amino groups. [CHEBI:33709, GOC:ef, GOC:mlg]",
                "id": "GO:0043200"
            }, {
                "name": "regulation of mast cell degranulation",
                "definition": "Any process that modulates the frequency, rate, or extent of mast cell degranulation. [ISBN:0781735149]",
                "id": "GO:0043304"
            }, {
                "name": "negative regulation of MAP kinase activity",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of MAP kinase activity. [GOC:dph, GOC:go_curators]",
                "id": "GO:0043407"
            }, {
                "name": "response to peptide hormone",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a peptide hormone stimulus. A peptide hormone is any of a class of peptides that are secreted into the blood stream and have endocrine functions in living animals. [PMID:11027914, PMID:15134857, Wikipedia:Peptide_hormone]",
                "id": "GO:0043434"
            }, {
                "name": "positive regulation of phosphatidylinositol 3-kinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of phosphatidylinositol 3-kinase activity. [GOC:bf]",
                "id": "GO:0043552"
            }, {
                "name": "innate immune response",
                "definition": "Innate immune responses are defense responses mediated by germline encoded components that directly recognize components of potential pathogens. [GO_REF:0000022, GOC:add, GOC:ebc, GOC:mtg_15nov05, GOC:mtg_sensu]",
                "id": "GO:0045087"
            }, {
                "name": "regulation of erythrocyte differentiation",
                "definition": "Any process that modulates the frequency, rate or extent of erythrocyte differentiation. [GOC:go_curators]",
                "id": "GO:0045646"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "ephrin receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of an ephrin receptor binding to an ephrin. [GOC:ceb]",
                "id": "GO:0048013"
            }, {
                "name": "response to axon injury",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an axon injury stimulus. [GOC:dgh, GOC:dph, GOC:jid, GOC:lm]",
                "id": "GO:0048678"
            }, {
                "name": "cytokine secretion",
                "definition": "The regulated release of cytokines from a cell. Cytokines are any of a group of proteins that function to control the survival, growth and differentiation of tissues and cells, and which have autocrine and paracrine activity. [GOC:ai, GOC:bf, ISBN:0198599471]",
                "id": "GO:0050663"
            }, {
                "name": "regulation of cytokine secretion",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of cytokines from a cell. [GOC:ai]",
                "id": "GO:0050707"
            }, {
                "name": "regulation of inflammatory response",
                "definition": "Any process that modulates the frequency, rate or extent of the inflammatory response, the immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. [GOC:ai]",
                "id": "GO:0050727"
            }, {
                "name": "positive regulation of peptidyl-tyrosine phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of the phosphorylation of peptidyl-tyrosine. [GOC:ai]",
                "id": "GO:0050731"
            }, {
                "name": "negative regulation of immune response",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the immune response, the immunological reaction of an organism to an immunogenic stimulus. [GOC:ai]",
                "id": "GO:0050777"
            }, {
                "name": "B cell receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the cross-linking of an antigen receptor on a B cell. [GOC:add]",
                "id": "GO:0050853"
            }, {
                "name": "regulation of B cell receptor signaling pathway",
                "definition": "Any process that modulates the frequency, rate or extent of signaling pathways initiated by the cross-linking of an antigen receptor on a B cell. [GOC:ai]",
                "id": "GO:0050855"
            }, {
                "name": "positive regulation of B cell receptor signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of signaling pathways initiated by the cross-linking of an antigen receptor on a B cell. [GOC:ai]",
                "id": "GO:0050861"
            }, {
                "name": "leukocyte migration",
                "definition": "The movement of a leukocyte within or between different tissues and organs of the body. [GOC:add, ISBN:0781735149, PMID:14680625, PMID:14708592, PMID:7507411, PMID:8600538]",
                "id": "GO:0050900"
            }, {
                "name": "positive regulation of cellular component movement",
                "definition": "Any process that activates or increases the frequency, rate or extent of the movement of a cellular component. [GOC:ai, GOC:dph, GOC:jl]",
                "id": "GO:0051272"
            }, {
                "name": "regulation of release of sequestered calcium ion into cytosol",
                "definition": "Any process that modulates the frequency, rate or extent of the release into the cytosolic compartment of calcium ions sequestered in the endoplasmic reticulum or mitochondria. [GOC:ai, GOC:tb]",
                "id": "GO:0051279"
            }, {
                "name": "positive regulation of glial cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of glial cell proliferation. [GOC:dph, GOC:sl, GOC:tb]",
                "id": "GO:0060252"
            }, {
                "name": "positive regulation of Fc receptor mediated stimulatory signaling pathway",
                "definition": "Any process that increases the rate, frequency or extent of the Fc receptor mediated stimulatory signaling pathway. The Fc receptor mediated stimulatory signaling pathway is a series of molecular signals generated as a consequence of a the binding of the Fc portion of an immunoglobulin by an Fc receptor capable of activating or perpetuating an immune response. The Fc portion of an immunoglobulin is its C-terminal constant region. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0060369"
            }, {
                "name": "JAK-STAT cascade involved in growth hormone signaling pathway",
                "definition": "The process in which STAT proteins (Signal Transducers and Activators of Transcription) are activated by members of the JAK (janus activated kinase) family of tyrosine kinases, following the binding of physiological ligands to the growth hormone receptor. Once activated, STATs dimerize and translocate to the nucleus and modulate the expression of target genes. [GOC:BHF, GOC:dph, PMID:11445442]",
                "id": "GO:0060397"
            }, {
                "name": "positive regulation of stress-activated protein kinase signaling cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signaling via the stress-activated protein kinase signaling cascade. [GOC:mah]",
                "id": "GO:0070304"
            }, {
                "name": "regulation of ERK1 and ERK2 cascade",
                "definition": "Any process that modulates the frequency, rate or extent of signal transduction mediated by the ERK1 and ERK2 cascade. [GOC:add, ISBN:0121245462, ISBN:0896039986]",
                "id": "GO:0070372"
            }, {
                "name": "negative regulation of ERK1 and ERK2 cascade",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of signal transduction mediated by the ERK1 and ERK2 cascade. [GOC:add, ISBN:0121245462, ISBN:0896039986]",
                "id": "GO:0070373"
            }, {
                "name": "positive regulation of oligodendrocyte progenitor proliferation",
                "definition": "Any process that activates or increases the rate or extent of oligodendrocyte progenitor proliferation. [GOC:mah, GOC:sl]",
                "id": "GO:0070447"
            }, {
                "name": "negative regulation of mast cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of mast cell proliferation. [GOC:add, GOC:mah]",
                "id": "GO:0070667"
            }, {
                "name": "positive regulation of mast cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of mast cell proliferation. [GOC:add, GOC:mah]",
                "id": "GO:0070668"
            }, {
                "name": "cellular response to retinoic acid",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a retinoic acid stimulus. [GOC:mah]",
                "id": "GO:0071300"
            }, {
                "name": "regulation of monocyte chemotaxis",
                "definition": "Any process that modulates the frequency, rate, or extent of monocyte chemotaxis. [GOC:dph, GOC:tb]",
                "id": "GO:0090025"
            }, {
                "name": "regulation of platelet aggregation",
                "definition": "Any process that modulates the rate, frequency or extent of platelet aggregation. Platelet aggregation is the adhesion of one platelet to one or more other platelets via adhesion molecules. [GOC:dph, GOC:tb]",
                "id": "GO:0090330"
            }, {
                "name": "dendritic cell differentiation",
                "definition": "The process in which a precursor cell type acquires the specialized features of a dendritic cell. A dendritic cell is a leukocyte of dendritic lineage specialized in the uptake, processing, and transport of antigens to lymph nodes for the purpose of stimulating an immune response via T cell activation. [CL:0000451, GOC:pr]",
                "id": "GO:0097028"
            }, {
                "name": "negative regulation of intracellular signal transduction",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of intracellular signal transduction. [GOC:dph, GOC:signaling, GOC:tb, GOC:TermGenie]",
                "id": "GO:1902532"
            }, {
                "name": "positive regulation of aspartic-type endopeptidase activity involved in amyloid precursor protein catabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of aspartic-type endopeptidase activity involved in amyloid precursor protein catabolic process. [GO_REF:0000059, GOC:sjp, GOC:TermGenie, PMID:24577224]",
                "id": "GO:1902961"
            }, {
                "name": "positive regulation of dendritic cell apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of dendritic cell apoptotic process. [GOC:mtg_apoptosis, GOC:obol]",
                "id": "GO:2000670"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrial intermembrane space",
                "definition": "The region between the inner and outer lipid bilayers of the mitochondrial envelope. [GOC:mah]",
                "id": "GO:0005758"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "cell-cell adherens junction",
                "definition": "An adherens junction which connects a cell to another cell. [GOC:hb]",
                "id": "GO:0005913"
            }, {
                "name": "postsynaptic density",
                "definition": "An electron dense network of proteins within and adjacent to the postsynaptic membrane of an asymetric, neuron-neuron synapse. Its major components include neurotransmitter receptors and the proteins that spatially and functionally organize them such as anchoring and scaffolding molecules, signaling enzymes and cytoskeletal components. [GOC:BHF, GOC:dos, GOC:ef, GOC:jid, GOC:pr, GOC:sjp, http://molneuro.kaist.ac.kr/psd, PMID:14532281, Wikipedia:Postsynaptic_density]",
                "id": "GO:0014069"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "mitochondrial crista",
                "definition": "Any of the inward folds of the mitochondrial inner membrane. Their number, extent, and shape differ in mitochondria from different tissues and organisms. They appear to be devices for increasing the surface area of the mitochondrial inner membrane, where the enzymes of electron transport and oxidative phosphorylation are found. Their shape can vary with the respiratory state of the mitochondria. [ISBN:0198506732]",
                "id": "GO:0030061"
            }, {
                "name": "extrinsic component of cytoplasmic side of plasma membrane",
                "definition": "The component of a plasma membrane consisting of gene products and protein complexes that are loosely bound to its cytoplasmic surface, but not integrated into the hydrophobic region. [GOC:mah]",
                "id": "GO:0031234"
            }, {
                "name": "mitochondrial membrane",
                "definition": "Either of the lipid bilayers that surround the mitochondrion and form the mitochondrial envelope. [GOC:mah, NIF_Subcellular:sao1045389829]",
                "id": "GO:0031966"
            }, {
                "name": "integrin alpha2-beta1 complex",
                "definition": "An integrin complex that comprises one alpha2 subunit and one beta1 subunit. [PMID:12297042]",
                "id": "GO:0034666"
            }, {
                "name": "mast cell granule",
                "definition": "Coarse, bluish-black staining cytoplasmic granules, bounded by a plasma membrane and found in mast cells and basophils. Contents include histamine, heparin, chondroitin sulfates, chymase and tryptase. [GOC:jl, http://www.ijp-online.com/archives/1969/001/02/r0000-0000tc.htm, PMID:12360215]",
                "id": "GO:0042629"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "non-membrane spanning protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + protein L-tyrosine = ADP + protein L-tyrosine phosphate by a non-membrane spanning protein. [EC:2.7.10.2]",
                "id": "GO:0004715"
            }, {
                "name": "signal transducer, downstream of receptor, with protein tyrosine kinase activity",
                "definition": "Conveys a signal from an upstream receptor or intracellular signal transducer by catalysis of the reaction: ATP + a protein-L-tyrosine = ADP + a protein-L-tyrosine phosphate. [EC:2.7.10.2]",
                "id": "GO:0004716"
            }, {
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "platelet-derived growth factor receptor binding",
                "definition": "Interacting selectively and non-covalently with the platelet-derived growth factor receptor. [GOC:ai]",
                "id": "GO:0005161"
            }, {
                "name": "integrin binding",
                "definition": "Interacting selectively and non-covalently with an integrin. [GOC:ceb]",
                "id": "GO:0005178"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "SH3 domain binding",
                "definition": "Interacting selectively and non-covalently with a SH3 domain (Src homology 3) of a protein, small protein modules containing approximately 50 amino acid residues found in a great variety of intracellular or membrane-associated proteins. [GOC:go_curators, Pfam:PF00018]",
                "id": "GO:0017124"
            }, {
                "name": "enzyme binding",
                "definition": "Interacting selectively and non-covalently with any enzyme. [GOC:jl]",
                "id": "GO:0019899"
            }, {
                "name": "ubiquitin protein ligase binding",
                "definition": "Interacting selectively and non-covalently with a ubiquitin protein ligase enzyme, any of the E3 proteins. [GOC:vp]",
                "id": "GO:0031625"
            }, {
                "name": "gamma-tubulin binding",
                "definition": "Interacting selectively and non-covalently with the microtubule constituent protein gamma-tubulin. [GOC:jl]",
                "id": "GO:0043015"
            }, {
                "name": "glycosphingolipid binding",
                "definition": "Interacting selectively and non-covalently with glycosphingolipid, a compound with residues of sphingoid and at least one monosaccharide. [GOC:jl]",
                "id": "GO:0043208"
            }, {
                "name": "ion channel binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on an ion channel, a protein complex that spans a membrane and forms a water-filled channel across the phospholipid bilayer allowing selective ion transport down its electrochemical gradient. [GOC:BHF, GOC:jl]",
                "id": "GO:0044325"
            }, {
                "name": "ephrin receptor binding",
                "definition": "Interacting selectively and non-covalently with an ephrin receptor. [GOC:ai]",
                "id": "GO:0046875"
            }, {
                "name": "phosphoprotein binding",
                "definition": "Interacting selectively and non-covalently with a phosphorylated protein. [GOC:ai]",
                "id": "GO:0051219"
            }, {
                "name": "phosphorylation-dependent protein binding",
                "definition": "Interacting selectively and non-covalently with a protein upon phosphorylation of the target protein. [PMID:26060076]",
                "id": "GO:0140031"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.10.2",
            "pathways": [{
                "pathway": "04660",
                "name": "T cell receptor signaling pathway",
                "class": "Organismal Systems; Immune system"
            }]
        }
    }, "MAPK10": {
        "name": "mTOR signaling pathway",
        "description": "The protein encoded by this gene is a member of the MAP kinase family. MAP kinases act as integration points for multiple biochemical signals, and thus are involved in a wide variety of cellular processes, such as proliferation, differentiation, transcription regulation and development. This kinase is specifically expressed in a subset of neurons in the nervous system, and is activated by threonine and tyrosine phosphorylation. Targeted deletion of this gene in mice suggests that it may have a role in stress-induced neuronal apoptosis. Alternatively spliced transcript variants encoding different isoforms have been described for this gene. A recent study provided evidence for translational readthrough in this gene, and expression of an additional C-terminally extended isoform via the use of an alternative in-frame translation termination codon. [provided by RefSeq, Dec 2017].",
        "entrez": "5602",
        "ensembl": "ENSG00000109339",
        "uniprot": "P53779",
        "hgnc": "6872",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "activation of MAPK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase (MAPK). [PMID:9561267]",
                "id": "GO:0000187"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "JNK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a JNK (a MAPK), a JNKK (a MAPKK) and a JUN3K (a MAP3K). The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:signaling, PMID:11790549, PMID:20811974]",
                "id": "GO:0007254"
            }, {
                "name": "JUN phosphorylation",
                "definition": "The process of introducing a phosphate group into a JUN protein. [GOC:jl]",
                "id": "GO:0007258"
            }, {
                "name": "response to light stimulus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a light stimulus, electromagnetic radiation of wavelengths classified as infrared, visible or ultraviolet light. [GOC:go_curators, ISBN:0582227089]",
                "id": "GO:0009416"
            }, {
                "name": "regulation of gene expression",
                "definition": "Any process that modulates the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010468"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "Fc-epsilon receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of the Fc portion of immunoglobulin E (IgE) to an Fc-epsilon receptor on the surface of a signal-receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. The Fc portion of an immunoglobulin is its C-terminal constant region. [GOC:phg, PMID:12413516, PMID:15048725]",
                "id": "GO:0038095"
            }, {
                "name": "regulation of circadian rhythm",
                "definition": "Any process that modulates the frequency, rate or extent of a circadian rhythm. A circadian rhythm is a biological process in an organism that recurs with a regularity of approximately 24 hours. [GOC:dph, GOC:jl, GOC:tb]",
                "id": "GO:0042752"
            }, {
                "name": "rhythmic process",
                "definition": "Any process pertinent to the generation and maintenance of rhythms in the physiology of an organism. [GOC:jid]",
                "id": "GO:0048511"
            }, {
                "name": "neuron development",
                "definition": "The process whose specific outcome is the progression of a neuron over time, from initial commitment of the cell to a specific fate, to the fully functional differentiated cell. [GOC:dph]",
                "id": "GO:0048666"
            }, {
                "name": "regulation of DNA binding transcription factor activity",
                "definition": "Any process that modulates the frequency, rate or extent of the activity of a transcription factor, any factor involved in the initiation or regulation of transcription. [GOC:ai]",
                "id": "GO:0051090"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "JUN kinase activity",
                "definition": "Catalysis of the reaction: JUN + ATP = JUN phosphate + ADP. This reaction is the phosphorylation and activation of members of the JUN family, a gene family that encodes nuclear transcription factors. [GOC:bf, ISBN:0198506732]",
                "id": "GO:0004705"
            }, {
                "name": "MAP kinase activity",
                "definition": "Catalysis of the reaction: protein + ATP = protein phosphate + ADP. This reaction is the phosphorylation of proteins. Mitogen-activated protein kinase; a family of protein kinases that perform a crucial step in relaying signals from the plasma membrane to the nucleus. They are activated by a wide range of proliferation- or differentiation-inducing signals; activation is strong with agonists such as polypeptide growth factors and tumor-promoting phorbol esters, but weak (in most cell backgrounds) by stress stimuli. [GOC:ma, ISBN:0198547684]",
                "id": "GO:0004707"
            }, {
                "name": "MAP kinase kinase activity",
                "definition": "Catalysis of the concomitant phosphorylation of threonine (T) and tyrosine (Y) residues in a Thr-Glu-Tyr (TEY) thiolester sequence in a MAP kinase (MAPK) substrate. [ISBN:0198547684]",
                "id": "GO:0004708"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.24",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }]
        }
    }, "ACLY": {
        "name": "Carbon fixation pathways in prokaryotes",
        "description": "ATP citrate lyase is the primary enzyme responsible for the synthesis of cytosolic acetyl-CoA in many tissues. The enzyme is a tetramer (relative molecular weight approximately 440,000) of apparently identical subunits. It catalyzes the formation of acetyl-CoA and oxaloacetate from citrate and CoA with a concomitant hydrolysis of ATP to ADP and phosphate. The product, acetyl-CoA, serves several important biosynthetic pathways, including lipogenesis and cholesterogenesis. In nervous tissue, ATP citrate-lyase may be involved in the biosynthesis of acetylcholine. Multiple transcript variants encoding distinct isoforms have been identified for this gene. [provided by RefSeq, Dec 2014].",
        "entrez": "47",
        "ensembl": "ENSG00000131473",
        "uniprot": "P53396",
        "hgnc": "115",
        "go_terms": {
            "biological_process": [{
                "name": "acetyl-CoA biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of acetyl-CoA, a derivative of coenzyme A in which the sulfhydryl group is acetylated. [GOC:go_curators]",
                "id": "GO:0006085"
            }, {
                "name": "citrate metabolic process",
                "definition": "The chemical reactions and pathways involving citrate, 2-hydroxy-1,2,3-propanetricarboyxlate. Citrate is widely distributed in nature and is an important intermediate in the TCA cycle and the glyoxylate cycle. [ISBN:0198506732]",
                "id": "GO:0006101"
            }, {
                "name": "oxaloacetate metabolic process",
                "definition": "The chemical reactions and pathways involving oxaloacetate, the anion of oxobutanedioic acid, an important intermediate in metabolism, especially as a component of the TCA cycle. [ISBN:0198506732]",
                "id": "GO:0006107"
            }, {
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "fatty acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a fatty acid, any of the aliphatic monocarboxylic acids that can be liberated by hydrolysis from naturally occurring fats and oils. Fatty acids are predominantly straight-chain acids of 4 to 24 carbon atoms, which may be saturated or unsaturated; branched fatty acids and hydroxy fatty acids also occur, and very long chain acids of over 30 carbons are found in waxes. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0006633"
            }, {
                "name": "cholesterol biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of cholesterol, cholest-5-en-3 beta-ol, the principal sterol of vertebrates and the precursor of many steroids, including bile acids and steroid hormones. [GOC:ai]",
                "id": "GO:0006695"
            }, {
                "name": "metabolic process",
                "definition": "The chemical reactions and pathways, including anabolism and catabolism, by which living organisms transform chemical substances. Metabolic processes typically transform small molecules, but also include macromolecular processes such as DNA repair and replication, and protein synthesis and degradation. [GOC:go_curators, ISBN:0198547684]",
                "id": "GO:0008152"
            }, {
                "name": "lipid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. [GOC:go_curators]",
                "id": "GO:0008610"
            }, {
                "name": "coenzyme A metabolic process",
                "definition": "The chemical reactions and pathways involving coenzyme A, 3'-phosphoadenosine-(5')diphospho(4')pantatheine, an acyl carrier in many acylation and acyl-transfer reactions in which the intermediate is a thiol ester. [ISBN:0198547684]",
                "id": "GO:0015936"
            }, {
                "name": "positive regulation of cellular metabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways by which individual cells transform chemical substances. [GOC:mah]",
                "id": "GO:0031325"
            }, {
                "name": "neutrophil degranulation",
                "definition": "The regulated exocytosis of secretory granules containing preformed mediators such as proteases, lipases, and inflammatory mediators by a neutrophil. [ISBN:0781735149]",
                "id": "GO:0043312"
            }, {
                "name": "fatty-acyl-CoA biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a fatty-acyl-CoA, any derivative of coenzyme A in which the sulfhydryl group is in thiolester linkage with a fatty-acyl group. [ISBN:0198506732]",
                "id": "GO:0046949"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "citrate lyase complex",
                "definition": "Citrate lyase is a multienzyme complex with three constituents: the alpha subunit, citrate-ACP transferase; the beta subunit, citryl-ACP lyase; and the gamma subunit, an acyl-carrier protein which also carries the prosthetic group components. All three subunits are required for citrate lyase enzyme activity. [EC:4.1.3.6, MetaCyc:ACECITLY-CPLX]",
                "id": "GO:0009346"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "azurophil granule lumen",
                "definition": "The volume enclosed by the membrane of an azurophil granule, a primary lysosomal granule found in neutrophil granulocytes that contains a wide range of hydrolytic enzymes and is released into the extracellular fluid. [GOC:bf, PMID:17152095]",
                "id": "GO:0035578"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }, {
                "name": "ficolin-1-rich granule lumen",
                "definition": "Any membrane-enclosed lumen that is part of a ficolin-1-rich granule. [GO_REF:0000064, GOC:TermGenie, PMID:23650620]",
                "id": "GO:1904813"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "catalytic activity",
                "definition": "Catalysis of a biochemical reaction at physiological temperatures. In biologically catalyzed reactions, the reactants are known as substrates, and the catalysts are naturally occurring macromolecular substances known as enzymes. Enzymes possess specific binding sites for substrates, and are usually composed wholly or largely of protein, but RNA that has catalytic activity (ribozyme) is often also regarded as enzymatic. [GOC:vw, ISBN:0198506732]",
                "id": "GO:0003824"
            }, {
                "name": "ATP citrate synthase activity",
                "definition": "Catalysis of the reaction: acetyl-CoA + ADP + H(+) + oxaloacetate + phosphate = ATP + citrate + CoA. [EC:2.3.3.8, RHEA:21163]",
                "id": "GO:0003878"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "lyase activity",
                "definition": "Catalysis of the cleavage of C-C, C-O, C-N and other bonds by other means than by hydrolysis or oxidation, or conversely adding a group to a double bond. They differ from other enzymes in that two substrates are involved in one reaction direction, but only one in the other direction. When acting on the single substrate, a molecule is eliminated and this generates either a new double bond or a new ring. [EC:4.-.-.-, ISBN:0198547684]",
                "id": "GO:0016829"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "transferase activity, transferring acyl groups, acyl groups converted into alkyl on transfer",
                "definition": "Catalysis of the transfer of an acyl group from one compound (donor) to another (acceptor), with the acyl group being converted into alkyl on transfer. [GOC:jl]",
                "id": "GO:0046912"
            }, {
                "name": "cofactor binding",
                "definition": "Interacting selectively and non-covalently with a cofactor, a substance that is required for the activity of an enzyme or other protein. Cofactors may be inorganic, such as the metal atoms zinc, iron, and copper in certain forms, or organic, in which case they are referred to as coenzymes. Cofactors may either be bound tightly to active sites or bind loosely with the substrate. [ISBN:0198506732]",
                "id": "GO:0048037"
            }]
        },
        "kegg_pathway": {
            "ec": "2.3.3.8",
            "pathways": [{
                "pathway": "00020",
                "name": "Citrate cycle (TCA cycle)",
                "class": "Metabolism; Carbohydrate metabolism"
            }, {
                "pathway": "00020",
                "name": "Citrate cycle (TCA cycle)",
                "class": "Metabolism; Carbohydrate metabolism"
            }, {
                "pathway": "00640",
                "name": "Propanoate metabolism",
                "class": "Metabolism; Carbohydrate metabolism"
            }, {
                "pathway": "00660",
                "name": "C5-Branched dibasic acid metabolism",
                "class": "Metabolism; Carbohydrate metabolism"
            }, {
                "pathway": "00720",
                "name": "Carbon fixation pathways in prokaryotes",
                "class": "Metabolism; Energy metabolism"
            }, {
                "pathway": "00720",
                "name": "Carbon fixation pathways in prokaryotes",
                "class": "Metabolism; Energy metabolism"
            }]
        }
    }, "CYP7B1": {
        "name": "cytochrome P450 family 7 subfamily B member 1",
        "description": "This gene encodes a member of the cytochrome P450 superfamily of enzymes. The cytochrome P450 proteins are monooxygenases which catalyze many reactions involved in drug metabolism and synthesis of cholesterol, steroids and other lipids. This endoplasmic reticulum membrane protein catalyzes the first reaction in the cholesterol catabolic pathway of extrahepatic tissues, which converts cholesterol to bile acids. This enzyme likely plays a minor role in total bile acid synthesis, but may also be involved in the development of atherosclerosis, neurosteroid metabolism and sex hormone synthesis. Mutations in this gene have been associated with hereditary spastic paraplegia (SPG5 or HSP), an autosomal recessive disorder. [provided by RefSeq, Apr 2016].",
        "entrez": "9420",
        "ensembl": "ENSG00000172817",
        "uniprot": "O75881",
        "hgnc": "2652",
        "go_terms": {
            "biological_process": [{
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "bile acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of bile acids, any of a group of steroid carboxylic acids occurring in bile. [GOC:go_curators]",
                "id": "GO:0006699"
            }, {
                "name": "steroid metabolic process",
                "definition": "The chemical reactions and pathways involving steroids, compounds with a 1,2,cyclopentanoperhydrophenanthrene nucleus. [ISBN:0198547684]",
                "id": "GO:0008202"
            }, {
                "name": "cholesterol metabolic process",
                "definition": "The chemical reactions and pathways involving cholesterol, cholest-5-en-3 beta-ol, the principal sterol of vertebrates and the precursor of many steroids, including bile acids and steroid hormones. It is a component of the plasma membrane lipid bilayer and of plasma lipoproteins and can be found in all animal tissues. [ISBN:0198506732]",
                "id": "GO:0008203"
            }, {
                "name": "sterol metabolic process",
                "definition": "The chemical reactions and pathways involving sterols, steroids with one or more hydroxyl groups and a hydrocarbon side-chain in the molecule. [ISBN:0198547684]",
                "id": "GO:0016125"
            }, {
                "name": "negative regulation of intracellular estrogen receptor signaling pathway",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the activity of an intracellular estrogen receptor signaling pathway. [GOC:mah]",
                "id": "GO:0033147"
            }, {
                "name": "B cell chemotaxis",
                "definition": "The directed movement of a B cell guided by a specific chemical concentration gradient. Movement may be towards a higher concentration (positive chemotaxis) or towards a lower concentration (negative chemotaxis). [CL:0000236, GOC:BHF]",
                "id": "GO:0035754"
            }, {
                "name": "positive regulation of epithelial cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of epithelial cell proliferation. [GOC:ai]",
                "id": "GO:0050679"
            }, {
                "name": "oxidation-reduction process",
                "definition": "A metabolic process that results in the removal or addition of one or more electrons to or from a substance, with or without the concomitant removal or addition of a proton or protons. [GOC:dhl, GOC:ecd, GOC:jh2, GOC:jid, GOC:mlg, GOC:rph]",
                "id": "GO:0055114"
            }, {
                "name": "prostate gland epithelium morphogenesis",
                "definition": "The process in which the anatomical structures of epithelia of the prostate gland are generated and organized. An epithelium consists of closely packed cells arranged in one or more layers, that covers the outer surfaces of the body or lines any internal cavity or tube. [GOC:dph]",
                "id": "GO:0060740"
            }],
            "cellular_component": [{
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "organelle membrane",
                "definition": "A membrane that is one of the two lipid bilayers of an organelle envelope or the outermost membrane of single membrane bound organelle. [GOC:dos, GOC:mah]",
                "id": "GO:0031090"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }],
            "molecular_function": [{
                "name": "monooxygenase activity",
                "definition": "Catalysis of the incorporation of one atom from molecular oxygen into a compound and the reduction of the other atom of oxygen to water. [http://www.onelook.com/, ISBN:0198506732]",
                "id": "GO:0004497"
            }, {
                "name": "iron ion binding",
                "definition": "Interacting selectively and non-covalently with iron (Fe) ions. [GOC:ai]",
                "id": "GO:0005506"
            }, {
                "name": "oxysterol 7-alpha-hydroxylase activity",
                "definition": "Catalysis of the reaction: an oxysterol + NADPH + O2 = 7-alpha-hydroxylated oxysterol + NADP+ + H2O. [PMID:10882791]",
                "id": "GO:0008396"
            }, {
                "name": "oxidoreductase activity",
                "definition": "Catalysis of an oxidation-reduction (redox) reaction, a reversible chemical reaction in which the oxidation state of an atom or atoms within a molecule is altered. One substrate acts as a hydrogen or electron donor and becomes oxidized, while the other acts as hydrogen or electron acceptor and becomes reduced. [GOC:go_curators]",
                "id": "GO:0016491"
            }, {
                "name": "oxidoreductase activity, acting on paired donors, with incorporation or reduction of molecular oxygen",
                "definition": "Catalysis of an oxidation-reduction (redox) reaction in which hydrogen or electrons are transferred from each of two donors, and molecular oxygen is reduced or incorporated into a donor. [GOC:mah]",
                "id": "GO:0016705"
            }, {
                "name": "heme binding",
                "definition": "Interacting selectively and non-covalently with heme, any compound of iron complexed in a porphyrin (tetrapyrrole) ring. [CHEBI:30413, GOC:ai]",
                "id": "GO:0020037"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "RPS6KB2": {
        "name": "Human papillomavirus infection",
        "description": "This gene encodes a member of the RSK (ribosomal S6 kinase) family of serine/threonine kinases. This kinase contains a kinase catalytic domain and phosphorylates the S6 ribosomal protein and eukaryotic translation initiation factor 4B (eIF4B). Phosphorylation of S6 leads to an increase in protein synthesis and cell proliferation. [provided by RefSeq, Jan 2015].",
        "entrez": "6199",
        "ensembl": "ENSG00000175634",
        "uniprot": "Q9UBS0",
        "hgnc": "10437",
        "go_terms": {
            "biological_process": [{
                "name": "translation",
                "definition": "The cellular metabolic process in which a protein is formed, using the sequence of a mature mRNA or circRNA molecule to specify the sequence of amino acids in a polypeptide chain. Translation is mediated by the ribosome, and begins with the formation of a ternary complex between aminoacylated initiator methionine tRNA, GTP, and initiation factor 2, which subsequently associates with the small subunit of the ribosome and an mRNA or circRNA. Translation ends with the release of a polypeptide chain from the ribosome. [GOC:go_curators]",
                "id": "GO:0006412"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "protein kinase B signaling",
                "definition": "A series of reactions, mediated by the intracellular serine/threonine kinase protein kinase B (also called AKT), which occurs as a result of a single trigger reaction or compound. [GOC:bf, PMID:20517722]",
                "id": "GO:0043491"
            }, {
                "name": "positive regulation of translational initiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of translational initiation. [GOC:go_curators]",
                "id": "GO:0045948"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "ribosomal protein S6 kinase activity",
                "definition": "Catalysis of the reaction: ribosomal protein S6 + ATP = ribosomal protein S6 phosphate + ATP. [GOC:mah, PMID:9822608]",
                "id": "GO:0004711"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "peptide binding",
                "definition": "Interacting selectively and non-covalently with peptides, any of a group of organic compounds comprising two or more amino acids linked by peptide bonds. [GOC:jl]",
                "id": "GO:0042277"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.1",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }, {
                "pathway": "04151",
                "name": "PI3K-Akt signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }, {
                "pathway": "04926",
                "name": "Relaxin signaling pathway",
                "class": "Organismal Systems; Endocrine system"
            }, {
                "pathway": "05165",
                "name": "Human papillomavirus infection",
                "class": "Human Diseases; Infectious disease: viral"
            }]
        }
    }, "GRM5": {
        "name": "glutamate metabotropic receptor 5",
        "description": "This gene encodes a member of the G-protein coupled receptor 3 protein family. The encoded protein is a metabatropic glutamate receptor, whose signaling activates a phosphatidylinositol-calcium second messenger system. This protein may be involved in the regulation of neural network activity and synaptic plasticity. Glutamatergic neurotransmission is involved in most aspects of normal brain function and can be perturbed in many neuropathologic conditions. A pseudogene of this gene has been defined on chromosome 11. Alternative splicing results in multiple transcript variants. [provided by RefSeq, May 2014].",
        "entrez": "2915",
        "ensembl": "ENSG00000168959",
        "uniprot": "P41594",
        "hgnc": "4597",
        "go_terms": {
            "biological_process": [{
                "name": "regulation of protein phosphorylation",
                "definition": "Any process that modulates the frequency, rate or extent of addition of phosphate groups into an amino acid in a protein. [GOC:hjd]",
                "id": "GO:0001932"
            }, {
                "name": "regulation of translation",
                "definition": "Any process that modulates the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of proteins by the translation of mRNA or circRNA. [GOC:isa_complete]",
                "id": "GO:0006417"
            }, {
                "name": "regulation of translational elongation",
                "definition": "Any process that modulates the frequency, rate, extent or accuracy of translational elongation. [GOC:go_curators]",
                "id": "GO:0006448"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "adenylate cyclase-inhibiting G-protein coupled glutamate receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a G-protein coupled glutamate receptor binding to its physiological ligand, where the pathway proceeds with inhibition of adenylyl cyclase and a subsequent decrease in the concentration of cyclic AMP (cAMP). [GOC:dph, GOC:mah, GOC:signaling, GOC:tb]",
                "id": "GO:0007196"
            }, {
                "name": "phospholipase C-activating G-protein coupled glutamate receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a G-protein coupled glutamate receptor binding to its physiological ligand, where the pathway proceeds with activation of phospholipase C (PLC) and a subsequent release of inositol trisphosphate (IP3) and diacylglycerol (DAG). [GOC:dph, GOC:mah, GOC:signaling, GOC:tb]",
                "id": "GO:0007206"
            }, {
                "name": "G-protein coupled glutamate receptor signaling pathway",
                "definition": "A series of molecular signals initiated by glutamate binding to a glutamate receptor on the surface of the target cell, and proceeding with the activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. Ends with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:9131252]",
                "id": "GO:0007216"
            }, {
                "name": "chemical synaptic transmission",
                "definition": "The vesicular release of classical neurotransmitter molecules from a presynapse, across a chemical synapse, the subsequent activation of neurotransmitter receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:jl, MeSH:D009435]",
                "id": "GO:0007268"
            }, {
                "name": "learning or memory",
                "definition": "The acquisition and processing of information and/or the storage and retrieval of this information over time. [GOC:jid, PMID:8938125]",
                "id": "GO:0007611"
            }, {
                "name": "learning",
                "definition": "Any process in an organism in which a relatively long-lasting adaptive behavioral change occurs as the result of experience. [ISBN:0582227089, ISBN:0721662544]",
                "id": "GO:0007612"
            }, {
                "name": "locomotory behavior",
                "definition": "The specific movement from place to place of an organism in response to external or internal stimuli. Locomotion of a whole organism in a manner dependent upon some combination of that organism's internal state and external conditions. [GOC:dph]",
                "id": "GO:0007626"
            }, {
                "name": "calcium-mediated signaling using intracellular calcium source",
                "definition": "A series of molecular signals in which a cell uses calcium ions released from an intracellular store to convert a signal into a response. [GOC:bf, GOC:BHF, PMID:20192754]",
                "id": "GO:0035584"
            }, {
                "name": "regulation of long-term neuronal synaptic plasticity",
                "definition": "A process that modulates long-term neuronal synaptic plasticity, the ability of neuronal synapses to change long-term as circumstances require. Long-term neuronal synaptic plasticity generally involves increase or decrease in actual synapse numbers. [GOC:jid, http://www.mercksource.com, PMID:11891290]",
                "id": "GO:0048169"
            }, {
                "name": "synapse organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of a synapse, the junction between a neuron and a target (neuron, muscle, or secretory cell). [GOC:ai, GOC:pr]",
                "id": "GO:0050808"
            }, {
                "name": "cognition",
                "definition": "The operation of the mind by which an organism becomes aware of objects of thought or perception; it includes the mental activities associated with thinking, learning, and memory. [http://www.onelook.com/, ISBN:0721619908]",
                "id": "GO:0050890"
            }, {
                "name": "regulation of synaptic transmission, glutamatergic",
                "definition": "Any process that modulates the frequency, rate or extent of glutamatergic synaptic transmission, the process of communication from a neuron to another neuron across a synapse using the neurotransmitter glutamate. [GOC:ai]",
                "id": "GO:0051966"
            }, {
                "name": "regulation of postsynaptic membrane potential",
                "definition": "Any process that modulates the potential difference across a post-synaptic membrane. [GOC:dph, GOC:ef]",
                "id": "GO:0060078"
            }, {
                "name": "positive regulation of protein tyrosine kinase activity",
                "definition": "Any process that increases the rate, frequency, or extent of protein tyrosine kinase activity. [GOC:dph, GOC:tb]",
                "id": "GO:0061098"
            }, {
                "name": "modulation of age-related behavioral decline",
                "definition": "Any process that modulates the processes that arise as an organism progresses toward the end of its lifespan that results in a decline in behavioral activities such as locomotory behavior, and learning or memory. [GOC:cjm, GOC:kmv, PMID:20523893]",
                "id": "GO:0090647"
            }, {
                "name": "regulation of postsynaptic cytosolic calcium ion concentration",
                "definition": "Any process that regulates the concentration of calcium in the postsynaptic cytosol. [GOC:dos]",
                "id": "GO:0099566"
            }, {
                "name": "regulation of intracellular calcium activated chloride channel activity",
                "definition": "Any process that modulates the frequency, rate or extent of intracellular calcium activated chloride channel activity. [GO_REF:0000059, GOC:als, GOC:TermGenie, PMID:22946059]",
                "id": "GO:1902938"
            }, {
                "name": "cellular response to amyloid-beta",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a amyloid-beta stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:23555824]",
                "id": "GO:1904646"
            }],
            "cellular_component": [{
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "postsynaptic density",
                "definition": "An electron dense network of proteins within and adjacent to the postsynaptic membrane of an asymetric, neuron-neuron synapse. Its major components include neurotransmitter receptors and the proteins that spatially and functionally organize them such as anchoring and scaffolding molecules, signaling enzymes and cytoskeletal components. [GOC:BHF, GOC:dos, GOC:ef, GOC:jid, GOC:pr, GOC:sjp, http://molneuro.kaist.ac.kr/psd, PMID:14532281, Wikipedia:Postsynaptic_density]",
                "id": "GO:0014069"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "presynaptic membrane",
                "definition": "A specialized area of membrane of the axon terminal that faces the plasma membrane of the neuron or muscle fiber with which the axon terminal establishes a synaptic junction; many synaptic junctions exhibit structural presynaptic characteristics, such as conical, electron-dense internal protrusions, that distinguish it from the remainder of the axon plasma membrane. [GOC:jl, ISBN:0815316194]",
                "id": "GO:0042734"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "glutamate receptor activity",
                "definition": "Combining with glutamate and transmitting the signal from one side of the membrane to the other to initiate a change in cell activity. [GOC:ai, GOC:signaling]",
                "id": "GO:0008066"
            }, {
                "name": "protein tyrosine kinase activator activity",
                "definition": "Increases the activity of a protein tyrosine kinase, an enzyme which phosphorylates a tyrosyl phenolic group on a protein. [GOC:ai, ISBN:0198506732]",
                "id": "GO:0030296"
            }, {
                "name": "G-protein coupled receptor activity involved in regulation of postsynaptic membrane potential",
                "definition": "G-protein coupled receptor activity occurring in the postsynaptic membrane that is part of a GPCR signaling pathway that positively regulates ion channel activity in the postsynaptic membrane. [GOC:dos]",
                "id": "GO:0099530"
            }, {
                "name": "neurotransmitter receptor activity involved in regulation of postsynaptic cytosolic calcium ion concentration",
                "definition": "Any neurotransmitter receptor activity that is involved in regulating the concentration of calcium in the postsynaptic cytosol. [GOC:dos]",
                "id": "GO:0099583"
            }, {
                "name": "protein tyrosine kinase binding",
                "definition": "Interacting selectively and non-covalently with protein tyrosine kinase. [PMID:25499537]",
                "id": "GO:1990782"
            }]
        },
        "kegg_pathway": {}
    }, "P2RY14": {
        "name": "purinergic receptor P2Y14",
        "description": "The product of this gene belongs to the family of G-protein coupled receptors, which contains several receptor subtypes with different pharmacological selectivity for various adenosine and uridine nucleotides. This receptor is a P2Y purinergic receptor for UDP-glucose and other UDP-sugars coupled to G-proteins. It has been implicated in extending the known immune system functions of P2Y receptors by participating in the regulation of the stem cell compartment, and it may also play a role in neuroimmune function. Two transcript variants encoding the same protein have been identified for this gene. [provided by RefSeq, Jul 2008].",
        "entrez": "9934",
        "ensembl": "ENSG00000174944",
        "uniprot": "Q15391",
        "hgnc": "16442",
        "go_terms": {
            "biological_process": [{
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "G-protein coupled purinergic nucleotide receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a receptor binding to an extracellular purine nucleotide and transmitting the signal to a heterotrimeric G-protein complex to initiate a change in cell activity. [GOC:BHF, PMID:9755289]",
                "id": "GO:0035589"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "G-protein coupled purinergic nucleotide receptor activity",
                "definition": "Combining with a purine nucleotide and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:mah, PMID:9755289]",
                "id": "GO:0045028"
            }, {
                "name": "UDP-activated nucleotide receptor activity",
                "definition": "Combining with a nucleotide and transmitting the signal to a heterotrimeric G-protein complex to initiate a change in cell activity, activated by UDP. [GOC:mah]",
                "id": "GO:0045029"
            }]
        },
        "kegg_pathway": {}
    }, "SCAP": {
        "name": "SREBF chaperone",
        "description": "This gene encodes a protein with a sterol sensing domain (SSD) and seven WD domains. In the presence of cholesterol, this protein binds to sterol regulatory element binding proteins (SREBPs) and mediates their transport from the ER to the Golgi. The SREBPs are then proteolytically cleaved and regulate sterol biosynthesis. Alternative splicing results in multiple transcript variants. [provided by RefSeq, Feb 2016].",
        "entrez": "22937",
        "ensembl": "ENSG00000114650",
        "uniprot": "Q12770",
        "hgnc": "30634",
        "go_terms": {
            "biological_process": [{
                "name": "response to hypoxia",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating lowered oxygen tension. Hypoxia, defined as a decline in O2 levels below normoxic levels of 20.8 - 20.95%, results in metabolic adaptation at both the cellular and organismal level. [GOC:hjd]",
                "id": "GO:0001666"
            }, {
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "aging",
                "definition": "A developmental process that is a deterioration and loss of function over time. Aging includes loss of functions such as resistance to disease, homeostasis, and fertility, as well as wear and tear. Aging includes cellular senescence, but is more inclusive. May precede death and may succeed developmental maturation (GO:0021700). [GOC:PO_curators]",
                "id": "GO:0007568"
            }, {
                "name": "steroid metabolic process",
                "definition": "The chemical reactions and pathways involving steroids, compounds with a 1,2,cyclopentanoperhydrophenanthrene nucleus. [ISBN:0198547684]",
                "id": "GO:0008202"
            }, {
                "name": "cholesterol metabolic process",
                "definition": "The chemical reactions and pathways involving cholesterol, cholest-5-en-3 beta-ol, the principal sterol of vertebrates and the precursor of many steroids, including bile acids and steroid hormones. It is a component of the plasma membrane lipid bilayer and of plasma lipoproteins and can be found in all animal tissues. [ISBN:0198506732]",
                "id": "GO:0008203"
            }, {
                "name": "regulation of fatty acid metabolic process",
                "definition": "Any process that modulates the frequency, rate or extent of the chemical reactions and pathways involving fatty acids. [GOC:go_curators]",
                "id": "GO:0019217"
            }, {
                "name": "response to insulin",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an insulin stimulus. Insulin is a polypeptide hormone produced by the islets of Langerhans of the pancreas in mammals, and by the homologous organs of other organisms. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0032868"
            }, {
                "name": "SREBP signaling pathway",
                "definition": "A series of molecular signals from the endoplasmic reticulum to the nucleus generated as a consequence of decreased levels of one or more sterols (and in some yeast, changes in oxygen levels) and which proceeds through activation of a sterol response element binding transcription factor (SREBP) to result in up-regulation of target gene transcription. [GOC:bf, GOC:mah, GOC:signaling, GOC:vw, PMID:12923525, PMID:22017871]",
                "id": "GO:0032933"
            }, {
                "name": "regulation of fatty acid biosynthetic process",
                "definition": "Any process that modulates the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of fatty acids, any of the aliphatic monocarboxylic acids that can be liberated by hydrolysis from naturally occurring fats and oils. [GOC:go_curators, GOC:jl]",
                "id": "GO:0042304"
            }, {
                "name": "regulation of cholesterol biosynthetic process",
                "definition": "Any process that modulates the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of cholesterol. [GOC:go_curators]",
                "id": "GO:0045540"
            }, {
                "name": "negative regulation of cholesterol biosynthetic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of cholesterol. [GOC:go_curators]",
                "id": "GO:0045541"
            }, {
                "name": "positive regulation of low-density lipoprotein particle receptor biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of low-density lipoprotein receptors. [GOC:go_curators]",
                "id": "GO:0045716"
            }],
            "cellular_component": [{
                "name": "Golgi membrane",
                "definition": "The lipid bilayer surrounding any of the compartments of the Golgi apparatus. [GOC:mah]",
                "id": "GO:0000139"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "ER to Golgi transport vesicle membrane",
                "definition": "The lipid bilayer surrounding a vesicle transporting substances from the endoplasmic reticulum to the Golgi. [GOC:ai, GOC:ascb_2009, GOC:dph, GOC:tb]",
                "id": "GO:0012507"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "cytoplasmic vesicle",
                "definition": "A vesicle found in the cytoplasm of a cell. [GOC:ai, GOC:mah, GOC:vesicles]",
                "id": "GO:0031410"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }],
            "molecular_function": [{
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "cholesterol binding",
                "definition": "Interacting selectively and non-covalently with cholesterol (cholest-5-en-3-beta-ol); the principal sterol of vertebrates and the precursor of many steroids, including bile acids and steroid hormones. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0015485"
            }, {
                "name": "unfolded protein binding",
                "definition": "Interacting selectively and non-covalently with an unfolded protein. [GOC:ai]",
                "id": "GO:0051082"
            }]
        },
        "kegg_pathway": {}
    }, "CDK5R1": {
        "name": "cyclin dependent kinase 5 regulatory subunit 1",
        "description": "The protein encoded by this gene (p35) is a neuron-specific activator of cyclin-dependent kinase 5 (CDK5); the activation of CDK5 is required for proper development of the central nervous system. The p35 form of this protein is proteolytically cleaved by calpain, generating a p25 form. The cleavage of p35 into p25 results in relocalization of the protein from the cell periphery to nuclear and perinuclear regions. P25 deregulates CDK5 activity by prolonging its activation and changing its cellular location. The p25 form accumulates in the brain neurons of patients with Alzheimer's disease. This accumulation correlates with an increase in CDK5 kinase activity, and may lead to aberrantly phosphorylated forms of the microtubule-associated protein tau, which contributes to Alzheimer's disease. [provided by RefSeq, Jul 2008].",
        "entrez": "8851",
        "ensembl": "ENSG00000176749",
        "uniprot": "Q15078",
        "hgnc": "1775",
        "go_terms": {
            "biological_process": [{
                "name": "regulation of cyclin-dependent protein serine/threonine kinase activity",
                "definition": "Any process that modulates the frequency, rate or extent of cyclin-dependent protein serine/threonine kinase activity. [GOC:go_curators, GOC:pr]",
                "id": "GO:0000079"
            }, {
                "name": "microtubule cytoskeleton organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of cytoskeletal structures comprising microtubules and their associated proteins. [GOC:mah]",
                "id": "GO:0000226"
            }, {
                "name": "neuron migration",
                "definition": "The characteristic movement of an immature neuron from germinal zones to specific positions where they will reside as they mature. [CL:0000540, GOC:go_curators]",
                "id": "GO:0001764"
            }, {
                "name": "neuron cell-cell adhesion",
                "definition": "The attachment of a neuron to another cell via adhesion molecules. [GOC:go_curators]",
                "id": "GO:0007158"
            }, {
                "name": "G-protein coupled acetylcholine receptor signaling pathway",
                "definition": "Any series of molecular signals initiated by an acetylcholine receptor on the surface of the target cell binding to one of its physiological ligands, and proceeding with the activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction and ends with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, ISBN:0815316194]",
                "id": "GO:0007213"
            }, {
                "name": "axon guidance",
                "definition": "The chemotaxis process that directs the migration of an axon growth cone to a specific target site in response to a combination of attractive and repulsive cues. [ISBN:0878932437]",
                "id": "GO:0007411"
            }, {
                "name": "axonal fasciculation",
                "definition": "The collection of axons into a bundle of rods, known as a fascicle. [GOC:dgh]",
                "id": "GO:0007413"
            }, {
                "name": "brain development",
                "definition": "The process whose specific outcome is the progression of the brain over time, from its formation to the mature structure. Brain development begins with patterning events in the neural tube and ends with the mature structure that is the center of thought and emotion. The brain is responsible for the coordination and control of bodily activities and the interpretation of information from the senses (sight, hearing, smell, etc.). [GOC:dph, GOC:jid, GOC:tb, UBERON:0000955]",
                "id": "GO:0007420"
            }, {
                "name": "cell proliferation",
                "definition": "The multiplication or reproduction of cells, resulting in the expansion of a cell population. [GOC:mah, GOC:mb]",
                "id": "GO:0008283"
            }, {
                "name": "embryo development ending in birth or egg hatching",
                "definition": "The process whose specific outcome is the progression of an embryo over time, from zygote formation until the end of the embryonic life stage. The end of the embryonic life stage is organism-specific and may be somewhat arbitrary; for mammals it is usually considered to be birth, for insects the hatching of the first instar larva from the eggshell. [GOC:go_curators, GOC:isa_complete, GOC:mtg_sensu]",
                "id": "GO:0009792"
            }, {
                "name": "regulation of macroautophagy",
                "definition": "Any process that modulates the frequency, rate or extent of macroautophagy. [GOC:krc]",
                "id": "GO:0016241"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "cerebellum development",
                "definition": "The process whose specific outcome is the progression of the cerebellum over time, from its formation to the mature structure. The cerebellum is the portion of the brain in the back of the head between the cerebrum and the pons. In mice, the cerebellum controls balance for walking and standing, modulates the force and range of movement and is involved in the learning of motor skills. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, ISBN:0838580343]",
                "id": "GO:0021549"
            }, {
                "name": "superior olivary nucleus maturation",
                "definition": "A developmental process, independent of morphogenetic (shape) change, that is required for the superior olivary nucleus to attain its fully functional state. The superior olivary nucleus is a small cylindrical mass on the dorsal surface of the lateral part of the trapezoid body of the pons, and it is situated immediately above the inferior olivary nucleus. It receives projections from the cochlear nucleus and thus is involved in the perception of sound. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, ISBN:0838580343]",
                "id": "GO:0021722"
            }, {
                "name": "hippocampus development",
                "definition": "The progression of the hippocampus over time from its initial formation until its mature state. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, ISBN:0878937420, UBERON:0002421]",
                "id": "GO:0021766"
            }, {
                "name": "cerebral cortex radially oriented cell migration",
                "definition": "The migration of cells in the developing cerebral cortex in which cells move from the ventricular and/or subventricular zone toward the surface of the brain. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, PMID:12626695]",
                "id": "GO:0021799"
            }, {
                "name": "layer formation in cerebral cortex",
                "definition": "The detachment of cells from radial glial fibers at the appropriate time when they cease to migrate and form distinct layer in the cerebral cortex. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, PMID:12626695]",
                "id": "GO:0021819"
            }, {
                "name": "neuron differentiation",
                "definition": "The process in which a relatively unspecialized cell acquires specialized features of a neuron. [GOC:mah]",
                "id": "GO:0030182"
            }, {
                "name": "negative regulation of axon extension",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of axon outgrowth. [GOC:mah]",
                "id": "GO:0030517"
            }, {
                "name": "positive regulation of microtubule polymerization",
                "definition": "Any process that activates or increases the frequency, rate or extent of microtubule polymerization. [GOC:mah]",
                "id": "GO:0031116"
            }, {
                "name": "neuron projection development",
                "definition": "The process whose specific outcome is the progression of a neuron projection over time, from its formation to the mature structure. A neuron projection is any process extending from a neural cell, such as axons or dendrites (collectively called neurites). [GOC:mah]",
                "id": "GO:0031175"
            }, {
                "name": "regulation of actin cytoskeleton organization",
                "definition": "Any process that modulates the frequency, rate or extent of the formation, arrangement of constituent parts, or disassembly of cytoskeletal structures comprising actin filaments and their associated proteins. [GOC:mah]",
                "id": "GO:0032956"
            }, {
                "name": "ionotropic glutamate receptor signaling pathway",
                "definition": "A series of molecular signals initiated by glutamate binding to a glutamate receptor on the surface of the target cell, followed by the movement of ions through a channel in the receptor complex. Ends with regulation of a downstream cellular process, e.g. transcription. [GOC:signaling, ISBN:0198506732]",
                "id": "GO:0035235"
            }, {
                "name": "serine phosphorylation of STAT protein",
                "definition": "The process of introducing a phosphate group to a serine residue of a STAT (Signal Transducer and Activator of Transcription) protein. [GOC:jl, PMID:10918594]",
                "id": "GO:0042501"
            }, {
                "name": "positive regulation of neuron apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell death of neurons by apoptotic process. [GOC:go_curators, GOC:mtg_apoptosis]",
                "id": "GO:0043525"
            }, {
                "name": "regulation of neuron differentiation",
                "definition": "Any process that modulates the frequency, rate or extent of neuron differentiation. [GOC:go_curators]",
                "id": "GO:0045664"
            }, {
                "name": "positive regulation of protein kinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein kinase activity. [GOC:go_curators]",
                "id": "GO:0045860"
            }, {
                "name": "negative regulation of transcription, DNA-templated",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045892"
            }, {
                "name": "ephrin receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of an ephrin receptor binding to an ephrin. [GOC:ceb]",
                "id": "GO:0048013"
            }, {
                "name": "rhythmic process",
                "definition": "Any process pertinent to the generation and maintenance of rhythms in the physiology of an organism. [GOC:jid]",
                "id": "GO:0048511"
            }, {
                "name": "regulation of dendritic spine morphogenesis",
                "definition": "Any process that modulates the rate, frequency, or extent of dendritic spine morphogenesis, the process in which the anatomical structures of a dendritic spine are generated and organized. A dendritic spine is a protrusion from a dendrite and a specialized subcellular compartment involved in synaptic transmission. [GOC:dph]",
                "id": "GO:0061001"
            }, {
                "name": "regulation of microtubule cytoskeleton organization",
                "definition": "Any process that modulates the frequency, rate or extent of the formation, arrangement of constituent parts, or disassembly of cytoskeletal structures comprising microtubules and their associated proteins. [GOC:mah]",
                "id": "GO:0070507"
            }, {
                "name": "positive regulation of cell cycle arrest",
                "definition": "Any process that increases the rate, frequency, or extent of cell cycle arrest, the process in which the cell cycle is halted during one of the normal phases. [GOC:mah]",
                "id": "GO:0071158"
            }, {
                "name": "positive regulation of protein serine/threonine kinase activity",
                "definition": "Any process that increases the rate, frequency, or extent of protein serine/threonine kinase activity. [GOC:mah]",
                "id": "GO:0071902"
            }, {
                "name": "positive regulation of protein targeting to membrane",
                "definition": "Any process that increases the frequency, rate or extent of the process of directing proteins towards a membrane, usually using signals contained within the protein. [GOC:tb]",
                "id": "GO:0090314"
            }, {
                "name": "regulation of signal transduction by p53 class mediator",
                "definition": "Any process that modulates the frequency, rate or extent of signal transduction by p53 class mediator. [GOC:TermGenie]",
                "id": "GO:1901796"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "microtubule",
                "definition": "Any of the long, generally straight, hollow tubes of internal diameter 12-15 nm and external diameter 24 nm found in a wide variety of eukaryotic cells; each consists (usually) of 13 protofilaments of polymeric tubulin, staggered in such a manner that the tubulin monomers are arranged in a helical pattern on the microtubular surface, and with the alpha/beta axes of the tubulin subunits parallel to the long axis of the tubule; exist in equilibrium with pool of tubulin monomers and can be rapidly assembled or disassembled in response to physiological stimuli; concerned with force generation, e.g. in the spindle. [ISBN:0879693568]",
                "id": "GO:0005874"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "postsynaptic density",
                "definition": "An electron dense network of proteins within and adjacent to the postsynaptic membrane of an asymetric, neuron-neuron synapse. Its major components include neurotransmitter receptors and the proteins that spatially and functionally organize them such as anchoring and scaffolding molecules, signaling enzymes and cytoskeletal components. [GOC:BHF, GOC:dos, GOC:ef, GOC:jid, GOC:pr, GOC:sjp, http://molneuro.kaist.ac.kr/psd, PMID:14532281, Wikipedia:Postsynaptic_density]",
                "id": "GO:0014069"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "cyclin-dependent protein kinase 5 holoenzyme complex",
                "definition": "A protein complex that activates cyclin-dependent kinase 5; composed of regulatory and catalytic subunits. [PMID:15689152]",
                "id": "GO:0016533"
            }, {
                "name": "axon",
                "definition": "The long process of a neuron that conducts nerve impulses, usually away from the cell body to the terminals and varicosities, which are sites of storage and release of neurotransmitter. [GOC:nln, ISBN:0198506732]",
                "id": "GO:0030424"
            }, {
                "name": "dendrite",
                "definition": "A neuron projection that has a short, tapering, morphology. Dendrites receive and integrate signals from other neurons or from sensory stimuli, and conduct nerve impulses towards the axon or the cell body. In most neurons, the impulse is conveyed from dendrites to axon via the cell body, but in some types of unipolar neuron, the impulse does not travel via the cell body. [GOC:aruk, GOC:bc, GOC:dos, GOC:mah, GOC:nln, ISBN:0198506732]",
                "id": "GO:0030425"
            }, {
                "name": "growth cone",
                "definition": "The migrating motile tip of a growing neuron projection, where actin accumulates, and the actin cytoskeleton is the most dynamic. [GOC:aruk, GOC:bc, ISBN:0815316194, PMID:10082468]",
                "id": "GO:0030426"
            }, {
                "name": "neuromuscular junction",
                "definition": "The junction between the axon of a motor neuron and a muscle fiber. In response to the arrival of action potentials, the presynaptic button releases molecules of neurotransmitters into the synaptic cleft. These diffuse across the cleft and transmit the signal to the postsynaptic membrane of the muscle fiber, leading to a change in post-synaptic potential. [GOC:nln]",
                "id": "GO:0031594"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }, {
                "name": "neuronal cell body",
                "definition": "The portion of a neuron that includes the nucleus, but excludes cell projections such as axons and dendrites. [GOC:go_curators]",
                "id": "GO:0043025"
            }, {
                "name": "dendritic spine",
                "definition": "A small, membranous protrusion from a dendrite that forms a postsynaptic compartment - typically receiving input from a single presynapse.  They function as partially isolated biochemical and an electrical compartments. Spine morphology is variable including \\thin\\\", \\\"stubby\\\", \\\"mushroom\\\", and \\\"branched\\\", with a continuum of intermediate morphologies. They typically terminate in a bulb shape, linked to the dendritic shaft by a restriction. Spine remodeling is though to be involved in synaptic plasticity.\" [GOC:nln]",
                "id": "GO:0043197"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }, {
                "name": "contractile fiber",
                "definition": "Fibers, composed of actin, myosin, and associated proteins, found in cells of smooth or striated muscle. [GOC:go_curators, ISBN:0815316194]",
                "id": "GO:0043292"
            }, {
                "name": "tubulin complex",
                "definition": "A heterodimer of tubulins alpha and beta that constitutes the protomer for microtubule assembly. [ISBN:0716731363]",
                "id": "GO:0045298"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }],
            "molecular_function": [{
                "name": "protease binding",
                "definition": "Interacting selectively and non-covalently with any protease or peptidase. [GOC:hjd]",
                "id": "GO:0002020"
            }, {
                "name": "actin binding",
                "definition": "Interacting selectively and non-covalently with monomeric or multimeric forms of actin, including actin filaments. [GOC:clt]",
                "id": "GO:0003779"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "calcium ion binding",
                "definition": "Interacting selectively and non-covalently with calcium ions (Ca2+). [GOC:ai]",
                "id": "GO:0005509"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "cyclin-dependent protein kinase 5 activator activity",
                "definition": "Binds to and increases the activity of cyclin-dependent protein kinase 5. [GOC:mah]",
                "id": "GO:0016534"
            }, {
                "name": "protein kinase binding",
                "definition": "Interacting selectively and non-covalently with a protein kinase, any enzyme that catalyzes the transfer of a phosphate group, usually from ATP, to a protein substrate. [GOC:jl]",
                "id": "GO:0019901"
            }, {
                "name": "ionotropic glutamate receptor binding",
                "definition": "Interacting selectively and non-covalently with an ionotropic glutamate receptor. Ionotropic glutamate receptors bind glutamate and exert an effect through the regulation of ion channels. [GOC:bf, ISBN:0198506732]",
                "id": "GO:0035255"
            }, {
                "name": "alpha-tubulin binding",
                "definition": "Interacting selectively and non-covalently with the microtubule constituent protein alpha-tubulin. [GOC:jl]",
                "id": "GO:0043014"
            }, {
                "name": "protein serine/threonine kinase activator activity",
                "definition": "Binds to and increases the activity of a protein serine/threonine kinase. [GOC:go_curators]",
                "id": "GO:0043539"
            }, {
                "name": "cadherin binding",
                "definition": "Interacting selectively and non-covalently with cadherin, a type I membrane protein involved in cell adhesion. [GOC:bf]",
                "id": "GO:0045296"
            }, {
                "name": "ephrin receptor binding",
                "definition": "Interacting selectively and non-covalently with an ephrin receptor. [GOC:ai]",
                "id": "GO:0046875"
            }, {
                "name": "beta-tubulin binding",
                "definition": "Interacting selectively and non-covalently with the microtubule constituent protein beta-tubulin. [GOC:krc]",
                "id": "GO:0048487"
            }, {
                "name": "actin filament binding",
                "definition": "Interacting selectively and non-covalently with an actin filament, also known as F-actin, a helical filamentous polymer of globular G-actin subunits. [ISBN:0198506732]",
                "id": "GO:0051015"
            }]
        },
        "kegg_pathway": {}
    }, "PDE11A": {
        "name": "phosphodiesterase 11A",
        "description": "The 3',5'-cyclic nucleotides cAMP and cGMP function as second messengers in a wide variety of signal transduction pathways. 3',5'-cyclic nucleotide phosphodiesterases (PDEs) catalyze the hydrolysis of cAMP and cGMP to the corresponding 5'-monophosphates and provide a mechanism to downregulate cAMP and cGMP signaling. This gene encodes a member of the PDE protein superfamily. Mutations in this gene are a cause of Cushing disease and adrenocortical hyperplasia. Multiple transcript variants encoding different isoforms have been found for this gene. [provided by RefSeq, Jul 2008].",
        "entrez": "50940",
        "ensembl": "ENSG00000128655",
        "uniprot": "Q9HCR9",
        "hgnc": "8773",
        "go_terms": {
            "biological_process": [{
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "metabolic process",
                "definition": "The chemical reactions and pathways, including anabolism and catabolism, by which living organisms transform chemical substances. Metabolic processes typically transform small molecules, but also include macromolecular processes such as DNA repair and replication, and protein synthesis and degradation. [GOC:go_curators, ISBN:0198547684]",
                "id": "GO:0008152"
            }],
            "cellular_component": [{
                "name": "cellular_component",
                "definition": "The part of a cell, extracellular environment or virus in which a gene product is located. A gene product may be located in one or more parts of a cell and its location may be as specific as a particular macromolecular complex, that is, a stable, persistent association of macromolecules that function together. [GOC:go_curators, NIF_Subcellular:sao-1337158144]",
                "id": "GO:0005575"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }],
            "molecular_function": [{
                "name": "catalytic activity",
                "definition": "Catalysis of a biochemical reaction at physiological temperatures. In biologically catalyzed reactions, the reactants are known as substrates, and the catalysts are naturally occurring macromolecular substances known as enzymes. Enzymes possess specific binding sites for substrates, and are usually composed wholly or largely of protein, but RNA that has catalytic activity (ribozyme) is often also regarded as enzymatic. [GOC:vw, ISBN:0198506732]",
                "id": "GO:0003824"
            }, {
                "name": "cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: a nucleoside cyclic phosphate + H2O = a nucleoside phosphate. [GOC:mah]",
                "id": "GO:0004112"
            }, {
                "name": "3',5'-cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: nucleoside 3',5'-cyclic phosphate + H2O = nucleoside 5'-phosphate. [EC:3.1.4.17]",
                "id": "GO:0004114"
            }, {
                "name": "3',5'-cyclic-AMP phosphodiesterase activity",
                "definition": "Catalysis of the reaction: adenosine 3',5'-cyclic phosphate + H2O = adenosine 5'-phosphate. [GOC:ai]",
                "id": "GO:0004115"
            }, {
                "name": "cGMP-stimulated cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: nucleoside 3',5'-cyclic phosphate + H2O = nucleoside 5'-phosphate; catalytic activity is increased in the presence of cGMP. [GOC:mah]",
                "id": "GO:0004118"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "phosphoric diester hydrolase activity",
                "definition": "Catalysis of the hydrolysis of a phosphodiester to give a phosphomonoester and a free hydroxyl group. [EC:3.1.4, GOC:curators]",
                "id": "GO:0008081"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "cGMP binding",
                "definition": "Interacting selectively and non-covalently with cGMP, the nucleotide cyclic GMP (guanosine 3',5'-cyclophosphate). [GOC:ai]",
                "id": "GO:0030553"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "3',5'-cyclic-GMP phosphodiesterase activity",
                "definition": "Catalysis of the reaction: guanosine 3',5'-cyclic phosphate + H2O = guanosine 5'-phosphate. [EC:3.1.4.35, MetaCyc:35-CYCLIC-GMP-PHOSPHODIESTERASE-RXN]",
                "id": "GO:0047555"
            }]
        },
        "kegg_pathway": {}
    }, "CACNA1A": {
        "name": "calcium voltage-gated channel subunit alpha1 A",
        "description": "Voltage-dependent calcium channels mediate the entry of calcium ions into excitable cells, and are also involved in a variety of calcium-dependent processes, including muscle contraction, hormone or neurotransmitter release, and gene expression. Calcium channels are multisubunit complexes composed of alpha-1, beta, alpha-2/delta, and gamma subunits. The channel activity is directed by the pore-forming alpha-1 subunit, whereas, the others act as auxiliary subunits regulating this activity. The distinctive properties of the calcium channel types are related primarily to the expression of a variety of alpha-1 isoforms, alpha-1A, B, C, D, E, and S. This gene encodes the alpha-1A subunit, which is predominantly expressed in neuronal tissue. Mutations in this gene are associated with 2 neurologic disorders, familial hemiplegic migraine and episodic ataxia 2. This gene also exhibits polymorphic variation due to (CAG)n-repeats. Multiple transcript variants encoding different isoforms have been found for this gene. In one set of transcript variants, the (CAG)n-repeats occur in the 3' UTR, and are not associated with any disease. But in another set of variants, an insertion extends the coding region to include the (CAG)n-repeats which encode a polyglutamine tract. Expansion of the (CAG)n-repeats from the normal 4-18 to 21-33 in the coding region is associated with spinocerebellar ataxia 6. [provided by RefSeq, Jul 2016].",
        "entrez": "773",
        "ensembl": "ENSG00000141837",
        "uniprot": "O00555",
        "hgnc": "1388",
        "go_terms": {
            "biological_process": [{
                "name": "regulation of neurotransmitter levels",
                "definition": "Any process that modulates levels of neurotransmitter. [GOC:jl]",
                "id": "GO:0001505"
            }, {
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "calcium ion transport",
                "definition": "The directed movement of calcium (Ca) ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006816"
            }, {
                "name": "positive regulation of cytosolic calcium ion concentration",
                "definition": "Any process that increases the concentration of calcium ions in the cytosol. [GOC:ai]",
                "id": "GO:0007204"
            }, {
                "name": "gamma-aminobutyric acid signaling pathway",
                "definition": "The series of molecular signals generated by the binding of gamma-aminobutyric acid (GABA, 4-aminobutyrate), an amino acid which acts as a neurotransmitter in some organisms, to a cell surface receptor. [GOC:mah]",
                "id": "GO:0007214"
            }, {
                "name": "chemical synaptic transmission",
                "definition": "The vesicular release of classical neurotransmitter molecules from a presynapse, across a chemical synapse, the subsequent activation of neurotransmitter receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:jl, MeSH:D009435]",
                "id": "GO:0007268"
            }, {
                "name": "neuron-neuron synaptic transmission",
                "definition": "The process of synaptic transmission from a neuron to another neuron across a synapse. [GOC:add, GOC:dos, GOC:jl, MeSH:D009435]",
                "id": "GO:0007270"
            }, {
                "name": "neuromuscular synaptic transmission",
                "definition": "The process of synaptic transmission from a neuron to a muscle, across a synapse. [GOC:dos, GOC:jl, MeSH:D009435]",
                "id": "GO:0007274"
            }, {
                "name": "synapse assembly",
                "definition": "The aggregation, arrangement and bonding together of a set of components to form a synapse.  This process ends when the synapse is mature (functional). [GOC:mah]",
                "id": "GO:0007416"
            }, {
                "name": "adult walking behavior",
                "definition": "The behavior of an adult relating to the progression of that organism along the ground by the process of lifting and setting down each leg. [GOC:jid, GOC:pr, ISBN:0198606907]",
                "id": "GO:0007628"
            }, {
                "name": "cell death",
                "definition": "Any biological process that results in permanent cessation of all vital functions of a cell. A cell should be considered dead when any one of the following molecular or morphological criteria is met: (1) the cell has lost the integrity of its plasma membrane; (2) the cell, including its nucleus, has undergone complete fragmentation into discrete bodies (frequently referred to as apoptotic bodies). The cell corpse (or its fragments) may be engulfed by an adjacent cell in vivo, but engulfment of whole cells should not be considered a strict criteria to define cell death as, under some circumstances, live engulfed cells can be released from phagosomes (see PMID:18045538). [GOC:mah, GOC:mtg_apoptosis, PMID:25236395]",
                "id": "GO:0008219"
            }, {
                "name": "regulation of hormone levels",
                "definition": "Any process that modulates the levels of hormone within an organism or a tissue. A hormone is any substance formed in very small amounts in one specialized organ or group of cells and carried (sometimes in the bloodstream) to another organ or group of cells in the same organism, upon which it has a specific regulatory action. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010817"
            }, {
                "name": "gamma-aminobutyric acid secretion",
                "definition": "The regulated release of gamma-aminobutyric acid by a cell or a tissue. The gamma-aminobutyric acid is the principal inhibitory neurotransmitter in the brain but is also found in several extraneural tissues. [GOC:ef]",
                "id": "GO:0014051"
            }, {
                "name": "regulation of acetylcholine secretion, neurotransmission",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of acetylcholine. [GOC:ef]",
                "id": "GO:0014056"
            }, {
                "name": "calcium ion regulated exocytosis",
                "definition": "The release of intracellular molecules (e.g. hormones, matrix proteins) contained within a membrane-bounded vesicle by fusion of the vesicle with the plasma membrane of a cell, induced by a rise in cytosolic calcium-ion levels. [GOC:go_curators]",
                "id": "GO:0017156"
            }, {
                "name": "regulation of calcium ion-dependent exocytosis",
                "definition": "Any process that modulates the frequency, rate or extent of calcium ion-dependent exocytosis. [GOC:go_curators]",
                "id": "GO:0017158"
            }, {
                "name": "transmission of nerve impulse",
                "definition": "The neurological system process in which a signal is transmitted through the nervous system by a combination of action potential propagation and synaptic transmission. [GOC:curators, ISBN:0815316194]",
                "id": "GO:0019226"
            }, {
                "name": "spinal cord motor neuron differentiation",
                "definition": "The process in which neuroepithelial cells in the ventral neural tube acquire specialized structural and/or functional features of motor neurons. Motor neurons innervate an effector (muscle or glandular) tissue and are responsible for transmission of motor impulses from the brain to the periphery. Differentiation includes the processes involved in commitment of a cell to a specific fate. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, PMID:11262869]",
                "id": "GO:0021522"
            }, {
                "name": "cerebellum maturation",
                "definition": "A developmental process, independent of morphogenetic (shape) change, that is required for the cerebellum to attain its fully functional state. The cerebellum is the portion of the brain in the back of the head between the cerebrum and the pons. The cerebellum controls balance for walking and standing, modulates the force and range of movement and is involved in the learning of motor skills. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, ISBN:0838580343]",
                "id": "GO:0021590"
            }, {
                "name": "cerebellar molecular layer development",
                "definition": "The process whose specific outcome is the progression of the cerebellar molecular layer nerve over time, from its formation to the mature structure. The molecular layer is the outermost layer of the cerebellar cortex. It contains the parallel fibers of the granule cells, interneurons such as stellate and basket cells, and the dendrites of the underlying Purkinje cells. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, ISBN:0838580343]",
                "id": "GO:0021679"
            }, {
                "name": "cerebellar Purkinje cell layer development",
                "definition": "The process whose specific outcome is the progression of the cerebellar Purkinje cell layer over time, from its formation to the mature structure. The Purkinje cell layer lies just underneath the molecular layer of the cerebellar cortex. It contains the neuronal cell bodies of the Purkinje cells that are arranged side by side in a single layer. Candelabrum interneurons are vertically oriented between the Purkinje cells. Purkinje neurons are inhibitory and provide the output of the cerebellar cortex through axons that project into the white matter. Extensive dendritic trees from the Purkinje cells extend upward in a single plane into the molecular layer where they synapse with parallel fibers of granule cells. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, ISBN:0838580343]",
                "id": "GO:0021680"
            }, {
                "name": "cerebellar Purkinje cell differentiation",
                "definition": "The process in which neuroblasts acquire specialized structural and/or functional features that characterize the mature cerebellar Purkinje cell. Differentiation includes the processes involved in commitment of a neuroblast to a Purkinje cell fate. A Purkinje cell is an inhibitory GABAergic neuron found in the cerebellar cortex that projects to the deep cerebellar nuclei and brain stem. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, PMID:15157725]",
                "id": "GO:0021702"
            }, {
                "name": "vestibular nucleus development",
                "definition": "The process whose specific outcome is the progression of the vestibular nucleus over time, from its formation to the mature structure. [GO_REF:0000021, GOC:cls, GOC:curators, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, PMID:16221589]",
                "id": "GO:0021750"
            }, {
                "name": "central nervous system neuron differentiation",
                "definition": "The process in which a relatively unspecialized cell acquires specialized features of a neuron whose cell body resides in the central nervous system. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06]",
                "id": "GO:0021953"
            }, {
                "name": "cellular chloride ion homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of chloride ions at the level of a cell. [GOC:mah]",
                "id": "GO:0030644"
            }, {
                "name": "regulation of sulfur amino acid metabolic process",
                "definition": "Any process that modulates the frequency, rate or extent of the chemical reactions and pathways involving sulfur amino acids. [GOC:mah]",
                "id": "GO:0031335"
            }, {
                "name": "negative regulation of hormone biosynthetic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of hormones. [GOC:ai]",
                "id": "GO:0032353"
            }, {
                "name": "ion transmembrane transport",
                "definition": "A process in which an ion is transported across a membrane. [GOC:mah]",
                "id": "GO:0034220"
            }, {
                "name": "regulation of ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of ions from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0034765"
            }, {
                "name": "synaptic transmission, glutamatergic",
                "definition": "The vesicular release of glutamate from a presynapse, across a chemical synapse, the subsequent activation of glutamate receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:dos]",
                "id": "GO:0035249"
            }, {
                "name": "regulation of membrane potential",
                "definition": "Any process that modulates the establishment or extent of a membrane potential, the electric potential existing across any membrane arising from charges in the membrane itself and from the charges present in the media on either side of the membrane. [GOC:jl, GOC:mtg_cardio, GOC:tb, ISBN:0198506732]",
                "id": "GO:0042391"
            }, {
                "name": "glucose homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of glucose within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042593"
            }, {
                "name": "receptor clustering",
                "definition": "The receptor metabolic process that results in grouping of a set of receptors at a cellular location, often to amplify the sensitivity of a signaling response. [GOC:bf, GOC:jl, GOC:pr, PMID:19747931, PMID:21453460]",
                "id": "GO:0043113"
            }, {
                "name": "negative regulation of neuron apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process in neurons. [GOC:go_curators, GOC:mtg_apoptosis]",
                "id": "GO:0043524"
            }, {
                "name": "response to pain",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a pain stimulus. Pain stimuli cause activation of nociceptors, peripheral receptors for pain, include receptors which are sensitive to painful mechanical stimuli, extreme heat or cold, and chemical stimuli. [GOC:jid, PMID:10203867, PMID:12723742, PMID:12843304, Wikipedia:Pain]",
                "id": "GO:0048265"
            }, {
                "name": "behavioral response to pain",
                "definition": "Any process that results in a change in the behavior of an organism as a result of a pain stimulus. Pain stimuli cause activation of nociceptors, peripheral receptors for pain, include receptors which are sensitive to painful mechanical stimuli, extreme heat or cold, and chemical stimuli. [GOC:jid]",
                "id": "GO:0048266"
            }, {
                "name": "calcium ion-regulated exocytosis of neurotransmitter",
                "definition": "The release of a neurotransmitter into the synaptic cleft by exocytosis of synaptic vesicles, where the release step is dependent on a rise in cytosolic calcium ion levels. [GOC:curators]",
                "id": "GO:0048791"
            }, {
                "name": "dendrite morphogenesis",
                "definition": "The process in which the anatomical structures of a dendrite are generated and organized. [GOC:aruk, GOC:bc, GOC:jl, ISBN:0198506732, PMID:22683681]",
                "id": "GO:0048813"
            }, {
                "name": "regulation of axonogenesis",
                "definition": "Any process that modulates the frequency, rate or extent of axonogenesis, the generation of an axon, the long process of a neuron. [GOC:ai]",
                "id": "GO:0050770"
            }, {
                "name": "regulation of insulin secretion",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of insulin. [GOC:ai]",
                "id": "GO:0050796"
            }, {
                "name": "nervous system process",
                "definition": "A organ system process carried out by any of the organs or tissues of neurological system. [GOC:ai, GOC:mtg_cardio]",
                "id": "GO:0050877"
            }, {
                "name": "musculoskeletal movement, spinal reflex action",
                "definition": "Involuntary movement caused by the application of a stimulus to an organism and a subsequent movement. The signal processing of this movement takes place in the spinal cord. [GOC:dph]",
                "id": "GO:0050883"
            }, {
                "name": "neuromuscular process controlling balance",
                "definition": "Any process that an organism uses to control its balance, the orientation of the organism (or the head of the organism) in relation to the source of gravity. In humans and animals, balance is perceived through visual cues, the labyrinth system of the inner ears and information from skin pressure receptors and muscle and joint receptors. [GOC:ai, GOC:dph, http://www.onelook.com/]",
                "id": "GO:0050885"
            }, {
                "name": "neuromuscular process",
                "definition": "Any process pertaining to the functions of the nervous and muscular systems of an organism. [GOC:ai]",
                "id": "GO:0050905"
            }, {
                "name": "membrane depolarization",
                "definition": "The process in which membrane potential decreases with respect to its steady-state potential, usually from negative potential to a more positive potential. For example, the initial depolarization during the rising phase of an action potential is in the direction from the negative steady-state resting potential towards the positive membrane potential that will be the peak of the action potential. [GOC:dh, Wikipedia:Depolarization]",
                "id": "GO:0051899"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "rhythmic synaptic transmission",
                "definition": "Any process involved in the generation of rhythmic, synchronous synaptic inputs in a neural circuit. [GOC:dph]",
                "id": "GO:0060024"
            }, {
                "name": "calcium ion transmembrane transport",
                "definition": "A process in which a calcium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:mah]",
                "id": "GO:0070588"
            }, {
                "name": "membrane depolarization during action potential",
                "definition": "The process in which membrane potential changes in the depolarizing direction from the negative resting potential towards the positive membrane potential that will be the peak of the action potential. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086010"
            }, {
                "name": "response to amyloid-beta",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a amyloid-beta stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:23555824]",
                "id": "GO:1904645"
            }, {
                "name": "cellular response to amyloid-beta",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a amyloid-beta stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:23555824]",
                "id": "GO:1904646"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "voltage-gated calcium channel complex",
                "definition": "A protein complex that forms a transmembrane channel through which calcium ions may pass in response to changes in membrane potential. [GOC:mah]",
                "id": "GO:0005891"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "dendrite",
                "definition": "A neuron projection that has a short, tapering, morphology. Dendrites receive and integrate signals from other neurons or from sensory stimuli, and conduct nerve impulses towards the axon or the cell body. In most neurons, the impulse is conveyed from dendrites to axon via the cell body, but in some types of unipolar neuron, the impulse does not travel via the cell body. [GOC:aruk, GOC:bc, GOC:dos, GOC:mah, GOC:nln, ISBN:0198506732]",
                "id": "GO:0030425"
            }, {
                "name": "cell projection",
                "definition": "A prolongation or process extending from a cell, e.g. a flagellum or axon. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0042995"
            }, {
                "name": "neuronal cell body",
                "definition": "The portion of a neuron that includes the nucleus, but excludes cell projections such as axons and dendrites. [GOC:go_curators]",
                "id": "GO:0043025"
            }, {
                "name": "synapse",
                "definition": "The junction between a nerve fiber of one neuron and another neuron, muscle fiber or glial cell. As the nerve fiber approaches the synapse it enlarges into a specialized structure, the presynaptic nerve ending, which contains mitochondria and synaptic vesicles. At the tip of the nerve ending is the presynaptic membrane; facing it, and separated from it by a minute cleft (the synaptic cleft) is a specialized area of membrane on the receiving cell, known as the postsynaptic membrane. In response to the arrival of nerve impulses, the presynaptic nerve ending secretes molecules of neurotransmitters into the synaptic cleft. These diffuse across the cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045202"
            }, {
                "name": "presynapse",
                "definition": "The part of a synapse that is part of the presynaptic cell. [GOC:dos]",
                "id": "GO:0098793"
            }],
            "molecular_function": [{
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "voltage-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a voltage-gated channel. An ion is an atom or group of atoms carrying an electric charge by virtue of having gained or lost one or more electrons. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0198506732, ISBN:0815340729]",
                "id": "GO:0005244"
            }, {
                "name": "voltage-gated calcium channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion by a voltage-gated channel. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, GOC:tb, ISBN:0815340729]",
                "id": "GO:0005245"
            }, {
                "name": "calcium channel activity",
                "definition": "Enables the facilitated diffusion of a calcium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005262"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "high voltage-gated calcium channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion by a high voltage-gated channel. A high voltage-gated channel is a channel whose open state is dependent on high voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0815340729, PMID:16382099]",
                "id": "GO:0008331"
            }, {
                "name": "syntaxin binding",
                "definition": "Interacting selectively and non-covalently with a syntaxin, a SNAP receptor involved in the docking of synaptic vesicles at the presynaptic zone of a synapse. [ISBN:0198506732]",
                "id": "GO:0019905"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "CACNA1E": {
        "name": "calcium voltage-gated channel subunit alpha1 E",
        "description": "Voltage-dependent calcium channels are multisubunit complexes consisting of alpha-1, alpha-2, beta, and delta subunits in a 1:1:1:1 ratio. These channels mediate the entry of calcium ions into excitable cells, and are also involved in a variety of calcium-dependent processes, including muscle contraction, hormone or neurotransmitter release, gene expression, cell motility, cell division and cell death. This gene encodes the alpha-1E subunit of the R-type calcium channels, which belong to the 'high-voltage activated' group that maybe involved in the modulation of firing patterns of neurons important for information processing. Alternatively spliced transcript variants encoding different isoforms have been described for this gene. [provided by RefSeq, Apr 2011].",
        "entrez": "777",
        "ensembl": "ENSG00000198216",
        "uniprot": "Q15878",
        "hgnc": "1392",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "calcium ion transport",
                "definition": "The directed movement of calcium (Ca) ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006816"
            }, {
                "name": "chemical synaptic transmission",
                "definition": "The vesicular release of classical neurotransmitter molecules from a presynapse, across a chemical synapse, the subsequent activation of neurotransmitter receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:jl, MeSH:D009435]",
                "id": "GO:0007268"
            }, {
                "name": "regulation of ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of ions from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0034765"
            }, {
                "name": "regulation of insulin secretion",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of insulin. [GOC:ai]",
                "id": "GO:0050796"
            }, {
                "name": "membrane depolarization",
                "definition": "The process in which membrane potential decreases with respect to its steady-state potential, usually from negative potential to a more positive potential. For example, the initial depolarization during the rising phase of an action potential is in the direction from the negative steady-state resting potential towards the positive membrane potential that will be the peak of the action potential. [GOC:dh, Wikipedia:Depolarization]",
                "id": "GO:0051899"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "calcium ion transmembrane transport",
                "definition": "A process in which a calcium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:mah]",
                "id": "GO:0070588"
            }, {
                "name": "membrane depolarization during action potential",
                "definition": "The process in which membrane potential changes in the depolarizing direction from the negative resting potential towards the positive membrane potential that will be the peak of the action potential. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086010"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "voltage-gated calcium channel complex",
                "definition": "A protein complex that forms a transmembrane channel through which calcium ions may pass in response to changes in membrane potential. [GOC:mah]",
                "id": "GO:0005891"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "voltage-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a voltage-gated channel. An ion is an atom or group of atoms carrying an electric charge by virtue of having gained or lost one or more electrons. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0198506732, ISBN:0815340729]",
                "id": "GO:0005244"
            }, {
                "name": "voltage-gated calcium channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion by a voltage-gated channel. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, GOC:tb, ISBN:0815340729]",
                "id": "GO:0005245"
            }, {
                "name": "calcium channel activity",
                "definition": "Enables the facilitated diffusion of a calcium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005262"
            }, {
                "name": "calcium ion binding",
                "definition": "Interacting selectively and non-covalently with calcium ions (Ca2+). [GOC:ai]",
                "id": "GO:0005509"
            }, {
                "name": "high voltage-gated calcium channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion by a high voltage-gated channel. A high voltage-gated channel is a channel whose open state is dependent on high voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0815340729, PMID:16382099]",
                "id": "GO:0008331"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "PKN3": {
        "name": "Phosphatidylinositol signaling system",
        "description": "",
        "entrez": "29941",
        "ensembl": "ENSG00000160447",
        "uniprot": "Q6P5Z2",
        "hgnc": "17999",
        "go_terms": {
            "biological_process": [{
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "epithelial cell migration",
                "definition": "The orderly movement of an epithelial cell from one site to another, often during the development of a multicellular organism. [GOC:ascb_2009, GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010631"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "protein kinase C activity",
                "definition": "Catalysis of the reaction: ATP + a protein = ADP + a phosphoprotein. This reaction requires diacylglycerol. [EC:2.7.11.13]",
                "id": "GO:0004697"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "GTP-Rho binding",
                "definition": "Interacting selectively and non-covalently with the GTP-bound form of the Rho protein. [GOC:mah]",
                "id": "GO:0017049"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.13",
            "pathways": [{
                "pathway": "04070",
                "name": "Phosphatidylinositol signaling system",
                "class": "Environmental Information Processing; Signal transduction"
            }]
        }
    }, "KCNA5": {
        "name": "potassium voltage-gated channel subfamily A member 5",
        "description": "Potassium channels represent the most complex class of voltage-gated ino channels from both functional and structural standpoints. Their diverse functions include regulating neurotransmitter release, heart rate, insulin secretion, neuronal excitability, epithelial electrolyte transport, smooth muscle contraction, and cell volume. Four sequence-related potassium channel genes - shaker, shaw, shab, and shal - have been identified in Drosophila, and each has been shown to have human homolog(s). This gene encodes a member of the potassium channel, voltage-gated, shaker-related subfamily. This member contains six membrane-spanning domains with a shaker-type repeat in the fourth segment. It belongs to the delayed rectifier class, the function of which could restore the resting membrane potential of beta cells after depolarization and thereby contribute to the regulation of insulin secretion. This gene is intronless, and the gene is clustered with genes KCNA1 and KCNA6 on chromosome 12. Defects in this gene are a cause of familial atrial fibrillation type 7 (ATFB7). [provided by RefSeq, May 2012].",
        "entrez": "3741",
        "ensembl": "ENSG00000130037",
        "uniprot": "P22460",
        "hgnc": "6224",
        "go_terms": {
            "biological_process": [{
                "name": "response to hypoxia",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating lowered oxygen tension. Hypoxia, defined as a decline in O2 levels below normoxic levels of 20.8 - 20.95%, results in metabolic adaptation at both the cellular and organismal level. [GOC:hjd]",
                "id": "GO:0001666"
            }, {
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "potassium ion transport",
                "definition": "The directed movement of potassium ions (K+) into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006813"
            }, {
                "name": "Notch signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to the receptor Notch on the surface of a target cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:go_curators, GOC:signaling]",
                "id": "GO:0007219"
            }, {
                "name": "response to mechanical stimulus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a mechanical stimulus. [GOC:hb]",
                "id": "GO:0009612"
            }, {
                "name": "response to organic substance",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic substance stimulus. [GOC:sm]",
                "id": "GO:0010033"
            }, {
                "name": "regulation of vasoconstriction",
                "definition": "Any process that modulates the frequency, rate or extent of reductions in the diameter of blood vessels. [GOC:jl]",
                "id": "GO:0019229"
            }, {
                "name": "regulation of ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of ions from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0034765"
            }, {
                "name": "regulation of membrane potential",
                "definition": "Any process that modulates the establishment or extent of a membrane potential, the electric potential existing across any membrane arising from charges in the membrane itself and from the charges present in the media on either side of the membrane. [GOC:jl, GOC:mtg_cardio, GOC:tb, ISBN:0198506732]",
                "id": "GO:0042391"
            }, {
                "name": "response to hydrogen peroxide",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hydrogen peroxide (H2O2) stimulus. [GOC:jl]",
                "id": "GO:0042542"
            }, {
                "name": "regulation of potassium ion transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of potassium ions (K+) into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:jl]",
                "id": "GO:0043266"
            }, {
                "name": "negative regulation of potassium ion transport",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the directed movement of potassium ions (K+) into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:jl]",
                "id": "GO:0043267"
            }, {
                "name": "regulation of insulin secretion",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of insulin. [GOC:ai]",
                "id": "GO:0050796"
            }, {
                "name": "protein oligomerization",
                "definition": "The process of creating protein oligomers, compounds composed of a small number, usually between three and ten, of component monomers; protein oligomers may be composed of different or identical monomers. Oligomers may be formed by the polymerization of a number of monomers or the depolymerization of a large protein polymer. [GOC:ai]",
                "id": "GO:0051259"
            }, {
                "name": "protein homooligomerization",
                "definition": "The process of creating protein oligomers, compounds composed of a small number, usually between three and ten, of identical component monomers. Oligomers may be formed by the polymerization of a number of monomers or the depolymerization of a large protein polymer. [GOC:ai]",
                "id": "GO:0051260"
            }, {
                "name": "negative regulation of cytosolic calcium ion concentration",
                "definition": "Any process that decreases the concentration of calcium ions in the cytosol. [GOC:ai]",
                "id": "GO:0051481"
            }, {
                "name": "potassium ion homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of potassium ions within an organism or cell. [GOC:jid, GOC:mah]",
                "id": "GO:0055075"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "response to hyperoxia",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating increased oxygen tension. [GOC:kmv]",
                "id": "GO:0055093"
            }, {
                "name": "membrane hyperpolarization",
                "definition": "The process in which membrane potential increases with respect to its steady-state potential, usually from negative potential to a more negative potential. For example, during the repolarization phase of an action potential the membrane potential often becomes more negative or hyperpolarized before returning to the steady-state resting potential. [GOC:dph]",
                "id": "GO:0060081"
            }, {
                "name": "regulation of atrial cardiac muscle cell membrane repolarization",
                "definition": "Any process that modulates the establishment or extent of a membrane potential in the polarizing direction towards the resting potential in an atrial cardiomyocyte. [GOC:dph, GOC:tb]",
                "id": "GO:0060372"
            }, {
                "name": "regulation of molecular function",
                "definition": "Any process that modulates the frequency, rate or extent of a molecular function, an elemental biological activity occurring at the molecular level, such as catalysis or binding. [GOC:isa_complete]",
                "id": "GO:0065009"
            }, {
                "name": "potassium ion export",
                "definition": "The directed movement of potassium ions out of a cell or organelle. [GOC:mah]",
                "id": "GO:0071435"
            }, {
                "name": "potassium ion transmembrane transport",
                "definition": "A process in which a potassium ion is transported from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0071805"
            }, {
                "name": "atrial cardiac muscle cell action potential",
                "definition": "An action potential that occurs in an atrial cardiac muscle cell. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086014"
            }, {
                "name": "membrane repolarization during bundle of His cell action potential",
                "definition": "The process in which ions are transported across a membrane such that the bundle of His cardiac muscle cell membrane potential changes in the direction from the positive membrane potential at the peak of the action potential towards the negative resting potential. [GOC:BHF, GOC:dph, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086050"
            }, {
                "name": "membrane repolarization during SA node cell action potential",
                "definition": "The process in which an SA node cardiac muscle cell membrane potential changes in the direction from the positive membrane potential at the peak of the action potential towards the negative resting potential. [GOC:BHF, GOC:dph, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086052"
            }, {
                "name": "regulation of heart rate by cardiac conduction",
                "definition": "A cardiac conduction process that modulates the frequency or rate of heart contraction. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086091"
            }, {
                "name": "membrane repolarization during atrial cardiac muscle cell action potential",
                "definition": "The process in which ions are transported across a membrane such that the atrial cardiomyocyte membrane potential changes in the direction from the positive membrane potential at the peak of the action potential towards the negative resting potential. [GOC:dph, GOC:mtg_cardiac_conduct_nov11, GOC:tb]",
                "id": "GO:0098914"
            }, {
                "name": "positive regulation of G1/S transition of mitotic cell cycle",
                "definition": "Any cell cycle regulatory process that promotes the commitment of a cell from G1 to S phase of the mitotic cell cycle. [GOC:mtg_cell_cycle]",
                "id": "GO:1900087"
            }, {
                "name": "positive regulation of myoblast proliferation",
                "definition": "Any process that activates or increases the frequency, rate or extent of myoblast proliferation. [GOC:BHF]",
                "id": "GO:2000288"
            }],
            "cellular_component": [{
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "caveola",
                "definition": "A membrane raft that forms small pit, depression, or invagination that communicates with the outside of a cell and extends inward, indenting the cytoplasm and the cell membrane. Examples include flask-shaped invaginations of the plasma membrane in adipocytes associated with caveolin proteins, and minute pits or incuppings of the cell membrane formed during pinocytosis.  Caveolae may be pinched off to form free vesicles within the cytoplasm. [GOC:mah, ISBN:0721662544, PMID:16645198]",
                "id": "GO:0005901"
            }, {
                "name": "voltage-gated potassium channel complex",
                "definition": "A protein complex that forms a transmembrane channel through which potassium ions may cross a cell membrane in response to changes in membrane potential. [GOC:mah]",
                "id": "GO:0008076"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "intercalated disc",
                "definition": "A complex cell-cell junction at which myofibrils terminate in cardiomyocytes; mediates mechanical and electrochemical integration between individual cardiomyocytes. The intercalated disc contains regions of tight mechanical attachment (fasciae adherentes and desmosomes) and electrical coupling (gap junctions) between adjacent cells. [GOC:mtg_muscle, PMID:11732910]",
                "id": "GO:0014704"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "Z disc",
                "definition": "Platelike region of a muscle sarcomere to which the plus ends of actin filaments are attached. [GOC:mtg_muscle, ISBN:0815316194]",
                "id": "GO:0030018"
            }, {
                "name": "potassium channel complex",
                "definition": "An ion channel complex through which potassium ions pass. [GOC:mah]",
                "id": "GO:0034705"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }, {
                "name": "intracellular canaliculus",
                "definition": "An apical plasma membrane part that forms a narrow enfolded luminal membrane channel, lined with numerous microvilli, that appears to extend into the cytoplasm of the cell. A specialized network of intracellular canaliculi is a characteristic feature of parietal cells of the gastric mucosa in vertebrates. [GOC:mah, ISBN:0721662544, PMID:10700045]",
                "id": "GO:0046691"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }],
            "molecular_function": [{
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "voltage-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a voltage-gated channel. An ion is an atom or group of atoms carrying an electric charge by virtue of having gained or lost one or more electrons. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0198506732, ISBN:0815340729]",
                "id": "GO:0005244"
            }, {
                "name": "voltage-gated potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by a voltage-gated channel. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0815340729]",
                "id": "GO:0005249"
            }, {
                "name": "delayed rectifier potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by a delayed rectifying voltage-gated channel. A delayed rectifying current-voltage relation is one where channel activation kinetics are time-dependent, and inactivation is slow. [GOC:mah, PMID:11343411, PMID:2462513]",
                "id": "GO:0005251"
            }, {
                "name": "potassium channel activity",
                "definition": "Enables the facilitated diffusion of a potassium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:BHF, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005267"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "outward rectifier potassium channel activity",
                "definition": "Enables the transmembrane transfer of a potassium ion by an outwardly-rectifying voltage-gated channel. An outwardly rectifying current-voltage relation is one where at any given driving force the outward flow of K+ ions exceeds the inward flow for the opposite driving force. [GOC:mah]",
                "id": "GO:0015271"
            }, {
                "name": "potassium channel inhibitor activity",
                "definition": "Stops, prevents, or reduces the activity of a potassium channel. [GOC:mah]",
                "id": "GO:0019870"
            }, {
                "name": "protein kinase binding",
                "definition": "Interacting selectively and non-covalently with a protein kinase, any enzyme that catalyzes the transfer of a phosphate group, usually from ATP, to a protein substrate. [GOC:jl]",
                "id": "GO:0019901"
            }, {
                "name": "alpha-actinin binding",
                "definition": "Interacting selectively and non-covalently with alpha-actinin, one of a family of proteins that cross-link F-actin as antiparallel homodimers. Alpha-actinin has a molecular mass of 93-103 KDa; at the N-terminus there are two calponin homology domains, at the C-terminus there are two EF-hands. These two domains are connected by the rod domain. This domain is formed by triple-helical spectrin repeats. [PMID:10984498, PMID:11699871, PMID:15014165]",
                "id": "GO:0051393"
            }, {
                "name": "voltage-gated potassium channel activity involved in bundle of His cell action potential repolarization",
                "definition": "Enables the transmembrane transfer of a potassium ion by a voltage-gated channel through the plasma membrane of a bundle of His cell contributing to the repolarization phase of an action potential. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086087"
            }, {
                "name": "voltage-gated potassium channel activity involved in atrial cardiac muscle cell action potential repolarization",
                "definition": "Enables the transmembrane transfer of a potassium ion by a voltage-gated channel through the plasma membrane of an atrial cardiomyocyte contributing to the repolarization phase of an action potential. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086089"
            }, {
                "name": "voltage-gated potassium channel activity involved in SA node cell action potential repolarization",
                "definition": "Enables the transmembrane transfer of a potassium ion by a voltage-gated channel through the plasma membrane of an SA node cell contributing to the repolarization phase of an action potential. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086090"
            }, {
                "name": "scaffold protein binding",
                "definition": "Interacting selectively and non-covalently with a scaffold protein. Scaffold proteins are crucial regulators of many key signaling pathways. Although not strictly defined in function, they are known to interact and/or bind with multiple members of a signaling pathway, tethering them into complexes. [GOC:BHF, GOC:sjp, PMID:10433269, Wikipedia:Scaffold_protein]",
                "id": "GO:0097110"
            }]
        },
        "kegg_pathway": {}
    }, "CACNA1D": {
        "name": "calcium voltage-gated channel subunit alpha1 D",
        "description": "Voltage-dependent calcium channels mediate the entry of calcium ions into excitable cells, and are also involved in a variety of calcium-dependent processes, including muscle contraction, hormone or neurotransmitter release, and gene expression. Calcium channels are multisubunit complexes composed of alpha-1, beta, alpha-2/delta, and gamma subunits. The channel activity is directed by the pore-forming alpha-1 subunit, whereas the others act as auxiliary subunits regulating this activity. The distinctive properties of the calcium channel types are related primarily to the expression of a variety of alpha-1 isoforms, namely alpha-1A, B, C, D, E, and S. This gene encodes the alpha-1D subunit. Several transcript variants encoding different isoforms have been found for this gene. [provided by RefSeq, Dec 2012].",
        "entrez": "776",
        "ensembl": "ENSG00000157388",
        "uniprot": "Q01668",
        "hgnc": "1391",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "calcium ion transport",
                "definition": "The directed movement of calcium (Ca) ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006816"
            }, {
                "name": "adenylate cyclase-modulating G-protein coupled receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a G-protein coupled receptor binding to its physiological ligand, where the pathway proceeds through activation or inhibition of adenylyl cyclase activity and a subsequent change in the concentration of cyclic AMP (cAMP). [GOC:mah, GOC:signaling, ISBN:0815316194]",
                "id": "GO:0007188"
            }, {
                "name": "sensory perception of sound",
                "definition": "The series of events required for an organism to receive an auditory stimulus, convert it to a molecular signal, and recognize and characterize the signal. Sonic stimuli are detected in the form of vibrations and are processed to form a sound. [GOC:ai]",
                "id": "GO:0007605"
            }, {
                "name": "regulation of ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of ions from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0034765"
            }, {
                "name": "regulation of insulin secretion",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of insulin. [GOC:ai]",
                "id": "GO:0050796"
            }, {
                "name": "positive regulation of calcium ion transport",
                "definition": "Any process that activates or increases the frequency, rate or extent of the directed movement of calcium ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0051928"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "regulation of atrial cardiac muscle cell membrane repolarization",
                "definition": "Any process that modulates the establishment or extent of a membrane potential in the polarizing direction towards the resting potential in an atrial cardiomyocyte. [GOC:dph, GOC:tb]",
                "id": "GO:0060372"
            }, {
                "name": "cardiac conduction",
                "definition": "Transfer of an organized electrical impulse across the heart to coordinate the contraction of cardiac muscles. The process begins with generation of an action potential (in the sinoatrial node (SA) in humans) and ends with a change in the rate, frequency, or extent of the contraction of the heart muscles. [GOC:dph]",
                "id": "GO:0061337"
            }, {
                "name": "calcium ion import",
                "definition": "The directed movement of calcium ions into a cell or organelle. [GOC:mah]",
                "id": "GO:0070509"
            }, {
                "name": "calcium ion transmembrane transport",
                "definition": "A process in which a calcium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:mah]",
                "id": "GO:0070588"
            }, {
                "name": "cardiac muscle cell action potential involved in contraction",
                "definition": "An action potential that occurs in a cardiac muscle cell and is involved in its contraction. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086002"
            }, {
                "name": "membrane depolarization during cardiac muscle cell action potential",
                "definition": "The process in which cardiac muscle cell membrane potential changes in the depolarizing direction from the negative resting potential towards the positive membrane potential that will be the peak of the action potential. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086012"
            }, {
                "name": "membrane depolarization during SA node cell action potential",
                "definition": "The process in which SA node cardiac muscle cell membrane potential changes in the depolarizing direction from the negative resting potential towards the positive membrane potential that will be the peak of the action potential. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086046"
            }, {
                "name": "regulation of heart rate by cardiac conduction",
                "definition": "A cardiac conduction process that modulates the frequency or rate of heart contraction. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086091"
            }, {
                "name": "regulation of potassium ion transmembrane transporter activity",
                "definition": "Any process that modulates the frequency, rate or extent of potassium ion transmembrane transporter activity. [GOC:BHF, GOC:TermGenie]",
                "id": "GO:1901016"
            }, {
                "name": "regulation of potassium ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of potassium ion transmembrane transport. [GOC:BHF, GOC:TermGenie]",
                "id": "GO:1901379"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "voltage-gated calcium channel complex",
                "definition": "A protein complex that forms a transmembrane channel through which calcium ions may pass in response to changes in membrane potential. [GOC:mah]",
                "id": "GO:0005891"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "Z disc",
                "definition": "Platelike region of a muscle sarcomere to which the plus ends of actin filaments are attached. [GOC:mtg_muscle, ISBN:0815316194]",
                "id": "GO:0030018"
            }, {
                "name": "L-type voltage-gated calcium channel complex",
                "definition": "A type of voltage-dependent calcium channel responsible for excitation-contraction coupling of skeletal, smooth, and cardiac muscle. 'L' stands for 'long-lasting' referring to the length of activation. [GOC:ame, PMID:12946355]",
                "id": "GO:1990454"
            }],
            "molecular_function": [{
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "voltage-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a voltage-gated channel. An ion is an atom or group of atoms carrying an electric charge by virtue of having gained or lost one or more electrons. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0198506732, ISBN:0815340729]",
                "id": "GO:0005244"
            }, {
                "name": "voltage-gated calcium channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion by a voltage-gated channel. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, GOC:tb, ISBN:0815340729]",
                "id": "GO:0005245"
            }, {
                "name": "calcium channel activity",
                "definition": "Enables the facilitated diffusion of a calcium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005262"
            }, {
                "name": "high voltage-gated calcium channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion by a high voltage-gated channel. A high voltage-gated channel is a channel whose open state is dependent on high voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0815340729, PMID:16382099]",
                "id": "GO:0008331"
            }, {
                "name": "ankyrin binding",
                "definition": "Interacting selectively and non-covalently with ankyrin, a 200 kDa cytoskeletal protein that attaches other cytoskeletal proteins to integral membrane proteins. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0030506"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "alpha-actinin binding",
                "definition": "Interacting selectively and non-covalently with alpha-actinin, one of a family of proteins that cross-link F-actin as antiparallel homodimers. Alpha-actinin has a molecular mass of 93-103 KDa; at the N-terminus there are two calponin homology domains, at the C-terminus there are two EF-hands. These two domains are connected by the rod domain. This domain is formed by triple-helical spectrin repeats. [PMID:10984498, PMID:11699871, PMID:15014165]",
                "id": "GO:0051393"
            }, {
                "name": "voltage-gated calcium channel activity involved in cardiac muscle cell action potential",
                "definition": "Enables the transmembrane transfer of a calcium ion by a voltage-gated channel across the plasma membrane of a cardiac muscle cell that contributes to the depolarization phase of an action potential. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086007"
            }, {
                "name": "voltage-gated calcium channel activity involved SA node cell action potential",
                "definition": "Enables the transmembrane transfer of a calcium ion by a voltage-gated channel across the plasma membrane of an SA node cardiac muscle cell that contributes to the depolarization phase of an action potential. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086059"
            }]
        },
        "kegg_pathway": {}
    }, "GPR85": {
        "name": "G protein-coupled receptor 85",
        "description": "Members of the G protein-coupled receptor (GPCR) family, such as GPR85, have a similar structure characterized by 7 transmembrane domains. Activation of GPCRs by extracellular stimuli, such as neurotransmitters, hormones, or light, induces an intracellular signaling cascade mediated by heterotrimeric GTP-binding proteins, or G proteins (Matsumoto et al., 2000 [PubMed 10833454]).[supplied by OMIM, Aug 2008].",
        "entrez": "54329",
        "ensembl": "ENSG00000164604",
        "uniprot": "P60893",
        "hgnc": "4536",
        "go_terms": {
            "biological_process": [{
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }],
            "cellular_component": [{
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }]
        },
        "kegg_pathway": {}
    }, "PRKACA": {
        "name": "protein kinase cAMP-activated catalytic subunit alpha",
        "description": "This gene encodes one of the catalytic subunits of protein kinase A, which exists as a tetrameric holoenzyme with two regulatory subunits and two catalytic subunits, in its inactive form. cAMP causes the dissociation of the inactive holoenzyme into a dimer of regulatory subunits bound to four cAMP and two free monomeric catalytic subunits. Four different regulatory subunits and three catalytic subunits have been identified in humans. cAMP-dependent phosphorylation of proteins by protein kinase A is important to many cellular processes, including differentiation, proliferation, and apoptosis. Constitutive activation of this gene caused either by somatic mutations, or genomic duplications of regions that include this gene, have been associated with hyperplasias and adenomas of the adrenal cortex and are linked to corticotropin-independent Cushing's syndrome. Alternative splicing results in multiple transcript variants encoding different isoforms. Tissue-specific isoforms that differ at the N-terminus have been described, and these isoforms may differ in the post-translational modifications that occur at the N-terminus of some isoforms. [provided by RefSeq, Jan 2015].",
        "entrez": "5566",
        "ensembl": "ENSG00000072062",
        "uniprot": "P17612",
        "hgnc": "9380",
        "go_terms": {
            "biological_process": [{
                "name": "G2/M transition of mitotic cell cycle",
                "definition": "The mitotic cell cycle transition by which a cell in G2 commits to M phase. The process begins when the kinase activity of M cyclin/CDK complex reaches a threshold high enough for the cell cycle to proceed. This is accomplished by activating a positive feedback loop that results in the accumulation of unphosphorylated and active M cyclin/CDK complex. [GOC:mtg_cell_cycle]",
                "id": "GO:0000086"
            }, {
                "name": "mesoderm formation",
                "definition": "The process that gives rise to the mesoderm. This process pertains to the initial formation of the structure from unspecified parts. [GOC:go_curators]",
                "id": "GO:0001707"
            }, {
                "name": "neural tube closure",
                "definition": "The last step in the formation of the neural tube, where the paired neural folds are brought together and fuse at the dorsal midline. [GOC:dph, ISBN:0878932437]",
                "id": "GO:0001843"
            }, {
                "name": "regulation of heart rate",
                "definition": "Any process that modulates the frequency or rate of heart contraction. [GOC:dph, GOC:tb, PMID:10358008]",
                "id": "GO:0002027"
            }, {
                "name": "stimulatory C-type lectin receptor signaling pathway",
                "definition": "Any series of molecular signals generated as a consequence of binding to a C-type lectin receptor capable of cellular activation. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002223"
            }, {
                "name": "renal water homeostasis",
                "definition": "Renal process involved in the maintenance of an internal steady state of water in the body. [GOC:mtg_cardio]",
                "id": "GO:0003091"
            }, {
                "name": "mRNA processing",
                "definition": "Any process involved in the conversion of a primary mRNA transcript into one or more mature mRNA(s) prior to translation into polypeptide. [GOC:mah]",
                "id": "GO:0006397"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "blood coagulation",
                "definition": "The sequential process in which the multiple coagulation factors of the blood interact, ultimately resulting in the formation of an insoluble fibrin clot; it may be divided into three stages: stage 1, the formation of intrinsic and extrinsic prothrombin converting principle; stage 2, the formation of thrombin; stage 3, the formation of stable fibrin polymers. [http://www.graylab.ac.uk/omd/, ISBN:0198506732]",
                "id": "GO:0007596"
            }, {
                "name": "regulation of G2/M transition of mitotic cell cycle",
                "definition": "Any process that modulates the rate or extent of progression from G2 phase to M phase of the mitotic cell cycle. [GOC:mtg_cell_cycle, PMID:17329565]",
                "id": "GO:0010389"
            }, {
                "name": "regulation of cardiac muscle contraction by regulation of the release of sequestered calcium ion",
                "definition": "Any process that modulates the frequency, rate or extent of cardiac muscle contraction via the regulation of the release of sequestered calcium ion by sarcoplasmic reticulum into cytosol. The sarcoplasmic reticulum is the endoplasmic reticulum of striated muscle, specialised for the sequestration of calcium ions that are released upon receipt of a signal relayed by the T tubules from the neuromuscular junction. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010881"
            }, {
                "name": "regulation of macroautophagy",
                "definition": "Any process that modulates the frequency, rate or extent of macroautophagy. [GOC:krc]",
                "id": "GO:0016241"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "cytokine-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a cytokine to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:19295629]",
                "id": "GO:0019221"
            }, {
                "name": "activation of protein kinase A activity",
                "definition": "Any process that initiates the activity of the inactive enzyme protein kinase A. [GOC:pde]",
                "id": "GO:0034199"
            }, {
                "name": "high-density lipoprotein particle assembly",
                "definition": "The non-covalent aggregation and arrangement of proteins and lipids to form a high-density lipoprotein particle. [GOC:BHF, GOC:mah]",
                "id": "GO:0034380"
            }, {
                "name": "cellular response to heat",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a heat stimulus, a temperature stimulus above the optimal temperature for that organism. [GOC:mah]",
                "id": "GO:0034605"
            }, {
                "name": "calcium-mediated signaling using intracellular calcium source",
                "definition": "A series of molecular signals in which a cell uses calcium ions released from an intracellular store to convert a signal into a response. [GOC:bf, GOC:BHF, PMID:20192754]",
                "id": "GO:0035584"
            }, {
                "name": "regulation of protein binding",
                "definition": "Any process that modulates the frequency, rate or extent of protein binding. [GOC:go_curators]",
                "id": "GO:0043393"
            }, {
                "name": "regulation of osteoblast differentiation",
                "definition": "Any process that modulates the frequency, rate or extent of osteoblast differentiation. [GOC:go_curators]",
                "id": "GO:0045667"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "positive regulation of protein export from nucleus",
                "definition": "Any process that activates or increases the frequency, rate or extent of directed movement of proteins from the nucleus into the cytoplasm. [GOC:bf]",
                "id": "GO:0046827"
            }, {
                "name": "sperm capacitation",
                "definition": "A process required for sperm to reach fertilization competence. Sperm undergo an incompletely understood series of morphological and molecular maturational processes, termed capacitation, involving, among other processes, protein tyrosine phosphorylation and increased intracellular calcium. [GOC:jid, ISBN:978-3-642-58301-8, PMID:11820818]",
                "id": "GO:0048240"
            }, {
                "name": "modulation of chemical synaptic transmission",
                "definition": "Any process that modulates the frequency or amplitude of synaptic transmission, the process of communication from a neuron to a target (neuron, muscle, or secretory cell) across a synapse.  Amplitude, in this case, refers to the change in postsynaptic membrane potential due to a single instance of synaptic transmission. [GOC:ai]",
                "id": "GO:0050804"
            }, {
                "name": "regulation of cytosolic calcium ion concentration",
                "definition": "Any process involved in the maintenance of an internal steady state of calcium ions within the cytosol of a cell or between the cytosol and its surroundings. [GOC:ai, GOC:mah, GOC:rph]",
                "id": "GO:0051480"
            }, {
                "name": "regulation of cardiac muscle contraction",
                "definition": "Any process that modulates the frequency, rate or extent of cardiac muscle contraction. [GOC:ecd]",
                "id": "GO:0055117"
            }, {
                "name": "regulation of ryanodine-sensitive calcium-release channel activity",
                "definition": "Any process that modulates the activity of a ryanodine-sensitive calcium-release channel. The ryanodine-sensitive calcium-release channel catalyzes the transmembrane transfer of a calcium ion by a channel that opens when a ryanodine class ligand has been bound by the channel complex or one of its constituent parts. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0060314"
            }, {
                "name": "regulation of proteasomal protein catabolic process",
                "definition": "Any process that modulates the rate, frequency, or extent of the chemical reactions and pathways resulting in the breakdown of a protein or peptide by hydrolysis of its peptide bonds that is mediated by the proteasome. [GOC:dph, GOC:tb]",
                "id": "GO:0061136"
            }, {
                "name": "regulation of protein processing",
                "definition": "Any process that modulates the frequency, rate or extent of protein processing, any protein maturation process achieved by the cleavage of a peptide bond or bonds within a protein. [GOC:mah]",
                "id": "GO:0070613"
            }, {
                "name": "positive regulation of cell cycle arrest",
                "definition": "Any process that increases the rate, frequency, or extent of cell cycle arrest, the process in which the cell cycle is halted during one of the normal phases. [GOC:mah]",
                "id": "GO:0071158"
            }, {
                "name": "cellular response to glucose stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a glucose stimulus. [GOC:mah]",
                "id": "GO:0071333"
            }, {
                "name": "cellular response to parathyroid hormone stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a parathyroid hormone stimulus. [GOC:mah]",
                "id": "GO:0071374"
            }, {
                "name": "cellular response to glucagon stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a glucagon stimulus. [GOC:mah]",
                "id": "GO:0071377"
            }, {
                "name": "cellular response to epinephrine stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an epinephrine stimulus. Epinephrine is a catecholamine that has the formula C9H13NO3; it is secreted by the adrenal medulla to act as a hormone, and released by certain neurons to act as a neurotransmitter active in the central nervous system. [CHEBI:33568, GOC:BHF, GOC:mah]",
                "id": "GO:0071872"
            }, {
                "name": "cell communication by electrical coupling involved in cardiac conduction",
                "definition": "The process that mediates signaling interactions between one cell and another cell by transfer of current between their adjacent cytoplasms via intercellular protein channels and contributes to the process of cardiac conduction. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086064"
            }, {
                "name": "ciliary basal body-plasma membrane docking",
                "definition": "The docking of a cytosolic centriole/basal body to the plasma membrane via the ciliary transition fibers. In some species this may happen via an intermediate step, by first docking to the ciliary vesicle via the ciliary transition fibers. The basal body-ciliary vesicle then relocates to the plasma membrane, followed by the ciliary vesicle fusing with the plasma membrane, effectively attaching the basal body to the plasma membrane. [GOC:cilia, PMID:13978319, PMID:23348840, PMID:23530209, PMID:25686250, PMID:26981235, Reactome:R-HSA-5620912.1]",
                "id": "GO:0097711"
            }, {
                "name": "negative regulation of smoothened signaling pathway involved in dorsal/ventral neural tube patterning",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of smoothened signaling pathway involved in dorsal/ventral neural tube patterning. [GOC:TermGenie]",
                "id": "GO:1901621"
            }, {
                "name": "regulation of cardiac conduction",
                "definition": "Any process that modulates the frequency, rate or extent of cardiac conduction. [GO_REF:0000058, GOC:BHF, GOC:mtg_cardiac_conduct_nov11, GOC:rph, GOC:TermGenie, PMID:12967627]",
                "id": "GO:1903779"
            }, {
                "name": "regulation of bicellular tight junction assembly",
                "definition": "Any process that modulates the frequency, rate or extent of tight junction assembly. [GOC:BHF]",
                "id": "GO:2000810"
            }],
            "cellular_component": [{
                "name": "acrosomal vesicle",
                "definition": "A structure in the head of a spermatozoon that contains acid hydrolases, and is concerned with the breakdown of the outer membrane of the ovum during fertilization. It lies just beneath the plasma membrane and is derived from the lysosome. [ISBN:0124325653, ISBN:0198506732]",
                "id": "GO:0001669"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "centrosome",
                "definition": "A structure comprised of a core structure (in most organisms, a pair of centrioles) and peripheral material from which a microtubule-based structure, such as a spindle apparatus, is organized. Centrosomes occur close to the nucleus during interphase in many eukaryotic cells, though in animal cells it changes continually during the cell-division cycle. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0005813"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "cilium",
                "definition": "A specialized eukaryotic organelle that consists of a filiform extrusion of the cell surface and of some cytoplasmic parts. Each cilium is largely bounded by an extrusion of the cytoplasmic (plasma) membrane, and contains a regular longitudinal array of microtubules, anchored to a basal body. [GOC:cilia, GOC:curators, GOC:kmv, GOC:vw, ISBN:0198547684, PMID:16824949, PMID:17009929, PMID:20144998]",
                "id": "GO:0005929"
            }, {
                "name": "axoneme",
                "definition": "The bundle of microtubules and associated proteins that forms the core of cilia (also called flagella) in eukaryotic cells and is responsible for their movements. [GOC:bf, GOC:cilia, ISBN:0198547684]",
                "id": "GO:0005930"
            }, {
                "name": "cAMP-dependent protein kinase complex",
                "definition": "An enzyme complex, composed of regulatory and catalytic subunits, that catalyzes protein phosphorylation. Inactive forms of the enzyme have two regulatory chains and two catalytic chains; activation by cAMP produces two active catalytic monomers and a regulatory dimer. [EC:2.7.11.11, ISBN:0198506732]",
                "id": "GO:0005952"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "nuclear speck",
                "definition": "A discrete extra-nucleolar subnuclear domain, 20-50 in number, in which splicing factors are seen to be localized by immunofluorescence microscopy. [http://www.cellnucleus.com/]",
                "id": "GO:0016607"
            }, {
                "name": "cytoplasmic vesicle",
                "definition": "A vesicle found in the cytoplasm of a cell. [GOC:ai, GOC:mah, GOC:vesicles]",
                "id": "GO:0031410"
            }, {
                "name": "motile cilium",
                "definition": "A cilium which may have a variable arrangement of axonemal microtubules and also contains molecular motors. It may beat with a whip-like pattern that promotes cell motility or transport of fluids and other cells across a cell surface, such as on epithelial cells that line the lumenal ducts of various tissues; or they may display a distinct twirling motion that directs fluid flow asymmetrically across the cellular surface to affect asymmetric body plan organization. Motile cilia can be found in single as well as multiple copies per cell. [GOC:cilia, GOC:dgh, GOC:kmv, PMID:17009929, PMID:20144998, PMID:22118931]",
                "id": "GO:0031514"
            }, {
                "name": "neuromuscular junction",
                "definition": "The junction between the axon of a motor neuron and a muscle fiber. In response to the arrival of action potentials, the presynaptic button releases molecules of neurotransmitters into the synaptic cleft. These diffuse across the cleft and transmit the signal to the postsynaptic membrane of the muscle fiber, leading to a change in post-synaptic potential. [GOC:nln]",
                "id": "GO:0031594"
            }, {
                "name": "calcium channel complex",
                "definition": "An ion channel complex through which calcium ions pass. [GOC:mah]",
                "id": "GO:0034704"
            }, {
                "name": "sperm flagellum",
                "definition": "A microtubule-based flagellum (or cilium) that is part of a sperm, a mature male germ cell that develops from a spermatid. [GOC:cilia, GOC:sart, PMID:8441407]",
                "id": "GO:0036126"
            }, {
                "name": "cell projection",
                "definition": "A prolongation or process extending from a cell, e.g. a flagellum or axon. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0042995"
            }, {
                "name": "dendritic spine",
                "definition": "A small, membranous protrusion from a dendrite that forms a postsynaptic compartment - typically receiving input from a single presynapse.  They function as partially isolated biochemical and an electrical compartments. Spine morphology is variable including \\thin\\\", \\\"stubby\\\", \\\"mushroom\\\", and \\\"branched\\\", with a continuum of intermediate morphologies. They typically terminate in a bulb shape, linked to the dendritic shaft by a restriction. Spine remodeling is though to be involved in synaptic plasticity.\" [GOC:nln]",
                "id": "GO:0043197"
            }, {
                "name": "plasma membrane raft",
                "definition": "A membrane raft that is part of the plasma membrane. [GOC:jl]",
                "id": "GO:0044853"
            }, {
                "name": "intercellular bridge",
                "definition": "A direct connection between the cytoplasm of two cells that is formed following the completion of cleavage furrow ingression during cell division. They are usually present only briefly prior to completion of cytokinesis. However, in some cases, such as the bridges between germ cells during their development, they become stabilised. [PMID:9635420]",
                "id": "GO:0045171"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }, {
                "name": "ciliary base",
                "definition": "Area of the cilium (also called flagellum) where the basal body and the axoneme are anchored to the plasma membrane. The ciliary base encompasses the distal part of the basal body, transition fibers and transition zone and is structurally and functionally very distinct from the rest of the cilium. In this area proteins are sorted and filtered before entering the cilium, and many ciliary proteins localize specifically to this area. [GOC:cilia, GOC:krc, PMID:22653444]",
                "id": "GO:0097546"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "cAMP-dependent protein kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein = ADP + a phosphoprotein. This reaction requires the presence of cAMP. [EC:2.7.11.11]",
                "id": "GO:0004691"
            }, {
                "name": "protein serine/threonine/tyrosine kinase activity",
                "definition": "Catalysis of the reactions: ATP + a protein serine = ADP + protein serine phosphate; ATP + a protein threonine = ADP + protein threonine phosphate; and ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [GOC:mah]",
                "id": "GO:0004712"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "protein kinase binding",
                "definition": "Interacting selectively and non-covalently with a protein kinase, any enzyme that catalyzes the transfer of a phosphate group, usually from ATP, to a protein substrate. [GOC:jl]",
                "id": "GO:0019901"
            }, {
                "name": "protein domain specific binding",
                "definition": "Interacting selectively and non-covalently with a specific domain of a protein. [GOC:go_curators]",
                "id": "GO:0019904"
            }, {
                "name": "manganese ion binding",
                "definition": "Interacting selectively and non-covalently with manganese (Mn) ions. [GOC:ai]",
                "id": "GO:0030145"
            }, {
                "name": "ubiquitin protein ligase binding",
                "definition": "Interacting selectively and non-covalently with a ubiquitin protein ligase enzyme, any of the E3 proteins. [GOC:vp]",
                "id": "GO:0031625"
            }, {
                "name": "protein kinase A regulatory subunit binding",
                "definition": "Interacting selectively and non-covalently with one or both of the regulatory subunits of protein kinase A. [GOC:mah]",
                "id": "GO:0034237"
            }]
        },
        "kegg_pathway": {}
    }, "HCAR2": {
        "name": "hydroxycarboxylic acid receptor 2",
        "description": "",
        "entrez": "338442",
        "ensembl": "ENSG00000182782",
        "uniprot": "Q8TDS4",
        "hgnc": "24827",
        "go_terms": {
            "biological_process": [{
                "name": "neutrophil apoptotic process",
                "definition": "Any apoptotic process in a neutrophil, any of the immature or mature forms of a granular leukocyte that in its mature form has a nucleus with three to five lobes connected by slender threads of chromatin, and cytoplasm containing fine inconspicuous granules and stainable by neutral dyes. [CL:0000775, GOC:add, GOC:mtg_apoptosis, PMID:12752675, PMID:12960266]",
                "id": "GO:0001781"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "positive regulation of neutrophil apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate, or extent of neutrophil apoptotic process. [GOC:add, GOC:mtg_apoptosis]",
                "id": "GO:0033031"
            }, {
                "name": "negative regulation of lipid catabolic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the chemical reactions and pathways resulting in the breakdown of lipids. [GOC:ai]",
                "id": "GO:0050995"
            }, {
                "name": "positive regulation of adiponectin secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of the regulated release of adiponectin from a cell. [GOC:BHF, GOC:mah]",
                "id": "GO:0070165"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "cell junction",
                "definition": "A cellular component that forms a specialized region of connection between two or more cells or between a cell and the extracellular matrix. At a cell junction, anchoring proteins extend through the plasma membrane to link cytoskeletal proteins in one cell to cytoskeletal proteins in neighboring cells or to proteins in the extracellular matrix. [GOC:mah, http://www.vivo.colostate.edu/hbooks/cmb/cells/pmemb/junctions_a.html, ISBN:0198506732]",
                "id": "GO:0030054"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "nicotinic acid receptor activity",
                "definition": "Combining with nicotinic acid to initiate a change in cell activity. [CHEBI:15940, GOC:mah, PMID:12522134]",
                "id": "GO:0070553"
            }]
        },
        "kegg_pathway": {}
    }, "PDE10A": {
        "name": "phosphodiesterase 10A",
        "description": "The protein encoded by this gene belongs to the cyclic nucleotide phosphodiesterase family. It plays a role in signal transduction by regulating the intracellular concentration of cyclic nucleotides. This protein can hydrolyze both cAMP and cGMP to the corresponding nucleoside 5' monophosphate, but has higher affinity for cAMP, and is more efficient with cAMP as substrate. Alternatively spliced transcript variants have been described for this gene. [provided by RefSeq, Dec 2011].",
        "entrez": "10846",
        "ensembl": "ENSG00000112541",
        "uniprot": "Q9Y233",
        "hgnc": "8772",
        "go_terms": {
            "biological_process": [{
                "name": "cAMP catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of the nucleotide cAMP (cyclic AMP, adenosine 3',5'-cyclophosphate). [ISBN:0198506732]",
                "id": "GO:0006198"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "metabolic process",
                "definition": "The chemical reactions and pathways, including anabolism and catabolism, by which living organisms transform chemical substances. Metabolic processes typically transform small molecules, but also include macromolecular processes such as DNA repair and replication, and protein synthesis and degradation. [GOC:go_curators, ISBN:0198547684]",
                "id": "GO:0008152"
            }, {
                "name": "regulation of protein kinase A signaling",
                "definition": "Any process that modulates the rate, frequency, or extent of protein kinase A signaling. PKA signaling is the series of reactions, mediated by the intracellular serine/threonine kinase protein kinase A, which occurs as a result of a single trigger reaction or compound. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010738"
            }, {
                "name": "regulation of cAMP-mediated signaling",
                "definition": "Any process which modulates the frequency, rate or extent of cAMP-mediated signaling, a series of molecular signals in which a cell uses cyclic AMP to convert an extracellular signal into a response. [GOC:jl]",
                "id": "GO:0043949"
            }, {
                "name": "cGMP catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of cyclic GMP, guanosine 3',5'-phosphate. [GOC:go_curators]",
                "id": "GO:0046069"
            }],
            "cellular_component": [{
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "neuronal cell body",
                "definition": "The portion of a neuron that includes the nucleus, but excludes cell projections such as axons and dendrites. [GOC:go_curators]",
                "id": "GO:0043025"
            }, {
                "name": "perikaryon",
                "definition": "The portion of the cell soma (neuronal cell body) that excludes the nucleus. [GOC:jl]",
                "id": "GO:0043204"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "catalytic activity",
                "definition": "Catalysis of a biochemical reaction at physiological temperatures. In biologically catalyzed reactions, the reactants are known as substrates, and the catalysts are naturally occurring macromolecular substances known as enzymes. Enzymes possess specific binding sites for substrates, and are usually composed wholly or largely of protein, but RNA that has catalytic activity (ribozyme) is often also regarded as enzymatic. [GOC:vw, ISBN:0198506732]",
                "id": "GO:0003824"
            }, {
                "name": "cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: a nucleoside cyclic phosphate + H2O = a nucleoside phosphate. [GOC:mah]",
                "id": "GO:0004112"
            }, {
                "name": "3',5'-cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: nucleoside 3',5'-cyclic phosphate + H2O = nucleoside 5'-phosphate. [EC:3.1.4.17]",
                "id": "GO:0004114"
            }, {
                "name": "3',5'-cyclic-AMP phosphodiesterase activity",
                "definition": "Catalysis of the reaction: adenosine 3',5'-cyclic phosphate + H2O = adenosine 5'-phosphate. [GOC:ai]",
                "id": "GO:0004115"
            }, {
                "name": "cGMP-stimulated cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: nucleoside 3',5'-cyclic phosphate + H2O = nucleoside 5'-phosphate; catalytic activity is increased in the presence of cGMP. [GOC:mah]",
                "id": "GO:0004118"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "phosphoric diester hydrolase activity",
                "definition": "Catalysis of the hydrolysis of a phosphodiester to give a phosphomonoester and a free hydroxyl group. [EC:3.1.4, GOC:curators]",
                "id": "GO:0008081"
            }, {
                "name": "drug binding",
                "definition": "Interacting selectively and non-covalently with a drug, any naturally occurring or synthetic substance, other than a nutrient, that, when administered or applied to an organism, affects the structure or functioning of the organism; in particular, any such substance used in the diagnosis, prevention, or treatment of disease. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0008144"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "cAMP binding",
                "definition": "Interacting selectively and non-covalently with cAMP, the nucleotide cyclic AMP (adenosine 3',5'-cyclophosphate). [GOC:ai]",
                "id": "GO:0030552"
            }, {
                "name": "cGMP binding",
                "definition": "Interacting selectively and non-covalently with cGMP, the nucleotide cyclic GMP (guanosine 3',5'-cyclophosphate). [GOC:ai]",
                "id": "GO:0030553"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "3',5'-cyclic-GMP phosphodiesterase activity",
                "definition": "Catalysis of the reaction: guanosine 3',5'-cyclic phosphate + H2O = guanosine 5'-phosphate. [EC:3.1.4.35, MetaCyc:35-CYCLIC-GMP-PHOSPHODIESTERASE-RXN]",
                "id": "GO:0047555"
            }]
        },
        "kegg_pathway": {}
    }, "ASIC1": {
        "name": "acid sensing ion channel subunit 1",
        "description": "This gene encodes a member of the acid-sensing ion channel (ASIC) family of proteins, which are part of the degenerin/epithelial sodium channel (DEG/ENaC) superfamily. Members of the ASIC family are sensitive to amiloride and function in neurotransmission. The encoded proteins function in learning, pain transduction, touch sensation, and development of memory and fear. Alternatively spliced transcript variants have been described. [provided by RefSeq, Feb 2012].",
        "entrez": "41",
        "ensembl": "ENSG00000110881",
        "uniprot": "P78348",
        "hgnc": "100",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "cation transport",
                "definition": "The directed movement of cations, atoms or small molecules with a net positive charge, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006812"
            }, {
                "name": "sodium ion transport",
                "definition": "The directed movement of sodium ions (Na+) into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006814"
            }, {
                "name": "calcium ion transport",
                "definition": "The directed movement of calcium (Ca) ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006816"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "memory",
                "definition": "The activities involved in the mental information processing system that receives (registers), modifies, stores, and retrieves informational stimuli. The main stages involved in the formation and retrieval of memory are encoding (processing of received information by acquisition), storage (building a permanent record of received information as a result of consolidation) and retrieval (calling back the stored information and use it in a suitable way to execute a given task). [GOC:curators, http://www.onelook.com/, ISBN:0582227089]",
                "id": "GO:0007613"
            }, {
                "name": "associative learning",
                "definition": "Learning by associating a stimulus (the cause) with a particular outcome (the effect). [ISBN:0582227089]",
                "id": "GO:0008306"
            }, {
                "name": "response to pH",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a pH stimulus. pH is a measure of the acidity or basicity of an aqueous solution. [GOC:jl, http://en.wikipedia.org/wiki/PH]",
                "id": "GO:0009268"
            }, {
                "name": "response to acidic pH",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a pH stimulus with pH < 7. pH is a measure of the acidity or basicity of an aqueous solution. [GOC:go_curators, GOC:tb, http://en.wikipedia.org/wiki/PH]",
                "id": "GO:0010447"
            }, {
                "name": "ion transmembrane transport",
                "definition": "A process in which an ion is transported across a membrane. [GOC:mah]",
                "id": "GO:0034220"
            }, {
                "name": "sodium ion transmembrane transport",
                "definition": "A process in which a sodium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:vw]",
                "id": "GO:0035725"
            }, {
                "name": "regulation of membrane potential",
                "definition": "Any process that modulates the establishment or extent of a membrane potential, the electric potential existing across any membrane arising from charges in the membrane itself and from the charges present in the media on either side of the membrane. [GOC:jl, GOC:mtg_cardio, GOC:tb, ISBN:0198506732]",
                "id": "GO:0042391"
            }, {
                "name": "negative regulation of neurotransmitter secretion",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the regulated release of a neurotransmitter. [GOC:ai]",
                "id": "GO:0046929"
            }, {
                "name": "sensory perception of sour taste",
                "definition": "The series of events required to receive a sour taste stimulus, convert it to a molecular signal, and recognize and characterize the signal. This is a neurological process. [GOC:ai]",
                "id": "GO:0050915"
            }, {
                "name": "protein homotrimerization",
                "definition": "The formation of a protein homotrimer, a macromolecular structure consisting of three noncovalently associated identical subunits. [GOC:hjd]",
                "id": "GO:0070207"
            }, {
                "name": "calcium ion transmembrane transport",
                "definition": "A process in which a calcium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:mah]",
                "id": "GO:0070588"
            }, {
                "name": "cellular response to pH",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a pH stimulus. pH is a measure of the acidity or basicity of an aqueous solution. [GOC:mah, http://en.wikipedia.org/wiki/PH]",
                "id": "GO:0071467"
            }],
            "cellular_component": [{
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "synapse",
                "definition": "The junction between a nerve fiber of one neuron and another neuron, muscle fiber or glial cell. As the nerve fiber approaches the synapse it enlarges into a specialized structure, the presynaptic nerve ending, which contains mitochondria and synaptic vesicles. At the tip of the nerve ending is the presynaptic membrane; facing it, and separated from it by a minute cleft (the synaptic cleft) is a specialized area of membrane on the receiving cell, known as the postsynaptic membrane. In response to the arrival of nerve impulses, the presynaptic nerve ending secretes molecules of neurotransmitters into the synaptic cleft. These diffuse across the cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045202"
            }],
            "molecular_function": [{
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "cation channel activity",
                "definition": "Enables the energy-independent passage of cations across a lipid bilayer down a concentration gradient. [GOC:def, GOC:dph, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005261"
            }, {
                "name": "sodium channel activity",
                "definition": "Enables the facilitated diffusion of a sodium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:BHF, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005272"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ligand-gated sodium channel activity",
                "definition": "Enables the transmembrane transfer of a sodium ion by a channel that opens when a specific ligand has been bound by the channel complex or one of its constituent parts. [GOC:mah]",
                "id": "GO:0015280"
            }, {
                "name": "ion gated channel activity",
                "definition": "Enables the transmembrane transfer of a solute by a channel that opens in response to a specific ion stimulus. [GOC:mtg_transport]",
                "id": "GO:0022839"
            }, {
                "name": "acid-sensing ion channel activity",
                "definition": "Enables the transmembrane transfer of a sodium ion by a neuronal, voltage-insensitive channel that opens when an extracellular proton has been bound by the channel complex. [GOC:jl]",
                "id": "GO:0044736"
            }]
        },
        "kegg_pathway": {}
    }, "GRM3": {
        "name": "glutamate metabotropic receptor 3",
        "description": "L-glutamate is the major excitatory neurotransmitter in the central nervous system and activates both ionotropic and metabotropic glutamate receptors. Glutamatergic neurotransmission is involved in most aspects of normal brain function and can be perturbed in many neuropathologic conditions. The metabotropic glutamate receptors are a family of G protein-coupled receptors, that have been divided into 3 groups on the basis of sequence homology, putative signal transduction mechanisms, and pharmacologic properties. Group I includes GRM1 and GRM5 and these receptors have been shown to activate phospholipase C. Group II includes GRM2 and GRM3 while Group III includes GRM4, GRM6, GRM7 and GRM8. Group II and III receptors are linked to the inhibition of the cyclic AMP cascade but differ in their agonist selectivities. [provided by RefSeq, Jul 2008].",
        "entrez": "2913",
        "ensembl": "ENSG00000198822",
        "uniprot": "Q14832",
        "hgnc": "4595",
        "go_terms": {
            "biological_process": [{
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "negative regulation of adenylate cyclase activity",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of adenylate cyclase activity. [GOC:go_curators]",
                "id": "GO:0007194"
            }, {
                "name": "adenylate cyclase-inhibiting G-protein coupled glutamate receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a G-protein coupled glutamate receptor binding to its physiological ligand, where the pathway proceeds with inhibition of adenylyl cyclase and a subsequent decrease in the concentration of cyclic AMP (cAMP). [GOC:dph, GOC:mah, GOC:signaling, GOC:tb]",
                "id": "GO:0007196"
            }, {
                "name": "G-protein coupled glutamate receptor signaling pathway",
                "definition": "A series of molecular signals initiated by glutamate binding to a glutamate receptor on the surface of the target cell, and proceeding with the activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. Ends with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:9131252]",
                "id": "GO:0007216"
            }, {
                "name": "chemical synaptic transmission",
                "definition": "The vesicular release of classical neurotransmitter molecules from a presynapse, across a chemical synapse, the subsequent activation of neurotransmitter receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:jl, MeSH:D009435]",
                "id": "GO:0007268"
            }, {
                "name": "regulation of synaptic transmission, glutamatergic",
                "definition": "Any process that modulates the frequency, rate or extent of glutamatergic synaptic transmission, the process of communication from a neuron to another neuron across a synapse using the neurotransmitter glutamate. [GOC:ai]",
                "id": "GO:0051966"
            }, {
                "name": "regulation of molecular function",
                "definition": "Any process that modulates the frequency, rate or extent of a molecular function, an elemental biological activity occurring at the molecular level, such as catalysis or binding. [GOC:isa_complete]",
                "id": "GO:0065009"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "postsynaptic density",
                "definition": "An electron dense network of proteins within and adjacent to the postsynaptic membrane of an asymetric, neuron-neuron synapse. Its major components include neurotransmitter receptors and the proteins that spatially and functionally organize them such as anchoring and scaffolding molecules, signaling enzymes and cytoskeletal components. [GOC:BHF, GOC:dos, GOC:ef, GOC:jid, GOC:pr, GOC:sjp, http://molneuro.kaist.ac.kr/psd, PMID:14532281, Wikipedia:Postsynaptic_density]",
                "id": "GO:0014069"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "axon",
                "definition": "The long process of a neuron that conducts nerve impulses, usually away from the cell body to the terminals and varicosities, which are sites of storage and release of neurotransmitter. [GOC:nln, ISBN:0198506732]",
                "id": "GO:0030424"
            }, {
                "name": "presynaptic membrane",
                "definition": "A specialized area of membrane of the axon terminal that faces the plasma membrane of the neuron or muscle fiber with which the axon terminal establishes a synaptic junction; many synaptic junctions exhibit structural presynaptic characteristics, such as conical, electron-dense internal protrusions, that distinguish it from the remainder of the axon plasma membrane. [GOC:jl, ISBN:0815316194]",
                "id": "GO:0042734"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }, {
                "name": "dendritic spine",
                "definition": "A small, membranous protrusion from a dendrite that forms a postsynaptic compartment - typically receiving input from a single presynapse.  They function as partially isolated biochemical and an electrical compartments. Spine morphology is variable including \\thin\\\", \\\"stubby\\\", \\\"mushroom\\\", and \\\"branched\\\", with a continuum of intermediate morphologies. They typically terminate in a bulb shape, linked to the dendritic shaft by a restriction. Spine remodeling is though to be involved in synaptic plasticity.\" [GOC:nln]",
                "id": "GO:0043197"
            }, {
                "name": "postsynaptic membrane",
                "definition": "A specialized area of membrane facing the presynaptic membrane on the tip of the nerve ending and separated from it by a minute cleft (the synaptic cleft). Neurotransmitters cross the synaptic cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045211"
            }],
            "molecular_function": [{
                "name": "group II metabotropic glutamate receptor activity",
                "definition": "A G-protein coupled receptor that is activated by trans-1-aminocyclopentane-1,3-dicarboxylic acid (t-ACPD) and inhibits adenylate cyclase activity. [GOC:dph]",
                "id": "GO:0001641"
            }, {
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "calcium channel regulator activity",
                "definition": "Modulates the activity of a calcium channel. [GOC:mah]",
                "id": "GO:0005246"
            }, {
                "name": "glutamate receptor activity",
                "definition": "Combining with glutamate and transmitting the signal from one side of the membrane to the other to initiate a change in cell activity. [GOC:ai, GOC:signaling]",
                "id": "GO:0008066"
            }]
        },
        "kegg_pathway": {}
    }, "MAPK8": {
        "name": "mTOR signaling pathway",
        "description": "The protein encoded by this gene is a member of the MAP kinase family. MAP kinases act as an integration point for multiple biochemical signals, and are involved in a wide variety of cellular processes such as proliferation, differentiation, transcription regulation and development. This kinase is activated by various cell stimuli, and targets specific transcription factors, and thus mediates immediate-early gene expression in response to cell stimuli. The activation of this kinase by tumor-necrosis factor alpha (TNF-alpha) is found to be required for TNF-alpha induced apoptosis. This kinase is also involved in UV radiation induced apoptosis, which is thought to be related to cytochrom c-mediated cell death pathway. Studies of the mouse counterpart of this gene suggested that this kinase play a key role in T cell proliferation, apoptosis and differentiation. Several alternatively spliced transcript variants encoding distinct isoforms have been reported. [provided by RefSeq, Apr 2016].",
        "entrez": "5599",
        "ensembl": "ENSG00000107643",
        "uniprot": "P45983",
        "hgnc": "6881",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "JNK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a JNK (a MAPK), a JNKK (a MAPKK) and a JUN3K (a MAP3K). The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:signaling, PMID:11790549, PMID:20811974]",
                "id": "GO:0007254"
            }, {
                "name": "JUN phosphorylation",
                "definition": "The process of introducing a phosphate group into a JUN protein. [GOC:jl]",
                "id": "GO:0007258"
            }, {
                "name": "response to UV",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an ultraviolet radiation (UV light) stimulus. Ultraviolet radiation is electromagnetic radiation with a wavelength in the range of 10 to 380 nanometers. [GOC:hb]",
                "id": "GO:0009411"
            }, {
                "name": "positive regulation of gene expression",
                "definition": "Any process that increases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010628"
            }, {
                "name": "regulation of macroautophagy",
                "definition": "Any process that modulates the frequency, rate or extent of macroautophagy. [GOC:krc]",
                "id": "GO:0016241"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "regulation of histone deacetylation",
                "definition": "Any process that modulates the frequency, rate or extent of the removal of acetyl groups from histones. [GOC:mah]",
                "id": "GO:0031063"
            }, {
                "name": "positive regulation of cyclase activity",
                "definition": "Any process that activates or increases the activity of a cyclase. [GOC:mah]",
                "id": "GO:0031281"
            }, {
                "name": "negative regulation of protein binding",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of protein binding. [GOC:mah]",
                "id": "GO:0032091"
            }, {
                "name": "regulation of protein localization",
                "definition": "Any process that modulates the frequency, rate or extent of any process in which a protein is transported to, or maintained in, a specific location. [GOC:dph, GOC:mah, GOC:tb]",
                "id": "GO:0032880"
            }, {
                "name": "cellular response to amino acid starvation",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of deprivation of amino acids. [GOC:ecd]",
                "id": "GO:0034198"
            }, {
                "name": "cellular response to reactive oxygen species",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a reactive oxygen species stimulus. Reactive oxygen species include singlet oxygen, superoxide, and oxygen free radicals. [GOC:mah]",
                "id": "GO:0034614"
            }, {
                "name": "Fc-epsilon receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of the Fc portion of immunoglobulin E (IgE) to an Fc-epsilon receptor on the surface of a signal-receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. The Fc portion of an immunoglobulin is its C-terminal constant region. [GOC:phg, PMID:12413516, PMID:15048725]",
                "id": "GO:0038095"
            }, {
                "name": "regulation of circadian rhythm",
                "definition": "Any process that modulates the frequency, rate or extent of a circadian rhythm. A circadian rhythm is a biological process in an organism that recurs with a regularity of approximately 24 hours. [GOC:dph, GOC:jl, GOC:tb]",
                "id": "GO:0042752"
            }, {
                "name": "positive regulation of apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043065"
            }, {
                "name": "negative regulation of apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043066"
            }, {
                "name": "rhythmic process",
                "definition": "Any process pertinent to the generation and maintenance of rhythms in the physiology of an organism. [GOC:jid]",
                "id": "GO:0048511"
            }, {
                "name": "neuron development",
                "definition": "The process whose specific outcome is the progression of a neuron over time, from initial commitment of the cell to a specific fate, to the fully functional differentiated cell. [GOC:dph]",
                "id": "GO:0048666"
            }, {
                "name": "regulation of DNA binding transcription factor activity",
                "definition": "Any process that modulates the frequency, rate or extent of the activity of a transcription factor, any factor involved in the initiation or regulation of transcription. [GOC:ai]",
                "id": "GO:0051090"
            }, {
                "name": "positive regulation of protein metabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways involving a protein. [GOC:ai]",
                "id": "GO:0051247"
            }, {
                "name": "stress-activated MAPK cascade",
                "definition": "A series of molecular signals in which a stress-activated MAP kinase cascade relays one or more of the signals; MAP kinase cascades involve at least three protein kinase activities and culminate in the phosphorylation and activation of a MAP kinase. [GOC:ai, PMID:15936270]",
                "id": "GO:0051403"
            }, {
                "name": "cellular response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:mah]",
                "id": "GO:0071222"
            }, {
                "name": "cellular response to mechanical stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a mechanical stimulus. [GOC:mah]",
                "id": "GO:0071260"
            }, {
                "name": "cellular response to cadmium ion",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cadmium (Cd) ion stimulus. [GOC:mah]",
                "id": "GO:0071276"
            }, {
                "name": "cellular response to cytokine stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cytokine stimulus. [GOC:mah]",
                "id": "GO:0071345"
            }, {
                "name": "positive regulation of deacetylase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of deacetylase activity, the catalysis of the hydrolysis of an acetyl group or groups from a substrate molecule. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0090045"
            }, {
                "name": "positive regulation of protein insertion into mitochondrial membrane involved in apoptotic signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein insertion into mitochondrial membrane involved in apoptotic signaling pathway. [GOC:mtg_apoptosis, GOC:TermGenie]",
                "id": "GO:1900740"
            }, {
                "name": "regulation of DNA replication origin binding",
                "definition": "Any process that modulates the frequency, rate or extent of DNA replication origin binding. [GO_REF:0000059, GOC:TermGenie, PMID:11850415]",
                "id": "GO:1902595"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "axon",
                "definition": "The long process of a neuron that conducts nerve impulses, usually away from the cell body to the terminals and varicosities, which are sites of storage and release of neurotransmitter. [GOC:nln, ISBN:0198506732]",
                "id": "GO:0030424"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }, {
                "name": "basal dendrite",
                "definition": "A dendrite that emerges near the basal pole of a neuron. In bipolar neurons, basal dendrites are either on the same side of the soma as the axon, or project toward the axon. [GOC:aruk, GOC:bc, NIF_Subcellular:sao1079900774, PMID:17046728, PMID:22683681]",
                "id": "GO:0097441"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "JUN kinase activity",
                "definition": "Catalysis of the reaction: JUN + ATP = JUN phosphate + ADP. This reaction is the phosphorylation and activation of members of the JUN family, a gene family that encodes nuclear transcription factors. [GOC:bf, ISBN:0198506732]",
                "id": "GO:0004705"
            }, {
                "name": "MAP kinase activity",
                "definition": "Catalysis of the reaction: protein + ATP = protein phosphate + ADP. This reaction is the phosphorylation of proteins. Mitogen-activated protein kinase; a family of protein kinases that perform a crucial step in relaying signals from the plasma membrane to the nucleus. They are activated by a wide range of proliferation- or differentiation-inducing signals; activation is strong with agonists such as polypeptide growth factors and tumor-promoting phorbol esters, but weak (in most cell backgrounds) by stress stimuli. [GOC:ma, ISBN:0198547684]",
                "id": "GO:0004707"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "enzyme binding",
                "definition": "Interacting selectively and non-covalently with any enzyme. [GOC:jl]",
                "id": "GO:0019899"
            }, {
                "name": "histone deacetylase regulator activity",
                "definition": "Modulates the activity of histone deacetylase. [GOC:bf]",
                "id": "GO:0035033"
            }, {
                "name": "histone deacetylase binding",
                "definition": "Interacting selectively and non-covalently with the enzyme histone deacetylase. [GOC:jl]",
                "id": "GO:0042826"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.24",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }]
        }
    }, "CDC7": {
        "name": "cell division cycle 7",
        "description": "This gene encodes a cell division cycle protein with kinase activity that is critical for the G1/S transition. The yeast homolog is also essential for initiation of DNA replication as cell division occurs. Overexpression of this gene product may be associated with neoplastic transformation for some tumors. Multiple alternatively spliced transcript variants that encode the same protein have been detected. [provided by RefSeq, Aug 2008].",
        "entrez": "8317",
        "ensembl": "ENSG00000097046",
        "uniprot": "O00311",
        "hgnc": "1745",
        "go_terms": {
            "biological_process": [{
                "name": "G1/S transition of mitotic cell cycle",
                "definition": "The mitotic cell cycle transition by which a cell in G1 commits to S phase. The process begins with the build up of G1 cyclin-dependent kinase (G1 CDK), resulting in the activation of transcription of G1 cyclins. The process ends with the positive feedback of the G1 cyclins on the G1 CDK which commits the cell to S phase, in which DNA replication is initiated. [GOC:mtg_cell_cycle]",
                "id": "GO:0000082"
            }, {
                "name": "double-strand break repair via break-induced replication",
                "definition": "The error-free repair of a double-strand break in DNA in which the centromere-proximal end of a broken chromosome searches for a homologous region in an intact chromosome. DNA synthesis initiates from the 3' end of the invading DNA strand, using the intact chromosome as the template, and progresses to the end of the chromosome. [GOC:elh, PMID:10357855]",
                "id": "GO:0000727"
            }, {
                "name": "DNA replication",
                "definition": "The cellular metabolic process in which a cell duplicates one or more molecules of DNA. DNA replication begins when specific sequences, known as origins of replication, are recognized and bound by initiation proteins, and ends when the original DNA molecule has been completely duplicated and the copies topologically separated. The unit of replication usually corresponds to the genome of the cell, an organelle, or a virus. The template for replication can either be an existing DNA molecule or RNA. [GOC:mah]",
                "id": "GO:0006260"
            }, {
                "name": "DNA replication initiation",
                "definition": "The process in which DNA-dependent DNA replication is started; this involves the separation of a stretch of the DNA double helix, the recruitment of DNA polymerases and the initiation of polymerase action. [ISBN:071673706X, ISBN:0815316194]",
                "id": "GO:0006270"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "phagocytosis",
                "definition": "An endocytosis process that results in the engulfment of external particulate material by phagocytes. The particles are initially contained within phagocytic vacuoles (phagosomes), which then fuse with primary lysosomes to effect digestion of the particles. [ISBN:0198506732]",
                "id": "GO:0006909"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "positive regulation of cell proliferation",
                "definition": "Any process that activates or increases the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008284"
            }, {
                "name": "regulation of cell shape",
                "definition": "Any process that modulates the surface configuration of a cell. [GOC:dph, GOC:go_curators, GOC:tb]",
                "id": "GO:0008360"
            }, {
                "name": "positive regulation of nuclear cell cycle DNA replication",
                "definition": "Any process that activates or increases the frequency, rate or extent of The DNA-dependent DNA replication that occurs in the nucleus of eukaryotic organisms as part of the cell cycle. [GOC:mtg_cell_cycle]",
                "id": "GO:0010571"
            }, {
                "name": "positive regulation of G2/M transition of mitotic cell cycle",
                "definition": "Any process that increases the rate or extent of progression from G2 phase to M phase of the mitotic cell cycle. [GOC:dph, GOC:mtg_cell_cycle, GOC:tb]",
                "id": "GO:0010971"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "cell cycle phase transition",
                "definition": "The cell cycle process by which a cell commits to entering the next cell cycle phase. [GOC:mtg_cell_cycle]",
                "id": "GO:0044770"
            }, {
                "name": "cell division",
                "definition": "The process resulting in division and partitioning of components of a cell to form more cells; may or may not be accompanied by the physical separation of a cell into distinct, individually membrane-bounded daughter cells. [GOC:di, GOC:go_curators, GOC:pr]",
                "id": "GO:0051301"
            }, {
                "name": "negative regulation of G0 to G1 transition",
                "definition": "A cell cycle process that stops, prevents, or reduces the rate or extent of the transition from the G0 quiescent state to the G1 phase. [GOC:mah]",
                "id": "GO:0070317"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "intercellular bridge",
                "definition": "A direct connection between the cytoplasm of two cells that is formed following the completion of cleavage furrow ingression during cell division. They are usually present only briefly prior to completion of cytokinesis. However, in some cases, such as the bridges between germ cells during their development, they become stabilised. [PMID:9635420]",
                "id": "GO:0045171"
            }, {
                "name": "mitotic spindle",
                "definition": "A spindle that forms as part of mitosis. Mitotic and meiotic spindles contain distinctive complements of proteins associated with microtubules. [GOC:mah, GOC:vw, PMID:11408572, PMID:18367542, PMID:8027178]",
                "id": "GO:0072686"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "LPL": {
        "name": "lipoprotein lipase",
        "description": "LPL encodes lipoprotein lipase, which is expressed in heart, muscle, and adipose tissue. LPL functions as a homodimer, and has the dual functions of triglyceride hydrolase and ligand/bridging factor for receptor-mediated lipoprotein uptake. Severe mutations that cause LPL deficiency result in type I hyperlipoproteinemia, while less extreme mutations in LPL are linked to many disorders of lipoprotein metabolism. [provided by RefSeq, Jul 2008].",
        "entrez": "4023",
        "ensembl": "ENSG00000175445",
        "uniprot": "P06858",
        "hgnc": "6677",
        "go_terms": {
            "biological_process": [{
                "name": "retinoid metabolic process",
                "definition": "The chemical reactions and pathways involving retinoids, any member of a class of isoprenoids that contain or are derived from four prenyl groups linked head-to-tail. Retinoids include retinol and retinal and structurally similar natural derivatives or synthetic compounds, but need not have vitamin A activity. [ISBN:0198506732]",
                "id": "GO:0001523"
            }, {
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "fatty acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a fatty acid, any of the aliphatic monocarboxylic acids that can be liberated by hydrolysis from naturally occurring fats and oils. Fatty acids are predominantly straight-chain acids of 4 to 24 carbon atoms, which may be saturated or unsaturated; branched fatty acids and hydroxy fatty acids also occur, and very long chain acids of over 30 carbons are found in waxes. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0006633"
            }, {
                "name": "triglyceride metabolic process",
                "definition": "The chemical reactions and pathways involving triglyceride, any triester of glycerol. The three fatty acid residues may all be the same or differ in any permutation. Triglycerides are important components of plant oils, animal fats and animal plasma lipoproteins. [ISBN:0198506732]",
                "id": "GO:0006641"
            }, {
                "name": "phospholipid metabolic process",
                "definition": "The chemical reactions and pathways involving phospholipids, any lipid containing phosphoric acid as a mono- or diester. [ISBN:0198506732]",
                "id": "GO:0006644"
            }, {
                "name": "response to cold",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cold stimulus, a temperature stimulus below the optimal temperature for that organism. [GOC:lr]",
                "id": "GO:0009409"
            }, {
                "name": "response to glucose",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a glucose stimulus. [GOC:jl]",
                "id": "GO:0009749"
            }, {
                "name": "positive regulation of macrophage derived foam cell differentiation",
                "definition": "Any process that increases the rate, frequency or extent of macrophage derived foam cell differentiation. Macrophage derived foam cell differentiation is the process in which a macrophage acquires the specialized features of a foam cell. A foam cell is a type of cell containing lipids in small vacuoles and typically seen in atherosclerotic lesions, as well as other conditions. [GOC:add, GOC:dph, GOC:tb]",
                "id": "GO:0010744"
            }, {
                "name": "positive regulation of cholesterol storage",
                "definition": "Any process that increases the rate or extent of cholesterol storage. Cholesterol storage is the accumulation and maintenance in cells or tissues of cholesterol, cholest-5-en-3 beta-ol, the principal sterol of vertebrates and the precursor of many steroids, including bile acids and steroid hormones. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010886"
            }, {
                "name": "positive regulation of sequestering of triglyceride",
                "definition": "Any process that increases the rate, frequency or extent of sequestering of triglyceride. Triglyceride sequestration is the process of binding or confining any triester of glycerol such that it is separated from other components of a biological system. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010890"
            }, {
                "name": "lipid catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. [GOC:go_curators]",
                "id": "GO:0016042"
            }, {
                "name": "triglyceride biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a triglyceride, any triester of glycerol. [ISBN:0198506732]",
                "id": "GO:0019432"
            }, {
                "name": "triglyceride catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of a triglyceride, any triester of glycerol. [ISBN:0198506732]",
                "id": "GO:0019433"
            }, {
                "name": "chylomicron remodeling",
                "definition": "The acquisition, loss or modification of a protein or lipid within a chylomicron, including the hydrolysis of triglyceride by lipoprotein lipase and the subsequent loss of free fatty acid. [GOC:BHF, GOC:expert_pt, GOC:mah, GOC:rl]",
                "id": "GO:0034371"
            }, {
                "name": "very-low-density lipoprotein particle remodeling",
                "definition": "The acquisition, loss or modification of a protein or lipid within a very-low-density lipoprotein particle, including the hydrolysis of triglyceride by hepatic lipase or lipoprotein lipase and the subsequent loss of free fatty acid. [GOC:BHF, GOC:expert_pt, GOC:mah, GOC:rl]",
                "id": "GO:0034372"
            }, {
                "name": "response to drug",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a drug stimulus. A drug is a substance used in the diagnosis, treatment or prevention of a disease. [GOC:jl]",
                "id": "GO:0042493"
            }, {
                "name": "cholesterol homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of cholesterol within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042632"
            }, {
                "name": "positive regulation of inflammatory response",
                "definition": "Any process that activates or increases the frequency, rate or extent of the inflammatory response. [GOC:ai]",
                "id": "GO:0050729"
            }, {
                "name": "regulation of lipoprotein lipase activity",
                "definition": "Any process that modulates the activity of the enzyme lipoprotein lipase. [GOC:ai]",
                "id": "GO:0051004"
            }, {
                "name": "low-density lipoprotein particle mediated signaling",
                "definition": "A series of molecular signals mediated by the detection of low-density lipoprotein particle. [GOC:BHF, GOC:rl, PMID:16013438]",
                "id": "GO:0055096"
            }, {
                "name": "triglyceride homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of triglyceride within an organism or cell. [GOC:BHF, GOC:mah]",
                "id": "GO:0070328"
            }, {
                "name": "positive regulation of chemokine secretion",
                "definition": "Any process that increases the rate, frequency or extent of chemokine secretion, the regulated release of chemokines from a cell. [GOC:BHF, GOC:rl]",
                "id": "GO:0090197"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "extracellular space",
                "definition": "That part of a multicellular organism outside the cells proper, usually taken to be outside the plasma membranes, and occupied by fluid. [ISBN:0198547684]",
                "id": "GO:0005615"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "extracellular matrix",
                "definition": "A structure lying external to one or more cells, which provides structural support for cells or tissues. [GOC:mah, NIF_Subcellular:nlx_subcell_20090513]",
                "id": "GO:0031012"
            }, {
                "name": "anchored component of membrane",
                "definition": "The component of a membrane consisting of the gene products that are tethered to the membrane only by a covalently attached anchor, such as a lipid group that is embedded in the membrane. Gene products with peptide sequences that are embedded in the membrane are excluded from this grouping. [GOC:dos, GOC:mah]",
                "id": "GO:0031225"
            }, {
                "name": "very-low-density lipoprotein particle",
                "definition": "A triglyceride-rich lipoprotein particle that is typically composed of APOB100, APOE and APOCs and has a density of about 1.006 g/ml and a diameter of between 20-80 nm. It is found in blood and transports endogenous products (newly synthesized cholesterol and triglycerides) from the liver. [GOC:BHF, GOC:expert_pt, GOC:mah, GOC:rl]",
                "id": "GO:0034361"
            }, {
                "name": "chylomicron",
                "definition": "A large lipoprotein particle (diameter 75-1200 nm) composed of a central core of triglycerides and cholesterol surrounded by a protein-phospholipid coating. The proteins include one molecule of apolipoprotein B-48 and may include a variety of apolipoproteins, including APOAs, APOCs and APOE. Chylomicrons are found in blood or lymph and carry lipids from the intestines into other body tissues. [GOC:jl, GOC:rl, http://biotech.icmb.utexas.edu/search/dict-search.html, PMID:10580165]",
                "id": "GO:0042627"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "lipoprotein lipase activity",
                "definition": "Catalysis of the reaction: triacylglycerol + H2O = diacylglycerol + a carboxylate, where the triacylglycerol is part of a lipoprotein. [EC:3.1.1.34, GOC:bf]",
                "id": "GO:0004465"
            }, {
                "name": "phospholipase activity",
                "definition": "Catalysis of the hydrolysis of a glycerophospholipid. [ISBN:0198506732]",
                "id": "GO:0004620"
            }, {
                "name": "triglyceride lipase activity",
                "definition": "Catalysis of the reaction: triacylglycerol + H2O = diacylglycerol + a carboxylate. [EC:3.1.1.3]",
                "id": "GO:0004806"
            }, {
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "heparin binding",
                "definition": "Interacting selectively and non-covalently with heparin, any member of a group of glycosaminoglycans found mainly as an intracellular component of mast cells and which consist predominantly of alternating alpha-(1->4)-linked D-galactose and N-acetyl-D-glucosamine-6-sulfate residues. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0008201"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "triglyceride binding",
                "definition": "Interacting selectively and non-covalently with any triester of glycerol. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0017129"
            }, {
                "name": "apolipoprotein binding",
                "definition": "Interacting selectively and non-covalently with an apolipoprotein, the protein component of a lipoprotein complex. [GOC:BHF, GOC:rl]",
                "id": "GO:0034185"
            }, {
                "name": "carboxylic ester hydrolase activity",
                "definition": "Catalysis of the hydrolysis of a carboxylic ester bond. [EC:3.1.1, EC:3.1.1.1, GOC:curators]",
                "id": "GO:0052689"
            }]
        },
        "kegg_pathway": {}
    }, "BACE2": {
        "name": "beta-secretase 2",
        "description": "This gene encodes an integral membrane glycoprotein that functions as an aspartic protease. The encoded protein cleaves amyloid precursor protein into amyloid beta peptide, which is a critical step in the etiology of Alzheimer's disease and Down syndrome. The protein precursor is further processed into an active mature peptide. Alternative splicing results in multiple transcript variants. [provided by RefSeq, Jul 2013].",
        "entrez": "25825",
        "ensembl": "ENSG00000182240",
        "uniprot": "Q9Y5Z0",
        "hgnc": "934",
        "go_terms": {
            "biological_process": [{
                "name": "proteolysis",
                "definition": "The hydrolysis of proteins into smaller polypeptides and/or amino acids by cleavage of their peptide bonds. [GOC:bf, GOC:mah]",
                "id": "GO:0006508"
            }, {
                "name": "membrane protein ectodomain proteolysis",
                "definition": "The proteolytic cleavage of transmembrane proteins and release of their ectodomain (extracellular domain). [GOC:jl, http://www.copewithcytokines.de/]",
                "id": "GO:0006509"
            }, {
                "name": "peptide hormone processing",
                "definition": "The generation of a mature peptide hormone by posttranslational processing of a prohormone. [GOC:mah]",
                "id": "GO:0016486"
            }, {
                "name": "protein catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of a protein by the destruction of the native, active configuration, with or without the hydrolysis of peptide bonds. [GOC:mah]",
                "id": "GO:0030163"
            }, {
                "name": "negative regulation of amyloid precursor protein biosynthetic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of amyloid precursor protein (APP), the precursor of amyloid-beta. [GOC:go_curators]",
                "id": "GO:0042985"
            }, {
                "name": "amyloid-beta metabolic process",
                "definition": "The chemical reactions and pathways involving amyloid-beta, a glycoprotein associated with Alzheimer's disease, and its precursor, amyloid precursor protein (APP). [GOC:ai]",
                "id": "GO:0050435"
            }],
            "cellular_component": [{
                "name": "endosome",
                "definition": "A vacuole to which materials ingested by endocytosis are delivered. [ISBN:0198506732, PMID:19696797]",
                "id": "GO:0005768"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "amyloid-beta binding",
                "definition": "Interacting selectively and non-covalently with amyloid-beta peptide/protein and/or its precursor. [GOC:hjd]",
                "id": "GO:0001540"
            }, {
                "name": "aspartic-type endopeptidase activity",
                "definition": "Catalysis of the hydrolysis of internal, alpha-peptide bonds in a polypeptide chain by a mechanism in which a water molecule bound by the side chains of aspartic residues at the active center acts as a nucleophile. [ISBN:0198506732]",
                "id": "GO:0004190"
            }, {
                "name": "peptidase activity",
                "definition": "Catalysis of the hydrolysis of a peptide bond. A peptide bond is a covalent bond formed when the carbon atom from the carboxyl group of one amino acid shares electrons with the nitrogen atom from the amino group of a second amino acid. [GOC:jl, ISBN:0815332181]",
                "id": "GO:0008233"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }]
        },
        "kegg_pathway": {}
    }, "UBA1": {
        "name": "ubiquitin like modifier activating enzyme 1",
        "description": "The protein encoded by this gene catalyzes the first step in ubiquitin conjugation to mark cellular proteins for degradation. This gene complements an X-linked mouse temperature-sensitive defect in DNA synthesis, and thus may function in DNA repair. It is part of a gene cluster on chromosome Xp11.23. Alternatively spliced transcript variants that encode the same protein have been described. [provided by RefSeq, Jul 2008].",
        "entrez": "7317",
        "ensembl": "ENSG00000130985",
        "uniprot": "P22314",
        "hgnc": "12469",
        "go_terms": {
            "biological_process": [{
                "name": "cellular protein modification process",
                "definition": "The covalent alteration of one or more amino acids occurring in proteins, peptides and nascent polypeptides (co-translational, post-translational modifications) occurring at the level of an individual cell. Includes the modification of charged tRNAs that are destined to occur in a protein (pre-translation modification). [GOC:go_curators]",
                "id": "GO:0006464"
            }, {
                "name": "ubiquitin-dependent protein catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of a protein or peptide by hydrolysis of its peptide bonds, initiated by the covalent attachment of a ubiquitin group, or multiple ubiquitin groups, to the protein. [GOC:go_curators]",
                "id": "GO:0006511"
            }, {
                "name": "cellular response to DNA damage stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating damage to its DNA from environmental insults or errors during metabolism. [GOC:go_curators]",
                "id": "GO:0006974"
            }, {
                "name": "protein ubiquitination",
                "definition": "The process in which one or more ubiquitin groups are added to a protein. [GOC:ai]",
                "id": "GO:0016567"
            }],
            "cellular_component": [{
                "name": "heterochromatin",
                "definition": "A compact and highly condensed form of chromatin. [GOC:elh]",
                "id": "GO:0000792"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "lysosomal membrane",
                "definition": "The lipid bilayer surrounding the lysosome and separating its contents from the cell cytoplasm. [GOC:ai]",
                "id": "GO:0005765"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "endosome membrane",
                "definition": "The lipid bilayer surrounding an endosome. [GOC:mah]",
                "id": "GO:0010008"
            }, {
                "name": "desmosome",
                "definition": "A cell-cell junction in which: on the cytoplasmic surface of each interacting plasma membrane is a dense plaque composed of a mixture of intracellular anchor proteins; a bundle of keratin intermediate filaments is attached to the surface of each plaque; transmembrane adhesion proteins of the cadherin family bind to the plaques and interact through their extracellular domains to hold the adjacent membranes together by a Ca2+-dependent mechanism. [GOC:mah, GOC:mtg_muscle, ISBN:0815332181]",
                "id": "GO:0030057"
            }, {
                "name": "rough endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the rough endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0030867"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "RNA binding",
                "definition": "Interacting selectively and non-covalently with an RNA molecule or a portion thereof. [GOC:jl, GOC:mah]",
                "id": "GO:0003723"
            }, {
                "name": "ubiquitin activating enzyme activity",
                "definition": "Catalysis of the reaction: E1 + ubiquitin + ATP--> E1-ubiquitin + AMP + PPi, where the E1-ubiquitin linkage is a thioester bond between the C-terminal glycine of Ub and a sulfhydryl side group of an E1 cysteine residue. This is the first step in a cascade of reactions in which ubiquitin is ultimately added to a protein substrate. [GOC:BioGRID, http://www.bostonbiochem.com/E-301.html, PMID:10072378]",
                "id": "GO:0004839"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "ubiquitin-like modifier activating enzyme activity",
                "definition": "Catalysis of the activation of small proteins, such as ubiquitin or ubiquitin-like proteins, through the formation of an ATP-dependent high-energy thiolester bond. [GOC:jl, GOC:mah]",
                "id": "GO:0008641"
            }, {
                "name": "ligase activity",
                "definition": "Catalysis of the joining of two substances, or two groups within a single molecule, with the concomitant hydrolysis of the diphosphate bond in ATP or a similar triphosphate. [EC:6, GOC:mah]",
                "id": "GO:0016874"
            }, {
                "name": "acid-amino acid ligase activity",
                "definition": "Catalysis of the ligation of an acid to an amino acid via a carbon-nitrogen bond, with the concomitant hydrolysis of the diphosphate bond in ATP or a similar triphosphate. [GOC:jl, GOC:mah]",
                "id": "GO:0016881"
            }]
        },
        "kegg_pathway": {}
    }, "EPHX2": {
        "name": "epoxide hydrolase 2",
        "description": "This gene encodes a member of the epoxide hydrolase family. The protein, found in both the cytosol and peroxisomes, binds to specific epoxides and converts them to the corresponding dihydrodiols. Mutations in this gene have been associated with familial hypercholesterolemia. Alternatively spliced transcript variants have been described. [provided by RefSeq, Feb 2012].",
        "entrez": "2053",
        "ensembl": "ENSG00000120915",
        "uniprot": "P34913",
        "hgnc": "3402",
        "go_terms": {
            "biological_process": [{
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "xenobiotic metabolic process",
                "definition": "The chemical reactions and pathways involving a xenobiotic compound, a compound foreign to living organisms. Used of chemical compounds, e.g. a xenobiotic chemical, such as a pesticide. [GOC:cab2]",
                "id": "GO:0006805"
            }, {
                "name": "cellular calcium ion homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of calcium ions at the level of a cell. [GOC:ceb, GOC:mah]",
                "id": "GO:0006874"
            }, {
                "name": "inflammatory response",
                "definition": "The immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. The process is characterized by local vasodilation, extravasation of plasma into intercellular spaces and accumulation of white blood cells and macrophages. [GO_REF:0000022, GOC:mtg_15nov05, ISBN:0198506732]",
                "id": "GO:0006954"
            }, {
                "name": "metabolic process",
                "definition": "The chemical reactions and pathways, including anabolism and catabolism, by which living organisms transform chemical substances. Metabolic processes typically transform small molecules, but also include macromolecular processes such as DNA repair and replication, and protein synthesis and degradation. [GOC:go_curators, ISBN:0198547684]",
                "id": "GO:0008152"
            }, {
                "name": "regulation of blood pressure",
                "definition": "Any process that modulates the force with which blood travels through the circulatory system. The process is controlled by a balance of processes that increase pressure and decrease pressure. [GOC:dph, GOC:mtg_cardio, ISBN:0721643949]",
                "id": "GO:0008217"
            }, {
                "name": "response to toxic substance",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a toxic stimulus. [GOC:lr]",
                "id": "GO:0009636"
            }, {
                "name": "positive regulation of gene expression",
                "definition": "Any process that increases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010628"
            }, {
                "name": "dephosphorylation",
                "definition": "The process of removing one or more phosphoric (ester or anhydride) residues from a molecule. [ISBN:0198506732]",
                "id": "GO:0016311"
            }, {
                "name": "drug metabolic process",
                "definition": "The chemical reactions and pathways involving a drug, a substance used in the diagnosis, treatment or prevention of a disease; as used here antibiotic substances (see antibiotic metabolism) are considered to be drugs, even if not used in medical or veterinary practice. [GOC:cab2]",
                "id": "GO:0017144"
            }, {
                "name": "epoxygenase P450 pathway",
                "definition": "The chemical reactions and pathways by which arachidonic acid is converted to other compounds including epoxyeicosatrienoic acids and dihydroxyeicosatrienoic acids. [GOC:mah, PMID:17979511]",
                "id": "GO:0019373"
            }, {
                "name": "aromatic compound catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of aromatic compounds, any substance containing an aromatic carbon ring. [GOC:ai]",
                "id": "GO:0019439"
            }, {
                "name": "cholesterol homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of cholesterol within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042632"
            }, {
                "name": "stilbene catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of stilbenes, a class of polyketide compounds formed from cinnamic acid and three molecules of malonyl CoA. [GOC:ai]",
                "id": "GO:0046272"
            }, {
                "name": "phospholipid dephosphorylation",
                "definition": "The process of removing one or more phosphate groups from a phosphorylated lipid, any member of a group of substances soluble in lipid solvents but only sparingly soluble in aqueous solvents. [ISBN:0198506732]",
                "id": "GO:0046839"
            }, {
                "name": "reactive oxygen species metabolic process",
                "definition": "The chemical reactions and pathways involving a reactive oxygen species, any molecules or ions formed by the incomplete one-electron reduction of oxygen. They contribute to the microbicidal activity of phagocytes, regulation of signal transduction and gene expression, and the oxidative damage to biopolymers. [CHEBI:26523, GOC:mah]",
                "id": "GO:0072593"
            }, {
                "name": "regulation of cholesterol metabolic process",
                "definition": "Any process that modulates the rate, frequency, or extent of cholesterol metabolism, the chemical reactions and pathways involving cholesterol, cholest-5-en-3 beta-ol, the principal sterol of vertebrates and the precursor of many steroids, including bile acids and steroid hormones. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0090181"
            }, {
                "name": "epoxide metabolic process",
                "definition": "The chemical reactions and pathways involving epoxides, compounds in which an oxygen atom is directly attached to two adjacent or non-adjacent carbon atoms of a carbon chain or ring system; thus cyclic ethers. [CHEBI:37407, GOC:rs, PMID:15822179]",
                "id": "GO:0097176"
            }, {
                "name": "positive regulation of blood vessel diameter",
                "definition": "Any process that increases the diameter of blood vessels. [GOC:pr]",
                "id": "GO:0097755"
            }],
            "cellular_component": [{
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "peroxisome",
                "definition": "A small organelle enclosed by a single membrane, and found in most eukaryotic cells. Contains peroxidases and other enzymes involved in a variety of metabolic processes including free radical detoxification, lipid catabolism and biosynthesis, and hydrogen peroxide metabolism. [GOC:pm, PMID:9302272, UniProtKB-KW:KW-0576]",
                "id": "GO:0005777"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "catalytic activity",
                "definition": "Catalysis of a biochemical reaction at physiological temperatures. In biologically catalyzed reactions, the reactants are known as substrates, and the catalysts are naturally occurring macromolecular substances known as enzymes. Enzymes possess specific binding sites for substrates, and are usually composed wholly or largely of protein, but RNA that has catalytic activity (ribozyme) is often also regarded as enzymatic. [GOC:vw, ISBN:0198506732]",
                "id": "GO:0003824"
            }, {
                "name": "epoxide hydrolase activity",
                "definition": "Catalysis of the reaction: an epoxide + H2O = a glycol. [EC:3.3.2.10]",
                "id": "GO:0004301"
            }, {
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "toxic substance binding",
                "definition": "Interacting selectively and non-covalently with a toxic substance, a poisonous substance that causes damage to biological systems. [GOC:bf, GOC:curators, GOC:jl, GOC:pr]",
                "id": "GO:0015643"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "phosphatase activity",
                "definition": "Catalysis of the hydrolysis of phosphoric monoesters, releasing inorganic phosphate. [EC:3.1.3, EC:3.1.3.41, GOC:curators, GOC:pg]",
                "id": "GO:0016791"
            }, {
                "name": "10-hydroxy-9-(phosphonooxy)octadecanoate phosphatase activity",
                "definition": "Catalysis of the reaction: (9S,10S)-10-hydroxy-9-(phosphonooxy)octadecanoate + H(2)O = (9S,10S)-9,10-dihydroxyoctadecanoate + phosphate. [EC:3.1.3.76, RHEA:16540]",
                "id": "GO:0033885"
            }, {
                "name": "lipid phosphatase activity",
                "definition": "Catalysis of the reaction: a phospholipid + H2O = a lipid + phosphate. [GOC:jl]",
                "id": "GO:0042577"
            }, {
                "name": "protein homodimerization activity",
                "definition": "Interacting selectively and non-covalently with an identical protein to form a homodimer. [GOC:jl]",
                "id": "GO:0042803"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "HCRTR2": {
        "name": "hypocretin receptor 2",
        "description": "The protein encoded by this gene is a G-protein coupled receptor involved in the regulation of feeding behavior. The encoded protein binds the hypothalamic neuropeptides orexin A and orexin B. A related gene (HCRTR1) encodes a G-protein coupled receptor that selectively binds orexin A. [provided by RefSeq, Jan 2009].",
        "entrez": "3062",
        "ensembl": "ENSG00000137252",
        "uniprot": "O43614",
        "hgnc": "4849",
        "go_terms": {
            "biological_process": [{
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "phospholipase C-activating G-protein coupled receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a G-protein coupled receptor binding to its physiological ligand, where the pathway proceeds with activation of phospholipase C (PLC) and a subsequent increase in the concentration of inositol trisphosphate (IP3) and diacylglycerol (DAG). [GOC:dph, GOC:mah, GOC:signaling, GOC:tb, ISBN:0815316194]",
                "id": "GO:0007200"
            }, {
                "name": "neuropeptide signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a peptide neurotransmitter binding to a cell surface receptor. [GOC:mah, ISBN:0815316194]",
                "id": "GO:0007218"
            }, {
                "name": "chemical synaptic transmission",
                "definition": "The vesicular release of classical neurotransmitter molecules from a presynapse, across a chemical synapse, the subsequent activation of neurotransmitter receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:jl, MeSH:D009435]",
                "id": "GO:0007268"
            }, {
                "name": "feeding behavior",
                "definition": "Behavior associated with the intake of food. [GOC:mah]",
                "id": "GO:0007631"
            }, {
                "name": "regulation of circadian sleep/wake cycle, wakefulness",
                "definition": "Any process that modulates the rate, frequency, or extent of the wakeful phase of the circadian sleep/wake cycle. The wakeful phase is the part of the circadian sleep/wake cycle where the organism is not asleep. [GOC:dph, GOC:tb]",
                "id": "GO:0010840"
            }, {
                "name": "circadian sleep/wake cycle process",
                "definition": "A behavioral process involved in the cycle from wakefulness through an orderly succession of sleep states and stages that occurs on an approximately 24 hour rhythm. [GOC:isa_complete]",
                "id": "GO:0022410"
            }, {
                "name": "cellular response to hormone stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hormone stimulus. [GOC:mah]",
                "id": "GO:0032870"
            }, {
                "name": "regulation of circadian sleep/wake cycle, sleep",
                "definition": "Any process that modulates the frequency, rate or extent of sleep; a readily reversible state of reduced awareness and metabolic activity that occurs periodically in many animals. [GOC:jl, ISBN:0192800981]",
                "id": "GO:0045187"
            }, {
                "name": "regulation of cytosolic calcium ion concentration",
                "definition": "Any process involved in the maintenance of an internal steady state of calcium ions within the cytosol of a cell or between the cytosol and its surroundings. [GOC:ai, GOC:mah, GOC:rph]",
                "id": "GO:0051480"
            }, {
                "name": "response to peptide",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a peptide stimulus. [GOC:pr, GOC:TermGenie]",
                "id": "GO:1901652"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "neuropeptide receptor activity",
                "definition": "Combining with a neuropeptide to initiate a change in cell activity. [GOC:ai]",
                "id": "GO:0008188"
            }, {
                "name": "orexin receptor activity",
                "definition": "Combining with orexin to initiate a change in cell activity. [GOC:ai]",
                "id": "GO:0016499"
            }, {
                "name": "peptide hormone binding",
                "definition": "Interacting selectively and non-covalently with any peptide with hormonal activity in animals. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0017046"
            }, {
                "name": "peptide binding",
                "definition": "Interacting selectively and non-covalently with peptides, any of a group of organic compounds comprising two or more amino acids linked by peptide bonds. [GOC:jl]",
                "id": "GO:0042277"
            }]
        },
        "kegg_pathway": {}
    }, "TRPA1": {
        "name": "transient receptor potential cation channel subfamily A member 1",
        "description": "The structure of the protein encoded by this gene is highly related to both the protein ankyrin and transmembrane proteins.  The specific function of this protein has not yet been determined; however, studies indicate the function may involve a role in signal transduction and growth control. [provided by RefSeq, Jul 2008].",
        "entrez": "8989",
        "ensembl": "ENSG00000104321",
        "uniprot": "O75762",
        "hgnc": "497",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "calcium ion transport",
                "definition": "The directed movement of calcium (Ca) ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006816"
            }, {
                "name": "cell surface receptor signaling pathway",
                "definition": "A series of molecular signals initiated by activation of a receptor on the surface of a cell. The pathway begins with binding of an extracellular ligand to a cell surface receptor, or for receptors that signal in the absence of a ligand, by ligand-withdrawal or the activity of a constitutively active receptor. The pathway ends with regulation of a downstream cellular process, e.g. transcription. [GOC:bf, GOC:mah, GOC:pr, GOC:signaling]",
                "id": "GO:0007166"
            }, {
                "name": "response to cold",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cold stimulus, a temperature stimulus below the optimal temperature for that organism. [GOC:lr]",
                "id": "GO:0009409"
            }, {
                "name": "response to organic substance",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic substance stimulus. [GOC:sm]",
                "id": "GO:0010033"
            }, {
                "name": "response to organic cyclic compound",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic cyclic compound stimulus. [CHEBI:33832, GOC:ef]",
                "id": "GO:0014070"
            }, {
                "name": "sensory perception of pain",
                "definition": "The series of events required for an organism to receive a painful stimulus, convert it to a molecular signal, and recognize and characterize the signal. Pain is medically defined as the physical sensation of discomfort or distress caused by injury or illness, so can hence be described as a harmful stimulus which signals current (or impending) tissue damage. Pain may come from extremes of temperature, mechanical damage, electricity or from noxious chemical substances. This is a neurological process. [http://www.onelook.com/]",
                "id": "GO:0019233"
            }, {
                "name": "ion transmembrane transport",
                "definition": "A process in which an ion is transported across a membrane. [GOC:mah]",
                "id": "GO:0034220"
            }, {
                "name": "response to drug",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a drug stimulus. A drug is a substance used in the diagnosis, treatment or prevention of a disease. [GOC:jl]",
                "id": "GO:0042493"
            }, {
                "name": "response to hydrogen peroxide",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hydrogen peroxide (H2O2) stimulus. [GOC:jl]",
                "id": "GO:0042542"
            }, {
                "name": "response to pain",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a pain stimulus. Pain stimuli cause activation of nociceptors, peripheral receptors for pain, include receptors which are sensitive to painful mechanical stimuli, extreme heat or cold, and chemical stimuli. [GOC:jid, PMID:10203867, PMID:12723742, PMID:12843304, Wikipedia:Pain]",
                "id": "GO:0048265"
            }, {
                "name": "response to stimulus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus. The process begins with detection of the stimulus and ends with a change in state or activity or the cell or organism. [GOC:ai, GOC:bf]",
                "id": "GO:0050896"
            }, {
                "name": "thermoception",
                "definition": "The series of events required for an organism to receive a temperature stimulus, convert it to a molecular signal, and recognize and characterize the signal. Thermoception in larger animals is mainly done in the skin; mammals have at least two types of sensor, for detecting heat (temperatures above body temperature) and cold (temperatures below body temperature). [GOC:ai, Wikipedia:Thermoception]",
                "id": "GO:0050955"
            }, {
                "name": "detection of mechanical stimulus involved in sensory perception of pain",
                "definition": "The series of events involved in the perception of pain in which a mechanical stimulus is received and converted into a molecular signal. [GOC:ai, GOC:dos]",
                "id": "GO:0050966"
            }, {
                "name": "detection of chemical stimulus involved in sensory perception of pain",
                "definition": "The series of events involved in the perception of pain in which a chemical stimulus is received and converted into a molecular signal. [GOC:ai]",
                "id": "GO:0050968"
            }, {
                "name": "release of sequestered calcium ion into cytosol",
                "definition": "The process in which calcium ions sequestered in the endoplasmic reticulum, Golgi apparatus or mitochondria are released into the cytosolic compartment. [GOC:dph, GOC:hjd, GOC:mtg_lung, PMID:1814929]",
                "id": "GO:0051209"
            }, {
                "name": "protein homotetramerization",
                "definition": "The formation of a protein homotetramer, a macromolecular structure consisting of four noncovalently associated identical subunits. [GOC:go_curators]",
                "id": "GO:0051289"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "calcium ion transmembrane transport",
                "definition": "A process in which a calcium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:mah]",
                "id": "GO:0070588"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "stereocilium bundle",
                "definition": "A bundle of cross-linked stereocilia, arranged around a kinocilium on the apical surface of a sensory hair cell (e.g. a neuromast, auditory or vestibular hair cell). Stereocilium bundles act as mechanosensory organelles by responding to fluid motion or fluid pressure changes. [GOC:ecd, PMID:15661519, PMID:7840137]",
                "id": "GO:0032421"
            }],
            "molecular_function": [{
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "calcium channel activity",
                "definition": "Enables the facilitated diffusion of a calcium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005262"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "channel activity",
                "definition": "Enables the energy-independent facilitated diffusion, mediated by passage of a solute through a transmembrane aqueous pore or channel. Stereospecificity is not exhibited but this transport may be specific for a particular molecular species or class of molecules. [GOC:mtg_transport, ISBN:0815340729, TC:1.-.-.-.-]",
                "id": "GO:0015267"
            }, {
                "name": "calcium-release channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion from intracellular stores by a channel that opens when a specific intracellular ligand has been bound by the channel complex or one of its constituent parts. [GOC:mah]",
                "id": "GO:0015278"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "temperature-gated cation channel activity",
                "definition": "Enables the transmembrane transfer of a cation by a channel that opens in response to a temperature stimulus (e.g. exposure to a temperature range different than the optimal temperature for that organism). [GOC:ha, GOC:pr, PMID:23027824]",
                "id": "GO:0097604"
            }]
        },
        "kegg_pathway": {}
    }, "BRSK2": {
        "name": "BR serine/threonine kinase 2",
        "description": "",
        "entrez": "9024",
        "ensembl": "ENSG00000174672",
        "uniprot": "Q8IWQ3",
        "hgnc": "11405",
        "go_terms": {
            "biological_process": [{
                "name": "G2/M transition of mitotic cell cycle",
                "definition": "The mitotic cell cycle transition by which a cell in G2 commits to M phase. The process begins when the kinase activity of M cyclin/CDK complex reaches a threshold high enough for the cell cycle to proceed. This is accomplished by activating a positive feedback loop that results in the accumulation of unphosphorylated and active M cyclin/CDK complex. [GOC:mtg_cell_cycle]",
                "id": "GO:0000086"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "exocytosis",
                "definition": "A process of secretion by a cell that results in the release of intracellular molecules (e.g. hormones, matrix proteins) contained within a membrane-bounded vesicle. Exocytosis can occur either by full fusion, when the vesicle collapses into the plasma membrane, or by a kiss-and-run mechanism that involves the formation of a transient contact, a pore, between a granule (for exemple of chromaffin cells) and the plasma membrane. The latter process most of the time leads to only partial secretion of the granule content. Exocytosis begins with steps that prepare vesicles for fusion with the membrane (tethering and docking) and ends when molecules are secreted from the cell. [GOC:mah, ISBN:0716731363, PMID:22323285]",
                "id": "GO:0006887"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "nervous system development",
                "definition": "The process whose specific outcome is the progression of nervous tissue over time, from its formation to its mature state. [GOC:dgh]",
                "id": "GO:0007399"
            }, {
                "name": "axonogenesis",
                "definition": "De novo generation of a long process of a neuron, including the terminal branched region. Refers to the morphogenesis or creation of shape or form of the developing axon, which carries efferent (outgoing) action potentials from the cell body towards target cells. [GOC:dph, GOC:jid, GOC:pg, GOC:pr, ISBN:0198506732]",
                "id": "GO:0007409"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "establishment of cell polarity",
                "definition": "The specification and formation of anisotropic intracellular organization or cell growth patterns. [GOC:mah]",
                "id": "GO:0030010"
            }, {
                "name": "neuron differentiation",
                "definition": "The process in which a relatively unspecialized cell acquires specialized features of a neuron. [GOC:mah]",
                "id": "GO:0030182"
            }, {
                "name": "actin cytoskeleton reorganization",
                "definition": "A process that is carried out at the cellular level which results in dynamic structural changes to the arrangement of constituent parts of cytoskeletal structures comprising actin filaments and their associated proteins. [GOC:ecd, GOC:mah]",
                "id": "GO:0031532"
            }, {
                "name": "ERAD pathway",
                "definition": "The protein catabolic pathway which targets endoplasmic reticulum (ER)-resident proteins for degradation by the cytoplasmic proteasome. It begins with recognition of the ER-resident protein, includes retrotranslocation (dislocation) of the protein from the ER to the cytosol, protein modifications necessary for correct substrate transfer (e.g. ubiquitination), transport of the protein to the proteasome, and ends with degradation of the protein by the cytoplasmic proteasome. [GOC:bf, GOC:PARL, PMID:20940304, PMID:21969857]",
                "id": "GO:0036503"
            }, {
                "name": "regulation of ATPase activity",
                "definition": "Any process that modulates the rate of ATP hydrolysis by an ATPase. [GOC:jl]",
                "id": "GO:0043462"
            }, {
                "name": "neuron projection morphogenesis",
                "definition": "The process in which the anatomical structures of a neuron projection are generated and organized. A neuron projection is any process extending from a neural cell, such as axons or dendrites. [GOC:mah]",
                "id": "GO:0048812"
            }, {
                "name": "cell division",
                "definition": "The process resulting in division and partitioning of components of a cell to form more cells; may or may not be accompanied by the physical separation of a cell into distinct, individually membrane-bounded daughter cells. [GOC:di, GOC:go_curators, GOC:pr]",
                "id": "GO:0051301"
            }, {
                "name": "regulation of insulin secretion involved in cellular response to glucose stimulus",
                "definition": "Any process that modulates the frequency, rate or extent of the regulated release of insulin that contributes to the response of a cell to glucose. [GOC:BHF, GOC:dph]",
                "id": "GO:0061178"
            }, {
                "name": "intrinsic apoptotic signaling pathway in response to endoplasmic reticulum stress",
                "definition": "A series of molecular signals in which an intracellular signal is conveyed to trigger the apoptotic death of a cell. The pathway is induced in response to a stimulus indicating endoplasmic reticulum (ER) stress, and ends when the execution phase of apoptosis is triggered. ER stress usually results from the accumulation of unfolded or misfolded proteins in the ER lumen. [GOC:mah, GOC:mtg_apoptosis, PMID:18701708]",
                "id": "GO:0070059"
            }, {
                "name": "regulation of retrograde protein transport, ER to cytosol",
                "definition": "Any process that modulates the frequency, rate or extent of retrograde protein transport, ER to cytosol. [GO_REF:0000058, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:18555783]",
                "id": "GO:1904152"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "centrosome",
                "definition": "A structure comprised of a core structure (in most organisms, a pair of centrioles) and peripheral material from which a microtubule-based structure, such as a spindle apparatus, is organized. Centrosomes occur close to the nucleus during interphase in many eukaryotic cells, though in animal cells it changes continually during the cell-division cycle. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0005813"
            }, {
                "name": "microtubule organizing center",
                "definition": "An intracellular structure that can catalyze gamma-tubulin-dependent microtubule nucleation and that can anchor microtubules by interacting with their minus ends, plus ends or sides. [GOC:vw, http://en.wikipedia.org/wiki/Microtubule_organizing_center, ISBN:0815316194, PMID:17072892, PMID:17245416]",
                "id": "GO:0005815"
            }, {
                "name": "cytoskeleton",
                "definition": "Any of the various filamentous elements that form the internal framework of cells, and typically remain after treatment of the cells with mild detergent to remove membrane constituents and soluble components of the cytoplasm. The term embraces intermediate filaments, microfilaments, microtubules, the microtrabecular lattice, and other structures characterized by a polymeric filamentous nature and long-range order within the cell. The various elements of the cytoskeleton not only serve in the maintenance of cellular shape but also have roles in other cellular functions, including cellular movement, cell division, endocytosis, and movement of organelles. [GOC:mah, ISBN:0198547684, PMID:16959967]",
                "id": "GO:0005856"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "protein kinase binding",
                "definition": "Interacting selectively and non-covalently with a protein kinase, any enzyme that catalyzes the transfer of a phosphate group, usually from ATP, to a protein substrate. [GOC:jl]",
                "id": "GO:0019901"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "tau-protein kinase activity",
                "definition": "Catalysis of the reaction: ATP + tau-protein = ADP + O-phospho-tau-protein. [EC:2.7.11.26, MetaCyc:TAU-PROTEIN-KINASE-RXN]",
                "id": "GO:0050321"
            }, {
                "name": "ATPase binding",
                "definition": "Interacting selectively and non-covalently with an ATPase, any enzyme that catalyzes the hydrolysis of ATP. [GOC:ai]",
                "id": "GO:0051117"
            }, {
                "name": "ATPase regulator activity",
                "definition": "Modulates the rate of ATP hydrolysis by an ATPase. [GOC:dph, GOC:tb]",
                "id": "GO:0060590"
            }]
        },
        "kegg_pathway": {}
    }, "CACNA1B": {
        "name": "calcium voltage-gated channel subunit alpha1 B",
        "description": "The protein encoded by this gene is the pore-forming subunit of an N-type voltage-dependent calcium channel, which controls neurotransmitter release from neurons. The encoded protein forms a complex with alpha-2, beta, and delta subunits to form the high-voltage activated channel. This channel is sensitive to omega-conotoxin-GVIA and omega-agatoxin-IIIA but insensitive to dihydropyridines. Two transcript variants encoding different isoforms have been found for this gene. [provided by RefSeq, Aug 2011].",
        "entrez": "774",
        "ensembl": "ENSG00000148408",
        "uniprot": "Q00975",
        "hgnc": "1389",
        "go_terms": {
            "biological_process": [{
                "name": "ion transport",
                "definition": "The directed movement of charged atoms or small charged molecules into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006811"
            }, {
                "name": "calcium ion transport",
                "definition": "The directed movement of calcium (Ca) ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006816"
            }, {
                "name": "chemical synaptic transmission",
                "definition": "The vesicular release of classical neurotransmitter molecules from a presynapse, across a chemical synapse, the subsequent activation of neurotransmitter receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:jl, MeSH:D009435]",
                "id": "GO:0007268"
            }, {
                "name": "neurotransmitter secretion",
                "definition": "The regulated release of neurotransmitter from the presynapse into the synaptic cleft via calcium-regulated exocytosis during synaptic transmission. [CHEBI:25512, GOC:dph]",
                "id": "GO:0007269"
            }, {
                "name": "locomotory behavior",
                "definition": "The specific movement from place to place of an organism in response to external or internal stimuli. Locomotion of a whole organism in a manner dependent upon some combination of that organism's internal state and external conditions. [GOC:dph]",
                "id": "GO:0007626"
            }, {
                "name": "regulation of heart contraction",
                "definition": "Any process that modulates the frequency, rate or extent of heart contraction. Heart contraction is the process in which the heart decreases in volume in a characteristic way to propel blood through the body. [GOC:dph, GOC:go_curators, GOC:tb]",
                "id": "GO:0008016"
            }, {
                "name": "regulation of blood pressure",
                "definition": "Any process that modulates the force with which blood travels through the circulatory system. The process is controlled by a balance of processes that increase pressure and decrease pressure. [GOC:dph, GOC:mtg_cardio, ISBN:0721643949]",
                "id": "GO:0008217"
            }, {
                "name": "regulation of ion transmembrane transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of ions from one side of a membrane to the other. [GOC:mah]",
                "id": "GO:0034765"
            }, {
                "name": "response to pain",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a pain stimulus. Pain stimuli cause activation of nociceptors, peripheral receptors for pain, include receptors which are sensitive to painful mechanical stimuli, extreme heat or cold, and chemical stimuli. [GOC:jid, PMID:10203867, PMID:12723742, PMID:12843304, Wikipedia:Pain]",
                "id": "GO:0048265"
            }, {
                "name": "membrane depolarization",
                "definition": "The process in which membrane potential decreases with respect to its steady-state potential, usually from negative potential to a more positive potential. For example, the initial depolarization during the rising phase of an action potential is in the direction from the negative steady-state resting potential towards the positive membrane potential that will be the peak of the action potential. [GOC:dh, Wikipedia:Depolarization]",
                "id": "GO:0051899"
            }, {
                "name": "regulation of calcium ion transport",
                "definition": "Any process that modulates the frequency, rate or extent of the directed movement of calcium ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0051924"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }, {
                "name": "calcium ion transmembrane transport",
                "definition": "A process in which a calcium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:mah]",
                "id": "GO:0070588"
            }, {
                "name": "membrane depolarization during action potential",
                "definition": "The process in which membrane potential changes in the depolarizing direction from the negative resting potential towards the positive membrane potential that will be the peak of the action potential. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]",
                "id": "GO:0086010"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "voltage-gated calcium channel complex",
                "definition": "A protein complex that forms a transmembrane channel through which calcium ions may pass in response to changes in membrane potential. [GOC:mah]",
                "id": "GO:0005891"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "dendrite",
                "definition": "A neuron projection that has a short, tapering, morphology. Dendrites receive and integrate signals from other neurons or from sensory stimuli, and conduct nerve impulses towards the axon or the cell body. In most neurons, the impulse is conveyed from dendrites to axon via the cell body, but in some types of unipolar neuron, the impulse does not travel via the cell body. [GOC:aruk, GOC:bc, GOC:dos, GOC:mah, GOC:nln, ISBN:0198506732]",
                "id": "GO:0030425"
            }, {
                "name": "neuronal cell body",
                "definition": "The portion of a neuron that includes the nucleus, but excludes cell projections such as axons and dendrites. [GOC:go_curators]",
                "id": "GO:0043025"
            }, {
                "name": "synapse",
                "definition": "The junction between a nerve fiber of one neuron and another neuron, muscle fiber or glial cell. As the nerve fiber approaches the synapse it enlarges into a specialized structure, the presynaptic nerve ending, which contains mitochondria and synaptic vesicles. At the tip of the nerve ending is the presynaptic membrane; facing it, and separated from it by a minute cleft (the synaptic cleft) is a specialized area of membrane on the receiving cell, known as the postsynaptic membrane. In response to the arrival of nerve impulses, the presynaptic nerve ending secretes molecules of neurotransmitters into the synaptic cleft. These diffuse across the cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045202"
            }, {
                "name": "presynapse",
                "definition": "The part of a synapse that is part of the presynaptic cell. [GOC:dos]",
                "id": "GO:0098793"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "ion channel activity",
                "definition": "Enables the facilitated diffusion of an ion (by an energy-independent process) by passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. May be either selective (it enables passage of a specific ion only) or non-selective (it enables passage of two or more ions of same charge but different size). [GOC:cy, GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005216"
            }, {
                "name": "voltage-gated ion channel activity",
                "definition": "Enables the transmembrane transfer of an ion by a voltage-gated channel. An ion is an atom or group of atoms carrying an electric charge by virtue of having gained or lost one or more electrons. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0198506732, ISBN:0815340729]",
                "id": "GO:0005244"
            }, {
                "name": "voltage-gated calcium channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion by a voltage-gated channel. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:mtg_transport, GOC:tb, ISBN:0815340729]",
                "id": "GO:0005245"
            }, {
                "name": "calcium channel activity",
                "definition": "Enables the facilitated diffusion of a calcium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005262"
            }, {
                "name": "calcium ion binding",
                "definition": "Interacting selectively and non-covalently with calcium ions (Ca2+). [GOC:ai]",
                "id": "GO:0005509"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "protein C-terminus binding",
                "definition": "Interacting selectively and non-covalently with a protein C-terminus, the end of any peptide chain at which the 1-carboxy function of a constituent amino acid is not attached in peptide linkage to another amino-acid residue. [ISBN:0198506732]",
                "id": "GO:0008022"
            }, {
                "name": "high voltage-gated calcium channel activity",
                "definition": "Enables the transmembrane transfer of a calcium ion by a high voltage-gated channel. A high voltage-gated channel is a channel whose open state is dependent on high voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0815340729, PMID:16382099]",
                "id": "GO:0008331"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "FYN": {
        "name": "T cell receptor signaling pathway",
        "description": "This gene is a member of the protein-tyrosine kinase oncogene family. It encodes a membrane-associated tyrosine kinase that has been implicated in the control of cell growth. The protein associates with the p85 subunit of phosphatidylinositol 3-kinase and interacts with the fyn-binding protein. Alternatively spliced transcript variants encoding distinct isoforms exist. [provided by RefSeq, Jul 2008].",
        "entrez": "2534",
        "ensembl": "ENSG00000010810",
        "uniprot": "P06241",
        "hgnc": "4037",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "neuron migration",
                "definition": "The characteristic movement of an immature neuron from germinal zones to specific positions where they will reside as they mature. [CL:0000540, GOC:go_curators]",
                "id": "GO:0001764"
            }, {
                "name": "stimulatory C-type lectin receptor signaling pathway",
                "definition": "Any series of molecular signals generated as a consequence of binding to a C-type lectin receptor capable of cellular activation. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002223"
            }, {
                "name": "adaptive immune response",
                "definition": "An immune response mediated by cells expressing specific receptors for antigen produced through a somatic diversification process, and allowing for an enhanced secondary response to subsequent exposures to the same antigen (immunological memory). [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002250"
            }, {
                "name": "immune system process",
                "definition": "Any process involved in the development or functioning of the immune system, an organismal system for calibrated responses to potential internal or invasive threats. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0002376"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "calcium ion transport",
                "definition": "The directed movement of calcium (Ca) ions into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006816"
            }, {
                "name": "cell surface receptor signaling pathway",
                "definition": "A series of molecular signals initiated by activation of a receptor on the surface of a cell. The pathway begins with binding of an extracellular ligand to a cell surface receptor, or for receptors that signal in the absence of a ligand, by ligand-withdrawal or the activity of a constitutively active receptor. The pathway ends with regulation of a downstream cellular process, e.g. transcription. [GOC:bf, GOC:mah, GOC:pr, GOC:signaling]",
                "id": "GO:0007166"
            }, {
                "name": "transmembrane receptor protein tyrosine kinase signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to a receptor on the surface of the target cell where the receptor possesses tyrosine kinase activity, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling]",
                "id": "GO:0007169"
            }, {
                "name": "multicellular organism development",
                "definition": "The biological process whose specific outcome is the progression of a multicellular organism over time from an initial condition (e.g. a zygote or a young adult) to a later condition (e.g. a multicellular animal or an aged adult). [GOC:dph, GOC:ems, GOC:isa_complete, GOC:tb]",
                "id": "GO:0007275"
            }, {
                "name": "axon guidance",
                "definition": "The chemotaxis process that directs the migration of an axon growth cone to a specific target site in response to a combination of attractive and repulsive cues. [ISBN:0878932437]",
                "id": "GO:0007411"
            }, {
                "name": "central nervous system development",
                "definition": "The process whose specific outcome is the progression of the central nervous system over time, from its formation to the mature structure. The central nervous system is the core nervous system that serves an integrating and coordinating function. In vertebrates it consists of the brain and spinal cord. In those invertebrates with a central nervous system it typically consists of a brain, cerebral ganglia and a nerve cord. [GOC:bf, GOC:jid, ISBN:0582227089]",
                "id": "GO:0007417"
            }, {
                "name": "blood coagulation",
                "definition": "The sequential process in which the multiple coagulation factors of the blood interact, ultimately resulting in the formation of an insoluble fibrin clot; it may be divided into three stages: stage 1, the formation of intrinsic and extrinsic prothrombin converting principle; stage 2, the formation of thrombin; stage 3, the formation of stable fibrin polymers. [http://www.graylab.ac.uk/omd/, ISBN:0198506732]",
                "id": "GO:0007596"
            }, {
                "name": "learning",
                "definition": "Any process in an organism in which a relatively long-lasting adaptive behavioral change occurs as the result of experience. [ISBN:0582227089, ISBN:0721662544]",
                "id": "GO:0007612"
            }, {
                "name": "feeding behavior",
                "definition": "Behavior associated with the intake of food. [GOC:mah]",
                "id": "GO:0007631"
            }, {
                "name": "regulation of cell shape",
                "definition": "Any process that modulates the surface configuration of a cell. [GOC:dph, GOC:go_curators, GOC:tb]",
                "id": "GO:0008360"
            }, {
                "name": "negative regulation of gene expression",
                "definition": "Any process that decreases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010629"
            }, {
                "name": "positive regulation of neuron projection development",
                "definition": "Any process that increases the rate, frequency or extent of neuron projection development. Neuron projection development is the process whose specific outcome is the progression of a neuron projection over time, from its formation to the mature structure. A neuron projection is any process extending from a neural cell, such as axons or dendrites (collectively called neurites). [GOC:dph, GOC:tb]",
                "id": "GO:0010976"
            }, {
                "name": "positive regulation of phosphatidylinositol 3-kinase signaling",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the phosphatidylinositol 3-kinase cascade. [GOC:ef]",
                "id": "GO:0014068"
            }, {
                "name": "viral process",
                "definition": "A multi-organism process in which a virus is a participant. The other participant is the host. Includes infection of a host cell, replication of the viral genome, and assembly of progeny virus particles. In some cases the viral genetic material may integrate into the host genome and only subsequently, under particular circumstances, 'complete' its life cycle. [GOC:bf, GOC:jl, GOC:mah]",
                "id": "GO:0016032"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "cell migration",
                "definition": "The controlled self-propelled movement of a cell from one site to a destination guided by molecular cues. Cell migration is a central process in the development and maintenance of multicellular organisms. [GOC:cjm, GOC:dph, GOC:ems, GOC:pf, http://en.wikipedia.org/wiki/Cell_migration]",
                "id": "GO:0016477"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "cytokine-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a cytokine to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:19295629]",
                "id": "GO:0019221"
            }, {
                "name": "cell differentiation",
                "definition": "The process in which relatively unspecialized cells, e.g. embryonic or regenerative cells, acquire specialized structural and/or functional features that characterize the cells, tissues, or organs of the mature organism or some other relatively stable phase of the organism's life history. Differentiation includes the processes involved in commitment of a cell to a specific fate and its subsequent development to the mature state. [ISBN:0198506732]",
                "id": "GO:0030154"
            }, {
                "name": "platelet activation",
                "definition": "A series of progressive, overlapping events triggered by exposure of the platelets to subendothelial tissue. These events include shape change, adhesiveness, aggregation, and release reactions. When carried through to completion, these events lead to the formation of a stable hemostatic plug. [http://www.graylab.ac.uk/omd/]",
                "id": "GO:0030168"
            }, {
                "name": "forebrain development",
                "definition": "The process whose specific outcome is the progression of the forebrain over time, from its formation to the mature structure. The forebrain is the anterior of the three primary divisions of the developing chordate brain or the corresponding part of the adult brain (in vertebrates, includes especially the cerebral hemispheres, the thalamus, and the hypothalamus and especially in higher vertebrates is the main control center for sensory and associative information processing, visceral functions, and voluntary motor functions). [http://www2.merriam-webster.com/cgi-bin/mwmednlm?book=Medical&va=forebrain]",
                "id": "GO:0030900"
            }, {
                "name": "T cell costimulation",
                "definition": "The process of providing, via surface-bound receptor-ligand pairs, a second, antigen-independent, signal in addition to that provided by the T cell receptor to augment T cell activation. [ISBN:0781735149]",
                "id": "GO:0031295"
            }, {
                "name": "negative regulation of protein ubiquitination",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the addition of ubiquitin groups to a protein. [GOC:mah]",
                "id": "GO:0031397"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "cellular response to platelet-derived growth factor stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a platelet-derived growth factor stimulus. [GOC:yaf]",
                "id": "GO:0036120"
            }, {
                "name": "peptidyl-tyrosine autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own tyrosine amino acid residues, or a tyrosine residue on an identical protein. [PMID:10037737, PMID:10068444, PMID:10940390]",
                "id": "GO:0038083"
            }, {
                "name": "Fc-gamma receptor signaling pathway involved in phagocytosis",
                "definition": "An Fc-gamma receptor signaling pathway that contributes to the endocytic engulfment of external particulate material by phagocytes. [GOC:phg, PMID:12488490, PMID:15466916]",
                "id": "GO:0038096"
            }, {
                "name": "T cell activation",
                "definition": "The change in morphology and behavior of a mature or immature T cell resulting from exposure to a mitogen, cytokine, chemokine, cellular ligand, or an antigen for which it is specific. [GOC:mgi_curators, ISBN:0781735140]",
                "id": "GO:0042110"
            }, {
                "name": "regulation of cell proliferation",
                "definition": "Any process that modulates the frequency, rate or extent of cell proliferation. [GOC:jl]",
                "id": "GO:0042127"
            }, {
                "name": "negative regulation of protein catabolic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the chemical reactions and pathways resulting in the breakdown of a protein by the destruction of the native, active configuration, with or without the hydrolysis of peptide bonds. [GOC:go_curators, GOC:jl, PMID:10207076]",
                "id": "GO:0042177"
            }, {
                "name": "response to drug",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a drug stimulus. A drug is a substance used in the diagnosis, treatment or prevention of a disease. [GOC:jl]",
                "id": "GO:0042493"
            }, {
                "name": "positive regulation of tyrosine phosphorylation of STAT protein",
                "definition": "Any process that activates or increases the frequency, rate or extent of the introduction of a phosphate group to a tyrosine residue of a STAT (Signal Transducer and Activator of Transcription) protein. [GOC:jl, PMID:11426647]",
                "id": "GO:0042531"
            }, {
                "name": "regulation of apoptotic process",
                "definition": "Any process that modulates the occurrence or rate of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0042981"
            }, {
                "name": "positive regulation of I-kappaB kinase/NF-kappaB signaling",
                "definition": "Any process that activates or increases the frequency, rate or extent of I-kappaB kinase/NF-kappaB signaling. [GOC:jl]",
                "id": "GO:0043123"
            }, {
                "name": "negative regulation of neuron apoptotic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of cell death by apoptotic process in neurons. [GOC:go_curators, GOC:mtg_apoptosis]",
                "id": "GO:0043524"
            }, {
                "name": "innate immune response",
                "definition": "Innate immune responses are defense responses mediated by germline encoded components that directly recognize components of potential pathogens. [GO_REF:0000022, GOC:add, GOC:ebc, GOC:mtg_15nov05, GOC:mtg_sensu]",
                "id": "GO:0045087"
            }, {
                "name": "response to ethanol",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an ethanol stimulus. [GOC:go_curators]",
                "id": "GO:0045471"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "phosphatidylinositol phosphorylation",
                "definition": "The process of introducing one or more phosphate groups into a phosphatidylinositol, any glycerophosphoinositol having one phosphatidyl group esterified to one of the hydroxy groups of inositol. [ISBN:0198506732]",
                "id": "GO:0046854"
            }, {
                "name": "vascular endothelial growth factor receptor signaling pathway",
                "definition": "Any series of molecular signals initiated by the binding of an extracellular ligand to a vascular endothelial growth factor receptor (VEGFR) located on the surface of the receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling, PR:000001971]",
                "id": "GO:0048010"
            }, {
                "name": "ephrin receptor signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of an ephrin receptor binding to an ephrin. [GOC:ceb]",
                "id": "GO:0048013"
            }, {
                "name": "dendrite morphogenesis",
                "definition": "The process in which the anatomical structures of a dendrite are generated and organized. [GOC:aruk, GOC:bc, GOC:jl, ISBN:0198506732, PMID:22683681]",
                "id": "GO:0048813"
            }, {
                "name": "regulation of defense response to virus by virus",
                "definition": "Any viral process that modulates the frequency, rate, or extent of the antiviral response of the host cell or organism. [GOC:ai, GOC:dph]",
                "id": "GO:0050690"
            }, {
                "name": "regulation of peptidyl-tyrosine phosphorylation",
                "definition": "Any process that modulates the frequency, rate or extent of the phosphorylation of peptidyl-tyrosine. [GOC:ai]",
                "id": "GO:0050730"
            }, {
                "name": "activated T cell proliferation",
                "definition": "The expansion of a T cell population following activation by an antigenic stimulus. [GOC:add, GOC:dph]",
                "id": "GO:0050798"
            }, {
                "name": "T cell receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the cross-linking of an antigen receptor on a T cell. [GOC:add]",
                "id": "GO:0050852"
            }, {
                "name": "leukocyte migration",
                "definition": "The movement of a leukocyte within or between different tissues and organs of the body. [GOC:add, ISBN:0781735149, PMID:14680625, PMID:14708592, PMID:7507411, PMID:8600538]",
                "id": "GO:0050900"
            }, {
                "name": "detection of mechanical stimulus involved in sensory perception of pain",
                "definition": "The series of events involved in the perception of pain in which a mechanical stimulus is received and converted into a molecular signal. [GOC:ai, GOC:dos]",
                "id": "GO:0050966"
            }, {
                "name": "positive regulation of protein kinase B signaling",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein kinase B signaling, a series of reactions mediated by the intracellular serine/threonine kinase protein kinase B. [GOC:ai]",
                "id": "GO:0051897"
            }, {
                "name": "cellular response to growth factor stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a growth factor stimulus. [GOC:mah]",
                "id": "GO:0071363"
            }, {
                "name": "cellular response to peptide hormone stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a peptide hormone stimulus. A peptide hormone is any of a class of peptides that are secreted into the blood stream and have endocrine functions in living animals. [GOC:mah]",
                "id": "GO:0071375"
            }, {
                "name": "cellular response to transforming growth factor beta stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a transforming growth factor beta stimulus. [GOC:ecd, PMID:15451575]",
                "id": "GO:0071560"
            }, {
                "name": "positive regulation of protein targeting to membrane",
                "definition": "Any process that increases the frequency, rate or extent of the process of directing proteins towards a membrane, usually using signals contained within the protein. [GOC:tb]",
                "id": "GO:0090314"
            }, {
                "name": "dendritic spine maintenance",
                "definition": "The organization process that preserves a dendritic spine in a stable functional or structural state. A dendritic spine is a specialized protrusion from a neuronal dendrite and is involved in synaptic transmission. [GOC:BHF, PMID:20410104]",
                "id": "GO:0097062"
            }, {
                "name": "positive regulation of protein localization to nucleus",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein localization to nucleus. [GOC:TermGenie]",
                "id": "GO:1900182"
            }, {
                "name": "positive regulation of neuron death",
                "definition": "Any process that activates or increases the frequency, rate or extent of neuron death. [GOC:rph, GOC:TermGenie]",
                "id": "GO:1901216"
            }, {
                "name": "negative regulation of dendritic spine maintenance",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of dendritic spine maintenance. [GO_REF:0000058, GOC:sjp, GOC:TermGenie, PMID:24328732]",
                "id": "GO:1902951"
            }, {
                "name": "response to amyloid-beta",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a amyloid-beta stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:23555824]",
                "id": "GO:1904645"
            }, {
                "name": "cellular response to L-glutamate",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a L-glutamate(1-) stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:25962137]",
                "id": "GO:1905232"
            }, {
                "name": "cellular response to glycine",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a glycine stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:18984164]",
                "id": "GO:1905430"
            }, {
                "name": "positive regulation of protein localization to membrane",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein localization to membrane. [GO_REF:0000058, GOC:bc, GOC:PARL, GOC:TermGenie, PMID:26911690]",
                "id": "GO:1905477"
            }, {
                "name": "regulation of calcium ion import across plasma membrane",
                "definition": "Any process that modulates the frequency, rate or extent of calcium ion import across plasma membrane. [GO_REF:0000058, GOC:bhm, GOC:TermGenie, PMID:17640527]",
                "id": "GO:1905664"
            }, {
                "name": "positive regulation of cysteine-type endopeptidase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of cysteine-type endopeptidase activity. [GOC:obol]",
                "id": "GO:2001056"
            }, {
                "name": "negative regulation of extrinsic apoptotic signaling pathway in absence of ligand",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of extrinsic apoptotic signaling pathway in absence of ligand. [GOC:mtg_apoptosis]",
                "id": "GO:2001240"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "endosome",
                "definition": "A vacuole to which materials ingested by endocytosis are delivered. [ISBN:0198506732, PMID:19696797]",
                "id": "GO:0005768"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "actin filament",
                "definition": "A filamentous structure formed of a two-stranded helical polymer of the protein actin and associated proteins. Actin filaments are a major component of the contractile apparatus of skeletal muscle and the microfilaments of the cytoskeleton of eukaryotic cells. The filaments, comprising polymerized globular actin molecules, appear as flexible structures with a diameter of 5-9 nm. They are organized into a variety of linear bundles, two-dimensional networks, and three dimensional gels. In the cytoskeleton they are most highly concentrated in the cortex of the cell just beneath the plasma membrane. [GOC:mah, ISBN:0198506732, PMID:10666339]",
                "id": "GO:0005884"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "postsynaptic density",
                "definition": "An electron dense network of proteins within and adjacent to the postsynaptic membrane of an asymetric, neuron-neuron synapse. Its major components include neurotransmitter receptors and the proteins that spatially and functionally organize them such as anchoring and scaffolding molecules, signaling enzymes and cytoskeletal components. [GOC:BHF, GOC:dos, GOC:ef, GOC:jid, GOC:pr, GOC:sjp, http://molneuro.kaist.ac.kr/psd, PMID:14532281, Wikipedia:Postsynaptic_density]",
                "id": "GO:0014069"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "dendrite",
                "definition": "A neuron projection that has a short, tapering, morphology. Dendrites receive and integrate signals from other neurons or from sensory stimuli, and conduct nerve impulses towards the axon or the cell body. In most neurons, the impulse is conveyed from dendrites to axon via the cell body, but in some types of unipolar neuron, the impulse does not travel via the cell body. [GOC:aruk, GOC:bc, GOC:dos, GOC:mah, GOC:nln, ISBN:0198506732]",
                "id": "GO:0030425"
            }, {
                "name": "extrinsic component of cytoplasmic side of plasma membrane",
                "definition": "The component of a plasma membrane consisting of gene products and protein complexes that are loosely bound to its cytoplasmic surface, but not integrated into the hydrophobic region. [GOC:mah]",
                "id": "GO:0031234"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }, {
                "name": "cell periphery",
                "definition": "The part of a cell encompassing the cell cortex, the plasma membrane, and any external encapsulating structures. [GOC:mah]",
                "id": "GO:0071944"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "G-protein coupled receptor binding",
                "definition": "Interacting selectively and non-covalently with a G-protein coupled receptor. [GOC:ceb, GOC:dph]",
                "id": "GO:0001664"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "non-membrane spanning protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + protein L-tyrosine = ADP + protein L-tyrosine phosphate by a non-membrane spanning protein. [EC:2.7.10.2]",
                "id": "GO:0004715"
            }, {
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "Ras guanyl-nucleotide exchange factor activity",
                "definition": "Stimulates the exchange of guanyl nucleotides associated with a GTPase of the Ras superfamily. Under normal cellular physiological conditions, the concentration of GTP is higher than that of GDP, favoring the replacement of GDP by GTP in association with the GTPase. [GOC:mah]",
                "id": "GO:0005088"
            }, {
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "tubulin binding",
                "definition": "Interacting selectively and non-covalently with monomeric or multimeric forms of tubulin, including microtubules. [GOC:clt]",
                "id": "GO:0015631"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "enzyme binding",
                "definition": "Interacting selectively and non-covalently with any enzyme. [GOC:jl]",
                "id": "GO:0019899"
            }, {
                "name": "type 5 metabotropic glutamate receptor binding",
                "definition": "Interacting selectively and non-covalently with a type 5 metabotropic glutamate receptor. [GOC:mah, GOC:nln]",
                "id": "GO:0031802"
            }, {
                "name": "T cell receptor binding",
                "definition": "Interacting selectively and non-covalently with a T cell receptor, the antigen-recognizing receptor on the surface of T cells. [GOC:jl]",
                "id": "GO:0042608"
            }, {
                "name": "CD4 receptor binding",
                "definition": "Interacting selectively and non-covalently with a CD4, a receptor found on the surface of T cells, monocytes and macrophages. [GOC:jl, MSH:D015704]",
                "id": "GO:0042609"
            }, {
                "name": "CD8 receptor binding",
                "definition": "Interacting selectively and non-covalently with a CD8, a receptor found on the surface of thymocytes and cytotoxic and suppressor T-lymphocytes. [GOC:jl, MSH:D016827]",
                "id": "GO:0042610"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "phosphatidylinositol 3-kinase binding",
                "definition": "Interacting selectively and non-covalently with a phosphatidylinositol 3-kinase, any enzyme that catalyzes the addition of a phosphate group to an inositol lipid at the 3' position of the inositol ring. [PMID:10209156, PMID:9255069]",
                "id": "GO:0043548"
            }, {
                "name": "ion channel binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on an ion channel, a protein complex that spans a membrane and forms a water-filled channel across the phospholipid bilayer allowing selective ion transport down its electrochemical gradient. [GOC:BHF, GOC:jl]",
                "id": "GO:0044325"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "ephrin receptor binding",
                "definition": "Interacting selectively and non-covalently with an ephrin receptor. [GOC:ai]",
                "id": "GO:0046875"
            }, {
                "name": "phosphatidylinositol-4,5-bisphosphate 3-kinase activity",
                "definition": "Catalysis of the reaction: 1-phosphatidyl-1D-myo-inositol 4,5-bisphosphate + ATP = a 1-phosphatidyl-1D-myo-inositol 3,4,5-trisphosphate + ADP + 2 H(+). [EC:2.7.1.153, RHEA:21295]",
                "id": "GO:0046934"
            }, {
                "name": "tau protein binding",
                "definition": "Interacting selectively and non-covalently with tau protein. tau is a microtubule-associated protein, implicated in Alzheimer's disease, Down Syndrome and ALS. [GOC:jid]",
                "id": "GO:0048156"
            }, {
                "name": "peptide hormone receptor binding",
                "definition": "Interacting selectively and non-covalently with a receptor for peptide hormones. [GOC:ai]",
                "id": "GO:0051428"
            }, {
                "name": "growth factor receptor binding",
                "definition": "Interacting selectively and non-covalently with a growth factor receptor. [GOC:mah, GOC:vw]",
                "id": "GO:0070851"
            }, {
                "name": "disordered domain specific binding",
                "definition": "Interacting selectively and non-covalently with a disordered domain of a protein. [GOC:gg, PMID:11746698]",
                "id": "GO:0097718"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.10.2",
            "pathways": [{
                "pathway": "04660",
                "name": "T cell receptor signaling pathway",
                "class": "Organismal Systems; Immune system"
            }]
        }
    }, "MAPK9": {
        "name": "mTOR signaling pathway",
        "description": "The protein encoded by this gene is a member of the MAP kinase family. MAP kinases act as an integration point for multiple biochemical signals, and are involved in a wide variety of cellular processes such as proliferation, differentiation, transcription regulation and development. This kinase targets specific transcription factors, and thus mediates immediate-early gene expression in response to various cell stimuli. It is most closely related to MAPK8, both of which are involved in UV radiation induced apoptosis, thought to be related to the cytochrome c-mediated cell death pathway. This gene and MAPK8 are also known as c-Jun N-terminal kinases. This kinase blocks the ubiquitination of tumor suppressor p53, and thus it increases the stability of p53 in nonstressed cells. Studies of this gene's mouse counterpart suggest a key role in T-cell differentiation. Several alternatively spliced transcript variants encoding distinct isoforms have been reported. [provided by RefSeq, Sep 2008].",
        "entrez": "5601",
        "ensembl": "ENSG00000050748",
        "uniprot": "P45984",
        "hgnc": "6886",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "JNK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a JNK (a MAPK), a JNKK (a MAPKK) and a JUN3K (a MAP3K). The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:signaling, PMID:11790549, PMID:20811974]",
                "id": "GO:0007254"
            }, {
                "name": "JUN phosphorylation",
                "definition": "The process of introducing a phosphate group into a JUN protein. [GOC:jl]",
                "id": "GO:0007258"
            }, {
                "name": "response to mechanical stimulus",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a mechanical stimulus. [GOC:hb]",
                "id": "GO:0009612"
            }, {
                "name": "positive regulation of gene expression",
                "definition": "Any process that increases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010628"
            }, {
                "name": "positive regulation of macrophage derived foam cell differentiation",
                "definition": "Any process that increases the rate, frequency or extent of macrophage derived foam cell differentiation. Macrophage derived foam cell differentiation is the process in which a macrophage acquires the specialized features of a foam cell. A foam cell is a type of cell containing lipids in small vacuoles and typically seen in atherosclerotic lesions, as well as other conditions. [GOC:add, GOC:dph, GOC:tb]",
                "id": "GO:0010744"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "cellular response to reactive oxygen species",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a reactive oxygen species stimulus. Reactive oxygen species include singlet oxygen, superoxide, and oxygen free radicals. [GOC:mah]",
                "id": "GO:0034614"
            }, {
                "name": "Fc-epsilon receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of the Fc portion of immunoglobulin E (IgE) to an Fc-epsilon receptor on the surface of a signal-receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. The Fc portion of an immunoglobulin is its C-terminal constant region. [GOC:phg, PMID:12413516, PMID:15048725]",
                "id": "GO:0038095"
            }, {
                "name": "regulation of circadian rhythm",
                "definition": "Any process that modulates the frequency, rate or extent of a circadian rhythm. A circadian rhythm is a biological process in an organism that recurs with a regularity of approximately 24 hours. [GOC:dph, GOC:jl, GOC:tb]",
                "id": "GO:0042752"
            }, {
                "name": "positive regulation of apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043065"
            }, {
                "name": "response to cadmium ion",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cadmium (Cd) ion stimulus. [GOC:ai]",
                "id": "GO:0046686"
            }, {
                "name": "rhythmic process",
                "definition": "Any process pertinent to the generation and maintenance of rhythms in the physiology of an organism. [GOC:jid]",
                "id": "GO:0048511"
            }, {
                "name": "neuron development",
                "definition": "The process whose specific outcome is the progression of a neuron over time, from initial commitment of the cell to a specific fate, to the fully functional differentiated cell. [GOC:dph]",
                "id": "GO:0048666"
            }, {
                "name": "regulation of DNA binding transcription factor activity",
                "definition": "Any process that modulates the frequency, rate or extent of the activity of a transcription factor, any factor involved in the initiation or regulation of transcription. [GOC:ai]",
                "id": "GO:0051090"
            }, {
                "name": "protein localization to tricellular tight junction",
                "definition": "A process in which a protein is transported to, or maintained in, a location within a tricellular tight junction. [PMID:24889144]",
                "id": "GO:0061833"
            }, {
                "name": "cellular response to cadmium ion",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cadmium (Cd) ion stimulus. [GOC:mah]",
                "id": "GO:0071276"
            }, {
                "name": "cellular response to organic substance",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic substance stimulus. [GOC:mah]",
                "id": "GO:0071310"
            }, {
                "name": "positive regulation of podosome assembly",
                "definition": "Any process that activates or increases the rate or extent of podosome assembly. [GOC:mah, GOC:sl]",
                "id": "GO:0071803"
            }, {
                "name": "positive regulation of apoptotic signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of apoptotic signaling pathway. [GOC:mtg_apoptosis]",
                "id": "GO:2001235"
            }],
            "cellular_component": [{
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "JUN kinase activity",
                "definition": "Catalysis of the reaction: JUN + ATP = JUN phosphate + ADP. This reaction is the phosphorylation and activation of members of the JUN family, a gene family that encodes nuclear transcription factors. [GOC:bf, ISBN:0198506732]",
                "id": "GO:0004705"
            }, {
                "name": "MAP kinase activity",
                "definition": "Catalysis of the reaction: protein + ATP = protein phosphate + ADP. This reaction is the phosphorylation of proteins. Mitogen-activated protein kinase; a family of protein kinases that perform a crucial step in relaying signals from the plasma membrane to the nucleus. They are activated by a wide range of proliferation- or differentiation-inducing signals; activation is strong with agonists such as polypeptide growth factors and tumor-promoting phorbol esters, but weak (in most cell backgrounds) by stress stimuli. [GOC:ma, ISBN:0198547684]",
                "id": "GO:0004707"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "transcription factor binding",
                "definition": "Interacting selectively and non-covalently with a transcription factor, any protein required to initiate or regulate transcription. [ISBN:0198506732]",
                "id": "GO:0008134"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.24",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }]
        }
    }, "NR3C2": {
        "name": "nuclear receptor subfamily 3 group C member 2",
        "description": "This gene encodes the mineralocorticoid receptor, which mediates aldosterone actions on salt and water balance within restricted target cells. The protein functions as a ligand-dependent transcription factor that binds to mineralocorticoid response elements in order to transactivate target genes. Mutations in this gene cause autosomal dominant pseudohypoaldosteronism type I, a disorder characterized by urinary salt wasting. Defects in this gene are also associated with early onset hypertension with severe exacerbation in pregnancy. Alternative splicing results in multiple transcript variants. [provided by RefSeq, Oct 2009].",
        "entrez": "4306",
        "ensembl": "ENSG00000151623",
        "uniprot": "P08235",
        "hgnc": "7979",
        "go_terms": {
            "biological_process": [{
                "name": "transcription, DNA-templated",
                "definition": "The cellular synthesis of RNA on a template of DNA. [GOC:jl, GOC:txnOH]",
                "id": "GO:0006351"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that modulates the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006357"
            }, {
                "name": "transcription initiation from RNA polymerase II promoter",
                "definition": "Any process involved in the assembly of the RNA polymerase II preinitiation complex (PIC) at an RNA polymerase II promoter region of a DNA template, resulting in the subsequent synthesis of RNA from that promoter. The initiation phase includes PIC assembly and the formation of the first few bonds in the RNA chain, including abortive initiation, which occurs when the first few nucleotides are repeatedly synthesized and then released. Promoter clearance, or release, is the transition between the initiation and elongation phases of transcription. [GOC:mah, GOC:txnOH]",
                "id": "GO:0006367"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "steroid hormone mediated signaling pathway",
                "definition": "A series of molecular signals mediated by a steroid hormone binding to a receptor. [PMID:12606724]",
                "id": "GO:0043401"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "receptor complex",
                "definition": "Any protein complex that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:go_curators]",
                "id": "GO:0043235"
            }],
            "molecular_function": [{
                "name": "RNA polymerase II transcription factor activity, sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with a specific DNA sequence in order to modulate transcription by RNA polymerase II. The transcription factor may or may not also interact selectively with a protein or macromolecular complex. [GOC:txnOH]",
                "id": "GO:0000981"
            }, {
                "name": "DNA binding",
                "definition": "Any molecular function by which a gene product interacts selectively and non-covalently with DNA (deoxyribonucleic acid). [GOC:dph, GOC:jl, GOC:tb, GOC:vw]",
                "id": "GO:0003677"
            }, {
                "name": "DNA binding transcription factor activity",
                "definition": "Interacting selectively and non-covalently with a specific DNA sequence (sometimes referred to as a motif) within the regulatory region of a gene in order to modulate transcription. [GOC:curators, GOC:txnOH]",
                "id": "GO:0003700"
            }, {
                "name": "steroid hormone receptor activity",
                "definition": "Combining with a steroid hormone and transmitting the signal within the cell to initiate a change in cell activity or function. [GOC:signaling, PMID:14708019]",
                "id": "GO:0003707"
            }, {
                "name": "steroid binding",
                "definition": "Interacting selectively and non-covalently with a steroid, any of a large group of substances that have in common a ring system based on 1,2-cyclopentanoperhydrophenanthrene. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0005496"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "zinc ion binding",
                "definition": "Interacting selectively and non-covalently with zinc (Zn) ions. [GOC:ai]",
                "id": "GO:0008270"
            }, {
                "name": "lipid binding",
                "definition": "Interacting selectively and non-covalently with a lipid. [GOC:ai]",
                "id": "GO:0008289"
            }, {
                "name": "sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with DNA of a specific nucleotide composition, e.g. GC-rich DNA binding, or with a specific sequence motif or type of DNA e.g. promotor binding or rDNA binding. [GOC:jl]",
                "id": "GO:0043565"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "CASP6": {
        "name": "caspase 6",
        "description": "This gene encodes a member of the cysteine-aspartic acid protease (caspase) family of enzymes. Sequential activation of caspases plays a central role in the execution-phase of cell apoptosis. Caspases exist as inactive proenzymes which undergo proteolytic processing at conserved aspartic acid residues to produce two subunits, large and small, that dimerize to form the active enzyme. This protein is processed by caspases 7, 8 and 10, and is thought to function as a downstream enzyme in the caspase activation cascade. Alternative splicing of this gene results in multiple transcript variants that encode different isoforms. [provided by RefSeq, Oct 2015].",
        "entrez": "839",
        "ensembl": "ENSG00000138794",
        "uniprot": "P55212",
        "hgnc": "1507",
        "go_terms": {
            "biological_process": [{
                "name": "proteolysis",
                "definition": "The hydrolysis of proteins into smaller polypeptides and/or amino acids by cleavage of their peptide bonds. [GOC:bf, GOC:mah]",
                "id": "GO:0006508"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "epithelial cell differentiation",
                "definition": "The process in which a relatively unspecialized cell acquires specialized features of an epithelial cell, any of the cells making up an epithelium. [GOC:ecd, PMID:11839751]",
                "id": "GO:0030855"
            }, {
                "name": "regulation of apoptotic process",
                "definition": "Any process that modulates the occurrence or rate of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0042981"
            }, {
                "name": "cellular response to staurosporine",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a staurosporine stimulus. [CHEBI:15738, GOC:mah]",
                "id": "GO:0072734"
            }, {
                "name": "execution phase of apoptosis",
                "definition": "A stage of the apoptotic process that starts with the controlled breakdown of the cell through the action of effector caspases or other effector molecules (e.g. cathepsins, calpains etc.). Key steps of the execution phase are rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:mtg_apoptosis, PMID:21760595]",
                "id": "GO:0097194"
            }],
            "cellular_component": [{
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }],
            "molecular_function": [{
                "name": "endopeptidase activity",
                "definition": "Catalysis of the hydrolysis of internal, alpha-peptide bonds in a polypeptide chain. [http://merops.sanger.ac.uk/about/glossary.htm#ENDOPEPTIDASE]",
                "id": "GO:0004175"
            }, {
                "name": "cysteine-type endopeptidase activity",
                "definition": "Catalysis of the hydrolysis of internal, alpha-peptide bonds in a polypeptide chain by a mechanism in which the sulfhydryl group of a cysteine residue at the active center acts as a nucleophile. [GOC:mah, http://merops.sanger.ac.uk/about/glossary.htm#CATTYPE, http://merops.sanger.ac.uk/about/glossary.htm#ENDOPEPTIDASE]",
                "id": "GO:0004197"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "peptidase activity",
                "definition": "Catalysis of the hydrolysis of a peptide bond. A peptide bond is a covalent bond formed when the carbon atom from the carboxyl group of one amino acid shares electrons with the nitrogen atom from the amino group of a second amino acid. [GOC:jl, ISBN:0815332181]",
                "id": "GO:0008233"
            }, {
                "name": "cysteine-type peptidase activity",
                "definition": "Catalysis of the hydrolysis of peptide bonds in a polypeptide chain by a mechanism in which the sulfhydryl group of a cysteine residue at the active center acts as a nucleophile. [GOC:mah, http://merops.sanger.ac.uk/about/glossary.htm#CATTYPE]",
                "id": "GO:0008234"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "cysteine-type endopeptidase activity involved in execution phase of apoptosis",
                "definition": "Catalysis of the hydrolysis of internal, alpha-peptide bonds in a polypeptide chain by a mechanism in which the sulfhydryl group of a cysteine residue at the active center acts as a nucleophile, and contributing to the execution phase of apoptosis. [GOC:mtg_apoptosis, http://en.wikipedia.org/wiki/Caspase]",
                "id": "GO:0097200"
            }]
        },
        "kegg_pathway": {}
    }, "SUCNR1": {
        "name": "succinate receptor 1",
        "description": "This gene encodes a G-protein-coupled receptor for succinate, an intermediate molecule of the citric acid cycle. It is involved in the promotion of hematopoietic progenitor cell development, and it has a potential role in renovascular hypertension which has known correlations to renal failure, diabetes and atherosclerosis. [provided by RefSeq, Oct 2009].",
        "entrez": "56670",
        "ensembl": "ENSG00000198829",
        "uniprot": "Q9BXA5",
        "hgnc": "4542",
        "go_terms": {
            "biological_process": [{
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "biological_process",
                "definition": "Any process specifically pertinent to the functioning of integrated living units: cells, tissues, organs, and organisms. A process is a collection of molecular events with a defined beginning and end. [GOC:go_curators, GOC:isa_complete]",
                "id": "GO:0008150"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }]
        },
        "kegg_pathway": {}
    }, "CYP8B1": {
        "name": "cytochrome P450 family 8 subfamily B member 1",
        "description": "This gene encodes a member of the cytochrome P450 superfamily of enzymes. The cytochrome P450 proteins are monooxygenases which catalyze many reactions involved in drug metabolism and synthesis of cholesterol, steroids and other lipids. This endoplasmic reticulum membrane protein catalyzes the conversion of 7 alpha-hydroxy-4-cholesten-3-one into 7-alpha,12-alpha-dihydroxy-4-cholesten-3-one. The balance between these two steroids determines the relative amounts of cholic acid and chenodeoxycholic acid both of which are secreted in the bile and affect the solubility of cholesterol. This gene is unique among the cytochrome P450 genes in that it is intronless. [provided by RefSeq, Jul 2008].",
        "entrez": "1582",
        "ensembl": "ENSG00000180432",
        "uniprot": "Q9UNU6",
        "hgnc": "2653",
        "go_terms": {
            "biological_process": [{
                "name": "bile acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of bile acids, any of a group of steroid carboxylic acids occurring in bile. [GOC:go_curators]",
                "id": "GO:0006699"
            }, {
                "name": "sterol metabolic process",
                "definition": "The chemical reactions and pathways involving sterols, steroids with one or more hydroxyl groups and a hydrocarbon side-chain in the molecule. [ISBN:0198547684]",
                "id": "GO:0016125"
            }, {
                "name": "oxidation-reduction process",
                "definition": "A metabolic process that results in the removal or addition of one or more electrons to or from a substance, with or without the concomitant removal or addition of a proton or protons. [GOC:dhl, GOC:ecd, GOC:jh2, GOC:jid, GOC:mlg, GOC:rph]",
                "id": "GO:0055114"
            }],
            "cellular_component": [{
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "organelle membrane",
                "definition": "A membrane that is one of the two lipid bilayers of an organelle envelope or the outermost membrane of single membrane bound organelle. [GOC:dos, GOC:mah]",
                "id": "GO:0031090"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }],
            "molecular_function": [{
                "name": "monooxygenase activity",
                "definition": "Catalysis of the incorporation of one atom from molecular oxygen into a compound and the reduction of the other atom of oxygen to water. [http://www.onelook.com/, ISBN:0198506732]",
                "id": "GO:0004497"
            }, {
                "name": "iron ion binding",
                "definition": "Interacting selectively and non-covalently with iron (Fe) ions. [GOC:ai]",
                "id": "GO:0005506"
            }, {
                "name": "sterol 12-alpha-hydroxylase activity",
                "definition": "Catalysis of the reaction: a steroid + donor-H2 + O2 = 12-alpha-hydroxysteroid + H2O. [GOC:mah]",
                "id": "GO:0008397"
            }, {
                "name": "oxidoreductase activity",
                "definition": "Catalysis of an oxidation-reduction (redox) reaction, a reversible chemical reaction in which the oxidation state of an atom or atoms within a molecule is altered. One substrate acts as a hydrogen or electron donor and becomes oxidized, while the other acts as hydrogen or electron acceptor and becomes reduced. [GOC:go_curators]",
                "id": "GO:0016491"
            }, {
                "name": "oxidoreductase activity, acting on paired donors, with incorporation or reduction of molecular oxygen",
                "definition": "Catalysis of an oxidation-reduction (redox) reaction in which hydrogen or electrons are transferred from each of two donors, and molecular oxygen is reduced or incorporated into a donor. [GOC:mah]",
                "id": "GO:0016705"
            }, {
                "name": "oxygen binding",
                "definition": "Interacting selectively and non-covalently with oxygen (O2). [GOC:jl]",
                "id": "GO:0019825"
            }, {
                "name": "heme binding",
                "definition": "Interacting selectively and non-covalently with heme, any compound of iron complexed in a porphyrin (tetrapyrrole) ring. [CHEBI:30413, GOC:ai]",
                "id": "GO:0020037"
            }, {
                "name": "7alpha-hydroxycholest-4-en-3-one 12alpha-hydroxylase activity",
                "definition": "Catalysis of the reaction: 7alpha-hydroxycholest-4-en-3-one + H(+) + NADPH + O(2) = 7alpha,12alpha-dihydroxycholest-4-en-3-one + H(2)O + NADP(+). [EC:1.14.13.95, RHEA:10507]",
                "id": "GO:0033778"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {}
    }, "PDE2A": {
        "name": "phosphodiesterase 2A",
        "description": "",
        "entrez": "5138",
        "ensembl": "ENSG00000186642",
        "uniprot": "O00408",
        "hgnc": "8777",
        "go_terms": {
            "biological_process": [{
                "name": "negative regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0000122"
            }, {
                "name": "cAMP catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of the nucleotide cAMP (cyclic AMP, adenosine 3',5'-cyclophosphate). [ISBN:0198506732]",
                "id": "GO:0006198"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "cAMP-mediated signaling",
                "definition": "Any intracellular signal transduction in which the signal is passed on within the cell via cyclic AMP (cAMP). Includes production of cAMP, and downstream effectors that further transmit the signal within the cell. [GOC:signaling]",
                "id": "GO:0019933"
            }, {
                "name": "cGMP-mediated signaling",
                "definition": "Any intracellular signal transduction in which the signal is passed on within the cell via cyclic GMP (cGMP). Includes production of cGMP, and downstream effectors that further transmit the signal within the cell. [GOC:signaling]",
                "id": "GO:0019934"
            }, {
                "name": "negative regulation of protein import into nucleus, translocation",
                "definition": "Any process that stops, prevents or reduces the vectorial transfer of a protein from the cytoplasm into the nucleus, across the nuclear membrane. [GOC:mah]",
                "id": "GO:0033159"
            }, {
                "name": "cellular response to drug",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a drug stimulus. A drug is a substance used in the diagnosis, treatment or prevention of a disease. [GOC:sl]",
                "id": "GO:0035690"
            }, {
                "name": "cellular response to macrophage colony-stimulating factor stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a macrophage colony-stimulating factor stimulus. [GOC:yaf, PMID:14687666]",
                "id": "GO:0036006"
            }, {
                "name": "negative regulation of vascular permeability",
                "definition": "Any process that reduces the extent to which blood vessels can be pervaded by fluid. [GOC:jl]",
                "id": "GO:0043116"
            }, {
                "name": "positive regulation of vascular permeability",
                "definition": "Any process that increases the extent to which blood vessels can be pervaded by fluid. [GOC:jl]",
                "id": "GO:0043117"
            }, {
                "name": "negative regulation of cAMP-mediated signaling",
                "definition": "Any process which stops, prevents, or reduces the frequency, rate or extent of cAMP-mediated signaling, a series of molecular signals in which a cell uses cyclic AMP to convert an extracellular signal into a response. [GOC:jl]",
                "id": "GO:0043951"
            }, {
                "name": "cGMP catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of cyclic GMP, guanosine 3',5'-phosphate. [GOC:go_curators]",
                "id": "GO:0046069"
            }, {
                "name": "positive regulation of inflammatory response",
                "definition": "Any process that activates or increases the frequency, rate or extent of the inflammatory response. [GOC:ai]",
                "id": "GO:0050729"
            }, {
                "name": "establishment of endothelial barrier",
                "definition": "The establishment of a barrier between endothelial cell layers, such as those in the brain, lung or intestine, to exert specific and selective control over the passage of water and solutes, thus allowing formation and maintenance of compartments that differ in fluid and solute composition. [GOC:dph]",
                "id": "GO:0061028"
            }, {
                "name": "calcium ion transmembrane transport",
                "definition": "A process in which a calcium ion is transported from one side of a membrane to the other by means of some agent such as a transporter or pore. [GOC:mah]",
                "id": "GO:0070588"
            }, {
                "name": "cellular response to mechanical stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a mechanical stimulus. [GOC:mah]",
                "id": "GO:0071260"
            }, {
                "name": "cellular response to cAMP",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cAMP (cyclic AMP, adenosine 3',5'-cyclophosphate) stimulus. [GOC:mah]",
                "id": "GO:0071320"
            }, {
                "name": "cellular response to cGMP",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cGMP (cyclic GMP, guanosine 3',5'-cyclophosphate) stimulus. [GOC:mah]",
                "id": "GO:0071321"
            }, {
                "name": "cellular response to transforming growth factor beta stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a transforming growth factor beta stimulus. [GOC:ecd, PMID:15451575]",
                "id": "GO:0071560"
            }, {
                "name": "cellular response to 2,3,7,8-tetrachlorodibenzodioxine",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a 2,3,7,8-tetrachlorodibenzodioxine stimulus. [GO_REF:0000071, GOC:TermGenie, PMID:23196670]",
                "id": "GO:1904613"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "mitochondrial outer membrane",
                "definition": "The outer, i.e. cytoplasm-facing, lipid bilayer of the mitochondrial envelope. [GOC:ai]",
                "id": "GO:0005741"
            }, {
                "name": "mitochondrial inner membrane",
                "definition": "The inner, i.e. lumen-facing, lipid bilayer of the mitochondrial envelope. It is highly folded to form cristae. [GOC:ai]",
                "id": "GO:0005743"
            }, {
                "name": "mitochondrial matrix",
                "definition": "The gel-like material, with considerable fine structure, that lies in the matrix space, or lumen, of a mitochondrion. It contains the enzymes of the tricarboxylic acid cycle and, in some organisms, the enzymes concerned with fatty acid oxidation. [GOC:as, ISBN:0198506732]",
                "id": "GO:0005759"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "presynaptic membrane",
                "definition": "A specialized area of membrane of the axon terminal that faces the plasma membrane of the neuron or muscle fiber with which the axon terminal establishes a synaptic junction; many synaptic junctions exhibit structural presynaptic characteristics, such as conical, electron-dense internal protrusions, that distinguish it from the remainder of the axon plasma membrane. [GOC:jl, ISBN:0815316194]",
                "id": "GO:0042734"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: a nucleoside cyclic phosphate + H2O = a nucleoside phosphate. [GOC:mah]",
                "id": "GO:0004112"
            }, {
                "name": "3',5'-cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: nucleoside 3',5'-cyclic phosphate + H2O = nucleoside 5'-phosphate. [EC:3.1.4.17]",
                "id": "GO:0004114"
            }, {
                "name": "3',5'-cyclic-AMP phosphodiesterase activity",
                "definition": "Catalysis of the reaction: adenosine 3',5'-cyclic phosphate + H2O = adenosine 5'-phosphate. [GOC:ai]",
                "id": "GO:0004115"
            }, {
                "name": "cGMP-stimulated cyclic-nucleotide phosphodiesterase activity",
                "definition": "Catalysis of the reaction: nucleoside 3',5'-cyclic phosphate + H2O = nucleoside 5'-phosphate; catalytic activity is increased in the presence of cGMP. [GOC:mah]",
                "id": "GO:0004118"
            }, {
                "name": "calcium channel activity",
                "definition": "Enables the facilitated diffusion of a calcium ion (by an energy-independent process) involving passage through a transmembrane aqueous pore or channel without evidence for a carrier-mediated mechanism. [GOC:mtg_transport, GOC:pr, ISBN:0815340729]",
                "id": "GO:0005262"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "phosphoric diester hydrolase activity",
                "definition": "Catalysis of the hydrolysis of a phosphodiester to give a phosphomonoester and a free hydroxyl group. [EC:3.1.4, GOC:curators]",
                "id": "GO:0008081"
            }, {
                "name": "drug binding",
                "definition": "Interacting selectively and non-covalently with a drug, any naturally occurring or synthetic substance, other than a nutrient, that, when administered or applied to an organism, affects the structure or functioning of the organism; in particular, any such substance used in the diagnosis, prevention, or treatment of disease. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0008144"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "cAMP binding",
                "definition": "Interacting selectively and non-covalently with cAMP, the nucleotide cyclic AMP (adenosine 3',5'-cyclophosphate). [GOC:ai]",
                "id": "GO:0030552"
            }, {
                "name": "cGMP binding",
                "definition": "Interacting selectively and non-covalently with cGMP, the nucleotide cyclic GMP (guanosine 3',5'-cyclophosphate). [GOC:ai]",
                "id": "GO:0030553"
            }, {
                "name": "TPR domain binding",
                "definition": "Interacting selectively and non-covalently with a tetratricopeptide repeat (TPR) domain of a protein, the consensus sequence of which is defined by a pattern of small and large hydrophobic amino acids and a structure composed of helices. [GOC:mah]",
                "id": "GO:0030911"
            }, {
                "name": "protein homodimerization activity",
                "definition": "Interacting selectively and non-covalently with an identical protein to form a homodimer. [GOC:jl]",
                "id": "GO:0042803"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "3',5'-cyclic-GMP phosphodiesterase activity",
                "definition": "Catalysis of the reaction: guanosine 3',5'-cyclic phosphate + H2O = guanosine 5'-phosphate. [EC:3.1.4.35, MetaCyc:35-CYCLIC-GMP-PHOSPHODIESTERASE-RXN]",
                "id": "GO:0047555"
            }]
        },
        "kegg_pathway": {}
    }, "HCRTR1": {
        "name": "hypocretin receptor 1",
        "description": "The protein encoded by this gene is a G-protein coupled receptor involved in the regulation of feeding behavior. The encoded protein selectively binds the hypothalamic neuropeptide orexin A. A related gene (HCRTR2) encodes a G-protein coupled receptor that binds orexin A and orexin B. [provided by RefSeq, Jan 2009].",
        "entrez": "3061",
        "ensembl": "ENSG00000121764",
        "uniprot": "O43613",
        "hgnc": "4848",
        "go_terms": {
            "biological_process": [{
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "G-protein coupled receptor signaling pathway",
                "definition": "A series of molecular signals that proceeds with an activated receptor promoting the exchange of GDP for GTP on the alpha-subunit of an associated heterotrimeric G-protein complex. The GTP-bound activated alpha-G-protein then dissociates from the beta- and gamma-subunits to further transmit the signal within the cell. The pathway begins with receptor-ligand interaction, or for basal GPCR signaling the pathway begins with the receptor activating its G protein in the absence of an agonist, and ends with regulation of a downstream cellular process, e.g. transcription.  The pathway can start from the plasma membrane, Golgi or nuclear membrane (PMID:24568158 and PMID:16902576). [GOC:bf, GOC:mah, PMID:16902576, PMID:24568158, Wikipedia:G_protein-coupled_receptor]",
                "id": "GO:0007186"
            }, {
                "name": "neuropeptide signaling pathway",
                "definition": "The series of molecular signals generated as a consequence of a peptide neurotransmitter binding to a cell surface receptor. [GOC:mah, ISBN:0815316194]",
                "id": "GO:0007218"
            }, {
                "name": "chemical synaptic transmission",
                "definition": "The vesicular release of classical neurotransmitter molecules from a presynapse, across a chemical synapse, the subsequent activation of neurotransmitter receptors at the postsynapse of a target cell (neuron, muscle, or secretory cell) and the effects of this activation on the postsynaptic membrane potential and ionic composition of the postsynaptic cytosol. This process encompasses both spontaneous and evoked release of neurotransmitter and all parts of synaptic vesicle exocytosis. Evoked transmission starts with the arrival of an action potential at the presynapse. [GOC:jl, MeSH:D009435]",
                "id": "GO:0007268"
            }, {
                "name": "feeding behavior",
                "definition": "Behavior associated with the intake of food. [GOC:mah]",
                "id": "GO:0007631"
            }, {
                "name": "cellular response to hormone stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a hormone stimulus. [GOC:mah]",
                "id": "GO:0032870"
            }, {
                "name": "regulation of circadian sleep/wake cycle, sleep",
                "definition": "Any process that modulates the frequency, rate or extent of sleep; a readily reversible state of reduced awareness and metabolic activity that occurs periodically in many animals. [GOC:jl, ISBN:0192800981]",
                "id": "GO:0045187"
            }, {
                "name": "regulation of cytosolic calcium ion concentration",
                "definition": "Any process involved in the maintenance of an internal steady state of calcium ions within the cytosol of a cell or between the cytosol and its surroundings. [GOC:ai, GOC:mah, GOC:rph]",
                "id": "GO:0051480"
            }, {
                "name": "positive regulation of ERK1 and ERK2 cascade",
                "definition": "Any process that activates or increases the frequency, rate or extent of signal transduction mediated by the ERK1 and ERK2 cascade. [GOC:mah]",
                "id": "GO:0070374"
            }, {
                "name": "response to peptide",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a peptide stimulus. [GOC:pr, GOC:TermGenie]",
                "id": "GO:1901652"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "signal transducer activity",
                "definition": "Conveys a signal across a cell to trigger a change in cell function or state. A signal is a physical entity or change in state that is used to transfer information in order to trigger a response. [GOC:go_curators]",
                "id": "GO:0004871"
            }, {
                "name": "G-protein coupled receptor activity",
                "definition": "Combining with an extracellular signal and transmitting the signal across the membrane by activating an associated G-protein; promotes the exchange of GDP for GTP on the alpha subunit of a heterotrimeric G-protein complex. [GOC:bf, http://www.iuphar-db.org, Wikipedia:GPCR]",
                "id": "GO:0004930"
            }, {
                "name": "orexin receptor activity",
                "definition": "Combining with orexin to initiate a change in cell activity. [GOC:ai]",
                "id": "GO:0016499"
            }, {
                "name": "peptide hormone binding",
                "definition": "Interacting selectively and non-covalently with any peptide with hormonal activity in animals. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0017046"
            }, {
                "name": "peptide binding",
                "definition": "Interacting selectively and non-covalently with peptides, any of a group of organic compounds comprising two or more amino acids linked by peptide bonds. [GOC:jl]",
                "id": "GO:0042277"
            }]
        },
        "kegg_pathway": {}
    }, "LRRK2": {
        "name": "Human papillomavirus infection",
        "description": "This gene is a member of the leucine-rich repeat kinase family and encodes a protein with an ankryin repeat region, a leucine-rich repeat (LRR) domain, a kinase domain, a DFG-like motif, a RAS domain, a GTPase domain, a MLK-like domain, and a WD40 domain. The protein is present largely in the cytoplasm but also associates with the mitochondrial outer membrane. Mutations in this gene have been associated with Parkinson disease-8. [provided by RefSeq, Jul 2008].",
        "entrez": "120892",
        "ensembl": "ENSG00000188906",
        "uniprot": "Q5S007",
        "hgnc": "18618",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "activation of MAPKK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase kinase (MAPKK). [PMID:9561267]",
                "id": "GO:0000186"
            }, {
                "name": "activation of MAPK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase (MAPK). [PMID:9561267]",
                "id": "GO:0000187"
            }, {
                "name": "negative regulation of protein phosphorylation",
                "definition": "Any process that stops, prevents or reduces the rate of addition of phosphate groups to amino acids within a protein. [GOC:hjd]",
                "id": "GO:0001933"
            }, {
                "name": "positive regulation of protein phosphorylation",
                "definition": "Any process that activates or increases the frequency, rate or extent of addition of phosphate groups to amino acids within a protein. [GOC:hjd]",
                "id": "GO:0001934"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "endocytosis",
                "definition": "A vesicle-mediated transport process in which cells take up external materials or membrane constituents by the invagination of a small region of the plasma membrane to form a new membrane-bounded vesicle. [GOC:mah, ISBN:0198506732, ISBN:0716731363]",
                "id": "GO:0006897"
            }, {
                "name": "autophagy",
                "definition": "The cellular catabolic process in which cells digest parts of their own cytoplasm; allows for both recycling of macromolecular constituents under conditions of cellular stress and remodeling the intracellular structure for cell differentiation. [GOC:autophagy, ISBN:0198547684, PMID:11099404, PMID:9412464]",
                "id": "GO:0006914"
            }, {
                "name": "response to oxidative stress",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of oxidative stress, a state often resulting from exposure to high levels of reactive oxygen species, e.g. superoxide anions, hydrogen peroxide (H2O2), and hydroxyl radicals. [GOC:jl, PMID:12115731]",
                "id": "GO:0006979"
            }, {
                "name": "mitochondrion organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of a mitochondrion; includes mitochondrial morphogenesis and distribution, and replication of the mitochondrial genome as well as synthesis of new mitochondrial components. [GOC:dph, GOC:jl, GOC:mah, GOC:sgd_curators, PMID:9786946]",
                "id": "GO:0007005"
            }, {
                "name": "Golgi organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of the Golgi apparatus. [GOC:dph, GOC:jl, GOC:mah]",
                "id": "GO:0007030"
            }, {
                "name": "lysosome organization",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of a lysosome. A lysosome is a cytoplasmic, membrane-bounded organelle that is found in most animal cells and that contains a variety of hydrolases. [GOC:mah]",
                "id": "GO:0007040"
            }, {
                "name": "spermatogenesis",
                "definition": "The process of formation of spermatozoa, including spermatocytogenesis and spermiogenesis. [GOC:jid, ISBN:9780878933846]",
                "id": "GO:0007283"
            }, {
                "name": "neuromuscular junction development",
                "definition": "A process that is carried out at the cellular level which results in the assembly, arrangement of constituent parts, or disassembly of a neuromuscular junction. [GOC:mtg_OBO2OWL_2013]",
                "id": "GO:0007528"
            }, {
                "name": "determination of adult lifespan",
                "definition": "The control of viability and duration in the adult phase of the life-cycle. [GOC:ems]",
                "id": "GO:0008340"
            }, {
                "name": "cellular response to starvation",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of deprivation of nourishment. [GOC:jl]",
                "id": "GO:0009267"
            }, {
                "name": "regulation of gene expression",
                "definition": "Any process that modulates the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010468"
            }, {
                "name": "regulation of autophagy",
                "definition": "Any process that modulates the frequency, rate or extent of autophagy. Autophagy is the process in which cells digest parts of their own cytoplasm. [GOC:dph, GOC:tb]",
                "id": "GO:0010506"
            }, {
                "name": "positive regulation of autophagy",
                "definition": "Any process that activates, maintains or increases the rate of autophagy. Autophagy is the process in which cells digest parts of their own cytoplasm. [GOC:dph, GOC:tb]",
                "id": "GO:0010508"
            }, {
                "name": "regulation of protein kinase A signaling",
                "definition": "Any process that modulates the rate, frequency, or extent of protein kinase A signaling. PKA signaling is the series of reactions, mediated by the intracellular serine/threonine kinase protein kinase A, which occurs as a result of a single trigger reaction or compound. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010738"
            }, {
                "name": "negative regulation of protein processing",
                "definition": "Any process that decreases the rate, frequency or extent of protein maturation by peptide bond cleavage. [GOC:dph, GOC:mah, GOC:tb]",
                "id": "GO:0010955"
            }, {
                "name": "negative regulation of neuron projection development",
                "definition": "Any process that decreases the rate, frequency or extent of neuron projection development. Neuron projection development is the process whose specific outcome is the progression of a neuron projection over time, from its formation to the mature structure. A neuron projection is any process extending from a neural cell, such as axons or dendrites (collectively called neurites). [GOC:dph, GOC:tb]",
                "id": "GO:0010977"
            }, {
                "name": "regulation of neuron maturation",
                "definition": "Any process that modulates the frequency, rate or extent of neuron maturation, the process leading to the attainment of the full functional capacity of a neuron. This process is independent of morphogenetic change. [GOC:ef]",
                "id": "GO:0014041"
            }, {
                "name": "negative regulation of macroautophagy",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of macroautophagy. [GOC:go_curators]",
                "id": "GO:0016242"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "calcium-mediated signaling",
                "definition": "Any intracellular signal transduction in which the signal is passed on within the cell via calcium ions. [GOC:signaling]",
                "id": "GO:0019722"
            }, {
                "name": "striatum development",
                "definition": "The progression of the striatum over time from its initial formation until its mature state. The striatum is a region of the forebrain consisting of the caudate nucleus, putamen and fundus striati. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, ISBN:0878937420]",
                "id": "GO:0021756"
            }, {
                "name": "olfactory bulb development",
                "definition": "The progression of the olfactory bulb over time from its initial formation until its mature state. The olfactory bulb coordinates neuronal signaling involved in the perception of smell. It receives input from the sensory neurons and outputs to the olfactory cortex. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06, ISBN:0878937420]",
                "id": "GO:0021772"
            }, {
                "name": "tangential migration from the subventricular zone to the olfactory bulb",
                "definition": "The migration of cells in the telencephalon from the subventricular zone to the olfactory bulb in which cells move orthogonally to the direction of radial migration and do not use radial glial cell processes as substrates for migration. [GO_REF:0000021, GOC:cls, GOC:dgh, GOC:dph, GOC:jid, GOC:mtg_15jun06]",
                "id": "GO:0022028"
            }, {
                "name": "cell differentiation",
                "definition": "The process in which relatively unspecialized cells, e.g. embryonic or regenerative cells, acquire specialized structural and/or functional features that characterize the cells, tissues, or organs of the mature organism or some other relatively stable phase of the organism's life history. Differentiation includes the processes involved in commitment of a cell to a specific fate and its subsequent development to the mature state. [ISBN:0198506732]",
                "id": "GO:0030154"
            }, {
                "name": "positive regulation of protein ubiquitination",
                "definition": "Any process that activates or increases the frequency, rate or extent of the addition of ubiquitin groups to a protein. [GOC:mah]",
                "id": "GO:0031398"
            }, {
                "name": "negative regulation of protein binding",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of protein binding. [GOC:mah]",
                "id": "GO:0032091"
            }, {
                "name": "positive regulation of protein binding",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein binding. [GOC:mah]",
                "id": "GO:0032092"
            }, {
                "name": "positive regulation of proteasomal ubiquitin-dependent protein catabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the breakdown of a protein or peptide by hydrolysis of its peptide bonds, initiated by the covalent attachment of ubiquitin, and mediated by the proteasome. [GOC:mah]",
                "id": "GO:0032436"
            }, {
                "name": "positive regulation of protein import into nucleus, translocation",
                "definition": "Any process that activates or increases the vectorial transfer of a protein from the cytoplasm into the nucleus, across the nuclear membrane. [GOC:mah]",
                "id": "GO:0033160"
            }, {
                "name": "negative regulation of GTPase activity",
                "definition": "Any process that stops or reduces the rate of GTP hydrolysis by a GTPase. [GO_REF:0000058, GOC:mah, GOC:rb, GOC:TermGenie, PMID:16143306, PMID:24335649]",
                "id": "GO:0034260"
            }, {
                "name": "cellular response to oxidative stress",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of oxidative stress, a state often resulting from exposure to high levels of reactive oxygen species, e.g. superoxide anions, hydrogen peroxide (H2O2), and hydroxyl radicals. [GOC:mah]",
                "id": "GO:0034599"
            }, {
                "name": "cellular protein localization",
                "definition": "Any process in which a protein is transported to, and/or maintained in, a specific location at the level of a cell. Localization at the cellular level encompasses movement within the cell, from within the cell to the cell surface, or from one location to another at the surface of a cell. [GOC:mah]",
                "id": "GO:0034613"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "regulation of kidney size",
                "definition": "Any process that modulates the size of a kidney. [GOC:bf]",
                "id": "GO:0035564"
            }, {
                "name": "exploration behavior",
                "definition": "The specific behavior of an organism in response to a novel environment or stimulus. [GOC:BHF, GOC:pr, PMID:11682103, PMID:9767169]",
                "id": "GO:0035640"
            }, {
                "name": "locomotory exploration behavior",
                "definition": "The specific movement from place to place of an organism in response to a novel environment. [GOC:sart, PMID:17151232]",
                "id": "GO:0035641"
            }, {
                "name": "regulation of lysosomal lumen pH",
                "definition": "Any process that modulates the pH of the lysosomal lumen, measured by the concentration of the hydrogen ion. [GOC:rph]",
                "id": "GO:0035751"
            }, {
                "name": "regulation of locomotion",
                "definition": "Any process that modulates the frequency, rate or extent of locomotion of a cell or organism. [GOC:ems]",
                "id": "GO:0040012"
            }, {
                "name": "regulation of membrane potential",
                "definition": "Any process that modulates the establishment or extent of a membrane potential, the electric potential existing across any membrane arising from charges in the membrane itself and from the charges present in the media on either side of the membrane. [GOC:jl, GOC:mtg_cardio, GOC:tb, ISBN:0198506732]",
                "id": "GO:0042391"
            }, {
                "name": "positive regulation of programmed cell death",
                "definition": "Any process that activates or increases the frequency, rate or extent of programmed cell death, cell death resulting from activation of endogenous cellular processes. [GOC:jl]",
                "id": "GO:0043068"
            }, {
                "name": "positive regulation of MAP kinase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of MAP kinase activity. [GOC:dph, GOC:go_curators]",
                "id": "GO:0043406"
            }, {
                "name": "positive regulation of GTPase activity",
                "definition": "Any process that activates or increases the activity of a GTPase. [GOC:jl, GOC:mah]",
                "id": "GO:0043547"
            }, {
                "name": "GTP metabolic process",
                "definition": "The chemical reactions and pathways involving GTP, guanosine triphosphate. [GOC:go_curators]",
                "id": "GO:0046039"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "intracellular distribution of mitochondria",
                "definition": "Any process that establishes the spatial arrangement of mitochondria within the cell. [GOC:jid]",
                "id": "GO:0048312"
            }, {
                "name": "neuron projection morphogenesis",
                "definition": "The process in which the anatomical structures of a neuron projection are generated and organized. A neuron projection is any process extending from a neural cell, such as axons or dendrites. [GOC:mah]",
                "id": "GO:0048812"
            }, {
                "name": "mitochondrion localization",
                "definition": "Any process in which a mitochondrion or mitochondria are transported to, and/or maintained in, a specific location within the cell. [GOC:ai]",
                "id": "GO:0051646"
            }, {
                "name": "positive regulation of nitric-oxide synthase biosynthetic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways resulting in the formation of a nitric oxide synthase enzyme. [GOC:ai]",
                "id": "GO:0051770"
            }, {
                "name": "regulation of mitochondrial depolarization",
                "definition": "Any process that modulates the frequency, rate or extent of the change in the membrane potential of the mitochondria from negative to positive. [GOC:ai]",
                "id": "GO:0051900"
            }, {
                "name": "regulation of synaptic transmission, glutamatergic",
                "definition": "Any process that modulates the frequency, rate or extent of glutamatergic synaptic transmission, the process of communication from a neuron to another neuron across a synapse using the neurotransmitter glutamate. [GOC:ai]",
                "id": "GO:0051966"
            }, {
                "name": "canonical Wnt signaling pathway",
                "definition": "The series of molecular signals initiated by binding of a Wnt protein to a frizzled family receptor on the surface of the target cell, followed by propagation of the signal via beta-catenin, and ending with a change in transcription of target genes. In this pathway, the activated receptor signals via downstream effectors that result in the inhibition of beta-catenin phosphorylation, thereby preventing degradation of beta-catenin. Stabilized beta-catenin can then accumulate and travel to the nucleus to trigger changes in transcription of target genes. [GOC:bf, GOC:dph, PMID:11532397, PMID:19619488]",
                "id": "GO:0060070"
            }, {
                "name": "excitatory postsynaptic potential",
                "definition": "A process that leads to a temporary increase in postsynaptic potential due to the flow of positively charged ions into the postsynaptic cell. The flow of ions that causes an EPSP is an excitatory postsynaptic current (EPSC) and makes it easier for the neuron to fire an action potential. [GOC:dph, GOC:ef]",
                "id": "GO:0060079"
            }, {
                "name": "regulation of dopamine receptor signaling pathway",
                "definition": "Any process that modulates the frequency, rate or extent of a dopamine receptor signaling pathway activity. A dopamine receptor signaling pathway is the series of molecular signals generated as a consequence of a dopamine receptor binding to one of its physiological ligands. [GOC:dph]",
                "id": "GO:0060159"
            }, {
                "name": "positive regulation of dopamine receptor signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of the dopamine receptor protein signaling pathway. A dopamine receptor signaling pathway is the series of molecular signals generated as a consequence of a dopamine receptor binding to one of its physiological ligands. [GOC:dph]",
                "id": "GO:0060161"
            }, {
                "name": "regulation of canonical Wnt signaling pathway",
                "definition": "Any process that modulates the rate, frequency, or extent of the Wnt signaling pathway through beta-catenin, the series of molecular signals initiated by binding of a Wnt protein to a frizzled family receptor on the surface of the target cell, followed by propagation of the signal via beta-catenin, and ending with a change in transcription of target genes. [GOC:dph, GOC:sdb_2009, GOC:tb]",
                "id": "GO:0060828"
            }, {
                "name": "regulation of dendritic spine morphogenesis",
                "definition": "Any process that modulates the rate, frequency, or extent of dendritic spine morphogenesis, the process in which the anatomical structures of a dendritic spine are generated and organized. A dendritic spine is a protrusion from a dendrite and a specialized subcellular compartment involved in synaptic transmission. [GOC:dph]",
                "id": "GO:0061001"
            }, {
                "name": "protein localization to mitochondrion",
                "definition": "A process in which a protein is transported to, or maintained in, a location within the mitochondrion. [GOC:ecd]",
                "id": "GO:0070585"
            }, {
                "name": "neuron death",
                "definition": "The process of cell death in a neuron. [GOC:BHF, GOC:mah]",
                "id": "GO:0070997"
            }, {
                "name": "cellular response to manganese ion",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a manganese ion stimulus. [GOC:mah]",
                "id": "GO:0071287"
            }, {
                "name": "cellular response to organic cyclic compound",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic cyclic compound stimulus. [GOC:mah]",
                "id": "GO:0071407"
            }, {
                "name": "reactive oxygen species metabolic process",
                "definition": "The chemical reactions and pathways involving a reactive oxygen species, any molecules or ions formed by the incomplete one-electron reduction of oxygen. They contribute to the microbicidal activity of phagocytes, regulation of signal transduction and gene expression, and the oxidative damage to biopolymers. [CHEBI:26523, GOC:mah]",
                "id": "GO:0072593"
            }, {
                "name": "regulation of mitochondrial fission",
                "definition": "Any process that modulates the rate, frequency or extent of mitochondrial fission. Mitochondrial fission is the division of a mitochondrion within a cell to form two or more separate mitochondrial compartments. [GOC:ascb_2009, GOC:dph, GOC:tb]",
                "id": "GO:0090140"
            }, {
                "name": "positive regulation of canonical Wnt signaling pathway",
                "definition": "Any process that increases the rate, frequency, or extent of the Wnt signaling pathway through beta-catenin, the series of molecular signals initiated by binding of a Wnt protein to a frizzled family receptor on the surface of the target cell, followed by propagation of the signal via beta-catenin, and ending with a change in transcription of target genes. [GOC:tb]",
                "id": "GO:0090263"
            }, {
                "name": "negative regulation of excitatory postsynaptic potential",
                "definition": "Any process that prevents the establishment or decreases the extent of the excitatory postsynaptic potential (EPSP) which is a temporary increase in postsynaptic potential due to the flow of positively charged ions into the postsynaptic cell. The flow of ions that causes an EPSP is an excitatory postsynaptic current (EPSC) and makes it easier for the neuron to fire an action potential. [GOC:BHF]",
                "id": "GO:0090394"
            }, {
                "name": "neuron projection arborization",
                "definition": "The process in which the anatomical structures of a neuron projection are generated and organized into branches. A neuron projection is any process extending from a neural cell, such as axons or dendrites. [GOC:aruk, GOC:bc, PMID:17114044, PMID:23270857, PMID:23764288]",
                "id": "GO:0140058"
            }, {
                "name": "positive regulation of synaptic vesicle endocytosis",
                "definition": "Any process that activates or increases the frequency, rate or extent of synaptic vesicle endocytosis. [GOC:BHF, GOC:TermGenie]",
                "id": "GO:1900244"
            }, {
                "name": "regulation of neuron death",
                "definition": "Any process that modulates the frequency, rate or extent of neuron death. [GOC:rph, GOC:TermGenie]",
                "id": "GO:1901214"
            }, {
                "name": "negative regulation of neuron death",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of neuron death. [GOC:rph, GOC:TermGenie]",
                "id": "GO:1901215"
            }, {
                "name": "positive regulation of histone deacetylase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of histone deacetylase activity. [GOC:BHF, GOC:rl, GOC:TermGenie, PMID:20497126]",
                "id": "GO:1901727"
            }, {
                "name": "negative regulation of endoplasmic reticulum stress-induced intrinsic apoptotic signaling pathway",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of an endoplasmic reticulum stress-induced intrinsic apoptotic signaling pathway. [GOC:BHF, GOC:mtg_apoptosis, GOC:rl, GOC:TermGenie, PMID:20160352]",
                "id": "GO:1902236"
            }, {
                "name": "positive regulation of protein autoubiquitination",
                "definition": "Any process that activates or increases the frequency, rate or extent of protein autoubiquitination. [GOC:rb, GOC:TermGenie, PMID:24069405]",
                "id": "GO:1902499"
            }, {
                "name": "regulation of neuroblast proliferation",
                "definition": "Any process that modulates the frequency, rate or extent of neuroblast proliferation. [GO_REF:0000058, GOC:PARL, GOC:rl, GOC:TermGenie, PMID:21168496]",
                "id": "GO:1902692"
            }, {
                "name": "regulation of synaptic vesicle transport",
                "definition": "Any process that modulates the frequency, rate or extent of synaptic vesicle transport. [GO_REF:0000058, GOC:kmv, GOC:TermGenie, PMID:23527112]",
                "id": "GO:1902803"
            }, {
                "name": "negative regulation of late endosome to lysosome transport",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of late endosome to lysosome transport. [GO_REF:0000058, GOC:pad, GOC:PARL, GOC:TermGenie, PMID:23949442]",
                "id": "GO:1902823"
            }, {
                "name": "negative regulation of autophagosome assembly",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of autophagosome assembly. [GO_REF:0000058, GOC:als, GOC:autophagy, GOC:TermGenie, PMID:21975012]",
                "id": "GO:1902902"
            }, {
                "name": "negative regulation of thioredoxin peroxidase activity by peptidyl-threonine phosphorylation",
                "definition": "A peptidyl-threonine phosphorylation that results in negative regulation of thioredoxin peroxidase activity. [GO_REF:0000063, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:21850687]",
                "id": "GO:1903125"
            }, {
                "name": "negative regulation of hydrogen peroxide-induced cell death",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of hydrogen peroxide-induced cell death. [GO_REF:0000058, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:14749723, PMID:24252804]",
                "id": "GO:1903206"
            }, {
                "name": "negative regulation of protein targeting to mitochondrion",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of protein targeting to mitochondrion. [GO_REF:0000058, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:21370995]",
                "id": "GO:1903215"
            }, {
                "name": "negative regulation of protein processing involved in protein targeting to mitochondrion",
                "definition": "Any process that stops, prevents or reduces the frequency, rate or extent of protein processing involved in protein targeting to mitochondrion. [GO_REF:0000058, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:21370995]",
                "id": "GO:1903217"
            }, {
                "name": "cellular response to dopamine",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a dopamine stimulus. [GO_REF:0000071, GOC:mr, GOC:TermGenie, PMID:11118945]",
                "id": "GO:1903351"
            }, {
                "name": "positive regulation of microglial cell activation",
                "definition": "Any process that activates or increases the frequency, rate or extent of microglial cell activation. [GO_REF:0000058, GOC:BHF, GOC:nc, GOC:TermGenie, PMID:19100238]",
                "id": "GO:1903980"
            }, {
                "name": "positive regulation of tumor necrosis factor secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of tumor necrosis factor secretion. [GO_REF:0000058, GOC:TermGenie, PMID:15560120]",
                "id": "GO:1904469"
            }, {
                "name": "Wnt signalosome assembly",
                "definition": "The aggregation, arrangement and bonding together of a set of components to form a Wnt signalosome. [GO_REF:0000079, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:22899650]",
                "id": "GO:1904887"
            }, {
                "name": "regulation of retrograde transport, endosome to Golgi",
                "definition": "Any process that modulates the frequency, rate or extent of retrograde transport, endosome to Golgi. [GO_REF:0000058, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:23395371]",
                "id": "GO:1905279"
            }, {
                "name": "regulation of CAMKK-AMPK signaling cascade",
                "definition": "Any process that modulates the frequency, rate or extent of CAMKK-AMPK signaling cascade. [GO_REF:0000058, GOC:TermGenie, PMID:22128786]",
                "id": "GO:1905289"
            }, {
                "name": "regulation of branching morphogenesis of a nerve",
                "definition": "Any process that modulates the frequency, rate or extent of branching morphogenesis of a nerve. [GOC:BHF]",
                "id": "GO:2000172"
            }, {
                "name": "regulation of synaptic vesicle exocytosis",
                "definition": "Any process that modulates the frequency, rate or extent of synaptic vesicle exocytosis. [GOC:obol]",
                "id": "GO:2000300"
            }],
            "cellular_component": [{
                "name": "extracellular space",
                "definition": "That part of a multicellular organism outside the cells proper, usually taken to be outside the plasma membranes, and occupied by fluid. [ISBN:0198547684]",
                "id": "GO:0005615"
            }, {
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "mitochondrial outer membrane",
                "definition": "The outer, i.e. cytoplasm-facing, lipid bilayer of the mitochondrial envelope. [GOC:ai]",
                "id": "GO:0005741"
            }, {
                "name": "mitochondrial inner membrane",
                "definition": "The inner, i.e. lumen-facing, lipid bilayer of the mitochondrial envelope. It is highly folded to form cristae. [GOC:ai]",
                "id": "GO:0005743"
            }, {
                "name": "mitochondrial matrix",
                "definition": "The gel-like material, with considerable fine structure, that lies in the matrix space, or lumen, of a mitochondrion. It contains the enzymes of the tricarboxylic acid cycle and, in some organisms, the enzymes concerned with fatty acid oxidation. [GOC:as, ISBN:0198506732]",
                "id": "GO:0005759"
            }, {
                "name": "lysosome",
                "definition": "A small lytic vacuole that has cell cycle-independent morphology and is found in most animal cells and that contains a variety of hydrolases, most of which have their maximal activities in the pH range 5-6. The contained enzymes display latency if properly isolated. About 40 different lysosomal hydrolases are known and lysosomes have a great variety of morphologies and functions. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0005764"
            }, {
                "name": "endosome",
                "definition": "A vacuole to which materials ingested by endocytosis are delivered. [ISBN:0198506732, PMID:19696797]",
                "id": "GO:0005768"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "Golgi-associated vesicle",
                "definition": "Any vesicle associated with the Golgi complex and involved in mediating transport within the Golgi or between the Golgi and other parts of the cell. [GOC:mah]",
                "id": "GO:0005798"
            }, {
                "name": "trans-Golgi network",
                "definition": "The network of interconnected tubular and cisternal structures located within the Golgi apparatus on the side distal to the endoplasmic reticulum, from which secretory vesicles emerge. The trans-Golgi network is important in the later stages of protein secretion where it is thought to play a key role in the sorting and targeting of secreted proteins to the correct destination. [GOC:vw, ISBN:0815316194]",
                "id": "GO:0005802"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "microvillus",
                "definition": "Thin cylindrical membrane-covered projections on the surface of an animal cell containing a core bundle of actin filaments. Present in especially large numbers on the absorptive surface of intestinal cells. [ISBN:0813516194]",
                "id": "GO:0005902"
            }, {
                "name": "synaptic vesicle",
                "definition": "A secretory organelle, typically 50 nm in diameter, of presynaptic nerve terminals; accumulates in high concentrations of neurotransmitters and secretes these into the synaptic cleft by fusion with the 'active zone' of the presynaptic plasma membrane. [PMID:10099709, PMID:12563290]",
                "id": "GO:0008021"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "inclusion body",
                "definition": "A discrete intracellular part formed of aggregated molecules such as proteins or other biopolymers. [GOC:mah, PMID:11121744]",
                "id": "GO:0016234"
            }, {
                "name": "cell junction",
                "definition": "A cellular component that forms a specialized region of connection between two or more cells or between a cell and the extracellular matrix. At a cell junction, anchoring proteins extend through the plasma membrane to link cytoskeletal proteins in one cell to cytoskeletal proteins in neighboring cells or to proteins in the extracellular matrix. [GOC:mah, http://www.vivo.colostate.edu/hbooks/cmb/cells/pmemb/junctions_a.html, ISBN:0198506732]",
                "id": "GO:0030054"
            }, {
                "name": "axon",
                "definition": "The long process of a neuron that conducts nerve impulses, usually away from the cell body to the terminals and varicosities, which are sites of storage and release of neurotransmitter. [GOC:nln, ISBN:0198506732]",
                "id": "GO:0030424"
            }, {
                "name": "dendrite",
                "definition": "A neuron projection that has a short, tapering, morphology. Dendrites receive and integrate signals from other neurons or from sensory stimuli, and conduct nerve impulses towards the axon or the cell body. In most neurons, the impulse is conveyed from dendrites to axon via the cell body, but in some types of unipolar neuron, the impulse does not travel via the cell body. [GOC:aruk, GOC:bc, GOC:dos, GOC:mah, GOC:nln, ISBN:0198506732]",
                "id": "GO:0030425"
            }, {
                "name": "growth cone",
                "definition": "The migrating motile tip of a growing neuron projection, where actin accumulates, and the actin cytoskeleton is the most dynamic. [GOC:aruk, GOC:bc, ISBN:0815316194, PMID:10082468]",
                "id": "GO:0030426"
            }, {
                "name": "intracellular ribonucleoprotein complex",
                "definition": "An intracellular macromolecular complex containing both protein and RNA molecules. [GOC:krc, GOC:vesicles]",
                "id": "GO:0030529"
            }, {
                "name": "synaptic vesicle membrane",
                "definition": "The lipid bilayer surrounding a synaptic vesicle. [GOC:mah]",
                "id": "GO:0030672"
            }, {
                "name": "cytoplasmic vesicle",
                "definition": "A vesicle found in the cytoplasm of a cell. [GOC:ai, GOC:mah, GOC:vesicles]",
                "id": "GO:0031410"
            }, {
                "name": "mitochondrial membrane",
                "definition": "Either of the lipid bilayers that surround the mitochondrion and form the mitochondrial envelope. [GOC:mah, NIF_Subcellular:sao1045389829]",
                "id": "GO:0031966"
            }, {
                "name": "cytoplasmic side of mitochondrial outer membrane",
                "definition": "The external (cytoplasmic) face of the mitochondrial outer membrane. [GOC:mah]",
                "id": "GO:0032473"
            }, {
                "name": "dendrite cytoplasm",
                "definition": "All of the contents of a dendrite, excluding the surrounding plasma membrane. [GOC:mah]",
                "id": "GO:0032839"
            }, {
                "name": "cell projection",
                "definition": "A prolongation or process extending from a cell, e.g. a flagellum or axon. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0042995"
            }, {
                "name": "neuron projection",
                "definition": "A prolongation or process extending from a nerve cell, e.g. an axon or dendrite. [GOC:jl, http://www.cogsci.princeton.edu/~wn/]",
                "id": "GO:0043005"
            }, {
                "name": "neuronal cell body",
                "definition": "The portion of a neuron that includes the nucleus, but excludes cell projections such as axons and dendrites. [GOC:go_curators]",
                "id": "GO:0043025"
            }, {
                "name": "terminal bouton",
                "definition": "Terminal inflated portion of the axon, containing the specialized apparatus necessary to release neurotransmitters. The axon terminus is considered to be the whole region of thickening and the terminal bouton is a specialized region of it. [GOC:dph, GOC:mc, GOC:nln, PMID:10218156, PMID:8409967]",
                "id": "GO:0043195"
            }, {
                "name": "perikaryon",
                "definition": "The portion of the cell soma (neuronal cell body) that excludes the nucleus. [GOC:jl]",
                "id": "GO:0043204"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }, {
                "name": "amphisome",
                "definition": "Intermediate organelles formed during macroautophagy through the fusion between autophagosomes and endosomes. [GOC:autophagy, GOC:sart, PMID:19008921, PMID:9705327]",
                "id": "GO:0044753"
            }, {
                "name": "autolysosome",
                "definition": "A type of secondary lysosome in which a primary lysosome has fused with the outer membrane of an autophagosome. It is involved in the second step of autophagy in which it degrades contents with acidic lysosomal hydrolases. [GOC:sart, NIF_Subcellular:sao8444068431, PMID:19008921]",
                "id": "GO:0044754"
            }, {
                "name": "membrane raft",
                "definition": "Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]",
                "id": "GO:0045121"
            }, {
                "name": "synapse",
                "definition": "The junction between a nerve fiber of one neuron and another neuron, muscle fiber or glial cell. As the nerve fiber approaches the synapse it enlarges into a specialized structure, the presynaptic nerve ending, which contains mitochondria and synaptic vesicles. At the tip of the nerve ending is the presynaptic membrane; facing it, and separated from it by a minute cleft (the synaptic cleft) is a specialized area of membrane on the receiving cell, known as the postsynaptic membrane. In response to the arrival of nerve impulses, the presynaptic nerve ending secretes molecules of neurotransmitters into the synaptic cleft. These diffuse across the cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045202"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }, {
                "name": "multivesicular body, internal vesicle",
                "definition": "A membrane-bounded vesicle wholly contained within a multivesicular body. [GOC:pde, PMID:21183070]",
                "id": "GO:0097487"
            }, {
                "name": "postsynapse",
                "definition": "The part of a synapse that is part of the post-synaptic cell. [GOC:dos]",
                "id": "GO:0098794"
            }, {
                "name": "caveola neck",
                "definition": "A membrane microdomain that forms a necklace around the bulb (crater) of a caveola.  Intramembrane particles are concentrated in this region and cytoskeletal components, including actin, are highly enriched in the area underlying it. [GOC:pad, GOC:PARL, PMID:17227843]",
                "id": "GO:0099400"
            }, {
                "name": "Wnt signalosome",
                "definition": "A multiprotein protein complex containing membrane-localized Wnt receptors and cytosolic protein complexes, which is capable of transmitting the Wnt signal. Contains at least a Wnt protein, LRP5 or LRP6, a member of the Frizzled (Fz) family, Axin and and a Dishevelled (DVL) protein. [GOC:bf, GOC:PARL, PMID:22899650, PMID:25336320]",
                "id": "GO:1990909"
            }],
            "molecular_function": [{
                "name": "SNARE binding",
                "definition": "Interacting selectively and non-covalently with a SNARE (soluble N-ethylmaleimide-sensitive factor attached protein receptor) protein. [PMID:12642621]",
                "id": "GO:0000149"
            }, {
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "actin binding",
                "definition": "Interacting selectively and non-covalently with monomeric or multimeric forms of actin, including actin filaments. [GOC:clt]",
                "id": "GO:0003779"
            }, {
                "name": "GTPase activity",
                "definition": "Catalysis of the reaction: GTP + H2O = GDP + phosphate. [ISBN:0198547684]",
                "id": "GO:0003924"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "MAP kinase kinase activity",
                "definition": "Catalysis of the concomitant phosphorylation of threonine (T) and tyrosine (Y) residues in a Thr-Glu-Tyr (TEY) thiolester sequence in a MAP kinase (MAPK) substrate. [ISBN:0198547684]",
                "id": "GO:0004708"
            }, {
                "name": "GTPase activator activity",
                "definition": "Binds to and increases the activity of a GTPase, an enzyme that catalyzes the hydrolysis of GTP. [GOC:mah]",
                "id": "GO:0005096"
            }, {
                "name": "binding",
                "definition": "The selective, non-covalent, often stoichiometric, interaction of a molecule with one or more specific sites on another molecule. [GOC:ceb, GOC:mah, ISBN:0198506732]",
                "id": "GO:0005488"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "GTP binding",
                "definition": "Interacting selectively and non-covalently with GTP, guanosine triphosphate. [GOC:ai]",
                "id": "GO:0005525"
            }, {
                "name": "microtubule binding",
                "definition": "Interacting selectively and non-covalently with microtubules, filaments composed of tubulin monomers. [GOC:krc]",
                "id": "GO:0008017"
            }, {
                "name": "tubulin binding",
                "definition": "Interacting selectively and non-covalently with monomeric or multimeric forms of tubulin, including microtubules. [GOC:clt]",
                "id": "GO:0015631"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "Rho GTPase binding",
                "definition": "Interacting selectively and non-covalently with Rho protein, any member of the Rho subfamily of the Ras superfamily of monomeric GTPases. Proteins in the Rho subfamily are involved in relaying signals from cell-surface receptors to the actin cytoskeleton. [ISBN:0198547684, PMID:12581856]",
                "id": "GO:0017048"
            }, {
                "name": "syntaxin-1 binding",
                "definition": "Interacting selectively and non-covalently with the SNAP receptor syntaxin-1. [GOC:ai]",
                "id": "GO:0017075"
            }, {
                "name": "receptor signaling complex scaffold activity",
                "definition": "Functions to provide a physical support for the assembly of a multiprotein receptor signaling complex. [GOC:mah]",
                "id": "GO:0030159"
            }, {
                "name": "clathrin binding",
                "definition": "Interacting selectively and non-covalently with a clathrin heavy or light chain, the main components of the coat of coated vesicles and coated pits, and which also occurs in synaptic vesicles. [GOC:jl, GOC:mah, ISBN:0198506732]",
                "id": "GO:0030276"
            }, {
                "name": "GTP-dependent protein kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein serine/threonine = ADP + protein serine/threonine phosphate, dependent on the presence of GTP. [GOC:ecd, PMID:17200152]",
                "id": "GO:0034211"
            }, {
                "name": "peroxidase inhibitor activity",
                "definition": "Interacts with, and stops, prevents or reduces the activity of a peroxidase. [GOC:bf, GOC:PARL]",
                "id": "GO:0036479"
            }, {
                "name": "co-receptor binding",
                "definition": "Interacting selectively and non-covalently with a coreceptor. A coreceptor acts in cooperation with a primary receptor to transmit a signal within the cell. [GOC:bf, GOC:jl]",
                "id": "GO:0039706"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "protein homodimerization activity",
                "definition": "Interacting selectively and non-covalently with an identical protein to form a homodimer. [GOC:jl]",
                "id": "GO:0042803"
            }, {
                "name": "ion channel binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on an ion channel, a protein complex that spans a membrane and forms a water-filled channel across the phospholipid bilayer allowing selective ion transport down its electrochemical gradient. [GOC:BHF, GOC:jl]",
                "id": "GO:0044325"
            }, {
                "name": "protein kinase A binding",
                "definition": "Interacting selectively and non-covalently with any subunit of protein kinase A. [GOC:ai]",
                "id": "GO:0051018"
            }, {
                "name": "beta-catenin destruction complex binding",
                "definition": "Interacting selectively and non-covalently with a beta-catenin destruction complex. [GO_REF:000102, GOC:bf, GOC:PARL, GOC:TermGenie, PMID:22899650]",
                "id": "GO:1904713"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.1",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }, {
                "pathway": "04151",
                "name": "PI3K-Akt signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }, {
                "pathway": "04926",
                "name": "Relaxin signaling pathway",
                "class": "Organismal Systems; Endocrine system"
            }, {
                "pathway": "05165",
                "name": "Human papillomavirus infection",
                "class": "Human Diseases; Infectious disease: viral"
            }]
        }
    }, "ACVR1B": {
        "name": "activin A receptor type 1B",
        "description": "This gene encodes an activin A type IB receptor. Activins are dimeric growth and differentiation factors which belong to the transforming growth factor-beta (TGF-beta) superfamily of structurally related signaling proteins. Activins signal through a heteromeric complex of receptor serine kinases which include at least two type I and two type II receptors. This protein is a type I receptor which is essential for signaling. Mutations in this gene are associated with pituitary tumors. Alternate splicing results in multiple transcript variants.[provided by RefSeq, Jun 2010].",
        "entrez": "91",
        "ensembl": "ENSG00000135503",
        "uniprot": "P36896",
        "hgnc": "172",
        "go_terms": {
            "biological_process": [{
                "name": "G1/S transition of mitotic cell cycle",
                "definition": "The mitotic cell cycle transition by which a cell in G1 commits to S phase. The process begins with the build up of G1 cyclin-dependent kinase (G1 CDK), resulting in the activation of transcription of G1 cyclins. The process ends with the positive feedback of the G1 cyclins on the G1 CDK which commits the cell to S phase, in which DNA replication is initiated. [GOC:mtg_cell_cycle]",
                "id": "GO:0000082"
            }, {
                "name": "in utero embryonic development",
                "definition": "The process whose specific outcome is the progression of the embryo in the uterus over time, from formation of the zygote in the oviduct, to birth. An example of this process is found in Mus musculus. [GOC:go_curators, GOC:mtg_sensu]",
                "id": "GO:0001701"
            }, {
                "name": "hair follicle development",
                "definition": "The process whose specific outcome is the progression of the hair follicle over time, from its formation to the mature structure. A hair follicle is a tube-like opening in the epidermis where the hair shaft develops and into which the sebaceous glands open. [GOC:dph, UBERON:0002073]",
                "id": "GO:0001942"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "transmembrane receptor protein serine/threonine kinase signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to a receptor on the surface of the target cell where the receptor possesses serine/threonine kinase activity, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling]",
                "id": "GO:0007178"
            }, {
                "name": "central nervous system development",
                "definition": "The process whose specific outcome is the progression of the central nervous system over time, from its formation to the mature structure. The central nervous system is the core nervous system that serves an integrating and coordinating function. In vertebrates it consists of the brain and spinal cord. In those invertebrates with a central nervous system it typically consists of a brain, cerebral ganglia and a nerve cord. [GOC:bf, GOC:jid, ISBN:0582227089]",
                "id": "GO:0007417"
            }, {
                "name": "regulation of signal transduction",
                "definition": "Any process that modulates the frequency, rate or extent of signal transduction. [GOC:sm]",
                "id": "GO:0009966"
            }, {
                "name": "positive regulation of gene expression",
                "definition": "Any process that increases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010628"
            }, {
                "name": "negative regulation of gene expression",
                "definition": "Any process that decreases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010629"
            }, {
                "name": "positive regulation of pathway-restricted SMAD protein phosphorylation",
                "definition": "Any process that increases the rate, frequency or extent of pathway-restricted SMAD protein phosphorylation. Pathway-restricted SMAD proteins and common-partner SMAD proteins are involved in the transforming growth factor beta receptor signaling pathways. [GOC:dph, GOC:tb]",
                "id": "GO:0010862"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-threonine phosphorylation",
                "definition": "The phosphorylation of peptidyl-threonine to form peptidyl-O-phospho-L-threonine. [RESID:AA0038]",
                "id": "GO:0018107"
            }, {
                "name": "signal transduction by protein phosphorylation",
                "definition": "A process in which the transfer of one or more phosphate groups to a substrate transmits a signal to the phosphorylated substrate. [GOC:mtg_signal, GOC:signaling]",
                "id": "GO:0023014"
            }, {
                "name": "negative regulation of cell growth",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, extent or direction of cell growth. [GOC:go_curators]",
                "id": "GO:0030308"
            }, {
                "name": "activin receptor signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to an activin receptor on the surface of a target cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:rl, GOC:signaling]",
                "id": "GO:0032924"
            }, {
                "name": "positive regulation of activin receptor signaling pathway",
                "definition": "Any process that activates or increases the frequency, rate or extent of the activity of any activin receptor signaling pathway. [GOC:BHF, GOC:rl]",
                "id": "GO:0032927"
            }, {
                "name": "nodal signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a nodal protein to an activin receptor on the surface of a target cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:BHF, GOC:vk, PMID:17287255]",
                "id": "GO:0038092"
            }, {
                "name": "positive regulation of erythrocyte differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of erythrocyte differentiation. [GOC:go_curators]",
                "id": "GO:0045648"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "development of primary female sexual characteristics",
                "definition": "The process whose specific outcome is the progression of the primary female sexual characteristics over time, from their formation to the mature structure. The primary female sexual characteristics are the ovaries, and they develop in response to sex hormone secretion. [GOC:ai]",
                "id": "GO:0046545"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "extrinsic apoptotic signaling pathway",
                "definition": "A series of molecular signals in which a signal is conveyed from the cell surface to trigger the apoptotic death of a cell. The pathway starts with either a ligand binding to a cell surface receptor, or a ligand being withdrawn from a cell surface receptor (e.g. in the case of signaling by dependence receptors), and ends when the execution phase of apoptosis is triggered. [GOC:mtg_apoptosis, GOC:yaf, PMID:17340152]",
                "id": "GO:0097191"
            }, {
                "name": "positive regulation of trophoblast cell migration",
                "definition": "Any process that activates or increases the frequency, rate or extent of trophoblast cell migration. [GOC:BHF, GOC:TermGenie]",
                "id": "GO:1901165"
            }],
            "cellular_component": [{
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "receptor complex",
                "definition": "Any protein complex that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:go_curators]",
                "id": "GO:0043235"
            }, {
                "name": "activin receptor complex",
                "definition": "A protein complex that acts as an activin receptor. Heterodimeric activin receptors, comprising one Type I activin receptor and one Type II receptor polypeptide, and heterotrimeric receptors have been observed. [PMID:8307945, PMID:8622651]",
                "id": "GO:0048179"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "transmembrane receptor protein serine/threonine kinase activity",
                "definition": "Combining with a signal and transmitting the signal from one side of the membrane to the other to initiate a change in cell activity by catalysis of the reaction: ATP protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [EC:2.7.11.30]",
                "id": "GO:0004675"
            }, {
                "name": "signal transducer, downstream of receptor, with serine/threonine kinase activity",
                "definition": "Conveys a signal from an upstream receptor or intracellular signal transducer by catalysis of the reaction: ATP protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [EC:2.7.11.13, GOC:bf, GOC:mah]",
                "id": "GO:0004702"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "activin receptor activity, type I",
                "definition": "Combining with activin-bound type II activin receptor to initiate a change in cell activity; upon binding, acts as a downstream transducer of activin signals. [GOC:mah, PMID:8622651]",
                "id": "GO:0016361"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "activin-activated receptor activity",
                "definition": "Combining with activin and transmitting the signal from one side of the membrane to the other to initiate a change in cell activity. Activin is one of two gonadal glycoproteins related to transforming growth factor beta. [GOC:mah, GOC:signaling, ISBN:0198506732]",
                "id": "GO:0017002"
            }, {
                "name": "growth factor binding",
                "definition": "Interacting selectively and non-covalently with any growth factor, proteins or polypeptides that stimulate a cell or organism to grow or proliferate. [GOC:curators]",
                "id": "GO:0019838"
            }, {
                "name": "ubiquitin protein ligase binding",
                "definition": "Interacting selectively and non-covalently with a ubiquitin protein ligase enzyme, any of the E3 proteins. [GOC:vp]",
                "id": "GO:0031625"
            }, {
                "name": "inhibin binding",
                "definition": "Interacting selectively and non-covalently with an inhibin monomer, any of the polypeptides that combine to form activin and inhibin dimers. [GOC:BHF, GOC:mah]",
                "id": "GO:0034711"
            }, {
                "name": "SMAD binding",
                "definition": "Interacting selectively and non-covalently with a SMAD signaling protein. [GOC:ai]",
                "id": "GO:0046332"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "activin binding",
                "definition": "Interacting selectively and non-covalently with activin, a dimer of inhibin-beta subunits. [GOC:jid, GOC:mah]",
                "id": "GO:0048185"
            }]
        },
        "kegg_pathway": {}
    }, "LIPG": {
        "name": "lipase G, endothelial type",
        "description": "The protein encoded by this gene has substantial phospholipase activity and may be involved in lipoprotein metabolism and vascular biology. This protein is designated a member of the TG lipase family by its sequence and characteristic lid region which provides substrate specificity for enzymes of the TG lipase family. [provided by RefSeq, Jul 2008].",
        "entrez": "9388",
        "ensembl": "ENSG00000101670",
        "uniprot": "Q9Y5X9",
        "hgnc": "6623",
        "go_terms": {
            "biological_process": [{
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "response to nutrient",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a nutrient stimulus. [GOC:go_curators]",
                "id": "GO:0007584"
            }, {
                "name": "cell proliferation",
                "definition": "The multiplication or reproduction of cells, resulting in the expansion of a cell population. [GOC:mah, GOC:mb]",
                "id": "GO:0008283"
            }, {
                "name": "phospholipid catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of phospholipids, any lipid containing phosphoric acid as a mono- or diester. [ISBN:0198506732]",
                "id": "GO:0009395"
            }, {
                "name": "positive regulation of high-density lipoprotein particle clearance",
                "definition": "Any process that increases the rate, frequency or extent of high-density lipoprotein particle clearance. High-density lipoprotein particle clearance is the process in which a high-density lipoprotein particle is removed from the blood via receptor-mediated endocytosis and its constituent parts degraded. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010983"
            }, {
                "name": "lipid catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. [GOC:go_curators]",
                "id": "GO:0016042"
            }, {
                "name": "positive regulation of cholesterol transport",
                "definition": "Any process that activates or increases the frequency, rate or extent of the directed movement of cholesterol into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:mah]",
                "id": "GO:0032376"
            }, {
                "name": "high-density lipoprotein particle remodeling",
                "definition": "The acquisition, loss or modification of a protein or lipid within a high-density lipoprotein particle, including the hydrolysis of triglyceride by hepatic lipase, with the subsequent loss of free fatty acid, and the transfer of cholesterol esters from LDL to a triglyceride-rich lipoprotein particle by cholesteryl ester transfer protein (CETP), with the simultaneous transfer of triglyceride to LDL. [GOC:BHF, GOC:expert_pt, GOC:mah, GOC:rl]",
                "id": "GO:0034375"
            }, {
                "name": "cholesterol homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of cholesterol within an organism or cell. [GOC:go_curators]",
                "id": "GO:0042632"
            }, {
                "name": "reverse cholesterol transport",
                "definition": "The directed movement of peripheral cell cholesterol, cholest-5-en-3-beta-ol, towards the liver for catabolism. [GOC:ecd, PMID:7751809]",
                "id": "GO:0043691"
            }, {
                "name": "regulation of lipoprotein metabolic process",
                "definition": "Any process that modulates the frequency, rate or extent of the chemical reactions and pathways involving lipoproteins, any conjugated, water-soluble protein in which the nonprotein group consists of a lipid or lipids. [GOC:ai]",
                "id": "GO:0050746"
            }, {
                "name": "phospholipid homeostasis",
                "definition": "Any process involved in the maintenance of an internal steady state of phospholipid within an organism or cell. [GOC:BHF, GOC:rl]",
                "id": "GO:0055091"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "extracellular space",
                "definition": "That part of a multicellular organism outside the cells proper, usually taken to be outside the plasma membranes, and occupied by fluid. [ISBN:0198547684]",
                "id": "GO:0005615"
            }, {
                "name": "early endosome",
                "definition": "A membrane-bounded organelle that receives incoming material from primary endocytic vesicles that have been generated by clathrin-dependent and clathrin-independent endocytosis; vesicles fuse with the early endosome to deliver cargo for sorting into recycling or degradation pathways. [GOC:mah, NIF_Subcellular:nlx_subcell_20090701, PMID:19696797]",
                "id": "GO:0005769"
            }, {
                "name": "Golgi apparatus",
                "definition": "A compound membranous cytoplasmic organelle of eukaryotic cells, consisting of flattened, ribosome-free vesicles arranged in a more or less regular stack. The Golgi apparatus differs from the endoplasmic reticulum in often having slightly thicker membranes, appearing in sections as a characteristic shallow semicircle so that the convex side (cis or entry face) abuts the endoplasmic reticulum, secretory vesicles emerging from the concave side (trans or exit face). In vertebrate cells there is usually one such organelle, while in invertebrates and plants, where they are known usually as dictyosomes, there may be several scattered in the cytoplasm. The Golgi apparatus processes proteins produced on the ribosomes of the rough endoplasmic reticulum; such processing includes modification of the core oligosaccharides of glycoproteins, and the sorting and packaging of proteins for transport to a variety of cellular locations. Three different regions of the Golgi are now recognized both in terms of structure and function: cis, in the vicinity of the cis face, trans, in the vicinity of the trans face, and medial, lying between the cis and trans regions. [ISBN:0198506732]",
                "id": "GO:0005794"
            }, {
                "name": "cell surface",
                "definition": "The external part of the cell wall and/or plasma membrane. [GOC:jl, GOC:mtg_sensu, GOC:sm]",
                "id": "GO:0009986"
            }],
            "molecular_function": [{
                "name": "lipoprotein lipase activity",
                "definition": "Catalysis of the reaction: triacylglycerol + H2O = diacylglycerol + a carboxylate, where the triacylglycerol is part of a lipoprotein. [EC:3.1.1.34, GOC:bf]",
                "id": "GO:0004465"
            }, {
                "name": "phospholipase activity",
                "definition": "Catalysis of the hydrolysis of a glycerophospholipid. [ISBN:0198506732]",
                "id": "GO:0004620"
            }, {
                "name": "triglyceride lipase activity",
                "definition": "Catalysis of the reaction: triacylglycerol + H2O = diacylglycerol + a carboxylate. [EC:3.1.1.3]",
                "id": "GO:0004806"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "heparin binding",
                "definition": "Interacting selectively and non-covalently with heparin, any member of a group of glycosaminoglycans found mainly as an intracellular component of mast cells and which consist predominantly of alternating alpha-(1->4)-linked D-galactose and N-acetyl-D-glucosamine-6-sulfate residues. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0008201"
            }, {
                "name": "phosphatidylcholine 1-acylhydrolase activity",
                "definition": "Catalysis of the reaction: phosphatidylcholine + H2O = 2-acylglycerophosphocholine + a carboxylate. [EC:3.1.1.32]",
                "id": "GO:0008970"
            }, {
                "name": "hydrolase activity",
                "definition": "Catalysis of the hydrolysis of various bonds, e.g. C-O, C-N, C-C, phosphoric anhydride bonds, etc. Hydrolase is the systematic name for any enzyme of EC class 3. [ISBN:0198506732]",
                "id": "GO:0016787"
            }, {
                "name": "carboxylic ester hydrolase activity",
                "definition": "Catalysis of the hydrolysis of a carboxylic ester bond. [EC:3.1.1, EC:3.1.1.1, GOC:curators]",
                "id": "GO:0052689"
            }]
        },
        "kegg_pathway": {}
    }, "COMT": {
        "name": "Betalain biosynthesis",
        "description": "Catechol-O-methyltransferase catalyzes the transfer of a methyl group from S-adenosylmethionine to catecholamines, including the neurotransmitters dopamine, epinephrine, and norepinephrine. This O-methylation results in one of the major degradative pathways of the catecholamine transmitters. In addition to its role in the metabolism of endogenous substances, COMT is important in the metabolism of catechol drugs used in the treatment of hypertension, asthma, and Parkinson disease. COMT is found in two forms in tissues, a soluble form (S-COMT) and a membrane-bound form (MB-COMT). The differences between S-COMT and MB-COMT reside within the N-termini. Several transcript variants are formed through the use of alternative translation initiation sites and promoters. [provided by RefSeq, Sep 2008].",
        "entrez": "1312",
        "ensembl": "ENSG00000093010",
        "uniprot": "P21964",
        "hgnc": "2228",
        "go_terms": {
            "biological_process": [{
                "name": "catecholamine metabolic process",
                "definition": "The chemical reactions and pathways involving any of a group of physiologically important biogenic amines that possess a catechol (3,4-dihydroxyphenyl) nucleus and are derivatives of 3,4-dihydroxyphenylethylamine. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0006584"
            }, {
                "name": "female pregnancy",
                "definition": "The set of physiological processes that allow an embryo or foetus to develop within the body of a female animal. It covers the time from fertilization of a female ovum by a male spermatozoon until birth. [ISBN:0192800825]",
                "id": "GO:0007565"
            }, {
                "name": "learning",
                "definition": "Any process in an organism in which a relatively long-lasting adaptive behavioral change occurs as the result of experience. [ISBN:0582227089, ISBN:0721662544]",
                "id": "GO:0007612"
            }, {
                "name": "short-term memory",
                "definition": "The memory process that deals with the storage, retrieval and modification of information received a short time (up to about 30 minutes) ago. This type of memory is typically dependent on direct, transient effects of second messenger activation. [http://hebb.mit.edu/courses/9.03/lecture4.html, ISBN:0582227089]",
                "id": "GO:0007614"
            }, {
                "name": "estrogen metabolic process",
                "definition": "The chemical reactions and pathways involving estrogens, C18 steroid hormones that can stimulate the development of female sexual characteristics. Also found in plants. [ISBN:0198506732]",
                "id": "GO:0008210"
            }, {
                "name": "catechol-containing compound metabolic process",
                "definition": "The chemical reactions and pathways involving a compound containing a pyrocatechol (1,2-benzenediol) nucleus or substituent. [GOC:sm, ISBN:0198547684]",
                "id": "GO:0009712"
            }, {
                "name": "response to organic cyclic compound",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic cyclic compound stimulus. [CHEBI:33832, GOC:ef]",
                "id": "GO:0014070"
            }, {
                "name": "cellular response to phosphate starvation",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of deprivation of phosphate. [GOC:jl]",
                "id": "GO:0016036"
            }, {
                "name": "methylation",
                "definition": "The process in which a methyl group is covalently attached to a molecule. [GOC:mah]",
                "id": "GO:0032259"
            }, {
                "name": "response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:add, ISBN:0721601464]",
                "id": "GO:0032496"
            }, {
                "name": "developmental process",
                "definition": "A biological process whose specific outcome is the progression of an integrated living unit: an anatomical structure (which may be a subcellular structure, cell, tissue, or organ), or organism over time from an initial condition to a later condition. [GOC:isa_complete]",
                "id": "GO:0032502"
            }, {
                "name": "negative regulation of renal sodium excretion",
                "definition": "Any process that decreases the amount of sodium excreted in urine over a unit of time. [GOC:mtg_25march11, GOC:yaf]",
                "id": "GO:0035814"
            }, {
                "name": "neurotransmitter catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of any of a group of substances that are released on excitation from the axon terminal of a presynaptic neuron of the central or peripheral nervous system and travel across the synaptic cleft to either excite or inhibit the target cell. [CHEBI:25512, GOC:jl]",
                "id": "GO:0042135"
            }, {
                "name": "dopamine metabolic process",
                "definition": "The chemical reactions and pathways involving dopamine, a catecholamine neurotransmitter and a metabolic precursor of noradrenaline and adrenaline. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0042417"
            }, {
                "name": "dopamine catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of dopamine, a catecholamine neurotransmitter and a metabolic precursor of noradrenaline and adrenaline. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0042420"
            }, {
                "name": "catecholamine catabolic process",
                "definition": "The chemical reactions and pathways resulting in the breakdown of any of a group of physiologically important biogenic amines that possess a catechol (3,4-dihydroxyphenyl) nucleus and are derivatives of 3,4-dihydroxyphenylethylamine. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0042424"
            }, {
                "name": "response to drug",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a drug stimulus. A drug is a substance used in the diagnosis, treatment or prevention of a disease. [GOC:jl]",
                "id": "GO:0042493"
            }, {
                "name": "response to estrogen",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of stimulus by an estrogen, C18 steroid hormones that can stimulate the development of female sexual characteristics. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0043627"
            }, {
                "name": "negative regulation of dopamine metabolic process",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the chemical reactions and pathways involving dopamine. [GOC:go_curators]",
                "id": "GO:0045963"
            }, {
                "name": "response to pain",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a pain stimulus. Pain stimuli cause activation of nociceptors, peripheral receptors for pain, include receptors which are sensitive to painful mechanical stimuli, extreme heat or cold, and chemical stimuli. [GOC:jid, PMID:10203867, PMID:12723742, PMID:12843304, Wikipedia:Pain]",
                "id": "GO:0048265"
            }, {
                "name": "multicellular organismal reproductive process",
                "definition": "The process, occurring above the cellular level, that is pertinent to the reproductive function of a multicellular organism. This includes the integrated processes at the level of tissues and organs. [GOC:dph, GOC:jid, GOC:tb]",
                "id": "GO:0048609"
            }, {
                "name": "negative regulation of smooth muscle cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of smooth muscle cell proliferation. [CL:0000192, GOC:ebc]",
                "id": "GO:0048662"
            }, {
                "name": "positive regulation of homocysteine metabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of the chemical reactions and pathways involving homocysteine. [GOC:ai]",
                "id": "GO:0050668"
            }, {
                "name": "regulation of sensory perception of pain",
                "definition": "Any process that modulates the frequency, rate or extent of the sensory perception of pain, the series of events required for an organism to receive a painful stimulus, convert it to a molecular signal, and recognize and characterize the signal. [GOC:ai]",
                "id": "GO:0051930"
            }],
            "cellular_component": [{
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "axon",
                "definition": "The long process of a neuron that conducts nerve impulses, usually away from the cell body to the terminals and varicosities, which are sites of storage and release of neurotransmitter. [GOC:nln, ISBN:0198506732]",
                "id": "GO:0030424"
            }, {
                "name": "dendrite",
                "definition": "A neuron projection that has a short, tapering, morphology. Dendrites receive and integrate signals from other neurons or from sensory stimuli, and conduct nerve impulses towards the axon or the cell body. In most neurons, the impulse is conveyed from dendrites to axon via the cell body, but in some types of unipolar neuron, the impulse does not travel via the cell body. [GOC:aruk, GOC:bc, GOC:dos, GOC:mah, GOC:nln, ISBN:0198506732]",
                "id": "GO:0030425"
            }, {
                "name": "dendritic spine",
                "definition": "A small, membranous protrusion from a dendrite that forms a postsynaptic compartment - typically receiving input from a single presynapse.  They function as partially isolated biochemical and an electrical compartments. Spine morphology is variable including \\thin\\\", \\\"stubby\\\", \\\"mushroom\\\", and \\\"branched\\\", with a continuum of intermediate morphologies. They typically terminate in a bulb shape, linked to the dendritic shaft by a restriction. Spine remodeling is though to be involved in synaptic plasticity.\" [GOC:nln]",
                "id": "GO:0043197"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }, {
                "name": "cell body",
                "definition": "The portion of a cell bearing surface projections such as axons, dendrites, cilia, or flagella that includes the nucleus, but excludes all cell projections. [GOC:go_curators]",
                "id": "GO:0044297"
            }, {
                "name": "postsynaptic membrane",
                "definition": "A specialized area of membrane facing the presynaptic membrane on the tip of the nerve ending and separated from it by a minute cleft (the synaptic cleft). Neurotransmitters cross the synaptic cleft and transmit the signal to the postsynaptic membrane. [ISBN:0198506732]",
                "id": "GO:0045211"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }],
            "molecular_function": [{
                "name": "magnesium ion binding",
                "definition": "Interacting selectively and non-covalently with magnesium (Mg) ions. [GOC:ai]",
                "id": "GO:0000287"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "methyltransferase activity",
                "definition": "Catalysis of the transfer of a methyl group to an acceptor molecule. [ISBN:0198506732]",
                "id": "GO:0008168"
            }, {
                "name": "O-methyltransferase activity",
                "definition": "Catalysis of the transfer of a methyl group to the oxygen atom of an acceptor molecule. [GOC:ai]",
                "id": "GO:0008171"
            }, {
                "name": "catechol O-methyltransferase activity",
                "definition": "Catalysis of the reaction: S-adenosyl-L-methionine + a catechol = S-adenosyl-L-homocysteine + a guaiacol. [EC:2.1.1.6]",
                "id": "GO:0016206"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }, {
                "name": "L-dopa O-methyltransferase activity",
                "definition": "Catalysis of the reaction: L-dopa + S-adenosyl-L-methionine  <=> 3-O-methyldopa + S-adenosyl-L-homocysteine + H+ [EC:2.1.1.6]",
                "id": "GO:0102084"
            }, {
                "name": "orcinol O-methyltransferase activity",
                "definition": "Catalysis of the reaction: orcinol + S-adenosyl-L-methionine  = H+ + 3-methoxy-5-hydroxytoluene + S-adenosyl-L-homocysteine [EC:2.1.1.6]",
                "id": "GO:0102938"
            }]
        },
        "kegg_pathway": {
            "ec": "2.1.1.6",
            "pathways": [{
                "pathway": "00140",
                "name": "Steroid hormone biosynthesis",
                "class": "Metabolism; Lipid metabolism"
            }, {
                "pathway": "00350",
                "name": "Tyrosine metabolism",
                "class": "Metabolism; Amino acid metabolism"
            }, {
                "pathway": "00965",
                "name": "Betalain biosynthesis",
                "class": "Metabolism; Biosynthesis of other secondary metabolites"
            }]
        }
    }, "JAK1": {
        "name": "T cell receptor signaling pathway",
        "description": "This gene encodes a membrane protein that is a member of a class of protein-tyrosine kinases (PTK) characterized by the presence of a second phosphotransferase-related domain immediately N-terminal to the PTK domain. The encoded kinase phosphorylates STAT proteins (signal transducers and activators of transcription) and plays a key role in interferon-alpha/beta and interferon-gamma signal transduction. Alternative splicing results in multiple transcript variants. [provided by RefSeq, Mar 2016].",
        "entrez": "3716",
        "ensembl": "ENSG00000162434",
        "uniprot": "P23458",
        "hgnc": "6190",
        "go_terms": {
            "biological_process": [{
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "transmembrane receptor protein tyrosine kinase signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to a receptor on the surface of the target cell where the receptor possesses tyrosine kinase activity, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling]",
                "id": "GO:0007169"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "cell migration",
                "definition": "The controlled self-propelled movement of a cell from one site to a destination guided by molecular cues. Cell migration is a central process in the development and maintenance of multicellular organisms. [GOC:cjm, GOC:dph, GOC:ems, GOC:pf, http://en.wikipedia.org/wiki/Cell_migration]",
                "id": "GO:0016477"
            }, {
                "name": "cytokine-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a cytokine to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling, PMID:19295629]",
                "id": "GO:0019221"
            }, {
                "name": "cell differentiation",
                "definition": "The process in which relatively unspecialized cells, e.g. embryonic or regenerative cells, acquire specialized structural and/or functional features that characterize the cells, tissues, or organs of the mature organism or some other relatively stable phase of the organism's life history. Differentiation includes the processes involved in commitment of a cell to a specific fate and its subsequent development to the mature state. [ISBN:0198506732]",
                "id": "GO:0030154"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "interleukin-12-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-12 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:BHF, GOC:signaling]",
                "id": "GO:0035722"
            }, {
                "name": "interleukin-15-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-15 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:BHF, GOC:signaling]",
                "id": "GO:0035723"
            }, {
                "name": "peptidyl-tyrosine autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own tyrosine amino acid residues, or a tyrosine residue on an identical protein. [PMID:10037737, PMID:10068444, PMID:10940390]",
                "id": "GO:0038083"
            }, {
                "name": "interleukin-2-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-2 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:nhn, GOC:signaling]",
                "id": "GO:0038110"
            }, {
                "name": "interleukin-7-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-7 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:nhn, GOC:signaling]",
                "id": "GO:0038111"
            }, {
                "name": "interleukin-9-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-9 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:nhn, GOC:signaling]",
                "id": "GO:0038113"
            }, {
                "name": "interleukin-21-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-21 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:nhn, GOC:signaling]",
                "id": "GO:0038114"
            }, {
                "name": "regulation of cell proliferation",
                "definition": "Any process that modulates the frequency, rate or extent of cell proliferation. [GOC:jl]",
                "id": "GO:0042127"
            }, {
                "name": "innate immune response",
                "definition": "Innate immune responses are defense responses mediated by germline encoded components that directly recognize components of potential pathogens. [GO_REF:0000022, GOC:add, GOC:ebc, GOC:mtg_15nov05, GOC:mtg_sensu]",
                "id": "GO:0045087"
            }, {
                "name": "response to antibiotic",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an antibiotic stimulus. An antibiotic is a chemical substance produced by a microorganism which has the capacity to inhibit the growth of or to kill other microorganisms. [GOC:ai, GOC:ef]",
                "id": "GO:0046677"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "interferon-gamma-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interferon-gamma to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. Interferon gamma is the only member of the type II interferon found so far. [GOC:add, GOC:dph, GOC:signaling, PR:000000017]",
                "id": "GO:0060333"
            }, {
                "name": "regulation of interferon-gamma-mediated signaling pathway",
                "definition": "Any process that modulates the rate, frequency or extent of the series of molecular events generated as a consequence of interferon-gamma binding to a cell surface receptor. [GOC:dph]",
                "id": "GO:0060334"
            }, {
                "name": "type I interferon signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a type I interferon to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. Type I interferons include the interferon-alpha, beta, delta, episilon, zeta, kappa, tau, and omega gene families. [GOC:add, GOC:dph, GOC:signaling, PR:000025848]",
                "id": "GO:0060337"
            }, {
                "name": "regulation of type I interferon-mediated signaling pathway",
                "definition": "Any process that modulates the rate, frequency or extent of a type I interferon-mediated signaling pathway. A type I interferon-mediated signaling pathway is the series of molecular events generated as a consequence of a type I interferon binding to a cell surface receptor. [GOC:dph]",
                "id": "GO:0060338"
            }, {
                "name": "regulation of molecular function",
                "definition": "Any process that modulates the frequency, rate or extent of a molecular function, an elemental biological activity occurring at the molecular level, such as catalysis or binding. [GOC:isa_complete]",
                "id": "GO:0065009"
            }, {
                "name": "interleukin-6-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-6 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:add, GOC:BHF, GOC:mah, GOC:signaling]",
                "id": "GO:0070102"
            }, {
                "name": "interleukin-27-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-27 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:add, GOC:BHF, GOC:mah, GOC:signaling]",
                "id": "GO:0070106"
            }, {
                "name": "interleukin-35-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of interleukin-35 to a receptor on the surface of a cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:add, GOC:mah, GOC:signaling]",
                "id": "GO:0070757"
            }, {
                "name": "positive regulation of sprouting angiogenesis",
                "definition": "Any process that activates or increases the frequency, rate or extent of sprouting angiogenesis. [GO_REF:0000058, GOC:TermGenie, PMID:16756958]",
                "id": "GO:1903672"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "endosome",
                "definition": "A vacuole to which materials ingested by endocytosis are delivered. [ISBN:0198506732, PMID:19696797]",
                "id": "GO:0005768"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "cytoskeleton",
                "definition": "Any of the various filamentous elements that form the internal framework of cells, and typically remain after treatment of the cells with mild detergent to remove membrane constituents and soluble components of the cytoplasm. The term embraces intermediate filaments, microfilaments, microtubules, the microtrabecular lattice, and other structures characterized by a polymeric filamentous nature and long-range order within the cell. The various elements of the cytoskeleton not only serve in the maintenance of cellular shape but also have roles in other cellular functions, including cellular movement, cell division, endocytosis, and movement of organelles. [GOC:mah, ISBN:0198547684, PMID:16959967]",
                "id": "GO:0005856"
            }, {
                "name": "focal adhesion",
                "definition": "Small region on the surface of a cell that anchors the cell to the extracellular matrix and that forms a point of termination of actin filaments. [ISBN:0124325653, ISBN:0815316208]",
                "id": "GO:0005925"
            }, {
                "name": "endomembrane system",
                "definition": "A collection of membranous structures involved in transport within the cell. The main components of the endomembrane system are endoplasmic reticulum, Golgi bodies, vesicles, cell membrane and nuclear envelope. Members of the endomembrane system pass materials through each other or though the use of vesicles. [GOC:lh]",
                "id": "GO:0012505"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "extrinsic component of cytoplasmic side of plasma membrane",
                "definition": "The component of a plasma membrane consisting of gene products and protein complexes that are loosely bound to its cytoplasmic surface, but not integrated into the hydrophobic region. [GOC:mah]",
                "id": "GO:0031234"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + a protein tyrosine = ADP + protein tyrosine phosphate. [EC:2.7.10]",
                "id": "GO:0004713"
            }, {
                "name": "non-membrane spanning protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + protein L-tyrosine = ADP + protein L-tyrosine phosphate by a non-membrane spanning protein. [EC:2.7.10.2]",
                "id": "GO:0004715"
            }, {
                "name": "Ras guanyl-nucleotide exchange factor activity",
                "definition": "Stimulates the exchange of guanyl nucleotides associated with a GTPase of the Ras superfamily. Under normal cellular physiological conditions, the concentration of GTP is higher than that of GDP, favoring the replacement of GDP by GTP in association with the GTPase. [GOC:mah]",
                "id": "GO:0005088"
            }, {
                "name": "receptor binding",
                "definition": "Interacting selectively and non-covalently with one or more specific sites on a receptor molecule, a macromolecule that undergoes combination with a hormone, neurotransmitter, drug or intracellular messenger to initiate a change in cell function. [GOC:bf, GOC:ceb, ISBN:0198506732]",
                "id": "GO:0005102"
            }, {
                "name": "growth hormone receptor binding",
                "definition": "Interacting selectively and non-covalently with the growth hormone receptor. [GOC:ai]",
                "id": "GO:0005131"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "protein phosphatase binding",
                "definition": "Interacting selectively and non-covalently with any protein phosphatase. [GOC:jl]",
                "id": "GO:0019903"
            }, {
                "name": "ubiquitin protein ligase binding",
                "definition": "Interacting selectively and non-covalently with a ubiquitin protein ligase enzyme, any of the E3 proteins. [GOC:vp]",
                "id": "GO:0031625"
            }, {
                "name": "CCR5 chemokine receptor binding",
                "definition": "Interacting selectively and non-covalently with a CCR5 chemokine receptor. [GOC:mah, GOC:nln]",
                "id": "GO:0031730"
            }, {
                "name": "metal ion binding",
                "definition": "Interacting selectively and non-covalently with any metal ion. [GOC:ai]",
                "id": "GO:0046872"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.10.2",
            "pathways": [{
                "pathway": "04660",
                "name": "T cell receptor signaling pathway",
                "class": "Organismal Systems; Immune system"
            }]
        }
    }, "MELK": {
        "name": "Human papillomavirus infection",
        "description": "",
        "entrez": "9833",
        "ensembl": "ENSG00000165304",
        "uniprot": "Q14680",
        "hgnc": "16870",
        "go_terms": {
            "biological_process": [{
                "name": "G2/M transition of mitotic cell cycle",
                "definition": "The mitotic cell cycle transition by which a cell in G2 commits to M phase. The process begins when the kinase activity of M cyclin/CDK complex reaches a threshold high enough for the cell cycle to proceed. This is accomplished by activating a positive feedback loop that results in the accumulation of unphosphorylated and active M cyclin/CDK complex. [GOC:mtg_cell_cycle]",
                "id": "GO:0000086"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "cell cycle",
                "definition": "The progression of biochemical and morphological phases and events that occur in a cell during successive cell replication or nuclear replication events. Canonically, the cell cycle comprises the replication and segregation of genetic material followed by the division of the cell, but in endocycles or syncytial cells nuclear replication or nuclear division may not be followed by cell division. [GOC:go_curators, GOC:mtg_cell_cycle]",
                "id": "GO:0007049"
            }, {
                "name": "cell proliferation",
                "definition": "The multiplication or reproduction of cells, resulting in the expansion of a cell population. [GOC:mah, GOC:mb]",
                "id": "GO:0008283"
            }, {
                "name": "intrinsic apoptotic signaling pathway in response to oxidative stress",
                "definition": "A series of molecular signals in which an intracellular signal is conveyed to trigger the apoptotic death of a cell. The pathway is induced in response to oxidative stress, a state often resulting from exposure to high levels of reactive oxygen species, and ends when the execution phase of apoptosis is triggered. [GOC:ai, GOC:mtg_apoptosis]",
                "id": "GO:0008631"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-tyrosine phosphorylation",
                "definition": "The phosphorylation of peptidyl-tyrosine to form peptidyl-O4'-phospho-L-tyrosine. [RESID:AA0039]",
                "id": "GO:0018108"
            }, {
                "name": "hemopoiesis",
                "definition": "The process whose specific outcome is the progression of the myeloid and lymphoid derived organ/tissue systems of the blood and other parts of the body over time, from formation to the mature structure. The site of hemopoiesis is variable during development, but occurs primarily in bone marrow or kidney in many adult vertebrates. [GOC:dgh, ISBN:0198506732]",
                "id": "GO:0030097"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "positive regulation of apoptotic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of cell death by apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043065"
            }, {
                "name": "protein autophosphorylation",
                "definition": "The phosphorylation by a protein of one or more of its own amino acid residues (cis-autophosphorylation), or residues on an identical protein (trans-autophosphorylation). [ISBN:0198506732]",
                "id": "GO:0046777"
            }, {
                "name": "neural precursor cell proliferation",
                "definition": "The multiplication or reproduction of neural precursor cells, resulting in the expansion of a cell population. A neural precursor cell is either a nervous system stem cell or a nervous system progenitor cell. [GOC:dph, GOC:yaf]",
                "id": "GO:0061351"
            }],
            "cellular_component": [{
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "cell cortex",
                "definition": "The region of a cell that lies just beneath the plasma membrane and often, but not always, contains a network of actin filaments and associated proteins. [GOC:mah, ISBN:0815316194]",
                "id": "GO:0005938"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "non-membrane spanning protein tyrosine kinase activity",
                "definition": "Catalysis of the reaction: ATP + protein L-tyrosine = ADP + protein L-tyrosine phosphate by a non-membrane spanning protein. [EC:2.7.10.2]",
                "id": "GO:0004715"
            }, {
                "name": "calcium ion binding",
                "definition": "Interacting selectively and non-covalently with calcium ions (Ca2+). [GOC:ai]",
                "id": "GO:0005509"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "lipid binding",
                "definition": "Interacting selectively and non-covalently with a lipid. [GOC:ai]",
                "id": "GO:0008289"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.1",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }, {
                "pathway": "04151",
                "name": "PI3K-Akt signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }, {
                "pathway": "04926",
                "name": "Relaxin signaling pathway",
                "class": "Organismal Systems; Endocrine system"
            }, {
                "pathway": "05165",
                "name": "Human papillomavirus infection",
                "class": "Human Diseases; Infectious disease: viral"
            }]
        }
    }, "PTGES": {
        "name": "prostaglandin E synthase",
        "description": "The protein encoded by this gene is a glutathione-dependent prostaglandin E synthase. The expression of this gene has been shown to be induced by proinflammatory cytokine interleukin 1 beta (IL1B). Its expression can also be induced by tumor suppressor protein TP53, and may be involved in TP53 induced apoptosis. Knockout studies in mice suggest that this gene may contribute to the pathogenesis of collagen-induced arthritis and mediate acute pain during inflammatory responses. [provided by RefSeq, Jul 2008].",
        "entrez": "9536",
        "ensembl": "ENSG00000148344",
        "uniprot": "O14684",
        "hgnc": "9599",
        "go_terms": {
            "biological_process": [{
                "name": "prostaglandin biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of prostaglandins, any of a group of biologically active metabolites which contain a cyclopentane ring. [GOC:ai]",
                "id": "GO:0001516"
            }, {
                "name": "acute inflammatory response",
                "definition": "Inflammation which comprises a rapid, short-lived, relatively uniform response to acute injury or antigenic challenge and is characterized by accumulations of fluid, plasma proteins, and granulocytic leukocytes. An acute inflammatory response occurs within a matter of minutes or hours, and either resolves within a few days or becomes a chronic inflammatory response. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002526"
            }, {
                "name": "chronic inflammatory response",
                "definition": "Inflammation of prolonged duration (weeks or months) in which active inflammation, tissue destruction, and attempts at repair are proceeding simultaneously. Although it may follow acute inflammation, chronic inflammation frequently begins insidiously, as a low-grade, smoldering, often asymptomatic response. [GO_REF:0000022, GOC:jal, GOC:mtg_15nov05, ISBN:0781735149]",
                "id": "GO:0002544"
            }, {
                "name": "lipid metabolic process",
                "definition": "The chemical reactions and pathways involving lipids, compounds soluble in an organic solvent but not, or sparingly, in an aqueous solvent. Includes fatty acids; neutral fats, other fatty-acid esters, and soaps; long-chain (fatty) alcohols and waxes; sphingoids and other long-chain bases; glycolipids, phospholipids and sphingolipids; and carotenes, polyprenols, sterols, terpenes and other isoprenoids. [GOC:ma]",
                "id": "GO:0006629"
            }, {
                "name": "fatty acid metabolic process",
                "definition": "The chemical reactions and pathways involving fatty acids, aliphatic monocarboxylic acids liberated from naturally occurring fats and oils by hydrolysis. [ISBN:0198547684]",
                "id": "GO:0006631"
            }, {
                "name": "fatty acid biosynthetic process",
                "definition": "The chemical reactions and pathways resulting in the formation of a fatty acid, any of the aliphatic monocarboxylic acids that can be liberated by hydrolysis from naturally occurring fats and oils. Fatty acids are predominantly straight-chain acids of 4 to 24 carbon atoms, which may be saturated or unsaturated; branched fatty acids and hydroxy fatty acids also occur, and very long chain acids of over 30 carbons are found in waxes. [GOC:mah, ISBN:0198506732]",
                "id": "GO:0006633"
            }, {
                "name": "prostaglandin metabolic process",
                "definition": "The chemical reactions and pathways involving prostaglandins, any of a group of biologically active metabolites which contain a cyclopentane ring due to the formation of a bond between two carbons of a fatty acid. They have a wide range of biological activities. [ISBN:0198506732]",
                "id": "GO:0006693"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "negative regulation of cell proliferation",
                "definition": "Any process that stops, prevents or reduces the rate or extent of cell proliferation. [GOC:go_curators]",
                "id": "GO:0008285"
            }, {
                "name": "response to organic cyclic compound",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of an organic cyclic compound stimulus. [CHEBI:33832, GOC:ef]",
                "id": "GO:0014070"
            }, {
                "name": "cyclooxygenase pathway",
                "definition": "The chemical reactions and pathways by which prostaglandins are formed from arachidonic acid, and in which prostaglandin-endoperoxide synthase (cyclooxygenase) catalyzes the committed step in the conversion of arachidonic acid to the prostaglandin-endoperoxides PGG2 and PGH2. [PMID:19854273]",
                "id": "GO:0019371"
            }, {
                "name": "response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:add, ISBN:0721601464]",
                "id": "GO:0032496"
            }, {
                "name": "response to retinoic acid",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a retinoic acid stimulus. [GOC:sl]",
                "id": "GO:0032526"
            }, {
                "name": "response to cytokine",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a cytokine stimulus. [GOC:sl]",
                "id": "GO:0034097"
            }, {
                "name": "response to calcium ion",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a calcium ion stimulus. [GOC:ai]",
                "id": "GO:0051592"
            }],
            "cellular_component": [{
                "name": "nuclear envelope lumen",
                "definition": "The region between the two lipid bilayers of the nuclear envelope; 20-40 nm wide. [GOC:ai]",
                "id": "GO:0005641"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "endoplasmic reticulum membrane",
                "definition": "The lipid bilayer surrounding the endoplasmic reticulum. [GOC:mah]",
                "id": "GO:0005789"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }, {
                "name": "intracellular membrane-bounded organelle",
                "definition": "Organized structure of distinctive morphology and function, bounded by a single or double lipid bilayer membrane and occurring within the cell. Includes the nucleus, mitochondria, plastids, vacuoles, and vesicles. Excludes the plasma membrane. [GOC:go_curators]",
                "id": "GO:0043231"
            }, {
                "name": "perinuclear region of cytoplasm",
                "definition": "Cytoplasm situated near, or occurring around, the nucleus. [GOC:jid]",
                "id": "GO:0048471"
            }],
            "molecular_function": [{
                "name": "isomerase activity",
                "definition": "Catalysis of the geometric or structural changes within one molecule. Isomerase is the systematic name for any enzyme of EC class 5. [ISBN:0198506732]",
                "id": "GO:0016853"
            }, {
                "name": "glutathione binding",
                "definition": "Interacting selectively and non-covalently with glutathione; a tripeptide composed of the three amino acids cysteine, glutamic acid and glycine. [GOC:bf, ISBN:0198506732]",
                "id": "GO:0043295"
            }, {
                "name": "prostaglandin-E synthase activity",
                "definition": "Catalysis of the reaction: prostaglandin H(2) = prostaglandin E(2). [EC:5.3.99.3, RHEA:12896]",
                "id": "GO:0050220"
            }]
        },
        "kegg_pathway": {}
    }, "SLC7A10": {
        "name": "solute carrier family 7 member 10",
        "description": "SLC7A10, in association with 4F2HC (SLC3A2; MIM 158070), mediates high-affinity transport of D-serine and several other neutral amino acids (Nakauchi et al., 2000 [PubMed 10863037]).[supplied by OMIM, Mar 2008].",
        "entrez": "56301",
        "ensembl": "ENSG00000130876",
        "uniprot": "Q9NS82",
        "hgnc": "11058",
        "go_terms": {
            "biological_process": [{
                "name": "amino acid transport",
                "definition": "The directed movement of amino acids, organic acids containing one or more amino substituents, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0006865"
            }, {
                "name": "neutral amino acid transport",
                "definition": "The directed movement of neutral amino acids, amino acids with no net charge, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai]",
                "id": "GO:0015804"
            }, {
                "name": "L-serine transport",
                "definition": "The directed movement of L-serine, the L-enantiomer of 2-amino-3-hydroxypropanoic acid, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:ai, GOC:jsg, GOC:mah]",
                "id": "GO:0015825"
            }, {
                "name": "D-alanine transport",
                "definition": "The directed movement of D-alanine, the D-enantiomer of 2-aminopropanoic acid, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [CHEBI:15570, GOC:jl, GOC:jsg, GOC:mah]",
                "id": "GO:0042941"
            }, {
                "name": "D-serine transport",
                "definition": "The directed movement of D-serine, the D-enantiomer of 2-amino-3-hydroxypropanoic acid, into, out of or within a cell, or between cells, by means of some agent such as a transporter or pore. [GOC:jl, GOC:jsg, GOC:mah]",
                "id": "GO:0042942"
            }, {
                "name": "leukocyte migration",
                "definition": "The movement of a leukocyte within or between different tissues and organs of the body. [GOC:add, ISBN:0781735149, PMID:14680625, PMID:14708592, PMID:7507411, PMID:8600538]",
                "id": "GO:0050900"
            }, {
                "name": "transmembrane transport",
                "definition": "The process in which a solute is transported across a lipid bilayer, from one side of a membrane to the other. [GOC:dph, GOC:jid]",
                "id": "GO:0055085"
            }],
            "cellular_component": [{
                "name": "plasma membrane",
                "definition": "The membrane surrounding a cell that separates the cell from its external environment. It consists of a phospholipid bilayer and associated proteins. [ISBN:0716731363]",
                "id": "GO:0005886"
            }, {
                "name": "integral component of plasma membrane",
                "definition": "The component of the plasma membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0005887"
            }, {
                "name": "membrane",
                "definition": "A lipid bilayer along with all the proteins and protein complexes embedded in it an attached to it. [GOC:dos, GOC:mah, ISBN:0815316194]",
                "id": "GO:0016020"
            }, {
                "name": "integral component of membrane",
                "definition": "The component of a membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos, GOC:go_curators]",
                "id": "GO:0016021"
            }],
            "molecular_function": [{
                "name": "neutral amino acid transmembrane transporter activity",
                "definition": "Enables the transfer of neutral amino acids from one side of a membrane to the other. Neutral amino acids have a pH of 7. [GOC:ai, GOC:mtg_transport, ISBN:0815340729]",
                "id": "GO:0015175"
            }, {
                "name": "L-serine transmembrane transporter activity",
                "definition": "Enables the transfer of L-serine from one side of a membrane to the other. L-serine is the L-enantiomer of 2-amino-3-hydroxypropanoic acid. [GOC:ai, GOC:jsg, GOC:mah, GOC:mtg_transport, ISBN:0815340729]",
                "id": "GO:0015194"
            }, {
                "name": "antiporter activity",
                "definition": "Enables the active transport of a solute across a membrane by a mechanism whereby two or more species are transported in opposite directions in a tightly coupled process not directly linked to a form of energy other than chemiosmotic energy. The reaction is: solute A(out) + solute B(in) = solute A(in) + solute B(out). [GOC:mtg_transport, ISBN:0815340729, PMID:10839820]",
                "id": "GO:0015297"
            }, {
                "name": "transmembrane transporter activity",
                "definition": "Enables the transfer of a substance, usually a specific substance or a group of related substances, from one side of a membrane to the other. [GOC:jid, GOC:mtg_transport, ISBN:0815340729]",
                "id": "GO:0022857"
            }]
        },
        "kegg_pathway": {}
    }, "MAPK14": {
        "name": "mTOR signaling pathway",
        "description": "The protein encoded by this gene is a member of the MAP kinase family. MAP kinases act as an integration point for multiple biochemical signals, and are involved in a wide variety of cellular processes such as proliferation, differentiation, transcription regulation and development. This kinase is activated by various environmental stresses and proinflammatory cytokines. The activation requires its phosphorylation by MAP kinase kinases (MKKs), or its autophosphorylation triggered by the interaction of MAP3K7IP1/TAB1 protein with this kinase. The substrates of this kinase include transcription regulator ATF2, MEF2C, and MAX, cell cycle regulator CDC25B, and tumor suppressor p53, which suggest the roles of this kinase in stress related transcription and cell cycle regulation, as well as in genotoxic stress response. Four alternatively spliced transcript variants of this gene encoding distinct isoforms have been reported. [provided by RefSeq, Jul 2008].",
        "entrez": "1432",
        "ensembl": "ENSG00000112062",
        "uniprot": "Q16539",
        "hgnc": "6876",
        "go_terms": {
            "biological_process": [{
                "name": "DNA damage checkpoint",
                "definition": "A cell cycle checkpoint that regulates progression through the cell cycle in response to DNA damage. A DNA damage checkpoint may blocks cell cycle progression (in G1, G2 or metaphase) or slow the rate at which S phase proceeds. [GOC:mtg_cell_cycle]",
                "id": "GO:0000077"
            }, {
                "name": "MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:bf, GOC:mtg_signaling_feb11, PMID:20811974, PMID:9561267]",
                "id": "GO:0000165"
            }, {
                "name": "activation of MAPK activity",
                "definition": "The initiation of the activity of the inactive enzyme MAP kinase (MAPK). [PMID:9561267]",
                "id": "GO:0000187"
            }, {
                "name": "cell morphogenesis",
                "definition": "The developmental process in which the size or shape of a cell is generated and organized. [GOC:clt, GOC:dph, GOC:go_curators, GOC:tb]",
                "id": "GO:0000902"
            }, {
                "name": "cartilage condensation",
                "definition": "The condensation of mesenchymal cells that have been committed to differentiate into chondrocytes. [ISBN:0878932437]",
                "id": "GO:0001502"
            }, {
                "name": "angiogenesis",
                "definition": "Blood vessel formation when new vessels emerge from the proliferation of pre-existing blood vessels. [ISBN:0878932453]",
                "id": "GO:0001525"
            }, {
                "name": "placenta development",
                "definition": "The process whose specific outcome is the progression of the placenta over time, from its formation to the mature structure. The placenta is an organ of metabolic interchange between fetus and mother, partly of embryonic origin and partly of maternal origin. [GOC:add, ISBN:068340007X]",
                "id": "GO:0001890"
            }, {
                "name": "chondrocyte differentiation",
                "definition": "The process in which a chondroblast acquires specialized structural and/or functional features of a chondrocyte. A chondrocyte is a polymorphic cell that forms cartilage. [GOC:dph]",
                "id": "GO:0002062"
            }, {
                "name": "positive regulation of cytokine secretion involved in immune response",
                "definition": "Any process that activates or increases the frequency, rate, or extent of cytokine secretion contributing to an immune response. [GOC:add]",
                "id": "GO:0002741"
            }, {
                "name": "glucose metabolic process",
                "definition": "The chemical reactions and pathways involving glucose, the aldohexose gluco-hexose. D-glucose is dextrorotatory and is sometimes known as dextrose; it is an important source of energy for living organisms and is found free as well as combined in homo- and hetero-oligosaccharides and polysaccharides. [ISBN:0198506732]",
                "id": "GO:0006006"
            }, {
                "name": "transcription, DNA-templated",
                "definition": "The cellular synthesis of RNA on a template of DNA. [GOC:jl, GOC:txnOH]",
                "id": "GO:0006351"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that modulates the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006357"
            }, {
                "name": "protein phosphorylation",
                "definition": "The process of introducing a phosphate group on to a protein. [GOC:hb]",
                "id": "GO:0006468"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "chemotaxis",
                "definition": "The directed movement of a motile cell or organism, or the directed growth of a cell guided by a specific chemical concentration gradient. Movement may be towards a higher concentration (positive chemotaxis) or towards a lower concentration (negative chemotaxis). [ISBN:0198506732]",
                "id": "GO:0006935"
            }, {
                "name": "cellular response to DNA damage stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus indicating damage to its DNA from environmental insults or errors during metabolism. [GOC:go_curators]",
                "id": "GO:0006974"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "cell surface receptor signaling pathway",
                "definition": "A series of molecular signals initiated by activation of a receptor on the surface of a cell. The pathway begins with binding of an extracellular ligand to a cell surface receptor, or for receptors that signal in the absence of a ligand, by ligand-withdrawal or the activity of a constitutively active receptor. The pathway ends with regulation of a downstream cellular process, e.g. transcription. [GOC:bf, GOC:mah, GOC:pr, GOC:signaling]",
                "id": "GO:0007166"
            }, {
                "name": "transmembrane receptor protein serine/threonine kinase signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of an extracellular ligand to a receptor on the surface of the target cell where the receptor possesses serine/threonine kinase activity, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:mah, GOC:signaling]",
                "id": "GO:0007178"
            }, {
                "name": "Ras protein signal transduction",
                "definition": "A series of molecular signals within the cell that are mediated by a member of the Ras superfamily of proteins switching to a GTP-bound active state. [GOC:bf]",
                "id": "GO:0007265"
            }, {
                "name": "skeletal muscle tissue development",
                "definition": "The developmental sequence of events leading to the formation of adult skeletal muscle tissue. The main events are: the fusion of myoblasts to form myotubes that increase in size by further fusion to them of myoblasts, the formation of myofibrils within their cytoplasm and the establishment of functional neuromuscular junctions with motor neurons. At this stage they can be regarded as mature muscle fibers. [GOC:mtg_muscle]",
                "id": "GO:0007519"
            }, {
                "name": "positive regulation of gene expression",
                "definition": "Any process that increases the frequency, rate or extent of gene expression. Gene expression is the process in which a gene's coding sequence is converted into a mature gene product or products (proteins or RNA). This includes the production of an RNA transcript as well as any processing to produce a mature RNA product or an mRNA or circRNA (for protein-coding genes) and the translation of that mRNA or circRNA into protein. Protein maturation is included when required to form an active form of a product from an inactive precursor form. [GOC:dph, GOC:tb]",
                "id": "GO:0010628"
            }, {
                "name": "positive regulation of macrophage chemotaxis",
                "definition": "Any process that increases the rate, frequency or extent of macrophage chemotaxis. Macrophage chemotaxis is the movement of a macrophage in response to an external stimulus. [GOC:BHF, GOC:dph, GOC:tb]",
                "id": "GO:0010759"
            }, {
                "name": "positive regulation of myotube differentiation",
                "definition": "Any process that activates, maintains or increases the frequency, rate or extent of myotube differentiation. Myotube differentiation is the process in which a relatively unspecialized cell acquires specialized features of a myotube cell. Myotubes are multinucleated cells that are formed when proliferating myoblasts exit the cell cycle, differentiate and fuse. [GOC:dph, GOC:tb]",
                "id": "GO:0010831"
            }, {
                "name": "myoblast differentiation involved in skeletal muscle regeneration",
                "definition": "The process in which a relatively unspecialized satellite cell acquires specialized features of a myoblast. This occurs as part of skeletal muscle regeneration. A myoblast is a mononucleate cell type that, by fusion with other myoblasts, gives rise to the myotubes that eventually develop into skeletal muscle fibers. [CL:0000056, GOC:ef, GOC:mtg_muscle, PMID:16607119]",
                "id": "GO:0014835"
            }, {
                "name": "phosphorylation",
                "definition": "The process of introducing a phosphate group into a molecule, usually with the formation of a phosphoric ester, a phosphoric anhydride or a phosphoric amide. [ISBN:0198506732]",
                "id": "GO:0016310"
            }, {
                "name": "peptidyl-serine phosphorylation",
                "definition": "The phosphorylation of peptidyl-serine to form peptidyl-O-phospho-L-serine. [RESID:AA0037]",
                "id": "GO:0018105"
            }, {
                "name": "fatty acid oxidation",
                "definition": "The removal of one or more electrons from a fatty acid, with or without the concomitant removal of a proton or protons, by reaction with an electron-accepting substance, by addition of oxygen or by removal of hydrogen. [ISBN:0198506732, MetaCyc:FAO-PWY]",
                "id": "GO:0019395"
            }, {
                "name": "regulation of ossification",
                "definition": "Any process that modulates the frequency, rate or extent of bone formation. [GOC:go_curators]",
                "id": "GO:0030278"
            }, {
                "name": "osteoclast differentiation",
                "definition": "The process in which a relatively unspecialized monocyte acquires the specialized features of an osteoclast. An osteoclast is a specialized phagocytic cell associated with the absorption and removal of the mineralized matrix of bone tissue. [CL:0000092, GOC:add, ISBN:0781735149, PMID:12161749]",
                "id": "GO:0030316"
            }, {
                "name": "positive regulation of cyclase activity",
                "definition": "Any process that activates or increases the activity of a cyclase. [GOC:mah]",
                "id": "GO:0031281"
            }, {
                "name": "lipopolysaccharide-mediated signaling pathway",
                "definition": "A series of molecular signals initiated by the binding of a lipopolysaccharide (LPS) to a receptor on the surface of a target cell, and ending with regulation of a downstream cellular process, e.g. transcription. Lipopolysaccharides are major components of the outer membrane of Gram-negative bacteria, making them prime targets for recognition by the immune system. [GOC:mah, GOC:signaling, PMID:15379975]",
                "id": "GO:0031663"
            }, {
                "name": "response to muramyl dipeptide",
                "definition": "Any process that results in a change in state or activity of an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a muramyl dipeptide stimulus. Muramyl dipeptide is derived from peptidoglycan. [GOC:add]",
                "id": "GO:0032495"
            }, {
                "name": "response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:add, ISBN:0721601464]",
                "id": "GO:0032496"
            }, {
                "name": "intracellular signal transduction",
                "definition": "The process in which a signal is passed on to downstream components within the cell, which become activated themselves to further propagate the signal and finally trigger a change in the function or state of the cell. [GOC:bf, GOC:jl, GOC:signaling, ISBN:3527303782]",
                "id": "GO:0035556"
            }, {
                "name": "cellular response to vascular endothelial growth factor stimulus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a vascular endothelial growth factor stimulus. [GOC:BHF, GOC:rl, PMID:18440775]",
                "id": "GO:0035924"
            }, {
                "name": "response to muscle stretch",
                "definition": "Any process that results in a change in state or activity of a cell or an organism (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a myofibril being extended beyond its slack length. [GOC:BHF, GOC:vk, PMID:14583192]",
                "id": "GO:0035994"
            }, {
                "name": "p38MAPK cascade",
                "definition": "An intracellular protein kinase cascade containing at least a p38 MAPK, a MAPKK and a MAP3K. The cascade can also contain two additional tiers: the upstream MAP4K and the downstream MAP Kinase-activated kinase (MAPKAPK). The kinases in each tier phosphorylate and activate the kinases in the downstream tier to transmit a signal within a cell. [GOC:signaling, PMID:20811974]",
                "id": "GO:0038066"
            }, {
                "name": "positive regulation of protein import into nucleus",
                "definition": "Any process that activates or increases the frequency, rate or extent of movement of proteins from the cytoplasm into the nucleus. [GOC:jl]",
                "id": "GO:0042307"
            }, {
                "name": "signal transduction in response to DNA damage",
                "definition": "A cascade of processes induced by the detection of DNA damage within a cell. [GOC:go_curators]",
                "id": "GO:0042770"
            }, {
                "name": "neutrophil degranulation",
                "definition": "The regulated exocytosis of secretory granules containing preformed mediators such as proteases, lipases, and inflammatory mediators by a neutrophil. [ISBN:0781735149]",
                "id": "GO:0043312"
            }, {
                "name": "positive regulation of erythrocyte differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of erythrocyte differentiation. [GOC:go_curators]",
                "id": "GO:0045648"
            }, {
                "name": "positive regulation of myoblast differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of myoblast differentiation. A myoblast is a mononucleate cell type that, by fusion with other myoblasts, gives rise to the myotubes that eventually develop into skeletal muscle fibers. [CL:0000056, GOC:go_curators, GOC:mtg_muscle]",
                "id": "GO:0045663"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "positive regulation of glucose import",
                "definition": "Any process that activates or increases the frequency, rate or extent of the import of the hexose monosaccharide glucose into a cell or organelle. [GOC:ai, GOC:dph, GOC:tb]",
                "id": "GO:0046326"
            }, {
                "name": "vascular endothelial growth factor receptor signaling pathway",
                "definition": "Any series of molecular signals initiated by the binding of an extracellular ligand to a vascular endothelial growth factor receptor (VEGFR) located on the surface of the receiving cell, and ending with regulation of a downstream cellular process, e.g. transcription. [GOC:ceb, GOC:signaling, PR:000001971]",
                "id": "GO:0048010"
            }, {
                "name": "regulation of DNA binding transcription factor activity",
                "definition": "Any process that modulates the frequency, rate or extent of the activity of a transcription factor, any factor involved in the initiation or regulation of transcription. [GOC:ai]",
                "id": "GO:0051090"
            }, {
                "name": "striated muscle cell differentiation",
                "definition": "The process in which a relatively unspecialized cell acquires specialized features of a striated muscle cell; striated muscle fibers are divided by transverse bands into striations, and cardiac and voluntary muscle are types of striated muscle. [CL:0000737, GOC:ai]",
                "id": "GO:0051146"
            }, {
                "name": "positive regulation of muscle cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of muscle cell differentiation. [CL:0000187, GOC:ai]",
                "id": "GO:0051149"
            }, {
                "name": "positive regulation of cardiac muscle cell proliferation",
                "definition": "Any process that activates or increases the frequency, rate or extent of cardiac muscle cell proliferation. [GOC:dph, GOC:rph]",
                "id": "GO:0060045"
            }, {
                "name": "3'-UTR-mediated mRNA stabilization",
                "definition": "An mRNA stabilization process in which one or more RNA-binding proteins associate with the 3'-untranslated region (UTR) of an mRNA. [GOC:mah, PMID:19029303]",
                "id": "GO:0070935"
            }, {
                "name": "cellular response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:mah]",
                "id": "GO:0071222"
            }, {
                "name": "cellular response to lipoteichoic acid",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipoteichoic acid stimulus; lipoteichoic acid is a major component of the cell wall of gram-positive bacteria and typically consists of a chain of glycerol-phosphate repeating units linked to a glycolipid anchor. [GOC:mah]",
                "id": "GO:0071223"
            }, {
                "name": "cellular response to tumor necrosis factor",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a tumor necrosis factor stimulus. [GOC:mah]",
                "id": "GO:0071356"
            }, {
                "name": "cellular response to ionizing radiation",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a ionizing radiation stimulus. Ionizing radiation is radiation with sufficient energy to remove electrons from atoms and may arise from spontaneous decay of unstable isotopes, resulting in alpha and beta particles and gamma rays. Ionizing radiation also includes X-rays. [GOC:mah]",
                "id": "GO:0071479"
            }, {
                "name": "negative regulation of canonical Wnt signaling pathway",
                "definition": "Any process that decreases the rate, frequency, or extent of the Wnt signaling pathway through beta-catenin, the series of molecular signals initiated by binding of a Wnt protein to a frizzled family receptor on the surface of the target cell, followed by propagation of the signal via beta-catenin, and ending with a change in transcription of target genes. [GOC:dph, GOC:tb]",
                "id": "GO:0090090"
            }, {
                "name": "positive regulation of brown fat cell differentiation",
                "definition": "Any process that increases the rate, frequency, or extent of brown fat cell differentiation. Brown fat cell differentiation is the process in which a relatively unspecialized cell acquires specialized features of a brown adipocyte, an animal connective tissue cell involved in adaptive thermogenesis. Brown adipocytes contain multiple small droplets of triglycerides and a high number of mitochondria. [GOC:BHF]",
                "id": "GO:0090336"
            }, {
                "name": "stress-induced premature senescence",
                "definition": "A cellular senescence process associated with the dismantling of a cell as a response to environmental factors such as hydrogen peroxide or X-rays. [GOC:BHF]",
                "id": "GO:0090400"
            }, {
                "name": "cellular response to virus",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a stimulus from a virus. [GOC:dos]",
                "id": "GO:0098586"
            }, {
                "name": "regulation of cytokine production involved in inflammatory response",
                "definition": "Any process that modulates the frequency, rate or extent of cytokine production involved in inflammatory response. [GOC:TermGenie]",
                "id": "GO:1900015"
            }, {
                "name": "positive regulation of myoblast fusion",
                "definition": "Any process that activates or increases the frequency, rate or extent of myoblast fusion. [GOC:BHF, GOC:rl, GOC:TermGenie, PMID:21364645]",
                "id": "GO:1901741"
            }, {
                "name": "regulation of signal transduction by p53 class mediator",
                "definition": "Any process that modulates the frequency, rate or extent of signal transduction by p53 class mediator. [GOC:TermGenie]",
                "id": "GO:1901796"
            }, {
                "name": "positive regulation of metallopeptidase activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of metallopeptidase activity. [GO_REF:0000059, GOC:TermGenie, PMID:26473732]",
                "id": "GO:1905050"
            }, {
                "name": "positive regulation of reactive oxygen species metabolic process",
                "definition": "Any process that activates or increases the frequency, rate or extent of reactive oxygen species metabolic process. [GOC:mah]",
                "id": "GO:2000379"
            }, {
                "name": "positive regulation of interleukin-12 secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of interleukin-12 secretion. [GOC:obol]",
                "id": "GO:2001184"
            }],
            "cellular_component": [{
                "name": "spindle pole",
                "definition": "Either of the ends of a spindle, where spindle microtubules are organized; usually contains a microtubule organizing center and accessory molecules, spindle microtubules and astral microtubules. [GOC:clt]",
                "id": "GO:0000922"
            }, {
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "intracellular",
                "definition": "The living contents of a cell; the matter contained within (but not including) the plasma membrane, usually taken to exclude large vacuoles and masses of secretory or ingested material. In eukaryotes it includes the nucleus and cytoplasm. [ISBN:0198506732]",
                "id": "GO:0005622"
            }, {
                "name": "cell",
                "definition": "The basic structural and functional unit of all organisms. Includes the plasma membrane and any external encapsulating structures such as the cell wall and cell envelope. [GOC:go_curators]",
                "id": "GO:0005623"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "nucleoplasm",
                "definition": "That part of the nuclear content other than the chromosomes or the nucleolus. [GOC:ma, ISBN:0124325653]",
                "id": "GO:0005654"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "mitochondrion",
                "definition": "A semiautonomous, self replicating organelle that occurs in varying numbers, shapes, and sizes in the cytoplasm of virtually all eukaryotic cells. It is notably the site of tissue respiration. [GOC:giardia, ISBN:0198506732]",
                "id": "GO:0005739"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "nuclear speck",
                "definition": "A discrete extra-nucleolar subnuclear domain, 20-50 in number, in which splicing factors are seen to be localized by immunofluorescence microscopy. [http://www.cellnucleus.com/]",
                "id": "GO:0016607"
            }, {
                "name": "secretory granule lumen",
                "definition": "The volume enclosed by the membrane of a secretory granule. [GOC:rph]",
                "id": "GO:0034774"
            }, {
                "name": "extracellular exosome",
                "definition": "A vesicle that is released into the extracellular region by fusion of the limiting endosomal membrane of a multivesicular body with the plasma membrane. Extracellular exosomes, also simply called exosomes, have a diameter of about 40-100 nm. [GOC:BHF, GOC:mah, GOC:vesicles, PMID:15908444, PMID:17641064, PMID:19442504, PMID:19498381, PMID:22418571, PMID:24009894]",
                "id": "GO:0070062"
            }, {
                "name": "ficolin-1-rich granule lumen",
                "definition": "Any membrane-enclosed lumen that is part of a ficolin-1-rich granule. [GO_REF:0000064, GOC:TermGenie, PMID:23650620]",
                "id": "GO:1904813"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein kinase activity",
                "definition": "Catalysis of the phosphorylation of an amino acid residue in a protein, usually according to the reaction: a protein + ATP = a phosphoprotein + ADP. [MetaCyc:PROTEIN-KINASE-RXN]",
                "id": "GO:0004672"
            }, {
                "name": "protein serine/threonine kinase activity",
                "definition": "Catalysis of the reactions: ATP + protein serine = ADP + protein serine phosphate, and ATP + protein threonine = ADP + protein threonine phosphate. [GOC:bf]",
                "id": "GO:0004674"
            }, {
                "name": "MAP kinase activity",
                "definition": "Catalysis of the reaction: protein + ATP = protein phosphate + ADP. This reaction is the phosphorylation of proteins. Mitogen-activated protein kinase; a family of protein kinases that perform a crucial step in relaying signals from the plasma membrane to the nucleus. They are activated by a wide range of proliferation- or differentiation-inducing signals; activation is strong with agonists such as polypeptide growth factors and tumor-promoting phorbol esters, but weak (in most cell backgrounds) by stress stimuli. [GOC:ma, ISBN:0198547684]",
                "id": "GO:0004707"
            }, {
                "name": "MAP kinase kinase activity",
                "definition": "Catalysis of the concomitant phosphorylation of threonine (T) and tyrosine (Y) residues in a Thr-Glu-Tyr (TEY) thiolester sequence in a MAP kinase (MAPK) substrate. [ISBN:0198547684]",
                "id": "GO:0004708"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "kinase activity",
                "definition": "Catalysis of the transfer of a phosphate group, usually from ATP, to a substrate molecule. [ISBN:0198506732]",
                "id": "GO:0016301"
            }, {
                "name": "transferase activity",
                "definition": "Catalysis of the transfer of a group, e.g. a methyl group, glycosyl group, acyl group, phosphorus-containing, or other groups, from one compound (generally regarded as the donor) to another compound (generally regarded as the acceptor). Transferase is the systematic name for any enzyme of EC class 2. [ISBN:0198506732]",
                "id": "GO:0016740"
            }, {
                "name": "enzyme binding",
                "definition": "Interacting selectively and non-covalently with any enzyme. [GOC:jl]",
                "id": "GO:0019899"
            }, {
                "name": "protein phosphatase binding",
                "definition": "Interacting selectively and non-covalently with any protein phosphatase. [GOC:jl]",
                "id": "GO:0019903"
            }, {
                "name": "mitogen-activated protein kinase p38 binding",
                "definition": "Interacting selectively and non-covalently with mitogen-activated protein kinase p38, an enzyme that catalyzes the transfer of phosphate from ATP to hydroxyl side chains on proteins in response to mitogen activation. [GOC:curators, PMID:17827184]",
                "id": "GO:0048273"
            }, {
                "name": "NFAT protein binding",
                "definition": "Interacting selectively and non-covalently with NFAT (nuclear factor of activated T cells) proteins, a family of transcription factors. NFAT proteins have crucial roles in the development and function of the immune system. [PMID:15928679]",
                "id": "GO:0051525"
            }]
        },
        "kegg_pathway": {
            "ec": "2.7.11.24",
            "pathways": [{
                "pathway": "04150",
                "name": "mTOR signaling pathway",
                "class": "Environmental Information Processing; Signal transduction"
            }]
        }
    }, "NLRP3": {
        "name": "NLR family pyrin domain containing 3",
        "description": "This gene encodes a pyrin-like protein containing a pyrin domain, a nucleotide-binding site (NBS) domain, and a leucine-rich repeat (LRR) motif. This protein interacts with the apoptosis-associated speck-like protein PYCARD/ASC, which contains a caspase recruitment domain, and is a member of the NALP3 inflammasome complex. This complex functions as an upstream activator of NF-kappaB signaling, and it plays a role in the regulation of inflammation, the immune response, and apoptosis. Mutations in this gene are associated with familial cold autoinflammatory syndrome (FCAS), Muckle-Wells syndrome (MWS), chronic infantile neurological cutaneous and articular (CINCA) syndrome, and neonatal-onset multisystem inflammatory disease (NOMID). Multiple alternatively spliced transcript variants encoding distinct isoforms have been identified for this gene. Alternative 5' UTR structures are suggested by available data; however, insufficient evidence is available to determine if all of the represented 5' UTR splice patterns are biologically valid. [provided by RefSeq, Oct 2008].",
        "entrez": "114548",
        "ensembl": "ENSG00000162711",
        "uniprot": "Q96P20",
        "hgnc": "16400",
        "go_terms": {
            "biological_process": [{
                "name": "cytokine secretion involved in immune response",
                "definition": "The regulated release of cytokines from a cell that contributes to an immune response. [GOC:add, ISBN:0781735149]",
                "id": "GO:0002374"
            }, {
                "name": "immune system process",
                "definition": "Any process involved in the development or functioning of the immune system, an organismal system for calibrated responses to potential internal or invasive threats. [GO_REF:0000022, GOC:add, GOC:mtg_15nov05]",
                "id": "GO:0002376"
            }, {
                "name": "negative regulation of acute inflammatory response",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate, or extent of an acute inflammatory response. [GOC:add]",
                "id": "GO:0002674"
            }, {
                "name": "positive regulation of type 2 immune response",
                "definition": "Any process that activates or increases the frequency, rate, or extent of a type 2 immune response. [GOC:add]",
                "id": "GO:0002830"
            }, {
                "name": "transcription, DNA-templated",
                "definition": "The cellular synthesis of RNA on a template of DNA. [GOC:jl, GOC:txnOH]",
                "id": "GO:0006351"
            }, {
                "name": "regulation of transcription, DNA-templated",
                "definition": "Any process that modulates the frequency, rate or extent of cellular DNA-templated transcription. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0006355"
            }, {
                "name": "apoptotic process",
                "definition": "A programmed cell death process which begins when a cell receives an internal (e.g. DNA damage) or external signal (e.g. an extracellular death ligand), and proceeds through a series of biochemical events (signaling pathway phase) which trigger an execution phase. The execution phase is the last step of an apoptotic process, and is typically characterized by rounding-up of the cell, retraction of pseudopodes, reduction of cellular volume (pyknosis), chromatin condensation, nuclear fragmentation (karyorrhexis), plasma membrane blebbing and fragmentation of the cell into apoptotic bodies. When the execution phase is completed, the cell has died. [GOC:cjm, GOC:dhl, GOC:ecd, GOC:go_curators, GOC:mtg_apoptosis, GOC:tb, ISBN:0198506732, PMID:18846107, PMID:21494263]",
                "id": "GO:0006915"
            }, {
                "name": "activation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that initiates the activity of the inactive enzyme cysteine-type endopeptidase in the context of an apoptotic process. [GOC:al, GOC:dph, GOC:jl, GOC:mtg_apoptosis, GOC:tb, PMID:14744432, PMID:18328827, Wikipedia:Caspase]",
                "id": "GO:0006919"
            }, {
                "name": "defense response",
                "definition": "Reactions, triggered in response to the presence of a foreign body or the occurrence of an injury, which result in restriction of damage to the organism attacked or prevention/recovery from the infection caused by the attack. [GOC:go_curators]",
                "id": "GO:0006952"
            }, {
                "name": "inflammatory response",
                "definition": "The immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. The process is characterized by local vasodilation, extravasation of plasma into intercellular spaces and accumulation of white blood cells and macrophages. [GO_REF:0000022, GOC:mtg_15nov05, ISBN:0198506732]",
                "id": "GO:0006954"
            }, {
                "name": "signal transduction",
                "definition": "The cellular process in which a signal is conveyed to trigger a change in the activity or state of a cell. Signal transduction begins with reception of a signal (e.g. a ligand binding to a receptor or receptor activation by a stimulus such as light), or for signal transduction in the absence of ligand, signal-withdrawal or the activity of a constitutively active receptor. Signal transduction ends with regulation of a downstream cellular process, e.g. regulation of transcription or regulation of a metabolic process. Signal transduction covers signaling from receptors located on the surface of the cell and signaling via molecules located within the cell. For signaling between cells, signal transduction is restricted to events at and within the receiving cell. [GOC:go_curators, GOC:mtg_signaling_feb11]",
                "id": "GO:0007165"
            }, {
                "name": "detection of biotic stimulus",
                "definition": "The series of events in which a biotic stimulus, one caused or produced by a living organism, is received and converted into a molecular signal. [GOC:hb]",
                "id": "GO:0009595"
            }, {
                "name": "protein deubiquitination",
                "definition": "The removal of one or more ubiquitin groups from a protein. [GOC:ai]",
                "id": "GO:0016579"
            }, {
                "name": "negative regulation of NF-kappaB transcription factor activity",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the activity of the transcription factor NF-kappaB. [GOC:dph, GOC:rl, GOC:tb]",
                "id": "GO:0032088"
            }, {
                "name": "interleukin-1 beta production",
                "definition": "The appearance of interleukin-1 beta due to biosynthesis or secretion following a cellular stimulus, resulting in an increase in its intracellular or extracellular levels. [GOC:mah]",
                "id": "GO:0032611"
            }, {
                "name": "interleukin-18 production",
                "definition": "The appearance of interleukin-18 due to biosynthesis or secretion following a cellular stimulus, resulting in an increase in its intracellular or extracellular levels. [GOC:mah]",
                "id": "GO:0032621"
            }, {
                "name": "positive regulation of interleukin-13 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-13 production. [GOC:mah]",
                "id": "GO:0032736"
            }, {
                "name": "positive regulation of interleukin-4 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-4 production. [GOC:mah]",
                "id": "GO:0032753"
            }, {
                "name": "positive regulation of interleukin-5 production",
                "definition": "Any process that activates or increases the frequency, rate, or extent of interleukin-5 production. [GOC:mah]",
                "id": "GO:0032754"
            }, {
                "name": "negative regulation of NF-kappaB import into nucleus",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the transfer of NF-kappaB, a transcription factor for eukaryotic RNA polymerase II promoters, from the cytoplasm into the nucleus, across the nuclear membrane. [GOC:jl, ISBN:0198506732]",
                "id": "GO:0042347"
            }, {
                "name": "positive regulation of cysteine-type endopeptidase activity involved in apoptotic process",
                "definition": "Any process that activates or increases the activity of a cysteine-type endopeptidase involved in the apoptotic process. [GOC:jl, GOC:mtg_apoptosis]",
                "id": "GO:0043280"
            }, {
                "name": "NLRP3 inflammasome complex assembly",
                "definition": "The aggregation, arrangement and bonding together of a set of components to form the NLRP3 inflammasome complex, occurring at the level of an individual cell. [GOC:jl, PMID:21048113]",
                "id": "GO:0044546"
            }, {
                "name": "innate immune response",
                "definition": "Innate immune responses are defense responses mediated by germline encoded components that directly recognize components of potential pathogens. [GO_REF:0000022, GOC:add, GOC:ebc, GOC:mtg_15nov05, GOC:mtg_sensu]",
                "id": "GO:0045087"
            }, {
                "name": "positive regulation of T-helper 2 cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of T-helper 2 cell differentiation. [GOC:go_curators]",
                "id": "GO:0045630"
            }, {
                "name": "positive regulation of transcription from RNA polymerase II promoter",
                "definition": "Any process that activates or increases the frequency, rate or extent of transcription from an RNA polymerase II promoter. [GOC:go_curators, GOC:txnOH]",
                "id": "GO:0045944"
            }, {
                "name": "interleukin-1 secretion",
                "definition": "The regulated release of interleukin-1 from a cell. Interleukin 1 is produced mainly by activated macrophages; it stimulates thymocyte proliferation by inducing interleukin 2 release and it is involved in the inflammatory response. [GOC:ai, ISBN:0198506732]",
                "id": "GO:0050701"
            }, {
                "name": "negative regulation of interleukin-1 beta secretion",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the regulated release of interleukin-1 beta from a cell. [GOC:ai]",
                "id": "GO:0050713"
            }, {
                "name": "positive regulation of interleukin-1 beta secretion",
                "definition": "Any process that activates or increases the frequency, rate or extent of the regulated release of interleukin-1 beta from a cell. [GOC:ai]",
                "id": "GO:0050718"
            }, {
                "name": "regulation of inflammatory response",
                "definition": "Any process that modulates the frequency, rate or extent of the inflammatory response, the immediate defensive reaction (by vertebrate tissue) to infection or injury caused by chemical or physical agents. [GOC:ai]",
                "id": "GO:0050727"
            }, {
                "name": "negative regulation of inflammatory response",
                "definition": "Any process that stops, prevents, or reduces the frequency, rate or extent of the inflammatory response. [GOC:ai]",
                "id": "GO:0050728"
            }, {
                "name": "defense response to Gram-positive bacterium",
                "definition": "Reactions triggered in response to the presence of a Gram-positive bacterium that act to protect the cell or organism. [GOC:ai]",
                "id": "GO:0050830"
            }, {
                "name": "positive regulation of NF-kappaB transcription factor activity",
                "definition": "Any process that activates or increases the frequency, rate or extent of activity of the transcription factor NF-kappaB. [GOC:dph, GOC:tb, PMID:15087454, PMID:15170030]",
                "id": "GO:0051092"
            }, {
                "name": "protein oligomerization",
                "definition": "The process of creating protein oligomers, compounds composed of a small number, usually between three and ten, of component monomers; protein oligomers may be composed of different or identical monomers. Oligomers may be formed by the polymerization of a number of monomers or the depolymerization of a large protein polymer. [GOC:ai]",
                "id": "GO:0051259"
            }, {
                "name": "defense response to virus",
                "definition": "Reactions triggered in response to the presence of a virus that act to protect the cell or organism. [GOC:ai]",
                "id": "GO:0051607"
            }, {
                "name": "cellular response to lipopolysaccharide",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a lipopolysaccharide stimulus; lipopolysaccharide is a major component of the cell wall of gram-negative bacteria. [GOC:mah]",
                "id": "GO:0071222"
            }, {
                "name": "cellular response to peptidoglycan",
                "definition": "Any process that results in a change in state or activity of a cell (in terms of movement, secretion, enzyme production, gene expression, etc.) as a result of a peptidoglycan stimulus. Peptidoglycan is a bacterial cell wall macromolecule. [GOC:mah]",
                "id": "GO:0071224"
            }, {
                "name": "positive regulation of T-helper 17 cell differentiation",
                "definition": "Any process that activates or increases the frequency, rate or extent of T-helper 17 cell differentiation. [GOC:BHF, GOC:mah]",
                "id": "GO:2000321"
            }, {
                "name": "positive regulation of T-helper 2 cell cytokine production",
                "definition": "Any process that activates or increases the frequency, rate or extent of T-helper 2 cell cytokine production. [GOC:obol]",
                "id": "GO:2000553"
            }],
            "cellular_component": [{
                "name": "extracellular region",
                "definition": "The space external to the outermost structure of a cell. For cells without external protective or external encapsulating structures this refers to space outside of the plasma membrane. This term covers the host cell environment outside an intracellular parasite. [GOC:go_curators]",
                "id": "GO:0005576"
            }, {
                "name": "nucleus",
                "definition": "A membrane-bounded organelle of eukaryotic cells in which chromosomes are housed and replicated. In most cells, the nucleus contains all of the cell's chromosomes except the organellar chromosomes, and is the site of RNA synthesis and processing. In some species, or in specialized cell types, RNA metabolism or DNA replication may be absent. [GOC:go_curators]",
                "id": "GO:0005634"
            }, {
                "name": "cytoplasm",
                "definition": "All of the contents of a cell excluding the plasma membrane and nucleus, but including other subcellular structures. [ISBN:0198547684]",
                "id": "GO:0005737"
            }, {
                "name": "endoplasmic reticulum",
                "definition": "The irregular network of unit membranes, visible only by electron microscopy, that occurs in the cytoplasm of many eukaryotic cells. The membranes form a complex meshwork of tubular channels, which are often expanded into slitlike cavities called cisternae. The ER takes two forms, rough (or granular), with ribosomes adhering to the outer surface, and smooth (with no ribosomes attached). [ISBN:0198506732]",
                "id": "GO:0005783"
            }, {
                "name": "cytosol",
                "definition": "The part of the cytoplasm that does not contain organelles but which does contain other particulate matter, such as protein complexes. [GOC:hjd, GOC:jl]",
                "id": "GO:0005829"
            }, {
                "name": "inflammasome complex",
                "definition": "A cytosolic protein complex that is capable of activating caspase-1. [GOC:dph, PMID:17599095]",
                "id": "GO:0061702"
            }, {
                "name": "NLRP3 inflammasome complex",
                "definition": "A protein complex that consists of three components, NLRP3 (NALP3), PYCARD and caspase-1. It is activated upon exposure to whole pathogens, as well as a number of structurally diverse pathogen- and danger-associated molecular patterns (PAMPs and DAMPs) and environmental irritants. Whole pathogens demonstrated to activate the NLRP3 inflammasome complex include the fungi Candida albicans and Saccharomyces cerevisiae, bacteria that produce pore-forming toxins, including Listeria monocytogenes and Staphylococcus aureus, and viruses such as Sendai virus, adenovirus, and influenza virus. [GOC:add, GOC:BHF, GOC:vp, PMID:20303873]",
                "id": "GO:0072559"
            }],
            "molecular_function": [{
                "name": "nucleotide binding",
                "definition": "Interacting selectively and non-covalently with a nucleotide, any compound consisting of a nucleoside that is esterified with (ortho)phosphate or an oligophosphate at any hydroxyl group on the ribose or deoxyribose. [GOC:mah, ISBN:0198547684]",
                "id": "GO:0000166"
            }, {
                "name": "protein binding",
                "definition": "Interacting selectively and non-covalently with any protein or protein complex (a complex of two or more proteins that may include other nonprotein molecules). [GOC:go_curators]",
                "id": "GO:0005515"
            }, {
                "name": "ATP binding",
                "definition": "Interacting selectively and non-covalently with ATP, adenosine 5'-triphosphate, a universally important coenzyme and enzyme regulator. [ISBN:0198506732]",
                "id": "GO:0005524"
            }, {
                "name": "transcription factor binding",
                "definition": "Interacting selectively and non-covalently with a transcription factor, any protein required to initiate or regulate transcription. [ISBN:0198506732]",
                "id": "GO:0008134"
            }, {
                "name": "identical protein binding",
                "definition": "Interacting selectively and non-covalently with an identical protein or proteins. [GOC:jl]",
                "id": "GO:0042802"
            }, {
                "name": "peptidoglycan binding",
                "definition": "Interacting selectively and non-covalently, in a non-covalent manner, with peptidoglycan, any of a class of glycoconjugates found in bacterial cell walls. [GOC:go_curators, PMID:14698226]",
                "id": "GO:0042834"
            }, {
                "name": "sequence-specific DNA binding",
                "definition": "Interacting selectively and non-covalently with DNA of a specific nucleotide composition, e.g. GC-rich DNA binding, or with a specific sequence motif or type of DNA e.g. promotor binding or rDNA binding. [GOC:jl]",
                "id": "GO:0043565"
            }]
        },
        "kegg_pathway": {}
    }
}

export const SYMBOL_DATA = [
    {
        symbol: 'MAPK13', prob: 0.997006,
    },
    {
        symbol: 'MAPK12', prob: 0.989417,
    },
    {
        symbol: 'VCP', prob: 0.979466,
    },
    {
        symbol: 'NOD2', prob: 0.973826,
    },
    {
        symbol: 'CLK4', prob: 0.970260,
    },
    {
        symbol: 'ABL2', prob: 0.960495,
    },
    {
        symbol: 'GRIA3', prob: 0.957516,
    },
]
