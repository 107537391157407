import React, { useState } from 'react'
import Box from "@material-ui/core/Box"
import BarHeader from "../components/BarHeader"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from '@material-ui/core/styles'
import Button from "@material-ui/core/Button"
import PhenotypicDataUpload from "./ProjectCreation/PhenotypicDataUpload"
import PreviewTable from "./ProjectCreation/PreviewTable"
import BackNav from "../components/BackNav"
import { addOrUpdateProject, getProject } from "../data/global"
import { v1 as uuidv1 } from 'uuid'
import { useParams } from 'react-router-dom'
import Title from "../components/Title"


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 100
    },
    runButton: {
        background: '#15d35d',
        height: 70,
        width: 70,
        borderRadius: 90,
        float: 'right'
    },
    saveButton: {
        height: 40,
        width: 350
    },
    buttonBox: {
        textAlign: 'center'
    }
}))


const Project = () => {
    const classes = useStyles()

    const params = useParams()
    const projectId = params.id ? params.id : uuidv1()

    let existingTitle = ''
    let existingRows = []
    let existingHeaders = []
    let existsAlready = false

    if(params.id !== undefined) {
        const existingProject = getProject(projectId)
        existingTitle = existingProject.title
        existingRows = existingProject.rows
        existingHeaders = existingProject.headers
        existsAlready = true
    }

    const [projectName, setProjectName] = useState(existingTitle)
    const [finalProjectName, setFinalProjectName] = useState(existingTitle)
    const [finalRows, setFinalRows] = useState(existingRows)
    const [rows, setRows] = useState(existingRows)
    const [headers, setHeaders] = useState(existingHeaders)
    const [created, setCreated] = useState(existsAlready)
    const runReady = projectName.length > 0 && finalRows.length > 0 && created

    const saveReady = projectName.length > 0


    const onSave = () => {
        setCreated(true)
        setFinalProjectName(projectName)
        setFinalRows(rows)
        addOrUpdateProject({id: projectId, title: projectName, rows: rows, headers: headers, status: 'created'})
    }

    return <Box className={classes.root} elevation={3}>
        <BarHeader />
        <BackNav />
        <Box m={1}>
            <Title title={created ? finalProjectName : 'New Project'} />
        </Box>
        <form>
            <Box m={2}>
                <TextField
                    id='outlined-email'
                    name='projectName'
                    label='Project Name'
                    margin='normal'
                    value={projectName}
                    onChange={event => setProjectName(event.target.value)}
                />
            </Box>

            <Box m={2}>
                <Typography variant="body1" component="p" gutterBottom>
                    Upload your phenotypic results data below:
                </Typography>
                <PhenotypicDataUpload setHeaders={setHeaders} setRows={setRows} />
            </Box>
            {(headers.length > 1) && <Box m={2}>
                <Typography variant="h5" component="h5" gutterBottom>
                    Data Preview
                </Typography>
                <PreviewTable headers={headers} rows={rows} />
            </Box>}
            <Box className={classes.buttonBox} m={4}>
                <Button
                    className={classes.saveButton}
                    variant='contained'
                    color='primary'
                    disabled={!saveReady}
                    onClick={onSave}
                >
                    {created ? 'Save' : 'Create'}
                </Button>
                <Button
                    className={classes.runButton}
                    variant='contained'
                    color='primary'
                    disabled={!runReady}
                >
                    RUN
                </Button>
            </Box>
        </form>
    </Box>
}

export default Project
