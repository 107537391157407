import Typography from "@material-ui/core/Typography"
import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import { COMPOUNDS } from '../../data/compounds'
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { green } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 370,
    maxHeight: 500,
    overflowY: 'scroll',
    padding: 5,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'none',
  },
  tabItem: {
    // background: 'pink',
    boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 2px rgba(0,0,0,0.14), 0px 1px 8px 2px rgba(0,0,0,0.12)",
    marginBottom: 4,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  tabList: {
    background: 'none'
  },
  smileContainer: {
    height: 350,
    width: 350,
    borderRadius: 350,
    overflow: 'hidden',
    marginTop: 50,
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    marginLeft: 100
  },
  listItemText: {
    fontSize: '0.7em',//Insert your required size
  },
  legendItem: {
    height: 20,
    width: 20,
    display: 'inline-block'
  }
}))


const CompoundList = ({ onClick, compounds, displayedSmile }) => {
  const classes = useStyles()

  const buttonStyle = ({ smile, prob, inScreen }) => {

    if (displayedSmile === smile) {
      return {
        // border: '2px solid black'
        background: 'black',
        color: 'white'
      }
    } else {
      const gradientStartColour = inScreen ? 'rgba(21, 197, 90, 0.1)' : 'rgba(21, 21, 21, 0.1)'
      const gradientEndColour = inScreen ? 'rgba(21, 197, 90, 0.7)' : 'rgba(21, 21, 21, 0.7)'
      return {
        backgroundImage: `linear-gradient(to right, ${gradientStartColour} 5%, ${gradientEndColour} 60% ${prob * 100}%, rgba(255,0,0,0) ${prob * 100}%)`
      }
    }
  }

  const smilesListItems = compounds.map((compound) =>
    <ListItem className={classes.tabItem} button onClick={() => onClick(compound.smile)} key={compound.smile}
              style={buttonStyle(compound)}>
      <Tooltip title={compound.prob} placement="right">
        <ListItemText classes={{ primary: classes.listItemText }} primary={`${compound.smile}`} />
      </Tooltip>
    </ListItem>
  )

  return (
    <div className={classes.root}>
      <List className={classes.tabList} component="nav" aria-label="main mailbox folders">
        {smilesListItems}
      </List>
    </div>
  )
}

const GreenCheckbox = withStyles({
  root: {
    color: 'rgba(21, 197, 90)',
    '&$checked': {
      color: 'rgba(21, 197, 90)',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GrayCheckbox = withStyles({
  root: {
    color: 'rgba(80, 80, 80)',
    '&$checked': {
      color: 'rgba(80, 80, 80)',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CompoundLegend = ({showScreened, showUnScreened, changeShowScreened, changeShowUnscreened}) => {
  return (<div>
    <FormControlLabel
      control={<GreenCheckbox checked={showScreened} onChange={changeShowScreened} name="checkedG"></GreenCheckbox>}
      label="Screened Compounds"
    />
    <FormControlLabel
      control={<GrayCheckbox checked={showUnScreened} onChange={changeShowUnscreened} name="checkedG"></GrayCheckbox>}
      label="Unscreened Compounds"
    />
  </div>)
}

const all_compounds = COMPOUNDS

const Compounds = () => {

  const classes = useStyles()

  const [displayedSmile, setDisplayedSmile] = useState(all_compounds[0].smile)
  const [showScreened, changeShowScreened] = useState(true)
  const [showUnScreened, changeShowUnscreened] = useState(true)

  let compounds = all_compounds
  if(!showScreened) {
    compounds = compounds.filter(compound => !compound.inScreen)
  }

  if(!showUnScreened) {
    compounds = compounds.filter(compound => compound.inScreen)
  }

  const toggleShowScreened = () => changeShowScreened(!showScreened)
  const toggleShowUnScreened = () => changeShowUnscreened(!showUnScreened)

  const onSmileClick = smile => setDisplayedSmile(smile)

  return (<div style={{ maxWidth: 900 }}>
    <Typography variant="h3" component="h3" gutterBottom>
      Compounds
    </Typography>
    <Typography variant="body2" component="p" gutterBottom>
      These are compounds that the machine learning process identified as being likely to cause the input phenotype.
    </Typography>
    <CompoundLegend
      showScreened={showScreened}
      showUnScreened={showUnScreened}
      changeShowScreened={toggleShowScreened}
      changeShowUnscreened={toggleShowUnScreened}/>
    <Grid container>
      <Grid item xs={6}><CompoundList onClick={onSmileClick} compounds={compounds}
                                      displayedSmile={displayedSmile} /></Grid>
      <Grid item xs={6}>{displayedSmile &&

      <div className={classes.smileContainer}><img alt="smiles" src={`/smiles/${displayedSmile}.svg`} /></div>}</Grid>
    </Grid>
  </div>)
}

export default Compounds
