import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Compounds from "./Results/Compounds"
import TargetEnrichment from "./Results/TargetEnrichment"
import AnnotationEnrichment from "./Results/AnnotationEnrichment"
import { a11yProps, TabPanel } from "../components/Tabs"
import Paper from "@material-ui/core/Paper"
import BarHeader from "../components/BarHeader"
import Box from "@material-ui/core/Box"
import BackNav from "../components/BackNav"
import Title from "../components/Title"
import { useParams } from "react-router-dom"
import { getProject } from "../data/global"


const useStyles = makeStyles(theme => ({
    tabRoot: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: '#ffffffad',
        display: 'flex'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    root: {
        marginTop: 100
    },
    resultsArea: {
        background: 'none'
    },
    resultsPaper: {
        background: 'none'
    }
}))

const ResultTabs = () => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div className={classes.tabRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Expected Compounds" {...a11yProps(0)} />
                <Tab label="Target Enrichments" {...a11yProps(1)} />
                <Tab label="Annotation Enrichments" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Compounds />
            </TabPanel>
            <TabPanel value={value} index={1} style={{ width: '80%' }}>
                <TargetEnrichment />
            </TabPanel>
            <TabPanel value={value} index={2} style={{ width: '80%' }}>
                <AnnotationEnrichment />
            </TabPanel>
        </div>
    )
}

const Results = () => {
    const classes = useStyles()

    const params = useParams()

    const projectId = params.id
    const project = getProject(projectId)

    return <Box className={classes.root} elevation={3}>
        <BarHeader />
        <BackNav />
        <Box m={1}>
            <Title title={project.title} />
        </Box>
        <Box className={classes.resultsArea} p={2}>
            <Paper className={classes.resultsPaper} elevation={3}>
                <ResultTabs />
            </Paper>
        </Box>
    </Box>
}

export default Results
