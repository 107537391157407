import { PROJECTS } from "./projects"

if (sessionStorage.getItem("projects") === null) {
    sessionStorage.setItem('projects', JSON.stringify(PROJECTS))
}


export const getProjects = () => {
    return JSON.parse(sessionStorage.getItem('projects'))
}

export const setProjects = (projects) => {
    sessionStorage.setItem('projects', JSON.stringify(projects))
}

export const getProject = id => {
    const projects = getProjects()
    const project = projects.find(proj => String(proj.id) === id)
    return project
}

export const addOrUpdateProject = (project) => {
    const projects = getProjects()

    let existingProject = projects.find(proj => proj.id === project.id)


    if(existingProject){
        existingProject = Object.assign(existingProject, project)
    } else {
        projects.push(project)
    }

    setProjects(projects)
}
