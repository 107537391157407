export const PROJECTS = [
    {
        id: 1,
        title: "PPAR-21",
        status: "completed",
        rows: [],
        headers: []
    },
    {
        id: 2,
        title: "SARS-CoV-2",
        status: "completed",
        rows: [],
        headers: []
    },
    {
        id: 3,
        title: "DSSTox BRAI",
        status: "completed",
        rows: [],
        headers: []
    },
    {
        id: 4,
        title: "DSSTox CFIN",
        status: "running",
        rows: [],
        headers: []
    },
    {
        id: 5,
        title: "DSSTox CIRC",
        status: "running",
        rows: [],
        headers: []
    },
    // {
    //     id: 3,
    //     title: "RET-12",
    //     status: "created",
    //     rows: [],
    //     headers: []
    // }
]
