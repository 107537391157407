import { green, yellow } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
    // palette: {
    //   primary: {
    //     main: '#556cd6',
    //   },
    //   secondary: {
    //     main: '#19857b',
    //   },
    //   error: {
    //     main: red.A400,
    //   },
    //   background: {
    //     default: '#fff',
    //   },
    // },

    palette: {
        primary: {
            light: '#fff',
            main: '#fff',
            dark: '#BDBDBD',
            contrast: '#000'
        },
        error: {
            light: yellow[300],
            main: yellow[500],
            dark: yellow[700],
            contrastText: '#000'
        }
    },
    typography: {
        fontFamily: 'Open Sans'
    },
    mainSection: {
        margin: 'auto',
        textAlign: 'center',
        maxWidth: '448px',
        padding: 10
    },
    whiteBorder: {
        border: '1px solid white',
        borderRadius: '8px'
    },
    overrides: {
        //   root: {
        //     '& MuiInput-input:before': {
        //       borderColor: 'white'
        //     }
        //   },
        MuiCssBaseline: {
            '@global': {
                body: {
                    // color: 'white',
                    backgroundImage: 'url(/blue_background.png)',
                    backgroundAttachment: 'fixed',
                    backgroundColor: '#339abc'
                }
            }
        },
        MuiFormControl: {
            root: {
                maxWidth: '-webkit-fill-available'
            }
        },
        MuiInput: {
            root: {
                color: 'white',
                width: '350px',
                maxWidth: '-webkit-fill-available'
            },
            underline: {
                '&:before': {
                    borderColor: '#BDBDBD !important'
                }
            }
        },
        MuiInputLabel: {
            root: {
                color: '#C6E6ED',
                '&$focused': {
                    'color': '#BDBDBD'
                }
            }
        },
        MuiSelect: {
            root: {
                color: 'black',
                backgroundColor: 'white'
            }
        },
        MuiButton: {
            root: {
                maxWidth: '-webkit-fill-available'
            },
            contained: {
                borderRadius: '18px'
            },
            containedSecondary: {
                color: 'white',
                backgroundColor: 'transparent',
                border: '2px solid white',
                '&:hover': {
                    border: '2px solid #BDBDBD',
                    backgroundColor: 'transparent',
                    color: '#BDBDBD'
                }
            }
        },
        MuiDivider: {
            root: {
                backgroundColor: '#ffffff8c'
            }
        },
        MuiIconButton: {
            root: {
                color: '#ffffffa3'
            }
        },
        MuiSwitch: {
            colorPrimary: {
                '&$checked': {
                    color: green[500]
                },
                '&$checked + $track': {
                    backgroundColor: green[500]
                }
            }
        },
        MuiStepLabel: {
            label: {
                color: 'rgb(255,255,255, 0.54) !important'
            },
            active: {
                color: 'white !important'
            },
            completed: {
                color: 'white !important'
            }
        },
        MuiStepIcon: {
            root: {
                color: 'rgba(0, 0, 0, 0.15)',
                '&$active': {
                    fill: '#60cd64',
                    '& $text': {
                        fill: 'rgba(0, 0, 0, 0.87)',
                    },
                    animation: 'spin 4s ease 0s infinite'
                },
            },
            text: {
                fill: '#fff',
            }
        },
        MuiTabs : {
            indicator: {
                backgroundColor: '#F8CA5C',
                width: 3
            }
        }
    }
})


export default theme
