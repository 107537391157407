import { makeStyles } from "@material-ui/core/styles"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import React from "react"

const useTableStyles = makeStyles({
    table: {
        minWidth: 650,
    },
})

const PreviewTable = ({ headers, rows }) => {
    const classes = useTableStyles()

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                {/*<TableHead>*/}
                {/*    <TableRow>*/}
                {/*        {headers.map(header => (*/}
                {/*            <TableCell>{header}</TableCell>*/}
                {/*        ))}*/}
                {/*    </TableRow>*/}
                {/*</TableHead>*/}
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            {headers.map(header => (
                                <TableCell>{row[header]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PreviewTable
