export const GO_DATA = [{
    'id': 'GO:0045121',
    'name': 'membrane raft',
    'subtree': 'cellular_component',
    'definition': 'Any of the small (10-200 nm), heterogeneous, highly dynamic, sterol- and sphingolipid-enriched membrane domains that compartmentalize cellular processes. Small rafts can sometimes be stabilized to form larger platforms through protein-protein and protein-lipid interactions. [PMID:16645198, PMID:20044567]',
    'members': ['SLC2A1',
        'TNF',
        'KCNA3',
        'FYN',
        'FAS',
        'LRRK2',
        'DPP4',
        'LYN',
        'KCNA5'],
    'p_value': 1.12e-04,
    'fdr': 2.22e-07,
    'neg_log_10': 3.95
},
    {
        'id': 'GO:0004705',
        'name': 'JUN kinase activity',
        'subtree': 'molecular_function',
        'definition': 'Catalysis of the reaction: JUN + ATP = JUN phosphate + ADP. This reaction is the phosphorylation and activation of members of the JUN family, a gene family that encodes nuclear transcription factors. [GOC:bf, ISBN:0198506732]',
        'members': ['MAPK10', 'MAPK8', 'MAPK9'],
        'p_value': 0.000188,
        'fdr': 1.59e-06,
        'neg_log_10': 3.72
    },
    {
        'id': 'GO:0004118',
        'name': 'cGMP-stimulated cyclic-nucleotide phosphodiesterase activity',
        'subtree': 'molecular_function',
        'definition': "Catalysis of the reaction: nucleoside 3',5'-cyclic phosphate + H2O = nucleoside 5'-phosphate; catalytic activity is increased in the presence of cGMP. [GOC:mah]",
        'members': ['PDE10A', 'PDE2A', 'PDE11A'],
        'p_value': 0.000198,
        'fdr': 1.59e-06,
        'neg_log_10': 3.70
    },
    {
        'id': 'GO:0004465',
        'name': 'lipoprotein lipase activity',
        'subtree': 'molecular_function',
        'definition': 'Catalysis of the reaction: triacylglycerol + H2O = diacylglycerol + a carboxylate, where the triacylglycerol is part of a lipoprotein. [EC:3.1.1.34, GOC:bf]',
        'members': ['LIPG', 'LPL'],
        'p_value': 0.000203,
        'fdr': 1.59e-06,
        'neg_log_10': 3.69
    },
    {
        'id': 'GO:0007258',
        'name': 'JUN phosphorylation',
        'subtree': 'biological_process',
        'definition': 'The process of introducing a phosphate group into a JUN protein. [GOC:jl]',
        'members': ['MAPK10', 'MAPK8', 'MAPK9'],
        'p_value': 0.000253,
        'fdr': 2.78e-06,
        'neg_log_10': 3.59
    },
    {
        'id': 'GO:0010754',
        'name': 'negative regulation of cGMP-mediated signaling',
        'subtree': 'biological_process',
        'definition': 'Any process that decreases the rate, frequency or extent of cGMP-mediated signaling. cGMP-mediated signaling is a series of molecular signals in which a cell uses cyclic GMP to convert an extracellular signal into a response. [GOC:BHF, GOC:dph, GOC:tb]',
        'members': [],
        'p_value': 0.000535,
        'fdr': 6.62e-06,
        'neg_log_10': 3.27
    },
    {
        'id': 'GO:0008331',
        'name': 'high voltage-gated calcium channel activity',
        'subtree': 'molecular_function',
        'definition': 'Enables the transmembrane transfer of a calcium ion by a high voltage-gated channel. A high voltage-gated channel is a channel whose open state is dependent on high voltage across the membrane in which it is embedded. [GOC:mtg_transport, ISBN:0815340729, PMID:16382099]',
        'members': ['CACNA1D', 'CACNA1A', 'CACNA1E', 'CACNA1B'],
        'p_value': 0.00178,
        'fdr': 2.23e-05,
        'neg_log_10': 2.74
    },
    {
        'id': 'GO:0005891',
        'name': 'voltage-gated calcium channel complex',
        'subtree': 'cellular_component',
        'definition': 'A protein complex that forms a transmembrane channel through which calcium ions may pass in response to changes in membrane potential. [GOC:mah]',
        'members': ['CACNA1D', 'CACNA1A', 'CACNA1E', 'CACNA1B'],
        'p_value': 0.00459,
        'fdr': 5.24e-05,
        'neg_log_10': 2.33
    },
    {
        'id': 'GO:0016499',
        'name': 'orexin receptor activity',
        'subtree': 'molecular_function',
        'definition': 'Combining with orexin to initiate a change in cell activity. [GOC:ai]',
        'members': ['HCRTR2', 'HCRTR1'],
        'p_value': 0.00736,
        'fdr': 1.12e-04,
        'neg_log_10': 2.13
    },
    {
        'id': 'GO:0004507',
        'name': 'steroid 11-beta-monooxygenase activity',
        'subtree': 'molecular_function',
        'definition': 'Catalysis of the reaction: a steroid + reduced adrenal ferredoxin + O2 = an 11-beta-hydroxysteroid + oxidized adrenal ferredoxin + H2O. [EC:1.14.15.4]',
        'members': ['CYP11B2', 'CYP11B1'],
        'p_value': 0.00746,
        'fdr': 1.12e-04,
        'neg_log_10': 2.12
    },
    {
        'id': 'GO:0047783',
        'name': 'corticosterone 18-monooxygenase activity',
        'subtree': 'molecular_function',
        'definition': 'Catalysis of the reaction: corticosterone + reduced adrenal ferredoxin + O2 = 18-hydroxycorticosterone + oxidized adrenal ferredoxin + H2O. [EC:1.14.15.5, MetaCyc:CORTICOSTERONE-18-MONOOXYGENASE-RXN]',
        'members': ['CYP11B2', 'CYP11B1'],
        'p_value': 0.00757,
        'fdr': 1.12e-04,
        'neg_log_10': 2.12
    },
    {
        'id': 'GO:0043197',
        'name': 'dendritic spine',
        'subtree': 'cellular_component',
        'definition': 'A small, membranous protrusion from a dendrite that forms a postsynaptic compartment - typically receiving input from a single presynapse.  They function as partially isolated biochemical and an electrical compartments. Spine morphology is variable including \\thin\\", \\"stubby\\", \\"mushroom\\", and \\"branched\\", with a continuum of intermediate morphologies. They typically terminate in a bulb shape, linked to the dendritic shaft by a restriction. Spine remodeling is though to be involved in synaptic plasticity." [GOC:nln]',
        'members': ['PRKACA', 'GRM3', 'CDK5R1', 'COMT'],
        'p_value': 0.00843,
        'fdr': 1.34e-04,
        'neg_log_10': 2.07
    },
    {
        'id': 'GO:0034651',
        'name': 'cortisol biosynthetic process',
        'subtree': 'biological_process',
        'definition': 'The chemical reactions and pathways resulting in the formation of cortisol, the steroid hormone 11-beta-17,21-trihydroxypregn-4-ene-3,20-dione. Cortisol is synthesized from cholesterol in the adrenal gland and controls carbohydrate, fat and protein metabolism and has anti-inflammatory properties. [CHEBI:17650, GOC:BHF, GOC:mah, GOC:rl]',
        'members': ['CYP11B2', 'CYP11B1'],
        'p_value': 0.00898,
        'fdr': 1.87e-04,
        'neg_log_10': 2.04
    },
    {
        'id': 'GO:0050968',
        'name': 'detection of chemical stimulus involved in sensory perception of pain',
        'subtree': 'biological_process',
        'definition': 'The series of events involved in the perception of pain in which a chemical stimulus is received and converted into a molecular signal. [GOC:ai]',
        'members': ['ASIC3', 'TRPA1'],
        'p_value': 9.01e-02,
        'fdr': 1.87e-04,
        'neg_log_10': 2.04
    },
    {
        'id': 'GO:0032342',
        'name': 'aldosterone biosynthetic process',
        'subtree': 'biological_process',
        'definition': 'The chemical reactions and pathways resulting in the formation of aldosterone, a corticosteroid hormone that is produced by the zona glomerulosa of the adrenal cortex and regulates salt (sodium and potassium) and water balance. [PMID:16527843]',
        'members': ['CYP11B2', 'CYP11B1'],
        'p_value': 9.06e-02,
        'fdr': 0.000187,
        'neg_log_10': 2.04
    },
    {
        'id': 'GO:0002374',
        'name': 'cytokine secretion involved in immune response',
        'subtree': 'biological_process',
        'definition': 'The regulated release of cytokines from a cell that contributes to an immune response. [GOC:add, ISBN:0781735149]',
        'members': ['NLRP3', 'NOD2'],
        'p_value': 0.0125,
        'fdr': 2.79e-04,
        'neg_log_10': 1.90
    },
    {
        'id': 'GO:0086089',
        'name': 'voltage-gated potassium channel activity involved in atrial cardiac muscle cell action potential repolarization',
        'subtree': 'molecular_function',
        'definition': 'Enables the transmembrane transfer of a potassium ion by a voltage-gated channel through the plasma membrane of an atrial cardiomyocyte contributing to the repolarization phase of an action potential. A voltage-gated channel is a channel whose open state is dependent on the voltage across the membrane in which it is embedded. [GOC:BHF, GOC:mtg_cardiac_conduct_nov11]',
        'members': ['KCNJ3', 'KCNA5'],
        'p_value': 0.0173,
        'fdr': 2.79e-04,
        'neg_log_10': 1.76
    },
    {
        'id': 'GO:0099056',
        'name': 'integral component of presynaptic membrane',
        'subtree': 'cellular_component',
        'definition': 'The component of the presynaptic membrane consisting of the gene products and protein complexes having at least some part of their peptide sequence embedded in the hydrophobic region of the membrane. [GOC:dos]',
        'members': [],
        'p_value': 0.0204,
        'fdr': 4.16e-04,
        'neg_log_10': 1.69
    },
    {
        'id': 'GO:0043204',
        'name': 'perikaryon',
        'subtree': 'cellular_component',
        'definition': 'The portion of the cell soma (neuronal cell body) that excludes the nucleus. [GOC:jl]',
        'members': ['LRRK2', 'PDE10A'],
        'p_value': 0.0282,
        'fdr': 6.45e-04,
        'neg_log_10': 1.54
    },
    {
        'id': 'GO:0004301',
        'name': 'epoxide hydrolase activity',
        'subtree': 'molecular_function',
        'definition': 'Catalysis of the reaction: an epoxide + H2O = a glycol. [EC:3.3.2.10]',
        'members': ['EPHX1', 'EPHX2'],
        'p_value': 0.0288,
        'fdr': 5.18e-04,
        'neg_log_10': 1.54
    },
    {
        'id': 'GO:0008076',
        'name': 'voltage-gated potassium channel complex',
        'subtree': 'cellular_component',
        'definition': 'A protein complex that forms a transmembrane channel through which potassium ions may cross a cell membrane in response to changes in membrane potential. [GOC:mah]',
        'members': ['KCNJ3', 'KCNA3', 'KCNJ4', 'KCNA5'],
        'p_value': 0.0291,
        'fdr': 6.79e-04,
        'neg_log_10': 1.53
    }]

export const KEGG_DATA = [
    {
        ec: '1.14.14.1',
        pathways: [
            {
                pathway: '00590',
                name: 'Fatty acid degradation',
                class: 'Metabolism; Lipid metabolism'
            },
            {
                pathway: '00140',
                name: 'Steroid hormone biosynthesis',
                class: 'Metabolism; Lipid metabolism'
            },
            {
                pathway: '00232',
                name: 'Caffeine metabolism',
                class: 'Metabolism; Biosynthesis of other secondary metabolites'
            },
            {
                pathway: '00380',
                name: 'Tryptophan metabolism',
                class: 'Metabolism; Amino acid metabolism'
            },
            {
                pathway: '00590',
                name: 'Arachidonic acid metabolism',
                class: 'Metabolism; Lipid metabolism'
            },
            {
                pathway: '00591',
                name: 'Linoleic acid metabolism',
                class: 'Metabolism; Lipid metabolism'
            }
        ]
    }
]

