import React from 'react'
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    title: {
        color: 'white'
    },
    paper: {
        background: 'none',
        textAlign: 'center',
        paddingLeft: 5,
        display: 'inline-block',
        paddingRight: 5
    }
})

const Title = ({ title }) => {
    const classes = useStyles()
    return (<Paper className={classes.paper} elevation={3}>
        <Typography className={classes.title} variant="h2" component="h2">
            {title}
        </Typography>
    </Paper>)
}

export default Title
