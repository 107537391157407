import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles"
import Fab from "@material-ui/core/Fab"
import BackIcon from '@material-ui/icons/ArrowBackOutlined';
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: 100,
        left: 5,
    },
}))


const BackNav = () => {
    const classes = useStyles()
    let history = useHistory()

    return (<div className={classes.root}>
        <Fab color="primary" aria-label="back" onClick={() => history.goBack()}>
            <BackIcon />
        </Fab>
    </div>)
}

export default BackNav
