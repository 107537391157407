export const COMPOUNDS = [
  {"smile": "CC(C)(C)C(O)C(=Cc1ccc(Cl)cc1Cl)n1cncn1", "prob": 0.9446751, "inScreen": true},
  {"smile": "CC(C1CC1)C(O)(Cn1cncn1)c1ccc(Cl)cc1", "prob": 0.9428185, "inScreen": false},
  {"smile": "COc1ccc(C(=CC(=O)N2CCOCC2)c2ccc(Cl)cc2)cc1OC", "prob": 0.9407512, "inScreen": true},
  {"smile": "CC1(C)C(C=C(Cl)Cl)C1C(=O)OCc1c(F)c(F)cc(F)c1F", "prob": 0.93388367, "inScreen": false},
  {"smile": "CSC1=NC(C)(c2ccccc2)C(=O)N1Nc1ccccc1", "prob": 0.9291553, "inScreen": true},
  {"smile": "CC(C)(C)C(O)C(Oc1ccc(Cl)cc1)n1cncn1", "prob": 0.92698306, "inScreen": true},
  {"smile": "CC(C)C(=O)Nc1ccc(Nc2ccccc2)cc1", "prob": 0.9245004, "inScreen": false},
  {"smile": "Cc1c(F)c(F)c(COC(=O)C2C(C=C(Cl)C(F)(F)F)C2(C)C)c(F)c1F", "prob": 0.9239533, "inScreen": false},
  {"smile": "O=C(Nc1ccc(Nc2ccccc2)cc1)C1CC1", "prob": 0.92358506, "inScreen": false},
  {"smile": "COc1cc(C(=O)NC2CCN(C)CC2)ccc1N=c1nc2c(c[nH]1)N(C)C(=O)C(F)(F)CN2C1CCCC1", "prob": 0.922587, "inScreen": true},
  {"smile": "CC(C)(C)C(O)C(Cc1ccc(Cl)cc1)n1cncn1", "prob": 0.9183515, "inScreen": false},
  {"smile": "CCC(C)N(C)C(=O)c1cc2ccccc2c(-c2ccccc2Cl)n1", "prob": 0.9176917, "inScreen": true},
  {"smile": "CS(=O)(=O)c1ccc(Nc2ccc(F)cc2)cc1", "prob": 0.9151787, "inScreen": false},
  {"smile": "COc1cc(C(C)C)c2c(c1)S(=O)(=O)N(COc1cc(=O)n3cccc(OCCN4CCCCC4)c3n1)C2=O", "prob": 0.91511554, "inScreen": false},
  {"smile": "FC(F)C(F)(F)OCC(Cn1cncn1)c1ccc(Cl)cc1Cl", "prob": 0.9139119, "inScreen": false},
  {"smile": "Fc1ccc(C2(Cn3cncn3)OC2c2ccccc2Cl)cc1", "prob": 0.9129504, "inScreen": false},
  {"smile": "CCCC(Cn1cncn1)c1ccc(Cl)cc1Cl", "prob": 0.91082853, "inScreen": false},
  {"smile": "C[Si](Cn1cncn1)(c1ccc(F)cc1)c1ccc(F)cc1", "prob": 0.9101483, "inScreen": false},
  {"smile": "CN1C(=O)N(c2ccccc2Br)Cc2c[nH]c(=Nc3ccc4c(c3)OCC(CO)O4)nc21", "prob": 0.9049294, "inScreen": false},
  {"smile": "CCC(C)C(=O)OC1CC(C)C=C2C=CC(C)C(CCC3CC(O)CC(=O)O3)C21", "prob": 0.90428805, "inScreen": false},
  {"smile": "CCC(C)(C)C(=O)OC1CC(C)C=C2C=CC(C)C(CCC3CC(O)CC(=O)O3)C21", "prob": 0.90428805, "inScreen": false},
  {"smile": "CCC(C)C(=O)OC1CCC=C2C=CC(C)C(CCC3CC(O)CC(=O)O3)C21", "prob": 0.90428805, "inScreen": false},
  {"smile": "CCCCC(C#N)(Cn1cncn1)c1ccc(Cl)cc1", "prob": 0.9018747, "inScreen": false},
  {"smile": "COc1cc(C(=O)NC2CCN(C)CC2)ccc1N=c1nc2c(c[nH]1)N(C)C(=O)CC(C)N2C1CCCC1", "prob": 0.89879227, "inScreen": false},
  {"smile": "O=C(OC1CN2CCC1CC2)N1CCc2ccccc2C1c1ccccc1", "prob": 0.89727503, "inScreen": false},
]
