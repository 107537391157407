import { makeStyles } from "@material-ui/styles"
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from "react"
import { useHistory } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMortarPestle, faPills, faPrescriptionBottle } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles({
    card: {
        maxWidth: 345,
        padding: 0,
        height: 120
    },
    cardContent: {
        padding: 0
    },
    button: {
        color: '#7a7a7a'
    },
    cardHeader: {
        color: 'white',
        width: '100%',
        height: 30,
        background: '#7d7d7d',
        paddingTop: 3,
        paddingLeft: 65

    },
    cardColor: {
        float: 'left',
        width: 60,
        height: 123,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 34

    },
    cardStatus: {
        height: 50,
        paddingTop: 30,
        textAlign: 'center'
    },
    cardStatusText: {
        textTransform: 'uppercase'
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
})

const STATUS_COLORS = {
    'running': '#55afbf',
    'created': '#b1b1b1',
    'error': '#EB2E21',
    'completed': '#15c55a'
}

const BUTTON_TEXT = {
    'running': 'View Progress',
    'created': 'Review Project',
    'error': 'View Error',
    'completed': 'View Results'
}

const REDIRECTION_LOCATIONS = {
    'running': '/progress',
    'created': '/project',
    'error': '/error',
    'completed': '/results'
}

const ICON_MAP = {
    'running': faPills,
    'created': faMortarPestle,
    'error': '/error',
    'completed': faPrescriptionBottle
}

const ProjectCard = ({ title, status, id }) => {

    const classes = useStyles()
    let history = useHistory()


    return (
        <Card className={classes.card}>
            <div className={classes.cardColor} style={{ background: STATUS_COLORS[status] }}>
                <FontAwesomeIcon size="3x" icon={ICON_MAP[status]} color="#202020" spin={status === 'running'} />
            </div>
            <CardContent className={classes.cardContent}>
                <div className={classes.cardHeader}>
                    <Typography gutterBottom variant="subtitle1" component="p">
                        {title}
                    </Typography>
                </div>
                <div className={classes.cardStatus}>
                    <Typography className={classes.cardStatusText} variant="body1" color="textSecondary" component="p">
                        {status}
                    </Typography>
                </div>

            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button size="small" color="primary" className={classes.button}
                        onClick={() => history.push(`${REDIRECTION_LOCATIONS[status]}/${id}`)}>
                    {BUTTON_TEXT[status]}
                </Button>
            </CardActions>
        </Card>
    )
}

export default ProjectCard
