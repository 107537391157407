import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import React from 'react'

const useStyles = makeStyles(theme => ({
    footer: {
        textAlign: 'center',
        marginBottom: 10,
        marginTop: 25,
        position: 'fixed',
        bottom: 5
    }
}))

const Footer = () => {
    const classes = useStyles()

    const now = new Date()

    return (
        <Container className={classes.footer}>
            <Link href='https://www.biosymetrics.com'>&copy;BioSymetrics {now.getFullYear()}</Link>
        </Container>
    )
}

export default Footer
