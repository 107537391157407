import Typography from "@material-ui/core/Typography"
import React, { useState } from "react"
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from "@material-ui/core/Box"
import { a11yProps, TabPanel } from "../../components/Tabs"
import { Bar, BarChart, CartesianGrid, Cell, Label, Legend, Tooltip as ChartTooltip, XAxis, YAxis } from "recharts"
import { GO_DATA } from "../../data/pathway"
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'
import Link from "@material-ui/core/Link"
import { TARGET_INFO } from "../../data/target"
import Divider from "@material-ui/core/Divider"
import GeneInformation from "./GeneInformation"
import Collapse from "@material-ui/core/Collapse"
import makeStyles from "@material-ui/core/styles/makeStyles"

const GO_FILL_MAPPING = {
    cellular_component: '#55AFBF',
    molecular_function: '#F9AA16',
    biological_process: '#15c55a'
}

const SELECTED_BAR_COLOR = '#000'

const useStyles = makeStyles(theme => ({
    link: {
        color: '#556cd6'
    },
    selectedLink: {
        color: '#19857b'
    },
    chartWrapper: {
        background: 'white',
        height: 'fit-content',
        borderRadius: 30,
        width: 'fit-content'
    },
}))

const CustomTooltipContent = props => {
    // payload[0] doesn't exist when tooltip isn't visible
    if (props.payload[0] != null) {
        debugger
        props.payload[0].name='FDR'
        props.payload[0].value=props.payload[0].payload.fdr.toExponential()
        return <DefaultTooltipContent {...props} label={`${props.payload[0].payload.name}`} />
    }

    // we just render the default
    return <DefaultTooltipContent {...props} />
}


const GoLegendMaker = ({ color }) => <span style={{
    width: 15,
    height: 12,
    background: color,
    marginRight: 5,
    display: "inline-block",
}} />

const renderGoLegend = (setFilter, currentFilter) => (props) => {


    const legendStyle = labelFilter => ({
        cursor: 'pointer',
        fontWeight: currentFilter === labelFilter ? 'bold' : 'normal'
    })

    return (
        <ul style={{ listStyleType: "none" }}>
            <li key={`item-1`} style={legendStyle('cellular_component')} onClick={() => setFilter('cellular_component')}><GoLegendMaker color={GO_FILL_MAPPING['cellular_component']} />Cellular Component</li>
            <li key={`item-2`} style={legendStyle('molecular_function')} onClick={() => setFilter('molecular_function')}><GoLegendMaker color={GO_FILL_MAPPING['molecular_function']} />Molecular Function</li>
            <li key={`item-3`} style={legendStyle('biological_process')} onClick={() => setFilter('biological_process')}><GoLegendMaker color={GO_FILL_MAPPING['biological_process']} />Biological Process</li>
            <li key={`item-4`} style={legendStyle('all')} onClick={() => setFilter('all')}><GoLegendMaker color='#333333' />All</li>
        </ul>
    )
}

const Members = ({ members, setSelectedSymbol, selectedSymbol }) => {
    const classes = useStyles()
    return (<Box m={2}>
        <Typography variant="h5" component="h5" gutterBottom>
            Members
        </Typography>

        {members.map(member =>
            <Typography variant="body1" component="p">
                <Link
                    className={member === selectedSymbol ? classes.selectedLink : classes.link}
                    href=""
                    color={member === selectedSymbol ? 'secondary' : 'primary'}
                    onClick={(e) => {
                        setSelectedSymbol(member)
                        e.preventDefault()
                    }}>{member} ({TARGET_INFO[member].name})</Link>
            </Typography>
        )}
    </Box>)
}

const GoTerm = ({ goTerm }) => {

    // Keep track of the previous go term to prevent the collapse animation from looking funny
    const [lastGoTerm, setLastGoTerm] = useState()
    const [selectedSymbol, setSelectedSymbol] = useState()
    const classes = useStyles()

    let goTermToRender

    // If there is no go term render the last go term while the animation finishes (if there was one) otherwise just
    // render a large white div - this is required so the first expand animation works properly
    if (!goTerm && !lastGoTerm) {
        return <div style={{ height: 1000 }}></div>
    } else if (goTerm && (!lastGoTerm || goTerm.name !== lastGoTerm.name)) {
        setLastGoTerm(goTerm)
        setSelectedSymbol()
        goTermToRender = goTerm
    } else {
        goTermToRender = lastGoTerm
    }

    return (
        <div>
            <Box m={2}>
                <Typography variant="h4" component="h4" gutterBottom>
                    {goTermToRender.name}
                </Typography>
                <Typography variant="subtitle1" component="p" gutterBottom>
                    <Link
                        className={classes.link}
                        target="_blank"
                        href={`http://amigo.geneontology.org/amigo/term/${goTermToRender.id}`}>{goTermToRender.id}</Link>
                </Typography>
                <Typography variant="body2" component="p" gutterBottom>
                    FDR: {goTermToRender.fdr.toExponential()}
                    <br />
                    -log<sub>10</sub> (FDR): {goTermToRender.neg_log_10}
                </Typography>
                <Typography variant="body2" component="p" gutterBottom>
                    {goTermToRender.definition}
                </Typography>


            </Box>
            <Divider />
            <Members members={goTermToRender.members} setSelectedSymbol={setSelectedSymbol}
                     selectedSymbol={selectedSymbol} />
            <Divider />
            <Collapse timeout={1000} in={selectedSymbol}>
                <GeneInformation symbol={selectedSymbol} />
            </Collapse>
        </div>
    )
}

const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
        </g>
    )

}

const CustomLabel = () => {
    return (<text x="15" y="147.5" transform="rotate(-90, 15, 147.5)" className="recharts-text recharts-label"
                  text-anchor="end">
        <tspan>
            -log
            <tspan baseline-shift = "sub">
                10
            </tspan>
            (FDR)
        </tspan>
    </text>)
}

const GoEnrichment = () => {

    const classes = useStyles()
    const [selectedGoTerm, setSelectedGoTerm] = useState()
    const [filter, setFilter] = useState('all')

    const onBarClick = goTerm => {
        if (selectedGoTerm === goTerm) {
            setSelectedGoTerm(undefined)
        } else {
            setSelectedGoTerm(goTerm)
        }
    }

    // Copy GO_DATA to prevent it from being modified
    let displayed_go_terms = [...GO_DATA]
    if (filter !== 'all') {
        displayed_go_terms = displayed_go_terms.filter(go_term => go_term.subtree === filter)
    }

    if(displayed_go_terms.length > 7) {
        displayed_go_terms = displayed_go_terms.splice(0, 8)
    }

    const goTerm = selectedGoTerm ? GO_DATA.find(element => element.id === selectedGoTerm) : null

    return (
        <Box>
            <Typography variant="h4" component="h4" gutterBottom>
                GO Enrichment
            </Typography>
            <Box m={2} p={3} className={classes.chartWrapper}>
                <BarChart
                    width={800}
                    height={400}
                    data={displayed_go_terms}
                    margin={{
                        top: 5, right: 10, left: 20, bottom: 80,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="id" tick={<CustomizedAxisTick />} interval={0}>
                    </XAxis>
                    <YAxis>
                        <Label content={<CustomLabel />} angle={-90} position="left" id={'y-axis'} />
                        {/*<Label value='look here' angle={-90} position="left" id={'y-axis'} />*/}
                    </YAxis>
                    <ChartTooltip content={<CustomTooltipContent />} />
                    <Legend content={renderGoLegend(setFilter, filter)} layout="vertical" verticalAlign="center" align="right" />
                    <Bar dataKey="neg_log_10" onClick={x => onBarClick(x.id)}>
                        {
                            displayed_go_terms.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                      stroke={SELECTED_BAR_COLOR}
                                      strokeWidth={entry.id === selectedGoTerm ? 2 : 0}
                                      fill={GO_FILL_MAPPING[entry.subtree]}
                                />
                            ))
                        }
                    </Bar>
                </BarChart>
            </Box>

            <Collapse timeout={1000} in={selectedGoTerm}>
                <GoTerm goTerm={goTerm} />
            </Collapse>

        </Box>)
}


const EnrichmentTabs = () => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="GO" {...a11yProps(0)} />
                <Tab label="PFAM" {...a11yProps(1)} disabled />
            </Tabs>
            <TabPanel value={value} index={0}>
                <GoEnrichment />
            </TabPanel>
            <TabPanel value={value} index={1}>

            </TabPanel>
        </div>
    )
}

const AnnotationEnrichment = () => {
    return (<div>
            <Typography variant="h3" component="h3" gutterBottom>
                Annotation Enrichments
            </Typography>
            <Typography variant="body2" component="p" gutterBottom>
                These Annotations are significantly enriched in the compounds most likely to be hits for the Phenotype
                of interest. They may be describing attributes of the Mechanism of Action for the phenotype, such as
                pathways, cellular components, or molecular functions involved in the phenotype.
            </Typography>
            <EnrichmentTabs />
        </div>

    )
}

export default AnnotationEnrichment
