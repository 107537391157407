import Typography from "@material-ui/core/Typography"
import React, { useState } from "react"
import { Bar, BarChart, CartesianGrid, Cell, Label, Tooltip as ChartTooltip, XAxis, YAxis, } from 'recharts'

import { SYMBOL_DATA } from '../../data/target'
import GeneInformation from "./GeneInformation"
import Collapse from "@material-ui/core/Collapse"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Box from "@material-ui/core/Box"


const useStyles = makeStyles(theme => ({
    chartWrapper: {
        background: 'white',
        height: 338,
        borderRadius: 30,
        width: 'fit-content'
    }
}))

const TargetEnrichment = () => {

    const classes = useStyles()

    const [selectedSymbol, setSelectedSymbol] = useState()

    const onBarClick = symbol => {
        if (selectedSymbol === symbol) {
            setSelectedSymbol(undefined)
        } else {
            setSelectedSymbol(symbol)
        }
    }

    return (
        <div>
            <Typography variant="h3" component="h3" gutterBottom>
                Target Enrichments
            </Typography>
            <Typography variant="body2" component="p" gutterBottom>
                These Targets are significantly enriched in the compounds most likely to be hits for the Phenotype of
                interest. These targets may be important to the Mechansim of Action for the phenotype, as they may
                encode proteins, enzymes, or subunits relevant to the pathway of interest.
            </Typography>
            <Box m={2} p={3} className={classes.chartWrapper}>
                <BarChart
                    width={600}
                    height={300}
                    data={SYMBOL_DATA}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="symbol">
                        <Label value="Symbol" offset={5} position="bottom" id='x-axis' />
                    </XAxis>
                    <YAxis type="number" domain={[.90, 1]}>
                        <Label value="Probability" angle={-90} position="left" id={'y-axis'} />
                    </YAxis>
                    <ChartTooltip />

                    <Bar dataKey="prob" onClick={x => onBarClick(x.symbol)}>
                        {
                            SYMBOL_DATA.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                      fill={entry.symbol === selectedSymbol ? "#F9AA16" : "#55AFBF"}
                                />
                            ))
                        }
                    </Bar>

                </BarChart>
            </Box>

            <Collapse
                timeout={1000} in={selectedSymbol}>
                <GeneInformation
                    symbol={selectedSymbol}
                />
            </Collapse>

        </div>
    )
}

export default TargetEnrichment
