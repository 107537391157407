import React, { useState } from 'react'
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/styles"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import { Redirect } from "react-router-dom"
import Footer from "../components/Footer"


const useStyles = makeStyles(theme => ({
    loginButtons: {
        width: '350px',
        margin: 5
    },
    loginArea: {
        ...theme.mainSection,
        ...theme.whiteBorder
    },
    header: {
        textAlign: 'center',
        marginTop: 100
    },
    logo: {
        maxWidth: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 448
        }
    },
    forgotPasswordLink: {
        color: '#FFF'
    }
}))


const Login = () => {

    const classes = useStyles()
    const [redirect, setRedirect] = useState()
    const [userName, setUsername] = useState()
    const [password, setPassword] = useState()

    const submit = (e) => {
        if (userName === 'gabe@biosymetrics.com' && password === 'zebraFish!') {
            setRedirect(true)
        } else if(userName === 'demo@biosymetrics.com' && password === 'zebraFish!') {
            setRedirect(true)
        } else {
            alert("Invalid username and password")
        }

        e.preventDefault()
    }

    if (redirect) {
        return <Redirect to="landing" />
    }

    return (
        <Box>
            <div className={classes.header}>
                <a href='/'><img src='/BioS-Logo.png' alt="Biosymetrics Logo" className={classes.logo} /></a>
            </div>
            <Box component='div' m={1} className={classes.loginArea}>
                <form onSubmit={submit}>
                    <Box>
                        <TextField
                            id='outlined-email'
                            name='email'
                            label='Email'
                            margin='normal'
                            value={userName}
                            onChange={event => setUsername(event.target.value)}
                        />
                    </Box>

                    <Box>
                        <TextField
                            id='outlined-password'
                            type='password'
                            name='password'
                            label='Password'
                            margin='normal'
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                        />
                    </Box>
                    <Link className={classes.forgotPasswordLink} href='#'>Forgot your password?</Link>

                    <Button
                        variant='contained'
                        color='primary'
                        className={classes.loginButtons}
                        type='submit'
                    >
                        LOG IN
                    </Button>
                </form>
            </Box>
            <Footer/>
        </Box>
    )
}

export default Login
