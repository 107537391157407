import React from 'react'
import Container from '@material-ui/core/Container'
import Login from "./pages/Login"
import Results from "./pages/Results"

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Landing from "./pages/Landing"
import Project from "./pages/Project"
import Progress from "./pages/Progress"
import Footer from "./components/Footer"
import StepProgress from './pages/StepProgress'


export default function App() {
    return (
        <Container maxWidth="lg">
            <Router>
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/landing/">
                        <Landing />
                    </Route>
                    <Route path="/results/:id?">
                        <Results />
                    </Route>
                    <Route path="/project/:id?">
                        <Project />
                    </Route>
                    <Route path="/progress/:id?/step/:stepNumber?">
                        <StepProgress />
                    </Route>
                    <Route path="/progress/:id">
                        <Progress />
                    </Route>

                    <Route path="/">
                        <Login />
                    </Route>
                </Switch>
            </Router>
        </Container>
    )
}
